import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const MarksConverter = () => {
  const [adekFile, setAdekFile] = useState(null);
  const [nceFile, setNceFile] = useState(null);

  const handleFileUpload = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const processFiles = async () => {
    if (!adekFile || !nceFile) {
      alert("Please upload both files.");
      return;
    }

    const adekData = await readFile(adekFile);
    const nceData = await readFile(nceFile);

    const updatedNceData = replaceSubjects(nceData);
    const adekWithKeys = addKeys(adekData, "PUPIL NUMBER", "SUBJECT");
    const nceWithKeys = addKeys(updatedNceData, "ESIS Number", "Subject");

    const updatedAdekData = updateAdekMarks(adekWithKeys, nceWithKeys);
    const finalAdekData = removeKeyColumn(updatedAdekData);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalAdekData, {
      skipHeader: false,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Updated Marks");

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "updated_adek.xlsx"
    );
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet, { defval: "" }); // Ensure blank cells are not skipped
        resolve(json);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(file);
    });
  };

  const replaceSubjects = (nceData) => {
    nceData.forEach((entry) => {
      if (entry["Grade"] === 7 || entry["Grade"] === 8) {
        if (entry["Subject"] === "English") {
          entry["Subject"] = "English Language";
        } else if (entry["Subject"] === "Maths") {
          entry["Subject"] = "Mathematics ";
        } else if (entry["Subject"] === "Science") {
          entry["Subject"] = "Science";
        } else if (entry["Subject"] === "Arabic") {
          entry["Subject"] = "Arabic Language";
        } else if (entry["Subject"] === "Islamic Studies") {
          entry["Subject"] = "Islamic Education";
        } else if (entry["Subject"] === "Social Studies") {
          entry["Subject"] = "UAE Social Studies";
        } else if (entry["Subject"] === "PE") {
          entry["Subject"] = "Physical Education";
        } else if (entry["Subject"] === "ICT") {
          entry["Subject"] = "Information and Communication Technology (ICT)";
        } else if (entry["Subject"] === "Moral Education") {
          entry["Subject"] = "Moral Education";
        }
      }
      if (entry["Subject"] === "History") {
        entry["Subject"] = "World History";
      }
      if (entry["Subject"] === "Technology") {
        entry["Subject"] = "Computer Science";
      }
      if (entry["Subject"] === "English 1") {
        entry["Subject"] = "English Language 1";
      }
      if (entry["Subject"] === "Social Studies") {
        entry["Subject"] = "UAE Social Studies";
      }
      if (entry["Subject"] === "English 2") {
        entry["Subject"] = "English Language 2";
      }

      if (entry["Grade"] === 9) {
        if (entry["Subject"] === "Arabic") {
          entry["Subject"] = "Arabic Language 1";
        } else if (entry["Subject"] === "Islamic Studies") {
          entry["Subject"] = "Islamic Education 1";
        }
      } else if (entry["Grade"] === 10) {
        if (entry["Subject"] === "Arabic") {
          entry["Subject"] = "Arabic Language 2";
        } else if (entry["Subject"] === "Islamic Studies") {
          entry["Subject"] = "Islamic Education 2";
        }
      }
    });

    return nceData;
  };

  const addKeys = (data, numberField, subjectField) => {
    return data.map((entry) => ({
      ...entry,
      key: `${entry[numberField]}_${entry[subjectField]}`,
    }));
  };

  const updateAdekMarks = (adekData, nceData) => {
    const nceMap = {};

    // Create a map of the NCE data
    nceData.forEach((entry) => {
      nceMap[entry.key] = entry["mark"];
    });

    // Update ADEK data using the map
    adekData.forEach((entry) => {
      if (nceMap[entry.key] !== undefined) {
        entry["MARK"] = nceMap[entry.key]; // Ensure the "MARK" column is properly populated
      }
    });

    return adekData;
  };

  const removeKeyColumn = (data) => {
    return data.map(({ key, ...rest }) => rest);
  };

  return (
    <div>
      <div>
        <label>
          ADEK Sheet
          <input
            type="file"
            onChange={(e) => handleFileUpload(e, setAdekFile)}
          />
        </label>
      </div>
      <div>
        <label>
          NCE Sheet
          <input
            type="file"
            onChange={(e) => handleFileUpload(e, setNceFile)}
          />
        </label>
      </div>
      <button onClick={processFiles}>Process Files</button>
    </div>
  );
};

export default MarksConverter;
