import React, { useState, useEffect } from 'react';
import generalQuery from '../generalQuery';
import './InterStudent.css'; // Ensure to create the corresponding CSS
import datesArr from "./intervTimetable";

const InterStudent = () => {
    const [view, setView] = useState('byEsis'); // Default view
    const [searchTerm, setSearchTerm] = useState(''); // Search term for either esis or class
    const [classes, setClasses] = useState([]); // Store unique classes
    const [data, setData] = useState([]); // Store all fetched data

    useEffect(() => {
        // Fetch data from interventiongroups25
        generalQuery('SELECT * FROM interventiongroups25').then((results) => {
            setData(results);

            // Extract unique class values and sort them
            const uniqueClasses = [...new Set(results.map(item => item.class))].sort((a, b) => a.localeCompare(b));
            setClasses(uniqueClasses);
        });
    }, []);

    const handleEsisSearch = (event) => {
        setSearchTerm(event.target.value);
        setView('byEsis');
    };

    const handleClassSearch = (event) => {
        setSearchTerm(event.target.value);
        setView('byClass');
    };

    const renderEsisView = () => {
        // Find the student based on the search term (esis)
        const student = data.find(item => item['esis#'] === searchTerm);
        
        if (!student) {
            return <p>No student found for the given eSIS number.</p>;
        }

        // Get the student's timetable
        const studentTimetable = datesArr
            .filter(date => date.cluster === student.cluster)
            .map((date, index) => ({
                session: `Session ${index + 1}`,
                date: date.date
            }));

        return (
            <div className="esis-view">
                <div className="student-details">
                    <p><strong>Student Name:</strong> {student.name}</p>
                    <p><strong>Class:</strong> {student.class}</p>
                    <p><strong>Group Name:</strong> {student.group}</p>
                    <p><strong>Subject:</strong> {student.subject}</p>
                    <p><strong>Teacher:</strong> {student.teacher}</p>
                    <p><strong>Room:</strong> {student.room}</p>
                </div>
                <div className="timetable">
                    <h4>Timetable</h4>
                    {studentTimetable.length > 0 ? (
                        <ul>
                            {studentTimetable.map((session, index) => (
                                <li key={index}>
                                    <strong>{session.session}:</strong> {session.date}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No timetable available.</p>
                    )}
                </div>
            </div>
        );
    };

    const renderClassView = () => {
        // Filter the data based on the selected class
        const filteredStudents = data.filter(item => item.class === searchTerm&&item.teacher!==null);

        return filteredStudents.length > 0 ? (
            <div className="class-view">
                <table>
                    <thead>
                        <tr>
                            <th>eSIS</th>
                            <th>Name</th>
                            <th>Subject</th>
                            <th>Group</th>
                            <th>Cluster</th>
                            <th>Teacher</th>
                            <th>Room</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStudents.map((student, index) => (
                            <tr key={index}>
                                <td>{student['esis#']}</td>
                                <td>{student.name}</td>
                                <td>{student.subject}</td>
                                <td>{student.group}</td>
                                <td>{student.cluster}</td>
                                <td>{student.teacher}</td>
                                <td>{student.room}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <p>No students found for the selected class.</p>
        );
    };

    return (
        <div className="inter-student-container">
            <div className="search-bar">
                <input 
                    type="text" 
                    placeholder="Search by eSIS" 
                    onChange={handleEsisSearch} 
                />
                <select onChange={handleClassSearch}>
                    <option value="">Select Class</option>
                    {classes.map((className, index) => (
                        <option key={index} value={className}>
                            {className}
                        </option>
                    ))}
                </select>
            </div>

            {view === 'byEsis' && renderEsisView()}
            {view === 'byClass' && renderClassView()}
        </div>
    );
};

export default InterStudent;
