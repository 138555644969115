import jsPDF from "jspdf";
import reportHead from "../reportHeadT12223.png";
import signature from "../signature.png";
import stamp from "../stamp.png";
// import reportTerm1CoverLetter from "../reportTerm1CoverLetter.png";
import conversion from "../conversion.png";
// import reportTerm1CoverLetter2 from "../reportTerm1CoverLetter2.png";
import { font } from "../font.js";
const endOfYearCard = (x, b, data) => {
  const getScore = (valForScore) => {
    if (valForScore >= 97) {
      return "A+";
    } else if (valForScore >= 93 && valForScore < 97) {
      return "A";
    } else if (valForScore >= 90 && valForScore < 93) {
      return "A-";
    } else if (valForScore >= 87 && valForScore < 90) {
      return "B+";
    } else if (valForScore >= 83 && valForScore < 87) {
      return "B";
    } else if (valForScore >= 80 && valForScore < 83) {
      return "B-";
    } else if (valForScore >= 77 && valForScore < 80) {
      return "C+";
    } else if (valForScore >= 73 && valForScore < 77) {
      return "C";
    } else if (valForScore >= 70 && valForScore < 73) {
      return "C-";
    } else if (valForScore >= 67 && valForScore < 70) {
      return "D+";
    } else if (valForScore >= 63 && valForScore < 67) {
      return "D";
    } else if (valForScore >= 60 && valForScore < 63) {
      return "D-";
    } else if (valForScore < 60) {
      return "F"
    }
  };
  let termAr = " نهاية العام الدراسي ";
  let termEn = "ُEnd of Year ";
  var doc = new jsPDF("", "", "", true);
 
  doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
  doc.addFont(
    "IBMPlexSansArabic-Medium-normal.ttf",
    "IBMPlexSansArabic-Medium",
    "normal"
  );
  doc.setFont("IBMPlexSansArabic-Medium"); // set font
  const singleReport = (x) => {
    doc.setFontSize(15);
    var width = doc.internal.pageSize.getWidth();
    doc.addImage(reportHead, 5, 5, 200, 38);
    doc.setLineWidth(1);
    doc.line(5, 43, width - 5, 43);
    doc.setFillColor(0, 105, 184);
    doc.line(5, 88, width - 5, 88);
    doc.setTextColor(0, 0, 0);
    doc.line(5, 43, 5, 88);
    doc.line(width - 5, 43, width - 5, 88);

    doc.line(5, 220, 5, 280);
    doc.line(width - 5, 220, width - 5, 280);
    doc.line(5, 220, width - 5, 220);
    doc.line(5, 280, width - 5, 280);

    doc.setFontSize(12);
    doc.text("Reporting Period: ", 7, 50);
    doc.text(":الفترة الاختبارية ", 178, 50);
    doc.text("Student Name: ", 7, 58);
    doc.text(":اسم الطالب", 183, 58);
    doc.text("Student ID: ", 7, 72);
    doc.text(":رقم الطالب  ", 183, 72);
    doc.text("Grade: ", 7, 80);
    doc.text(":الصف  ", 190, 80);
    doc.setTextColor(0, 0, 0);
    doc.text("Date: ", 10, 230);
    doc.text(" التاريخ ", width - 10, 230, { align: "right" });
    doc.text("Approved / School Principal: ", 10, 240);
    doc.text(" يعتمد / مديرالمدرسة ", width - 10, 240, { align: "right" });
    doc.setFontSize(10);
    doc.text(termEn, 47, 50);
    doc.text(termAr, 150, 50);
    doc.text(x.name, 47, 58);
    doc.text(x.arabicname, 100, 62);
    doc.text(x.esis, 47, 72);
    doc.text(x.esis, 150, 72);
    doc.text(x.homeroom, 100, 80);
    doc.text(new Date().toDateString(), 80, 230);
    doc.text("Hassan Al Yahri", 80, 250);
    doc.addImage(signature, 80, 253, 20, 20);
    doc.addImage(stamp, 106, 246, 30, 30);
    doc.setLineWidth(0.1);

    if (x.block === "no") {

      doc.line(5, 92, 5, 197);
      doc.line(width - 5, 92, width - 5, 197);
      doc.line(70, 92, 70, 197);
      doc.line(108, 92, 108, 197);
      doc.line(108.5, 92, 108.5, 197);
      doc.line(170, 92, 170, 197);

      doc.setFillColor(0, 105, 184);
      doc.rect(5, 92, width - 10.1, 14.5, "F");
      const column1 = [
        "Subject",
        "Islamic Education",
        "Arabic Language",
        "Mathematics ",
        "English Language",
        "Science",
        x.eco ? "Financial Literacy" : "Music",
        "Subject",
        "ICT",
        "Moral Education",
        "Art",
        "Physical Ed",
        "UAE Social Studies",
        x.gov ? "History" : "Drama",
      ];
      const column2 = [
        "المادة",
        "التربية الاسلامية",
        "اللغة العربية",
        "الرياضيات",
        "الانجليزية",
        "العلوم",
        x.eco ? "مبادئ مالية" : "الموسيقي",
        "المادة",
        "تكنولوجبا المعلومات و التواصل",
        "التربية الأخلاقية",
        "الفن",
        "التربية الرياضية",
        "الاجتماعيات",
        x.gov ? "التاريخ" : "الدراما",
      ];
      const values = [
        "Grade",
        getScore(+x.islamic),
        getScore(+x.arabic),
        getScore(+x.math),
        getScore(+x.english),
        getScore(+x.science),
        x.eco ? getScore(+x.eco) : getScore(+x.music),
        "Grade",
        getScore(+x.comp),
        getScore(+x.ss),
        getScore(+x.art),
        getScore(+x.pe),
        getScore(+x.ss),
        x.gov ? getScore(x.gov) : getScore(+x.drama),
      ];
      for (var i = 1; i < 8; ++i) {
        if (i < 7) {
          doc.setFillColor(182, 229, 248);
          if (b === "individual") {
            doc.rect(5.5, 92 + i * 15, 64, 14.5, "F");
          }
        }
        if (i > 1) {
          doc.setTextColor(0, 0, 0);
        } else {
          doc.setTextColor(255, 255, 255);
        }
        doc.setFontSize(10);
        doc.line(5, 92 + i * 15, width - 5, 92 + i * 15);
        doc.text(column1[i - 1], 9, 90 + i * 14);
        doc.text(column2[i - 1], 68, 90 + i * 14, { align: "right" });
        if (i !== 1) {
          doc.setFontSize(12);
        }
        if (values[i - 1] === "F") {
          doc.setTextColor(255, 0, 0);
        }
        if (values[i - 1] && values[i - 1] !== "null") {
          doc.text(values[i - 1], 88, 90 + i * 14);
        }
      }

      for (var i = 8; i < 15; ++i) {
        if (i < 14) {
          doc.setFillColor(182, 229, 248);
          if (b === "individual") {
            doc.rect(108.6, 77.2 + (i - 6) * 15, 61, 14.3, "F");
          }
        }
        if (i === 8) {
          doc.setTextColor(255, 255, 255);
        } else {
          doc.setTextColor(0, 0, 0);
        }
        doc.setFontSize(10);
        doc.text(column1[i - 1], 112, 90 + (i - 7) * 14);
        doc.text(column2[i - 1], 169, 90 + (i - 7) * 14, { align: "right" });
        if (i !== 8) {
          doc.setFontSize(12);
        }
        if (values[i - 1] === "F") {
          doc.setTextColor(255, 0, 0);
        }
        if (values[i - 1] && values[i - 1] !== "null") {
          doc.text(values[i - 1], 186, 90 + (i - 7) * 14);
        }
      }
    } else {
      doc.text(
        // "Please contact the school in the beginning of next term!",
        "Please contact the school!",
        70,
        150
      );
      doc.text(
        // "يرجي التواصل مع ادارة المدرسة في بداية الفصل القادم",
        "يرجي التواصل مع ادارة المدرسة",
        200,
        190,
        {
          align: "right",
        }
      );
      doc.text(
        "تم حجب الشهادة بسبب عدم تحديث كل أو أحد البيانات التالية: ",
        200,
        170,
        { align: "right" }
      );
      doc.text("السكن - هوية الأم-هوية الأب - هوية الطالب", 200, 180, {
        align: "right",
      });
    }
  };
  if (b === "onefile") {
    data
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .forEach((e) => {
        singleReport(e);
        if (data.indexOf(e) + 1 !== data.length) {
          doc.addPage();
        }
      });
    doc.save(data[0].homeroom);
  } else {
    singleReport(x);
    // doc.text("نظرا لخلل فني تتم حاليا مراجعة نظام اصدار التقارير !", 150, 90, {
    //   align: "right",
    // });
    // doc.text(" نأسف عن الإزعاج وكل التوفيق لأبنائنا الطلاب ", 150, 100, {
    //   align: "right",
    // });
    doc.setFontSize(15);

    if (b === "individual") {
      doc.addPage();
      doc.addImage(conversion, 5, 5, 200, 80);
      doc.setFontSize(10);
      doc.text("Best Wishes", 100, 100);
      doc.setFontSize(15);
    }
   
    doc.save(x.esis);
  }


};

export default endOfYearCard;
