import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ClassLists from "./ClassLists";
const Example = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    return (
      <div style={{ textAlign: "center" }}>
      <button onClick={handlePrint}>طباعة PDF</button>
      <ClassLists ref={componentRef} />
    </div>
  );
};

export default Example;
