import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(comm, hw, lessons, sub) {
  return { comm, hw, lessons, sub };
}
export default function WplTable({ week, wplData, grade }) {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(wplData.filter((e) => e.week === week && e.grade === grade));
  }, []);
  const getEntries = (x) => {
    if (tableData.filter((e) => e.sub === x)[0]) {
      return tableData.filter((e) => e.sub === x)[0];
    } else {
      return "";
    }
  };
  const rows = [
    createData(
      getEntries("ar").comm,
      getEntries("ar").hw,
      getEntries("ar").lessons,
      "لغة عربية"
    ),
    createData(
      getEntries("is").comm,
      getEntries("is").hw,
      getEntries("is").lessons,
      "اسلامية"
    ),
    createData(
      getEntries("social").comm,
      getEntries("social").hw,
      getEntries("social").lessons,
      "اجتماعيات"
    ),
    createData(
      getEntries("moral").comm,
      getEntries("moral").hw,
      getEntries("moral").lessons,
      "أخلاقية"
    ),
    createData(
      getEntries("math").comm,
      getEntries("math").hw,
      getEntries("math").lessons,
      "Math"
    ),
    createData(
      getEntries("en").comm,
      getEntries("en").hw,
      getEntries("en").lessons,
      "English"
    ),
    createData(
      getEntries("science").comm,
      getEntries("science").hw,
      getEntries("science").lessons,
      "Science"
    ),
    createData(
      getEntries("dt").comm,
      getEntries("dt").hw,
      getEntries("dt").lessons,
      "Design and Technology"
    ),
    createData(
      getEntries("pe").comm,
      getEntries("pe").hw,
      getEntries("pe").lessons,
      "التربية الرياضية"
    ),
    createData(
      getEntries("art").comm,
      getEntries("art").hw,
      getEntries("art").lessons,
      "الفنون"
    ),
  ];
  let tableRows = [];
  if (tableData.length === 0) {
    return <h1>لا توجد بيانات</h1>;
  } else {
    return (
      <Container style={{ marginTop: "10%" }}>
        <div style={{ float: "right" }}>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() =>
              // printDocument(rows)
              {
                rows.map((r) => {
                  tableRows.push([r.sub, r.lessons, r.hw, r.comm]);
                });
                printDocument(tableRows,week,grade);
              }
            }
          >
           تنزيل
          </Button> */}
        </div>
        <TableContainer component={Paper} id="tblExport">
          <Table
            className={classes.table}
            aria-label="simple table"
            id="tableToPrint"
          >
            <TableHead>
              <TableRow>
                <TableCell align="right">
                  <strong>ملاحظات</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>مهام و واجبات</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {" "}
                    ماذا نتعلم؟ <br></br>What do we learn?
                  </strong>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.sub}>
                  <TableCell align="center">{row.comm}</TableCell>
                  <TableCell align="center">{row.hw}</TableCell>
                  <TableCell align="center">{row.lessons}</TableCell>
                  <TableCell align="center">{row.sub}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
