import React from "react";
import { FcDocument } from "react-icons/fc";
import { FcTemplate } from "react-icons/fc";
import { FcSearch } from "react-icons/fc";
import { FcAreaChart } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { FcPositiveDynamic } from "react-icons/fc";
import { MdEventAvailable } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
// import { getmapreport } from "../components/wpl/StudentManual/GeneratePDF";
const NavHome = ({ switcher, header }) => {
  function extractNumber(str) {
    const match = str.match(/\d+/); // Regular expression to find one or more digits
    if (match) {
      return parseInt(match[0], 10); // Convert the found string to a number
    } else {
      return null; // Return null if no digits are found
    }
  }
  return (
    <div
      style={{
        display: "flex",
        placeContent: "center",
        placeItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          switcher("progressReport");
          header(false);
        }}
      >
        <br />
        <div
          className="card card2"
          style={{
            // marginTop:"-45px",
            marginLeft: "15px",
            marginBottom: "15px",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcPositiveDynamic size={"100px"}  />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#ac590e",
              marginBottom: "15px",
            }}
          >
           Adaa' آدائي
          </span>
        </div>
      </div>
      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          switcher("myattendance");
          header(false);
        }}
      >
        <br />
        <div
          className="card card2"
          style={{
            // marginTop:"-45px",
            marginLeft: "15px",
            marginBottom: "15px",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <MdEventAvailable size={"100px"} color="#00bcd4" />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",

              marginBottom: "15px",
            }}
          >
            My Attendance حضوري
          </span>
        </div>
      </div>
      {extractNumber(sessionStorage.getItem("grade")) === 7 ||
      extractNumber(sessionStorage.getItem("grade")) === 8 ? (
        ""
      ) : (
        <div
          style={{ marginBottom: "3rem" }}
          onClick={() => {
            switcher("creditHours");
            // header(false);
          }}
        >
          <div
            className="card"
            style={{
              width: "200px",
              display: "flex",
              flexDirection: "column",
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
              cursor: "pointer",
              borderRadius: "1rem",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <AccessAlarmIcon
                style={{ color: "darkblue", fontSize: "100px" }}
              />
            </div>
            <span
              style={{
                fontWeight: "bold",
                color: "#0e80ac",
                marginBottom: "15px",
              }}
            >
              Credit Hours
            </span>
          </div>
        </div>
      )}
      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          switcher("assessmentSchedule");
          // header(false);
        }}
      >
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcCalendar size={"100px"} color="#00bcd4" />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            Assessments
          </span>
        </div>
      </div>

      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => switcher("revisionviewNew")}
      >
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcMindMap size={"100px"} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            WPL الخطة الأسبوعية{" "}
          </span>
        </div>
      </div>
      <div style={{ marginBottom: "3rem" }}>
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
          onClick={() => {
            switcher("mapreport");
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcAreaChart size={"100px"} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            MAP Report
          </span>
        </div>
      </div>
      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          switcher("scheduleView");
          // header(false);
        }}
      >
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcDocument size={"100px"} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            Reports
          </span>
        </div>
      </div>

      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          // document.getElementById("linkhjh").click();
          switcher("timetable");
        }}
      >
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcTemplate size={"100px"} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            Timetable
          </span>
        </div>
      </div>

      <div
        style={{ marginBottom: "3rem" }}
        onClick={() => {
          switcher("revisionview");
          header(false);
        }}
      >
        <div
          className="card"
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.35)",
            cursor: "pointer",
            borderRadius: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FcSearch size={"100px"} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#0e80ac",
              marginBottom: "15px",
            }}
          >
            Find Your Class
          </span>
        </div>
      </div>
    </div>
  );
};

export default NavHome;
