import ReactDom from "react-dom";
import "./portal.css";
const Portal = ({ children }) => {
  return ReactDom.createPortal(
    <div className="portal-backdrop">
      <div className="portal">{children}</div>
    </div>,
    document.body
  );
};

export default Portal;
