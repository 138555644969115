import React, { useEffect, useState } from "react";

const HomeroomTeachers = () => {
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState([]);
  const generalQuery = async (z, p) => {
    setData([]);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      for (var i = 0; i < 12; i++) {
        setCounter((counter) => [...counter, i]);
      }
      resp.forEach((d) => {
        let arr = [];
        const periods = [
          "m1",
          "m2",
          "m3",
          "m4",
          "m5",
          "m6",
          "t1",
          "t2",
          "t3",
          "t4",
          "t5",
          "t6",
          "w1",
          "w2",
          "w3",
          "w4",
          "w5",
          "w6",
          "th1",
          "th2",
          "th3",
          "th4",
          "th5",
          "th6",
          "f1",
          "f2",
          "f3",
          "f4",
        ];
        periods.forEach((p) => {
          if (d[p] !== "null" && arr.indexOf(d[p]) === -1) {
            arr.push(d[p]);
          }
        });
        setData((data) => [...data, [d.teacher, d.sub].concat(arr)]);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    generalQuery("select * from teacherssummary", "teacherssummary");
  }, []);
  return (
    <div>
      <div className="container">
        {data.length > 0 && (
          <table>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return <tr key={d[0]}>{counter.map(c=>{return<td key={d[0]+d[c]}>{d[c]}</td>})}</tr>;
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default HomeroomTeachers;
