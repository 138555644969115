import React from "react";
import MAPtimetable from "./MAPtimetable";
import TimetableNew from "./timetableNew";

const Edupage = () => {
  return (
    <div className="website-embed">
      <a href="https://amschool.edupage.org/" target="_blank" >
        Timetable
      </a>
    </div>
  );
};

export default Edupage;
