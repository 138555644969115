import React, { useEffect, useState } from "react";
import generalQuery from "./generalQuery";
import { CircularProgress } from "@material-ui/core";
import "./CreditHoursPerStudent.css";
const CreditHoursPerStudent = ({ studentnumber, selectedSub, maxGrade }) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    generalQuery(
      `select ${selectedSub} from credithours where pupilnumber='${studentnumber}'`
    ).then((results) => {
      setValue(results[0][selectedSub.toLowerCase()]);
      setLoading(true);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <>
          <>{value + "/" + maxGrade + "="}</>{" "}
          <span
            id={
              Math.ceil((value * 100) / maxGrade) > 83
                ? "greenbackground"
                : "tomatobackground"
            }
          >
            {Math.ceil((value * 100) / maxGrade) + "%"}
          </span>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default CreditHoursPerStudent;
