import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import generalQuery from "../generalQuery";
import "./TeacherDashboard.css";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Portal from "../views/Portal";
import FeedbackForm from "./FeedbackForm";
import FeedbackFormView from "./FeedbackFormView";

const TeacherDashboard = () => {
  const [students, setstudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teaacherSubject, setTeaacherSubject] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSub, setSelectedSub] = useState("");
  const [classes, setClasses] = useState([]);
  const [termTested, setTermTested] = useState("mapspring2024");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState("");
  const [loadingForTable, setLoadingForTable] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [sortStatus, setSortStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [showPortal, setShowPortal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState();
  const [showPortalView, setShowPortalView] = useState(false);
  useEffect(() => {
    generalQuery(
      `SELECT teacher, course FROM ${termTested} GROUP BY teacher, course ORDER BY teacher`
    ).then((results) => {
      setTeaacherSubject(results);
      let arr = [];
      results.forEach((r) => {
        if (arr.indexOf(r.teacher) === -1) {
          arr.push(r.teacher);
        }
      });
      setTeachers(arr);
      setLoading(false);
    });
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      color: "black",
    },
  }));
  const ViewAndEdit = ({ studentData }) => {
    const classes = useStyles();

    return (
      <div className="teacherDashboard">
        <IconButton
          aria-label="View"
          className={classes.button}
          onClick={() => {
            setSelectedStudent(studentData);
            setShowPortalView(true);
          }}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          aria-label="Edit"
          className={classes.button}
          onClick={() => {
            setSelectedStudent(studentData);
            setShowPortal(true);
          }}
        >
          {sessionStorage.getItem("teachername")?.trim() ===
            selectedTeacher && <EditIcon />}
        </IconButton>
      </div>
    );
  };

  const getData = (teacher, sub) => {
    setLoadingForTable(true);
    generalQuery(
      `select * from mapspring2024 where teacher='${teacher}' and course='${sub}' order by class`
    ).then((results) => {
      let arr = [];
      results.forEach((r) => {
        if (!arr.includes(r.class)) {
          arr.push(r.class);
        }

        setClasses(arr);
      });
      setstudents(results);
      setFilteredStudents(results);
      setLoadingForTable(false);
    });
  };
  const ratioCalculator = (c) => {
    return Math.ceil(
      (students.filter(
        (student) =>
          student.class === c &&
          (student.falltospringmetprojectedgrowth === "Yes" ||
            student.falltospringmetprojectedgrowth === "Yes*")
      ).length *
        100) /
        students.filter((student) => student.class === c).length
    );
  };
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <div className="dropdowns">
          <select
            className="dropdown"
            id="test-name"
            onChange={(e) => {
              setStatus("");
              setSelectedClass("");
              setTermTested(e.target.value);
            }}
          >
            <option value="Spring 2023-2024">Spring 23-24</option>
          </select>
          <select
            className="dropdown"
            id="teacher-name"
            onChange={(e) => {
              setStatus("");
              setSelectedClass("");
              setSelectedTeacher(e.target.value);
              let subTemp = teaacherSubject.filter(
                (ts) => ts.teacher === e.target.value
              )[0]["course"];
              getData(e.target.value, subTemp);
              setSubjects(
                teaacherSubject.filter((ts) => ts.teacher === e.target.value)
              );
              setSelectedSub(subTemp);
            }}
          >
            <option value="">----Teacher----</option>
            {teachers.map((t) => {
              return (
                <option key={t} value={t}>
                  {t}
                </option>
              );
            })}
          </select>
          <select
            className="dropdown"
            id="subject"
            onChange={(e) => {
              setStatus("");
              setSelectedClass("");
              setSelectedSub(e.target.value);
              getData(selectedTeacher, e.target.value);
            }}
          >
            {subjects.map((s) => {
              return (
                <option key={s.course} value={s.course}>
                  {s.course}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <>
        {loadingForTable ? (
          <div className="container">
            <CircularProgress />
          </div>
        ) : (
          <div className="container">
            {students.length > 0 && (
              <div className="component-container ">
                <div className="ratio-container" style={{ width: "50%" }}>
                  <table className="student-table">
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Ratio of Students Exceeded or Met their Target</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classes.map((c) => {
                        return (
                          <tr key={c}>
                            <td>{c}</td>
                            <td
                              className={
                                ratioCalculator(c) < 90
                                  ? "notMetSummary"
                                  : "metOrExceed"
                              }
                            >
                              {ratioCalculator(c)}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="percentage">
                  {/* <div className="percentage-label">Overall Ratio</div>
                  <div className="percentage-value">
                    {Math.ceil(
                      (students.filter(
                        (student) =>
                          student.falltospringmetprojectedgrowth === "Yes" ||
                          student.falltospringmetprojectedgrowth === "Yes*"
                      ).length *
                        100) /
                        students.length
                    )}
                    %
                  </div> */}
                </div>
                <div className="table-container">
                  <div className="filters">
                    <select
                      className="dropdown"
                      onChange={(e) => {
                        setStatus("all");
                        document.getElementById(
                          "statusDropDown"
                        ).selectedIndex = 0;
                        setSelectedClass(e.target.value);
                        if (e.target.value === "all") {
                          setFilteredStudents([...students]);
                        } else {
                          setFilteredStudents([
                            ...students.filter(
                              (s) => s.class === e.target.value
                            ),
                          ]);
                        }
                      }}
                    >
                      <option value="all">Filter By Class</option>
                      {classes.map((c) => {
                        return (
                          <option key={c + "dropDown"} value={c}>
                            {c}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      className="dropdown"
                      id="statusDropDown"
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (selectedClass !== "") {
                          if (e.target.value === "all") {
                            setFilteredStudents([
                              ...students.filter(
                                (s) => s.class === selectedClass
                              ),
                            ]);
                          } else {
                            setFilteredStudents([
                              ...students.filter(
                                (s) =>
                                  s.falltospringmetprojectedgrowth ===
                                    e.target.value && s.class === selectedClass
                              ),
                            ]);
                          }
                        } else {
                          if (e.target.value === "all") {
                            setFilteredStudents([...students]);
                          } else {
                            setFilteredStudents([
                              ...students.filter(
                                (s) =>
                                  s.falltospringmetprojectedgrowth ===
                                  e.target.value
                              ),
                            ]);
                          }
                        }
                      }}
                    >
                      <option value="all">Filter By Achievement Status</option>
                      <option value="Yes*">Target Met</option>
                      <option value="Yes">Target Exceeded</option>
                      <option value="No">Target Not Met</option>
                      <option value="No*">On Borderline</option>
                    </select>
                  </div>

                  <table className="student-table">
                    <thead>
                      <tr>
                        <th>eSIS </th>
                        <th
                          onClick={() => {
                            setSortStatus(!sortStatus);
                            if (sortStatus) {
                              setFilteredStudents([
                                ...filteredStudents.sort((a, b) =>
                                  Number(a.name > b.name ? -1 : 1)
                                ),
                              ]);
                            } else {
                              setFilteredStudents([
                                ...filteredStudents.sort((a, b) =>
                                  Number(a.name > b.name ? 1 : -1)
                                ),
                              ]);
                            }
                          }}
                        >
                          Name<span className="sort-arrow">&#8645;</span>
                        </th>
                        <th>Class</th>
                        <th>Subject</th>
                        {/* <th>Behavior</th> */}
                        <th>Fall</th>
                        <th>Past Target</th>
                        <th
                          onClick={() => {
                            setSortStatus(!sortStatus);
                            if (sortStatus) {
                              setFilteredStudents([
                                ...filteredStudents.sort((a, b) =>
                                  Number(
                                    a.testritscore > b.testritscore ? -1 : 1
                                  )
                                ),
                              ]);
                            } else {
                              setFilteredStudents([
                                ...filteredStudents.sort((a, b) =>
                                  Number(
                                    a.testritscore > b.testritscore ? 1 : -1
                                  )
                                ),
                              ]);
                            }
                          }}
                        >
                          Spring
                          <span className="sort-arrow">&#8645;</span>
                        </th>
                        {/* <th>Achievement Status</th>  */}
                        <th>Feedback & Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStudents.map((student, index) => (
                        <tr key={index}>
                          <td>{student.studentid}</td>
                          <td>{student.schoolname}</td>
                          <td>{student.class}</td>
                          <td>{student.course}</td>
                          {/* <td>
                            {Math.floor(Number(student.school_stateid) * 100)}%
                          </td> */}
                          <td>
                            {!isNaN(
                              Number(student.testritscore) -
                                Number(student.falltospringobservedgrowth)
                            ) &&
                              Number(student.testritscore) -
                                Number(student.falltospringobservedgrowth)}
                          </td>
                          {/* {console.log(student)} */}
                          <td style={{ color: "blue", fontWeight: "bold" }}>
                            {!isNaN(
                              Number(student.testritscore) -
                                Number(student.falltospringobservedgrowth) +
                                Number(student.falltospringprojectedgrowth)
                            ) &&
                              Number(student.testritscore) -
                                Number(student.falltospringobservedgrowth) +
                                Number(student.falltospringprojectedgrowth)}
                          </td>
                          <td
                            className={
                              student.falltospringmetprojectedgrowth === "No*"
                                ? "closeToMeet"
                                : student.falltospringmetprojectedgrowth ===
                                  "Yes*"
                                ? "Exceed"
                                : student.falltospringmetprojectedgrowth ===
                                  "Yes"
                                ? "metOrExceed"
                                : student.falltospringmetprojectedgrowth ===
                                  "No"
                                ? "notMet"
                                : ""
                            }
                          >
                            {!isNaN(student.testritscore) &&
                              student.testritscore}
                          </td>

                          <td>
                            <ViewAndEdit studentData={student} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </>
      {showPortal ? (
        <Portal>
          <FeedbackForm
            settinShowPortal={(v) => setShowPortal(v)}
            selectedStudent={selectedStudent}
          />
        </Portal>
      ) : (
        ""
      )}
      {showPortalView ? (
        <Portal>
          <FeedbackFormView
            settinShowPortal={(v) => setShowPortalView(v)}
            selectedStudent={selectedStudent}
          />
        </Portal>
      ) : (
        ""
      )}
    </div>
  );
};

export default TeacherDashboard;
