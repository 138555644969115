import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Example from "./classLists/Toprint";
// import AttendanceTeacher from "./AttendanceTeacher";
import Attendancehistory from "./AttendanceHistory";
import InfoManager from "../components/wpl/StudentManual/InfoManager";

import Pecattendance from "./PecAttendance";
import AttendanceTakingTracker from "./AttendanceTakingTracker";
import TimetableNew from "./timetableNew";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Teacher = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="متابعة الحضور" {...a11yProps(0)} />
          <Tab label=" Timetable الجدول المدرسي  " {...a11yProps(1)} />
          <Tab label="قوائم الطلاب Class Lists" {...a11yProps(2)} />
  
          <Tab label="بيانات الطالب Student Info" {...a11yProps(3)} />
          <Tab
            label="Attendance History سجلات الحضور و الغياب"
            {...a11yProps(4)}
          />
          <Tab
            label="School Attendance"
            {...a11yProps(5)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}> 
        <AttendanceTakingTracker/>

       </TabPanel>
      <TabPanel value={value} index={1}> 
        {/* <Attendance /> */}
        <TimetableNew />
      
       </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ overflow: "auto" }}>
          <Example />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <div style={{ overflow: "auto" }}>
          <Attendancehistory />
        </div>
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <div style={{ overflow: "auto" }}>
          <InfoManager />
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div style={{ overflow: "auto" }}>
        <Attendancehistory />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div style={{ overflow: "auto" }}>
        <Pecattendance />
        </div>
      </TabPanel>
    </div>
  );
};
export default Teacher;
