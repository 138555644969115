import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ProgressReport.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SaveIcon from "@mui/icons-material/Save";
import { ProgressReportPdf } from "./ProgressReportPdf";
import readinessar from "../../src/readinessar.png";
import readinessEn from "../../src/readinessEn.png";
import attiudeen from "../../src/attiudeen.png";
import attiudear from "../../src/attiudear.png";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
const ProgressReport = () => {
  const [data, setData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [en, setEn] = useState(true);
  const [sub, setSub] = useState("");
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState("Report 2");

  const animatedComponent = makeAnimated();

  const enRecom = [
    {
      value: "enRecom0",
      label: "Engage your child in effective communication!",
    },
    { value: "enRecom1", label: "Set aside a time and place for reading!" },
    {
      value: "enRecom2",
      label:
        "Ask your child questions about a story you read together or a movie you watched together!",
    },
    {
      value: "enRecom3",
      label: "Help your child memorize the multiplication tables!",
    },
    {
      value: "enRecom4",
      label: "Encourage your child to calculate without calculator!",
    },
  ];
  const arRecom = [
    { value: "arRecom0", label: "أشرك طفلك في التخاطب الفعال" },
    { value: "arRecom1", label: " خصص وقتًا ومكانًا للقراءة" },
    {
      value: "arRecom2",
      label: "اطرح على طفلك أسئلة حول قصة قرأتها معًا أو فيلم شاهدته معًا",
    },
    { value: "arRecom3", label: " ساعد طفلك على حفظ جداول الضرب" },
    { value: "arRecom4", label: " شجع طفلك على الحساب بدون آلة حاسبة" },
  ];
  const enReadiness = [
    { value: "arRecom0", label: "Brings books and material." },
    { value: "arRecom1", label: "Works independently." },
    { value: "arRecom2", label: "Exerts best effort." },
    { value: "arRecom3", label: "Attends regularly and on time." },
    { value: "arRecom4", label: "Submits quality work on time." },
    {
      value: "arRecom5",
      label: "Good Performance on Assignments and Projects.",
    },
    { value: "arRecom6", label: "Good Performance on Assessments." },
    { value: "arRecom7", label: "Works cooperatively with peers." },
  ];
  const arReadiness = [
    { value: "arRecom0", label: "يحضر الكتب و الأدوات" },
    { value: "arRecom1", label: " لديه القدرة على أداء المهام منفرداً" },
    { value: "arRecom2", label: "يبذل أقصى ما لديه من جهد" },
    { value: "arRecom3", label: "يلتزم بالحضور المنتظم وفي الوقت المحدد" },
    { value: "arRecom4", label: "يقدم الواجب في موعده بجودة و دقة" },
    {
      value: "arRecom5",
      label: " يؤدي أداءً جيداً بالواجبات والمشاريع العملية",
    },
    { value: "arRecom6", label: " يؤدي أداءً جيداً بالاختبارات" },
    { value: "arRecom7", label: " يعمل بشكل تعاوني مع أقرانه" },
  ];
  const enBehavior = [
    {
      value: "arRecom0",
      label:
        "Listens attentively to explanations, discussions, presentations and group work activities ",
    },
    {
      value: "arRecom1",
      label:
        "Asking questions, making suggestions, offering opinions, sharing experiences and taking part in debates  ",
    },
  ];
  const arBehavior = [
    {
      value: "arRecom0",
      label:
        "يستمع باهتمام إلى التفسيرات والمناقشات والعروض التقديمية وأنشطة العمل الجماعي",
    },
    {
      value: "arRecom1",
      label:
        " يشارك بنشاط خلال جلسات الفصل ، أي طرح الأسئلة وتقديم الاقتراحات وتقديم الآراء وتبادل الخبرات والمشاركة في المناقشات  ",
    },
  ];
  const selectScale = (type, d) => {
    return (
      <select
        className="select progressSelect"
        id={"expectations" + data.indexOf(d) + type}
        onChange={(e) => {
          if (e.target.value === "0") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "lightgreen";
          } else if (e.target.value === "1") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "orange";
          } else if (e.target.value === "2") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "red";
          }
          d[sub.toLowerCase() + type + "scale"] = e.target.value;
        }}
      >
        <option value=""></option>
        {scale.map((s) => {
          return (
            <option key={s} value={scale.indexOf(s)}>
              {s}
            </option>
          );
        })}
      </select>
    );
  };
  const selectScalearr = (type, d) => {
    return (
      <select
        className="select progressSelect"
        id={"expectations" + data.indexOf(d) + type}
        onChange={(e) => {
          if (e.target.value === "0") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "lightgreen";
          } else if (e.target.value === "1") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "orange";
          } else if (e.target.value === "2") {
            document.getElementById(
              "expectations" + data.indexOf(d) + type
            ).style.backgroundColor = "red";
          }
          d[sub.toLowerCase() + type + "scale"] = e.target.value;
        }}
      >
        <option value=""></option>
        {scalear.map((s) => {
          return (
            <option key={s} value={scalear.indexOf(s)}>
              {s}
            </option>
          );
        })}
      </select>
    );
  };

  const scale = [
    "Exceeds Expectations",
    "Meeting Expectations",
    "Below Expectations",
  ];
  const scalear = ["يفوق التوقعات", "يلبي التوقعات", "دون التوقعات"];
  const scaleatt = [
    "Rarely or Never Absent",
    "Sometimes Absent",
    "Frequentaly Absent",
  ];
  const scaleattarr = ["نادر الغياب", "غائب في بعض الأحيان", "غياب متكرر"];
  const scaleClassName = (d, type) => {
    if (scale[Number(d[sub.toLowerCase() + type])] === "Exceeds Expectations") {
      return "exceeding";
    } else if (
      scale[Number(d[sub.toLowerCase() + type])] === "Meeting Expectations"
    ) {
      return "meeting";
    } else if (
      scale[Number(d[sub.toLowerCase() + type])] === "Below Expectations"
    ) {
      return "below";
    } else {
      return "";
    }
  };
  const generalQuery = async (z, p) => {
    setData([]);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setLoading(false);
      if (p === "getteacherclasses") {
        let arr = [];
        resp.forEach((r) => {
          ["m", "t", "w", "th", "f"].forEach((d) => {
            ["1", "2", "3", "4", "5", "6"].forEach((p) => {
              if (
                r[d + p] &&
                arr.indexOf(
                  "G" + r[d + p].slice(0, 1) + "-" + r[d + p].slice(-1)
                ) === -1
              ) {
                arr.push("G" + r[d + p].slice(0, 1) + "-" + r[d + p].slice(-1));
              }
            });
          });
        });
        setTeacherClasses(
          arr
            .filter((a) => a.slice(0, 2) !== "Gn")
            .sort((a, b) => (a > b ? 1 : -1))
        );
      }
      if (p === "getData") {
        if (["English", "Math", "Science"].includes(sub)) {
          setData(resp.sort((a, b) => (a.name > b.name ? 1 : -1)));
        } else {
          setData(resp.sort((a, b) => (a.arabicname > b.arabicname ? 1 : -1)));
        }
        setLoading(false);
      }
      if (p === "getReports") {
        let arr = [];
        resp.forEach((r) => {
          arr.push("Report " + r.table_name.slice(-1));
        });
        setReports(arr);
      }
      if (p === "updateData") {
        generalQuery(
          `select * from ${"prgrpt" + selectedReport.slice(-1)
          } where homeroom = '${selectedClass}'`,
          "getData"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const exportChecker = (d) => {
    if (
      // (d["arabicrecom"] && d["arabicrecom"][0] === null) ||
      // (d["englishrecom"] && d["englishrecom"][0] === null) ||
      // (d["sciencerecom"] && d["sciencerecom"][0] === null) ||
      // (d["mathrecom"] && d["mathrecom"][0] === null) ||
      // (d["arabicrecom"] && d["arabicrecom"][0] === "null") ||
      // (d["englishrecom"] && d["englishrecom"][0] === "null") ||
      // (d["sciencerecom"] && d["sciencerecom"][0] === "null") ||
      // (d["mathrecom"] && d["mathrecom"][0] === "null") ||
      !d["arabicattendancescale"] ||
      !d["arabicbehaviourscale"] ||
      !d["arabicreadinessscale"] ||
      !d["englishattendancescale"] ||
      !d["englishbehaviourscale"] ||
      !d["englishreadinessscale"] ||
      !d["mathattendancescale"] ||
      !d["mathbehaviourscale"] ||
      !d["mathreadinessscale"] ||
      !d["scienceattendancescale"] ||
      !d["sciencebehaviourscale"] ||
      !d["sciencereadinessscale"] ||
      d["	arabicattendancescale"] === "undefined" ||
      d["arabicbehaviourscale"] === "undefined" ||
      d["arabicreadinessscale"] === "undefined" ||
      d["englishattendancescale"] === "undefined" ||
      d["englishbehaviourscale"] === "undefined" ||
      d["englishreadinessscale"] === "undefined" ||
      d["mathattendancescale"] === "undefined" ||
      d["mathbehaviourscale"] === "undefined" ||
      d["mathreadinessscale"] === "undefined" ||
      d["scienceattendancescale"] === "undefined" ||
      d["sciencebehaviourscale"] === "undefined" ||
      d["sciencereadinessscale	"] === "undefined" ||
      d["arabicattendancescale"] === "null" ||
      d["arabicbehaviourscale"] === "null" ||
      d["arabicreadinessscale"] === "null" ||
      d["englishattendancescale"] === "null" ||
      d["englishbehaviourscale"] === "null" ||
      d["englishreadinessscale"] === "null" ||
      d["mathattendancescale"] === "null" ||
      d["mathbehaviourscale"] === "null" ||
      d["mathreadinessscale"] === "null" ||
      d["scienceattendancescale"] === "null" ||
      d["sciencebehaviourscale"] === "null" ||
      d["sciencereadinessscale"] === "null"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const teachername = sessionStorage.getItem("teachername");
  useEffect(() => {
    if (sessionStorage.getItem("sub") === "Biology") {
      setSub("Science");
    } else if (sessionStorage.getItem("sub") === "Is/Ss") {
        setSub("Arabic");
      }

      else {
        setSub(sessionStorage.getItem("sub"));
      }

    generalQuery(
      `select * from teacherssummary where teacher='${teachername?.trim()}'`,
      "getteacherclasses"
    );
    generalQuery(
      `SELECT table_name FROM information_schema.tables WHERE table_schema='public' AND table_type='BASE TABLE' and table_name like 'prgrpt%'`,
      "getReports"
    );
    if (sessionStorage.getItem("sub") === "Arabic") {
      setEn(false);
    }
  }, []);

  if (teacherClasses.length === 0 || loading) {
    return <CircularProgress />;
  }
  return (
    <div>
      <h4>Hello, {teachername}</h4>
      <div className="container">
        {/* <select
          className="select"
          onChange={(e) => {
            setData([]);
            setSelectedReport(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option value="">Report....</option>
          {reports
            .sort((a, b) => (a < b ? 1 : -1))
            .map((r) => {
              return (
                <option key={r} value={r}>
                  {r}
                </option>
              );
            })}
        </select> */}
        <select
          className="select"
          onChange={(e) => {
            setSelectedClass(e.target.value);
            setData([]);
          }}
        >
          <option value="">Select Class</option>
          {teacherClasses.map((c) => {
            return <option key={c}>{c}</option>;
          })}
        </select>
        <button
          className="select"
          onClick={() => {
            setLoading(true);
            if (selectedClass === "" || selectedReport === "") {
              alert("Please select a class أرجو تحديد  الشعبة ");
            } else {
              generalQuery(
                `select * from ${"prgrpt" + selectedReport.slice(-1)
                } where homeroom = '${selectedClass}'`,
                "getData"
              );
            }
          }}
        >
          Let's Go!
        </button>
      </div>
      <div>
        {data.length > 0 ? (
          <>
            <div className="container">
              {" "}
              <div className="container">
                {/* <button
                  className="container select wider"
                  onClick={() => {
                    setLoading(true);
                    data.forEach((d) => {
                      generalQuery(
                        `update ${"prgrpt" + selectedReport.slice(-1)} set  ${sub.toLowerCase() + "readinessscale"
                        }='${d[sub.toLowerCase() + "readinessscale"]}', ${sub.toLowerCase() + "behaviourscale"
                        }='${d[sub.toLowerCase() + "behaviourscale"]}', ${sub.toLowerCase() + "recom"
                        }=Array['${d[sub.toLowerCase() + "recom"]}'], ${sub.toLowerCase() + "attendancescale"
                        }='${d[sub.toLowerCase() + "attendancescale"]
                        }' where esis = '${d.esis}'`,
                        "updateData"
                      );
                    });
                  }}
                >
                  Save <SaveIcon />
                </button> */}
              </div>
            </div>
            <div className="container">
              <table className=" reportTable">
                <thead>
                  <tr>
                    <th>eSIS</th>
                    {["English", "Math", "Science"].includes(sub) ? (
                      <th>Name</th>
                    ) : (
                      <th>الاسم</th>
                    )}
                    {en ? <th>Attendance</th> : <th>الحضور</th>}
                    {en ? (
                      <th>
                        Academic Readiness
                        <img src={readinessEn} style={{ width: "70%" }} />
                      </th>
                    ) : (
                      <th>
                        الاستعداد للتعلم
                        <img src={readinessar} style={{ width: "70%" }} />
                      </th>
                    )}
                    {en ? (
                      <th>
                        Behavior for Learning <img src={attiudeen} />
                      </th>
                    ) : (
                      <th>
                        سلوك التعلم <img src={attiudear} />
                      </th>
                    )}

                    {en ? <th>Recommendations </th> : <th>التوصيات</th>}
                    {/* <th>
                      <span
                        onClick={() => {
                          setLoading(true);
                          data.forEach((e) => {
                            setTimeout(() => {
                              ProgressReportPdf(
                                e,
                                enReadiness,
                                enBehavior,
                                enRecom,
                                arReadiness,
                                arBehavior,
                                arRecom
                              );
                            }, 200);
                          });
                        }}
                      >
                        <CloudDownloadIcon fontSize="large" />
                      </span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) => {
                    return (
                      <tr key={d.esis}>
                        <td>{d.esis}</td>
                        {["English", "Math", "Science"].includes(sub) ? (
                          <td>{d.name}</td>
                        ) : (
                          <td>{d.arabicname}</td>
                        )}
                        {d[sub.toLowerCase() + "attendancescale"] === null ||
                          d[sub.toLowerCase() + "attendancescale"] ===
                          "undefined" ||
                          d[sub.toLowerCase() + "attendancescale"] === "null" ? (
                          <>
                            {en ? (
                              <td>{selectScale("attendance", d)}</td>
                            ) : (
                              <td>{selectScalearr("attendance", d)}</td>
                            )}
                          </>
                        ) : (
                          <td>
                            <div
                              className={scaleClassName(d, "attendancescale")}
                            >
                              {en
                                ? scale[
                                Number(
                                  d[sub.toLowerCase() + "attendancescale"]
                                )
                                ]
                                : scalear[
                                Number(
                                  d[sub.toLowerCase() + "attendancescale"]
                                )
                                ]}
                            </div>
                            <div>
                              {" "}
                              {en
                                ? selectScale("attendance", d)
                                : selectScalearr("attendance", d)}
                            </div>
                          </td>
                        )}
                        {d[sub.toLowerCase() + "readinessscale"] === null ||
                          d[sub.toLowerCase() + "readinessscale"] ===
                          "undefined" ||
                          d[sub.toLowerCase() + "readinessscale"] === "null" ? (
                          <>
                            {en ? (
                              <td>{selectScale("readiness", d)}</td>
                            ) : (
                              <td>{selectScalearr("readiness", d)}</td>
                            )}
                          </>
                        ) : (
                          <td>
                            <div
                              className={scaleClassName(d, "readinessscale")}
                            >
                              {en
                                ? scale[
                                Number(
                                  d[sub.toLowerCase() + "readinessscale"]
                                )
                                ]
                                : scalear[
                                Number(
                                  d[sub.toLowerCase() + "readinessscale"]
                                )
                                ]}
                            </div>
                            <div>
                              {" "}
                              {en
                                ? selectScale("readiness", d)
                                : selectScalearr("readiness", d)}
                            </div>
                          </td>
                        )}
                        {d[sub.toLowerCase() + "behaviourscale"] === null ||
                          d[sub.toLowerCase() + "behaviourscale"] ===
                          "undefined" ||
                          d[sub.toLowerCase() + "behaviourscale"] === "null" ? (
                          <>
                            {en ? (
                              <td>{selectScale("behaviour", d)}</td>
                            ) : (
                              <td>{selectScalearr("behaviour", d)}</td>
                            )}
                          </>
                        ) : (
                          <td>
                            <div
                              className={scaleClassName(d, "behaviourscale")}
                            >
                              {en
                                ? scale[
                                Number(
                                  d[sub.toLowerCase() + "behaviourscale"]
                                )
                                ]
                                : scalear[
                                Number(
                                  d[sub.toLowerCase() + "behaviourscale"]
                                )
                                ]}
                            </div>
                            <div>
                              {en
                                ? selectScale("behaviour", d)
                                : selectScalearr("behaviour", d)}
                            </div>
                          </td>
                        )}
                        {en ? (
                          <>
                            <td>
                              {d[sub.toLowerCase() + "recom"] === null ||
                                d[sub.toLowerCase() + "recom"][0] ===
                                "undefined" ||
                                d[sub.toLowerCase() + "recom"][0] === "null" ? (
                                <Select
                                  components={animatedComponent}
                                  isMulti
                                  className="progressrepotSelect"
                                  options={enRecom}
                                  isClearable={true}
                                  isSearchable={true}
                                  closeMenuOnSelect={false}
                                  onChange={(e) => {
                                    let arr = [];
                                    e.forEach((n) => {
                                      arr.push(n.value);
                                    });
                                    d[sub.toLowerCase() + "recom"] = arr;
                                  }}
                                />
                              ) : (
                                <>
                                  {d[sub.toLowerCase() + "recom"][0]
                                    .split(",")
                                    .map((e) => {
                                      return (
                                        <li key={"li" + d.esis + e}>
                                          {enRecom[e.slice(-1)] !== undefined
                                            ? enRecom[e.slice(-1)].label
                                            : ""}
                                        </li>
                                      );
                                    })}

                                  <Select
                                    components={animatedComponent}
                                    isMulti
                                    className="progressrepotSelect"
                                    options={enRecom}
                                    isClearable={true}
                                    isSearchable={true}
                                    closeMenuOnSelect={false}
                                    onChange={(e) => {
                                      let arr = [];
                                      e.forEach((n) => {
                                        arr.push(n.value);
                                      });
                                      d[sub.toLowerCase() + "recom"] = arr;
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          </>
                        ) : (
                          <td>
                            {d[sub.toLowerCase() + "recom"] === null ||
                              d[sub.toLowerCase() + "recom"][0] === "undefined" ||
                              d[sub.toLowerCase() + "recom"][0] === "null" ? (
                              <Select
                                components={animatedComponent}
                                isMulti
                                className="progressrepotSelect"
                                options={arRecom}
                                isClearable={true}
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                isRtl={true}
                                onChange={(e) => {
                                  let arr = [];
                                  e.forEach((n) => {
                                    arr.push(n.value);
                                  });
                                  d[sub.toLowerCase() + "recom"] = arr;
                                }}
                              />
                            ) : (
                              <>
                                {d[sub.toLowerCase() + "recom"][0]
                                  .split(",")
                                  .map((e) => {
                                    return (
                                      <li key={"li" + d.esis + e}>
                                        {arRecom[e.slice(-1)]?.label}
                                      </li>
                                    );
                                  })}

                                <Select
                                  components={animatedComponent}
                                  isMulti
                                  className="progressrepotSelect"
                                  options={arRecom}
                                  isClearable={true}
                                  isSearchable={true}
                                  closeMenuOnSelect={false}
                                  isRtl={true}
                                  onChange={(e) => {
                                    let arr = [];
                                    e.forEach((n) => {
                                      arr.push(n.value);
                                    });
                                    d[sub.toLowerCase() + "recom"] = arr;
                                  }}
                                />
                              </>
                            )}
                          </td>
                        )}
                        {en ? (
                          <td>
                            {exportChecker(d) ? (
                              <button
                                className=""
                                onClick={() =>
                                  ProgressReportPdf(
                                    d,
                                    enReadiness,
                                    enBehavior,
                                    enRecom,
                                    arReadiness,
                                    arBehavior,
                                    arRecom
                                  )
                                }
                              >
                                Export Progress Report
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          <td>
                            {exportChecker(d) ? (
                              <button
                                className=""
                                onClick={() => {
                                  ProgressReportPdf(
                                    d,
                                    enReadiness,
                                    enBehavior,
                                    enRecom,
                                    arReadiness,
                                    arBehavior,
                                    arRecom
                                  );
                                }}
                              >
                                طباعة تقرير المتابعة
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : !loading && data.length !== 0 ? (
          <CircularProgress />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProgressReport;
