import React, { useState, useEffect } from "react";

const Recordabsentstudents = ({ settingShowIndividualAtt }) => {
  const [absentEsis, setAbsentEsis] = useState([]);
  const [days, setDays] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [showData, setShowData] = useState(false);
  const getStudentsData = async () => {
    try {
      const body = { tablename: "students" };
      const response = await fetch(
        "https://mut.herokuapp.com/getStudentsData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setStudentsData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const getAbsentesis = async (tablename) => {
    try {
      const body = { tablename };
      const response = await fetch(
        "https://mut.herokuapp.com/getAbsentesisOnly",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      resp.forEach((e) => {
        absentEsis.push(e.studentnumber);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getAttendanceNames = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getAttendanceNames",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      console.log(resp)
      setDays(resp);
      // resp.forEach((d) => {
      //   updatePeriods(d.table_name);
      // });
      setDays(resp);
      resp.forEach((d) => {
        getAbsentesis(d.table_name);
      });
    } catch (error) {
      console.log(error);
    }
  };
  // const updatePeriods = (table) => {
  //   ["p1", "p2", "p3", "p4", "p5", "p6"].forEach(async (p) => {
  //     try {
  //       const body = { p, table };
  //       const response = await fetch(
  //         "https://mut.herokuapp.com/updatePeriods",
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(body),
  //         }
  //       );
  //       const resp = await response.json();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });
  // };
  useEffect(() => {
    getStudentsData();
    getAttendanceNames();
  }, []);
  return (
    <div>
      <button onClick={()=>{settingShowIndividualAtt(false)}}>Get Me Out</button>
      <button
        onClick={() => {
          setShowData(true);
        }}
      >
        console
      </button>
      {showData && (
        <table>
          <thead>
            <th>eSIS</th>
            <th>Name</th>
            <th>Class</th>
            <th>Number of Absence Days</th>
            <th>%of Attendance</th>
            <th>mobile</th>
          </thead>
          <tbody>
            {studentsData.map((s) => {
              return (
                <tr key={s.studentnumber}>
                  <td>{s.studentnumber}</td>
                  <td>{s.englishname}</td>
                  <td>{s.homroom}</td>
                  <td>
                    {absentEsis.filter((e) => e === s.studentnumber).length}
                  </td>
                  <td>
                    {days.length -
                      absentEsis.filter((e) => e === s.studentnumber).length}
                  </td>
                  <td>{s.mobile}</td>
                  <td>{s.phonenumber}</td>
                  <td>{s.father}</td>
                  <td>{s.mother}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}{" "}
    </div>
  );
};

export default Recordabsentstudents;
