import React, { useState, useEffect } from "react";
import readXlsxFile from "read-excel-file";
import generalQuery from "../generalQuery";
import exportDataToExcel from "../exportDataToExcel";
import { CircularProgress } from "@mui/material";
import Portal from "./Portal";
const FileUploader = ({ datatype, switcher }) => {
  const [showPortal, setShowPortal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingView, setLoadingView] = useState(true);
  const [data, setData] = useState([]);
  const [refresher, setRefresher] = useState();
  const InsertstrCreator = (data) => {
    let str = "";
    data[0].forEach((e) => {
      str = str + e.replace(/\s/g, "") + ",";
    });
    let valuesStringArr = "";
    data.slice(1).forEach((d) => {
      let valuesString = "";
      d.forEach((e) => {
        if (e) {
          valuesString =
            valuesString + "'" + e.toString().replace(/'/g, "''") + "'" + ",";
        } else {
          valuesString = valuesString + "'" + e + "'" + ",";
        }
      });
      valuesStringArr =
        valuesStringArr + "(" + valuesString.slice(0, -1) + "),";
    });
    return (
      "(" +
      str.slice(0, -1) +
      ")" +
      " Values " +
      valuesStringArr.slice(0, -1) +
      ";"
    );
  };
  const InsertstrCreatorMorethan500Records = (data) => {
    let n = Math.floor(data.length / 50);
    let lastset = data.length - n * 50;
    for (var i = 1; i < n + 1; ++i) {
      let str = "";
      data[0].forEach((e) => {
        str = str + e.replace(/\s/g, "") + ",";
      });
      let valuesStringArr = "";
      data.slice(50 * i - 49, 1 + Number(50 * i)).forEach((d) => {
        let valuesString = "";
        d.forEach((e) => {
          if (e) {
            valuesString =
              valuesString + "'" + e.toString().replace(/'/g, "''") + "'" + ",";
          } else {
            valuesString = valuesString + "'" + e + "'" + ",";
          }
        });
        valuesStringArr =
          valuesStringArr + "(" + valuesString.slice(0, -1) + "),";
      });
      let finalStr =
        "(" +
        str.slice(0, -1) +
        ")" +
        " Values " +
        valuesStringArr.slice(0, -1) +
        ";";
      let coded = `insert into ${datatype} ${finalStr}`;

      generalQuery(coded);
    }
    let str = "";
    data[0].forEach((e) => {
      str = str + e.replace(/\s/g, "") + ",";
    });
    let valuesStringArr = "";
    data.slice(-1 * lastset + 1).forEach((d) => {
      let valuesString = "";
      d.forEach((e) => {
        if (e) {
          valuesString =
            valuesString + "'" + e.toString().replace(/'/g, "''") + "'" + ",";
        } else {
          valuesString = valuesString + "'" + e + "'" + ",";
        }
      });
      valuesStringArr =
        valuesStringArr + "(" + valuesString.slice(0, -1) + "),";
    });
    let finalstLast =
      "(" +
      str.slice(0, -1) +
      ")" +
      " Values " +
      valuesStringArr.slice(0, -1) +
      ";";
    let coded = `insert into ${datatype} ${finalstLast}`;
    generalQuery(coded);
  };
  useEffect(() => {
    generalQuery(`select * from ${datatype}`).then((values) => {
      setLoading(false);
      setLoadingView(false);
      setData(values);
      if (document.getElementById("uploadField" + datatype)) {
        document.getElementById("uploadField" + datatype).value = null;
      }
    });
  }, [refresher, switcher]);
  if (loadingView) {
    return <CircularProgress />;
  }
  return (
    <div>
      <div className="container">
        <input
          type="file"
          accept=".xlsx"
          id={"uploadField" + datatype}
          onChange={(e) => {
            readXlsxFile(e.target.files[0]).then((rows) => {
              if (rows.length < 130) {
                let coded = `insert into ${datatype} ${InsertstrCreator(rows)}`;
                generalQuery(coded);
              } else {
                InsertstrCreatorMorethan500Records(rows);
              }
              let coded = `insert into ${datatype} ${InsertstrCreator(rows)}`;
            });
          }}
        ></input>
      </div>
      <div>
        <div
          className="container"
          style={{ marginLeft: "15px", userSelect: "none" }}
        >
          {!loading ? (
            <>
              {data.length > 0 ? (
                <>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      exportDataToExcel(data, Object.keys(data[0]), datatype);
                    }}
                  >
                    Current
                  </a>
                  <button
                    className="red"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      setShowPortal(true);
                    }}
                  >
                    X
                  </button>
                </>
              ) : (
                ""
                // <div className="container">
                //   <input
                //     type="file"
                //     accept=".xlsx"
                //     id={"uploadField" + datatype}
                //     onChange={(e) => {
                //       readXlsxFile(e.target.files[0]).then((rows) => {
                //         if (rows.length < 130) {
                //           let coded = `insert into ${datatype} ${InsertstrCreator(
                //             rows
                //           )}`;
                //           generalQuery(coded);
                //         } else {
                //           InsertstrCreatorMorethan500Records(rows);
                //         }
                //       });
                //     }}
                //   ></input>
                //   <button
                //     onClick={() => {
                //       setRefresher(Math.random());
                //     }}
                //   >
                //     Upload
                //   </button>
                // </div>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      {showPortal ? (
        <Portal>
          <div>
            <div className="container">
              You are about to delete..
              <span style={{ color: "red" }}>{datatype}</span>. Are you sure?
            </div>
            <div className="container">
              <button
                className="red"
                onClick={() => {
                  setShowPortal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="green"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setLoading(true);
                  generalQuery(`truncate ${datatype}`);
                  setRefresher(Math.random());
                  setShowPortal(false);
                }}
              >
                Yes,I'm sure...
              </button>
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUploader;
