import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";

const Dailyabsentstudentsreport = () => {
  const [absData, setAbsData] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [tablename, setTablename] = useState("");
  const [loading, setLoading] = useState(false);
  const [setudentsData, setSetudentsData] = useState([]);

  const generalQuery = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setLoading(false);
      if (p === "att") {
        resp.forEach((e) => {
          if (e.p1 === 0) {
            e.p1 = 0;
          } else {
            e.p1 = Number(e.p1);
          }
          if (e.p2 === 0) {
            e.p2 = 0;
          } else {
            e.p2 = Number(e.p2);
          }
          if (e.p3 === 0) {
            e.p3 = 0;
          } else {
            e.p3 = Number(e.p3);
          }
          if (e.p4 === 0) {
            e.p4 = 0;
          } else {
            e.p4 = Number(e.p4);
          }
          if (e.p5 === 0) {
            e.p5 = 0;
          } else {
            e.p5 = Number(e.p5);
          }
          if (e.p6 === 0) {
            e.p6 = 0;
          } else {
            e.p6 = Number(e.p6);
          }
        });

        setAbsData(
          resp.filter((r) => r.p1 + r.p2 + r.p3 + r.p4 + r.p5 + r.p6 === 0)
        );
      } else if (p === "students") {
        setSetudentsData(resp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const updatePeriods = (table) => {
  //   ["p1", "p2", "p3", "p4", "p5", "p6"].forEach(async (p) => {
  //     try {
  //       const body = { p, table };
  //       const response = await fetch(
  //         "https://mut.herokuapp.com/updatePeriods",
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(body),
  //         }
  //       );
  //       const resp = await response.json();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });
  // };

  const headers = [
    { label: "esis", key: "studentnumber" },
    { label: "Name", key: "englishname" },
    { label: "الأسم", key: "username" },
    { label: "Homeroom", key: "homroom" },
    { label: "Father", key: "father" },
    { label: "Mother", key: "mother" },
    { label: "Phone1", key: "mobile" },
    { label: "Phone2", key: "phonenumber" },
  ];

  return (
    <div>
      <div>
        <input
          className="inputField"
          type="date"
          value={selectedDay}
          onChange={(e) => {
            setAbsData([]);
            setTablename(
              "zatt" + e.target.value.replace("-", "").replace("-", "")
            );
            setSelectedDay(e.target.value);
          }}
        />
        <button
          onClick={() => {
            generalQuery(`select * from ${tablename}`, "att");
            generalQuery(`select * from students`, "students");
          }}
        >
          Show Data
        </button>
      </div>
      <CSVLink
        data={absData}
        headers={headers}
        filename={
          // new Date().toISOString().slice(0, 10) +
          // "--" +
          // period +
          // "--" +
          // selectedClass +
          // ".csv"
          selectedDay
        }
      >
        Download
      </CSVLink>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : absData.length > 0 ? (
        <div>
          <table className="coverageTable">
            <thead>
              <th>eSIS</th>
              <th>الاسم</th>
              <th>Name</th>
              <th>Homeroom</th>
              <th>Phone1</th>
              <th>Phone2</th>
              <th>Father</th>
              <th>Mother</th>
            </thead>
            <tbody>
              {absData.map((a) => {
                return (
                  <tr key={a.username}>
                    <td>{a.pupilnumber}</td>
                    <td>{a.arabicname}</td>
                    <td>{a.studentname}</td>
                    <td>{a.homeroomcode}</td>
                    <td>
                      {
                        setudentsData.filter(
                          (s) => s.studentnumber === a.pupilnumber
                        )[0]?.father
                      }
                    </td>
                    <td>   {
                        setudentsData.filter(
                          (s) => s.studentnumber === a.pupilnumber
                        )[0]?.mother
                      }</td>
                    <td>   {
                        setudentsData.filter(
                          (s) => s.studentnumber === a.pupilnumber
                        )[0]?.phonenumber
                      }</td>
                    <td>   {
                        setudentsData.filter(
                          (s) => s.studentnumber === a.pupilnumber
                        )[0]?.mobile
                      }</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dailyabsentstudentsreport;
