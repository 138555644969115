import React from "react";
import Backarrow from "./BackArrow";
import term123TestSchedule from "../WPLPDF/term123TestSchedule.pdf";
import w27 from "../WPLPDF/Week 2(4-09-23)/Grade 7.pdf";
import w28 from "../WPLPDF/Week 2(4-09-23)/Grade 8.pdf";
import w29 from "../WPLPDF/Week 2(4-09-23)/Grade 9.pdf";
import w210 from "../WPLPDF/Week 2(4-09-23)/Grade 10.pdf";
import w37 from "../WPLPDF/Week 3(11-09-23)/Grade 7.pdf";
import w38 from "../WPLPDF/Week 3(11-09-23)/Grade 8.pdf";
import w39 from "../WPLPDF/Week 3(11-09-23)/Grade 9.pdf";
import w310 from "../WPLPDF/Week 3(11-09-23)/Grade 10.pdf";
import w47 from "../WPLPDF/Week 4(18-09-23)/Grade 7.pdf";
import w48 from "../WPLPDF/Week 4(18-09-23)/Grade 8.pdf";
import w49 from "../WPLPDF/Week 4(18-09-23)/Grade 9.pdf";
import w410 from "../WPLPDF/Week 4(18-09-23)/Grade 10.pdf";
import w57 from "../WPLPDF/Week 5(25-09-23)/Grade 7.pdf";
import w58 from "../WPLPDF/Week 5(25-09-23)/Grade 8.pdf";
import w59 from "../WPLPDF/Week 5(25-09-23)/Grade 9.pdf";
import w510 from "../WPLPDF/Week 5(25-09-23)/Grade 10.pdf";
import w67 from "../WPLPDF/Week 6(02-10-23)/Grade 7.pdf";
import w68 from "../WPLPDF/Week 6(02-10-23)/Grade 8.pdf";
import w69 from "../WPLPDF/Week 6(02-10-23)/Grade 9.pdf";
import w610 from "../WPLPDF/Week 6(02-10-23)/Grade 10.pdf";
import w77 from "../WPLPDF/Week 7(09-10-23)/Grade 7.pdf";
import w78 from "../WPLPDF/Week 7(09-10-23)/Grade 8.pdf";
import w79 from "../WPLPDF/Week 7(09-10-23)/Grade 9.pdf";
import w710 from "../WPLPDF/Week 7(09-10-23)/Grade 10.pdf";
import w87 from "../WPLPDF/Week 8(17-10-23)/Grade 7.pdf";
import w88 from "../WPLPDF/Week 8(17-10-23)/Grade 8.pdf";
import w89 from "../WPLPDF/Week 8(17-10-23)/Grade 9.pdf";
import w810 from "../WPLPDF/Week 8(17-10-23)/Grade 10.pdf";
import w97 from "../WPLPDF/Week 9(23-10-23)/Grade 7.pdf";
import w98 from "../WPLPDF/Week 9(23-10-23)/Grade 8.pdf";
import w99 from "../WPLPDF/Week 9(23-10-23)/Grade 9.pdf";
import w910 from "../WPLPDF/Week 9(23-10-23)/Grade 10.pdf";
import w107 from "../WPLPDF/Week 10(30-10-23)/Grade 7.pdf";
import w108 from "../WPLPDF/Week 10(30-10-23)/Grade 8.pdf";
import w109 from "../WPLPDF/Week 10(30-10-23)/Grade 9.pdf";
import w1010 from "../WPLPDF/Week 10(30-10-23)/Grade 10.pdf";
import w117 from "../WPLPDF/Week 11(6-11-23)/Grade 7.pdf";
import w118 from "../WPLPDF/Week 11(6-11-23)/Grade 8.pdf";
import w119 from "../WPLPDF/Week 11(6-11-23)/Grade 9.pdf";
import w1110 from "../WPLPDF/Week 11(6-11-23)/Grade 10.pdf";
import w127 from "../WPLPDF/Week 12(13-11-23)/Grade 7.pdf";
import w128 from "../WPLPDF/Week 12(13-11-23)/Grade 8.pdf";
import w129 from "../WPLPDF/Week 12(13-11-23)/Grade 9.pdf";
import w1210 from "../WPLPDF/Week 12(13-11-23)/Grade 10.pdf";
import w139 from "../WPLPDF/Week 13(27-11-23)/Grade 9.pdf";
import w1310 from "../WPLPDF/Week 13(27-11-23)/Grade 10.pdf";
const WplDisplay = () => {
  const grade = sessionStorage.getItem("grade");
  return (
    <div>
      <Backarrow />
      <div>
        <a href={term123TestSchedule} target="_blank" >
          جدول اختبارات نهاية الفصل الدراسي الأول 2023-2024
        </a>
        <br />
        <br />
      </div>
      {grade.includes("7") ? (
        <div>
          <a href={w117} target="_blank" >
            Week 11(6-11-23)
          </a>
          <br />
          <br />
          <a href={w127} target="_blank" >
            Week 12(13-11-23)
          </a>
          <br />
          <br />
          <a href={w107} target="_blank" >
            Week 10(30-10-23)
          </a>
          <br />
          <br />
          <a href={w97} target="_blank" >
            Week 9(23-10-23)
          </a>
          <br />
          <br />
          <a href={w87} target="_blank" >
            Week 8(16-10-23)
          </a>
          <br />
          <br />
          <a href={w77} target="_blank" >
            Week 7 (09-10-23)
          </a>
          <br />
          <br />
          <a href={w67} target="_blank" >
            Week 6 (02-10-23)
          </a>
          <br />
          <br />
          <a href={w57} target="_blank" >
            Week 5 (25-09-23)
          </a>
          <br />
          <br />
          <a href={w47} target="_blank" >
            Week 4 (18-09-23)
          </a>
          <br />
          <br />
          <a href={w37} target="_blank" >
            Week 3 (11-09-23)
          </a>
          <br />
          <br />
          <a href={w27} target="_blank" >
            Week 2 (4-09-23)
          </a>
        </div>
      ) : grade.includes("8") ? (
        <div>
          <a href={w128} target="_blank" >
            Week 12(13-11-23)
          </a>
          <br />
          <br />
          <a href={w118} target="_blank" >
            Week 11(6-11-23)
          </a>
          <br />
          <br />
          <a href={w108} target="_blank" >
            Week 10(30-10-23)
          </a>
          <br />
          <br />
          <a href={w98} target="_blank" >
            Week 9(23-10-23)
          </a>
          <br />
          <br />
          <a href={w88} target="_blank" >
            Week 8(16-10-23)
          </a>
          <br />
          <br />
          <a href={w78} target="_blank" >
            Week 7 (09-10-23)
          </a>
          <br />
          <br />
          <a href={w68} target="_blank" >
            Week 6 (02-10-23)
          </a>
          <br />
          <br />
          <a href={w58} target="_blank" >
            Week 5 (25-09-23)
          </a>
          <br />
          <br />
          <a href={w48} target="_blank" >
            Week 4 (18-09-23)
          </a>
          <br />
          <br />
          <a href={w38} target="_blank" >
            Week 3 (11-09-23)
          </a>
          <br />
          <br />
          <a href={w28} target="_blank" >
            Week 2 (4-09-23)
          </a>
        </div>
      ) : grade.includes("9") ? (
        <div>
          <a href={w139} target="_blank" >
            Week 13(27-11-23)
          </a>
          <br />
          <br />
          <a href={w129} target="_blank" >
            Week 12(13-11-23)
          </a>
          <br />
          <br />
          <a href={w119} target="_blank" >
            Week 11(6-11-23)
          </a>
          <br />
          <br />
          <a href={w109} target="_blank" >
            Week 10(30-10-23)
          </a>
          <br />
          <br />
          <a href={w99} target="_blank" >
            Week 9(23-10-23)
          </a>
          <br />
          <br />
          <a href={w89} target="_blank" >
            Week 8(16-10-23)
          </a>
          <br />
          <br />
          <a href={w79} target="_blank" >
            Week 7 (09-10-23)
          </a>
          <br />
          <br />
          <a href={w69} target="_blank" >
            Week 6 (02-10-23)
          </a>
          <br />
          <br />
          <a href={w59} target="_blank" >
            Week 5 (25-09-23)
          </a>
          <br />
          <br />
          <a href={w49} target="_blank" >
            Week 4 (18-09-23)
          </a>
          <br />
          <br />
          <a href={w39} target="_blank" >
            Week 3 (11-09-23)
          </a>
          <br />
          <br />
          <a href={w29} target="_blank" >
            Week 2 (4-09-23)
          </a>
        </div>
      ) : grade.includes("10") ? (
        <div>
          <a href={w1310} target="_blank" >
            Week 13(27-11-23)
          </a>
          <br />
          <br />
          <a href={w1210} target="_blank" >
            Week 12(13-11-23)
          </a>
          <br />
          <br />
          <a href={w1110} target="_blank" >
            Week 11(6-11-23)
          </a>
          <br />
          <br />
          <a href={w1010} target="_blank" >
            Week 10(30-10-23)
          </a>
          <br />
          <br />
          <a href={w910} target="_blank" >
            Week 9(23-10-23)
          </a>
          <br />
          <br />
          <a href={w810} target="_blank" >
            Week 8(16-10-23)
          </a>
          <br />
          <br />
          <a href={w710} target="_blank" >
            Week 7 (09-10-23)
          </a>
          <br />
          <br />
          <a href={w610} target="_blank" >
            Week 6(02-10-23)
          </a>
          <br />
          <br />
          <a href={w510} target="_blank" >
            Week 5(18-09-23)
          </a>
          <br />
          <br />
          <a href={w410} target="_blank" >
            Week 4(18-09-23)
          </a>
          <br />
          <br />
          <a href={w310} target="_blank" >
            Week 3(11-09-23)
          </a>
          <br />
          <br />
          <a href={w210} target="_blank" >
            Week 2 (4-09-23)
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WplDisplay;
