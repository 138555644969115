import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import QuizIcon from "@mui/icons-material/Quiz";
import GroupsIcon from "@mui/icons-material/Groups";
import SuperAdminProgressReportTracker from "./SuperAdminProgressReportTracker";
import SuperAdminProgressUsers from "./SuperAdminProgressUsers";
import SuperadminFinalChecker from "./SuperadminFinalChecker";
import SummarizeIcon from "@mui/icons-material/Summarize";
import EndoFTermReport from "./EndoFTermReport";
import SuperExportAdek from "./SuperExportAdek";
import SuperCoverage from "./SuperCoverage";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import TimetableCreator from "../components/timetable/TimetableCreator";
import CreateInterGroups from "./CreateInterGroups";
const SuperAdmin = () => {
  const [value, setValue] = React.useState(0);
  return (
    <div>
      <div className="container">
        <Box sx={{ width: 500 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label="Data Management ادارة البيانات"
              icon={<GroupsIcon />}
            />
            <BottomNavigationAction
              label="Marks Entry ادخال الدرجات"
              icon={<QuizIcon />}
            />
            <BottomNavigationAction
              label="Report Cards التقارير"
              icon={<SummarizeIcon />}
            />
            <BottomNavigationAction
              label="Progress Report تقرير المتابعة"
              icon={<ShowChartIcon />}
            />
            <BottomNavigationAction
              label="Export MAP Bulk"
              icon={<GroupsIcon />}
            />
            <BottomNavigationAction
              label="Manage Intervention"
              icon={<CalendarViewMonthIcon />}
            />
          </BottomNavigation>
        </Box>
      </div>
      {value === 3 ? (
        <SuperAdminProgressReportTracker />
      ) : value === 1 ? (
        <SuperadminFinalChecker />
      ) : value === 0 ? (
        <SuperAdminProgressUsers />
      ) : value === 2 ? (
        <EndoFTermReport />
      ) : value === 4 ? (
        <SuperExportAdek />
      ) : value === 5 ? (
        // <SuperCoverage/>
        // <TimetableCreator/>
        <CreateInterGroups />
      ) : (
        ""
      )}
    </div>
  );
};

export default SuperAdmin;
