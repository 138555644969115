import React, { useEffect, useState } from "react";
import generalQuery from "../generalQuery";
// Import the CSS file if your setup supports CSS Modules or similar methods
import "./examtimetable.css";

const CreditHoursInd = () => {
  const [creditHours, setCreditHours] = useState({});
  const stClass = sessionStorage
    .getItem("grade")
    ?.replace("G", "")
    .replace("-", "");
  const [subjects, setSubjects] = useState([]);
  function getUniqueValues(jsonObject) {
    // Get all the values from the JSON object, excluding the 'class' property
    const values = Object.keys(jsonObject)
      .filter((key) => key !== "class") // Exclude the 'class' key
      .map((key) => jsonObject[key]); // Map the remaining keys to their values

    // Create a Set from the values to filter out duplicates
    const uniqueValues = new Set(values);

    // Convert the Set back to an array
    return Array.from(uniqueValues);
  }

  useEffect(() => {
    generalQuery(
      `select * from subjectsummaryfixed where class='${stClass}'`
    ).then((results) => {
      setSubjects(getUniqueValues(results[0]));
    });
    let filteredResult = {};
    generalQuery(
      `select * from credithours where pupilnumber='${sessionStorage.getItem(
        "esis"
      )}'`
    ).then((results) => {
      const result = results[0];

      Object.keys(result).forEach((key) => {
        if (subjects.includes(key.toUpperCase().replace("_", "-"))) {
          Object.assign(filteredResult, {
            [key.toUpperCase().replace("_", "-")]: result[key],
          });
        }
      });

      setCreditHours(filteredResult);
    });
  }, []);

  return (
    <div>
      <table id="examtimetable">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Credit Hours</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(creditHours).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CreditHoursInd;
