import React, { useState, useEffect } from "react";
import { getmapreport } from "../components/wpl/StudentManual/GeneratePDF";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backarrow from "./BackArrow";
const MapReports = () => {
  const [loading, setLoading] = useState(false);
  const xAxisValue = [
    "Winter'23",
    "Spring'23",
    "Fall'23",
    "Winter'24",
    "Spring'24",
  ];

  const [arrbenchmarks, setArrbenchmarks] = useState([]);

  const getMapBenchmarks = async () => {
    try {
      const body = {
        idqueryString: ` select * from mapbenchmarks where grade='${
          Number(sessionStorage.getItem("grade")?.slice(0, 2).slice(-1)) - 1
        }'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setArrbenchmarks(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMapBenchmarks();
  }, []);
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Backarrow />
      <div>
        {/* <>
          <label style={{ fontWeight: "bold" }}>Term Rostered</label>
          <select
            style={{
              margin: "10px",
              height: "30px",
              width: "150px",
              border: "solid blue 1px",
              borderRadius: "5px",
            }}
            onChange={(e) => {
              setTerm(e.target.value);
              if (e.target.value === "mapspring22") {
                setTitle("Summer 2022");
                setFromPeriod("Fall 2021");
                setToPeriod("Spring 2022");
                setXAxisValue([
                  "Winter'21",
                  "Spring'21",
                  "Fall'21",
                  "Winter'22",
                  "Spring'22",
                ]);
              } else if (e.target.value === "mapfall22") {
                setTitle("Fall 2022");
                setFromPeriod("Fall 2021");
                setToPeriod("Fall 2022");
                setXAxisValue([
                  "Spring'21",
                  "Fall'21",
                  "Winter'22",
                  "Spring'22",
                  "Fall'21",
                ]);
              }
            }}
          >
            <option value="">-------</option>
           
            <option value="mapfall22">Fall 2022</option>
            <option value="mapspring22">Spring 2022</option>
          </select>
        </> */}
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            setLoading(true);
            getmapreport(
              "mapspringreport24",
              "Spring 2024",
              "Fall 2023",
              "Spring 2024",
              sessionStorage.getItem("esis"),
              xAxisValue,
              arrbenchmarks
            );
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          }}
        >
          Download MAP Report Spring 2023-2024
        </a>
      </div>
    </div>
  );
};

export default MapReports;
