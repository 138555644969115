import React, { useState, useEffect } from "react";
import "./swapsTable.css";
const Swapstable = ({
  freeTeachers,
  swapClass,
  swapPeriod,
  arr,
  selectedTable,
  selectedTeacher,
  periodsArr,
}) => {
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    setShowTable(true);
  }, [swapClass]);

  const assignSwap = async (
    mainValue,
    swapedValue,
    mainSelectedPeriod,
    swapedSelectedPeriod,
    swapedTeacher
  ) => {
    try {
      const body = {
        tablename: selectedTable,
        mainValue,
        swapedValue,
        mainSelectedPeriod,
        swapedSelectedPeriod,
        mainTeacher: selectedTeacher,
        swapedTeacher,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/assignSwap",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {showTable && (
          <table className="swapsTableNew">
            <thead>
              <tr>
                <th>Teacher</th>
                <th>P1</th>
                <th>P2</th>
                <th>P3</th>
                <th>P4</th>
                <th>P5</th>
                <th>P6</th>
              </tr>
            </thead>
            <tbody>
              {freeTeachers.map((e) => {
                return (
                  <tr key={e.teacher}>
                    <td>{e.teacher}</td>
                    <td
                      className={
                        swapClass === e.p1 &&
                        e[swapPeriod] === "free" &&
                        arr[0] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "1",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p1 &&
                      e[swapPeriod] === "free" &&
                      arr[0] === "free"
                        ? swapClass
                        : ""}
                    </td>
                    <td
                      className={
                        swapClass === e.p2 &&
                        e[swapPeriod] === "free" &&
                        arr[1] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "2",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p2 &&
                      e[swapPeriod] === "free" &&
                      arr[1] === "free"
                        ? swapClass
                        : ""}
                    </td>
                    <td
                      className={
                        swapClass === e.p3 &&
                        e[swapPeriod] === "free" &&
                        arr[2] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "3",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p3 &&
                      e[swapPeriod] === "free" &&
                      arr[2] === "free"
                        ? swapClass
                        : ""}
                    </td>
                    <td
                      className={
                        swapClass === e.p4 &&
                        e[swapPeriod] === "free" &&
                        arr[3] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "4",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p4 &&
                      e[swapPeriod] === "free" &&
                      arr[3] === "free"
                        ? swapClass
                        : ""}
                    </td>
                    <td
                      className={
                        swapClass === e.p5 &&
                        e[swapPeriod] === "free" &&
                        arr[4] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "5",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p5 &&
                      e[swapPeriod] === "free" &&
                      arr[4] === "free"
                        ? swapClass
                        : ""}
                    </td>
                    <td
                      className={
                        swapClass === e.p6 &&
                        e[swapPeriod] === "free" &&
                        arr[5] === "free"
                          ? "tdForSwaps"
                          : ""
                      }
                      onClick={() => {
                        assignSwap(
                          swapClass + "---" + selectedTeacher,
                          swapClass + "---" + e.teacher,
                          periodsArr[Number(swapPeriod.slice(-1)) - 1].slice(
                            0,
                            1
                          ) + "6",
                          periodsArr[Number(swapPeriod.slice(-1)) - 1],
                          e.teacher
                        );
                      }}
                    >
                      {swapClass === e.p6 &&
                      e[swapPeriod] === "free" &&
                      arr[5]=== "free"
                        ? swapClass
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Swapstable;
