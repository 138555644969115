import jsPDF from "jspdf";
import { font } from "../font";
import header from "../../src/ProgressreportHead.png";
import section1Prgrsrpt from "../../src/section1Prgrsrpt.PNG";
import section2Prgrsrpt from "../../src/section2Prgrsrpt.png";
import attendanceSection from "../../src/attendanceSection.png";
export const ProgressReportPdf = (d) => {
  var doc = new jsPDF();

  doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
  doc.addFont(
    "IBMPlexSansArabic-Medium-normal.ttf",
    "IBMPlexSansArabic-Medium",
    "normal"
  );
  doc.setFont("IBMPlexSansArabic-Medium");
  doc.addImage(header, 5, 5, 200, 30);
  doc.setFontSize(12);
  doc.text("Progress Report - تقرير متابعة", 85, 45);
  doc.line(5, 48, 205, 48);
  doc.line(5, 70, 205, 70);
  doc.line(5, 48, 5, 70);
  doc.line(205, 48, 205, 70);
  doc.setFontSize(10);
  doc.text("Al Mutanabi School", 8, 55);
  doc.text(d.name + ("    - " + d.esis), 8, 60);
  doc.text(d.homeroom, 8, 65);
  doc.text("مدرسة المتنبي", 180, 55);
  doc.text(d.arabicname, 120, 65);
  doc.text(": اسم الطالب", 180, 65);
  doc.addImage(section1Prgrsrpt, 5, 75, 200, 90);
  doc.addImage(section2Prgrsrpt, 5, 175, 200, 110);
  const sectionScale = (scale, h) => {
    if (d[scale] === "0") {
      let x = 125;
      let y = h;
      doc.setFillColor(144, 238, 144);
      doc.rect(x, y, 50, 10, "FD");
      doc.setTextColor(0, 0, 0);
      doc.text("Above Expectations", x + 10, y + 3.5);
      doc.text("يفوق التوقعات", x + 15, y + 8);
    } else if (d[scale] === "1") {
      let x = 125;
      let y = h;
      doc.setFillColor(255, 194, 0);
      doc.rect(x, y, 50, 10, "FD");
      doc.setTextColor(0, 0, 0);
      doc.text("Meeting Expectations", x + 10, y + 3.5);
      doc.text("يلبي التوقعات", x + 15, y + 8);
    } else if (d[scale] === "2") {
      let x = 125;
      let y = h;
      doc.setFillColor(255, 0, 0);
      doc.rect(x, y, 50, 10, "FD");
      doc.setTextColor(255, 255, 255);
      doc.text("Below Expectations", x + 10, y + 3.5);
      doc.text("أقل من المتوقع", x + 15, y + 8);
    } else {
      let x = 125;
      let y = h;
      doc.setFillColor(255, 255, 255);
      // doc.rect(x, y, 50, 10, "FD");
      doc.setTextColor(0, 0, 0);
      doc.text("No Entry Yet!", x + 10, y + 3.5);
      doc.text("لم يتم الادخال", x + 15, y + 8);
    }
  };
  const getAtt = () => {
    return [
      d["arabicattendancescale"],
      d["englishattendancescale"],
      d["scienceattendancescale"],
      d["mathattendancescale"],
    ].sort((a, b) => (a < b ? 1 : -1))[0];
  };
  sectionScale("englishreadinessscale", 85);
  sectionScale("mathreadinessscale", 105);
  sectionScale("sciencereadinessscale", 125);
  sectionScale("arabicreadinessscale", 145);
  sectionScale("englishbehaviourscale", 195);
  sectionScale("mathbehaviourscale", 215);
  sectionScale("sciencebehaviourscale", 235);
  sectionScale("arabicbehaviourscale", 255);
  doc.addPage();
  doc.addImage(attendanceSection, 5, 15, 200, 20);
  if (getAtt() === "0") {
    let x = 125;
    let y = 20;
    doc.setFillColor(144, 238, 144);
    doc.rect(x, y, 50, 10, "FD");
    doc.setTextColor(0, 0, 0);
    doc.text("Above Expectations", x + 10, y + 3.5);
    doc.text("يفوق التوقعات", x + 15, y + 8);
  } else if (getAtt() === "1") {
    let x = 125;
    let y = 20;
    doc.setFillColor(255, 194, 0);
    doc.rect(x, y, 50, 10, "FD");
    doc.setTextColor(0, 0, 0);
    doc.text("Meeting Expectations", x + 10, y + 3.5);
    doc.text("يلبي التوقعات", x + 15, y + 8);
  } else if (getAtt() === "2") {
    let x = 125;
    let y = 20;
    doc.setFillColor(255, 0, 0);
    doc.rect(x, y, 50, 10, "FD");
    doc.setTextColor(255, 255, 255);
    doc.text("Below Expectations", x + 10, y + 3.5);
    doc.text("أقل من المتوقع", x + 15, y + 8);
  } else {
    let x = 125;
    let y = 20;
    doc.setFillColor(255, 255, 255);
    // doc.rect(x, y, 50, 10, "FD");
    doc.setTextColor(0, 0, 0);
    doc.text("No Entry Yet!", x + 10, y + 3.5);
    doc.text("لم يتم الادخال", x + 15, y + 8);
  }
  const enRecom = [
    "Engage your child in effective communication!",
    "Set aside a time and place for reading!",
    "Ask your child questions about a story you read together or a movie you watched together!",
    "Help your child memorize the multiplication tables!",
    "Encourage your child to calculate without calculator!",
  ];
  const arRecom = [
    "أشرك طفلك في التخاطب الفعال",
    " خصص وقتًا ومكانًا للقراءة",
    "اطرح على طفلك أسئلة حول قصة قرأتها معًا أو فيلم شاهدته معًا",
    " ساعد طفلك على حفظ جداول الضرب",
    " شجع طفلك على الحساب بدون آلة حاسبة",
  ];
  let arr = [];
  ["English", "Arabic", "Math", "Science"].forEach((sub) => {
    d[sub.toLowerCase() + "recom"][0].split(",").forEach((e) => {
      if (arr.indexOf(e.slice(2, 8)) === -1) {
        arr.push(e.slice(2, 8));
      }
    });
  });
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(12);
  doc.text("Recommendations", 10, 45);
  doc.line(10, 48, 45, 48);
  doc.setFontSize(10);
  let j = 0;
  arr.forEach((a) => {
    if (enRecom[Number(a.slice(-1))]) {
      doc.text("- " + enRecom[Number(a.slice(-1))], 10, 60 + 10 * j);
    }
    j += 1;
  });
  doc.setFontSize(12);
  doc.text("توصيــــــــات", 180, 70 + 10 * arr.length);
  doc.line(180, 72 + 10 * arr.length, 198, 72 + 10 * arr.length);
  doc.setFontSize(11);
  let i = 0;
  arr.forEach((a) => {
    if (arRecom[Number(a.slice(-1))]) {
      doc.text(
        arRecom[Number(a.slice(-1))] + " - ",
        190,
        80 + 10 * arr.length + 10 * i,
        { align: "right" }
      );
    }
    i += 1;
  });
  doc.save(d.esis);
};
