import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import kbEncoder from "./encode";
const SuperadminFinalChecker = () => {
  const [marksData, setMarksData] = useState([]);
  const [marksData2, setMarksData2] = useState([]);
  const [marksData3, setMarksData3] = useState([]);
  const [databeh, setDatabeh] = useState([]);
  const [att, setDataAtt] = useState([]);
  const [quizzes, setDataQuizzes] = useState([]);
  const [gradedassignments, setDataGradedassignments] = useState([]);
  const [unitassessments, setDataunitassessments] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const generalQuery = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setLoading(false);
      if (p === "getclasses") {
        setClasses(resp.filter((r) => r.homroom !== "null"));
      }
      if (p === "behatt2223t3") {
        setDatabeh(resp);
        setDataAtt(resp);
      }
      // if (p === "getDataAtt") {
      //   setDataAtt(resp);
      // }
      if (p === "unitassessments2223t3") {
        setDataunitassessments(resp);
      }
      if (p === "quizzes2223t3") {
        setDataQuizzes(resp);
      }
      if (p === "gradedassignments2223t3") {
        setDataGradedassignments(resp);
      }
      if (p === "getmarkssummary") {
        setMarksData(cleanData(resp));
      }
      if (p === "getmarkssummary2") {
        setMarksData2(cleanData(resp));
      }
      if (p === "getmarkssummary3") {
        setMarksData3(cleanData(resp));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cleanData = (resp) => {
    ["math", "science", "eco", "gov", "islamic", "ss"].forEach((sub) => {
      resp.forEach((r) => {
        if (r[sub] === null || r[sub] === "null") {
          r[sub] = "";
        }
        if (r[sub + "eot"] === null || r[sub + "eot"] === "null") {
          r[sub + "eot"] = "";
        }
        if (r[sub + "grade"] === null || r[sub + "grade"] === "null") {
          r[sub + "grade"] = "";
        }
      });
    });
    ["arabic", "english"].forEach((sub) => {
      resp.forEach((r) => {
        if (r[sub] === null || r[sub] === "null") {
          r[sub] = "";
        }
        if (r[sub + "reading"] === null || r[sub + "reading"] === "null") {
          r[sub + "reading"] = "";
        }
        if (r[sub + "writing"] === null || r[sub + "writing"] === "null") {
          r[sub + "writing"] = "";
        }
        if (r[sub + "eot"] === null || r[sub + "eot"] === "null") {
          r[sub + "eot"] = "";
        }
        if (r[sub + "grade"] === null || r[sub + "grade"] === "null") {
          r[sub + "grade"] = "";
        }
      });
    });

    return resp;
  };
  useEffect(() => {
    generalQuery(`select * from finalsheet2223t1`, "getmarkssummary");
    generalQuery(
      `select * from finalsheet2223t2 where dropped is null`,
      "getmarkssummary2"
    );
    generalQuery(
      `select * from finalsheet2223t3 where dropped is null`,
      "getmarkssummary3"
    );
    generalQuery(
      `select distinct(homroom) from students order by homroom`,
      "getclasses"
    );
    generalQuery(
      `select homeroom,arabict as arabic,artt as art,compt as comp,dramat as drama,ecot as eco,englisht as english,govt as gov,islamict as
      islamic,matht as math,musict as music,pet as pe,sciencet as science,sst as ss from behatt2223t3`,
      "getBehaviourData"
    );
    generalQuery(
      `select homeroom,arabicatt as arabic ,artatt as art ,compatt as comp ,dramaatt as drama , 
       ecoatt as eco ,englishatt as english ,govatt as gov ,islamicatt as islamic ,mathatt as math ,
       musicatt as music ,peatt as pe ,scienceatt as science ,ssatt as ss from behatt2223t3`,
      "getDataAtt"
    );

    [
      "behatt2223t3",
      "unitassessments2223t3",
      "quizzes2223t3",
      "gradedassignments2223t3",
    ].forEach((a) => {
      generalQuery(
        `select homeroom,dropped,arabict as arabic,artt as art,compt as comp,dramat as drama,ecot as eco,englisht as english,govt as gov,islamict as islamic,matht as math,musict as music,pet as pe,sciencet as science,sst as ss from ${a} where dropped is null`,
        a
      );
    });
    [
      "math",
      "arabic",
      "science",
      "english",
      "art",
      "comp",
      "drama",
      "eco",
      "gov",
      "islamic",
      "music",
      "pe",
      "ss",
    ].forEach((sub) => {
      generalQuery(
        `select GA.esis,GA.name,GA.arabicname,GA.homeroom,GA.${sub}t as ga,Q.${sub}t as qz,UA.${sub}t as ua,BA.${sub}t as beh,BA.${sub}att as att,Fnl.${sub} as total,Fnl.${sub}grade as score, Fnl.${sub}eot as endofterm from gradedassignments GA inner join quizzes Q on GA.esis= Q.esis inner join unitassessments UA on UA.esis=Q.esis inner join term1finalsheet Fnl on Fnl.esis=Q.esis inner join behatt BA on BA.esis=UA.esis order by GA.homeroom`,
        "csvdata"
      );
    });
  }, []);
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  const countSubject = (sub, cla) => {
    let classGradedAssignments = gradedassignments.filter(
      (gas) => gas.homeroom === cla
    );
    let classQuizzes = quizzes.filter((gas) => gas.homeroom === cla);
    let classUnitassessments = unitassessments.filter(
      (gas) => gas.homeroom === cla
    );
    let classBeh = databeh.filter((gas) => gas.homeroom === cla);
    let classAtt = att.filter((gas) => gas.homeroom === cla);
    let counter = 0;
    classGradedAssignments.forEach((a) => {
      if (a[sub] !== null) {
        if (!isNaN(Number(a[sub]))) {
          counter++;
        }
      }
    });
    classQuizzes.forEach((a) => {
      if (a[sub] !== null) {
        if (!isNaN(Number(a[sub]))) {
          counter++;
        }
      }
    });
    classUnitassessments.forEach((a) => {
      if (a[sub] !== null) {
        if (!isNaN(Number(a[sub]))) {
          counter++;
        }
      }
    });
    classBeh.forEach((a) => {
      if (a[sub] !== null) {
        if (!isNaN(Number(a[sub]))) {
          counter++;
        }
      }
    });
    classAtt.forEach((a) => {
      if (a[sub] !== null) {
        if (!isNaN(Number(a[sub]))) {
          counter++;
        }
      }
    });
    return Math.floor(((counter / 5) * 100) / classAtt.length);
  };

  const headers = [
    { label: "esis", key: "esis" },
    { label: "name", key: "name" },
    { label: "arabicname", key: "arabicname" },
    { label: "homeroom", key: "homeroom" },
    { label: "islamicTotal", key: "islamiceot" },
    { label: "ArTotal", key: "arabiceot" },
    { label: "mathTotal", key: "matheot" },
    { label: "EnTotal", key: "englisheot" },
    { label: "scienceTotal", key: "scienceeot" },
    { label: "ssTotal", key: "sseot" },
    { label: "compTotal", key: "compeot" },
    { label: "moralTotal", key: "sseot" },
    { label: "artTotal", key: "arteot" },
    { label: "musicTotal", key: "musiceot" },
    { label: "peTotal", key: "peeot" },
    { label: "dramaTotal", key: "dramaeot" },
    { label: "ArReading", key: "arabicreading" },
    { label: "ArWriting", key: "arabicwriting" },
    { label: "ArGrade", key: "arabicgrade" },
    { label: "EnReading", key: "englishreading" },
    { label: "EnWriting", key: "englishwriting" },
    { label: "EnGrade", key: "englishgrade" },
    { label: "mathExam", key: "math" },
    { label: "mathGrade", key: "mathgrade" },
    { label: "scienceExam", key: "science" },
    { label: "scienceGrade", key: "sciencegrade" },
    { label: "islamicExam", key: "islamic" },
    { label: "islamicGrade", key: "islamicgrade" },
    { label: "ssExam", key: "ss" },
    { label: "ssGrade", key: "ssgrade" },
    { label: "ecoExam", key: "eco" },
    { label: "ecoTotal", key: "ecoeot" },
    { label: "ecoGrade", key: "ecograde" },
    { label: "govExam", key: "gov" },
    { label: "govTotal", key: "goveot" },
    { label: "govGrade", key: "govgrade" },
    { label: "peGrade", key: "pegrade" },
    { label: "dramaGrade", key: "dramagrade" },
    { label: "musicGrade", key: "musicgrade" },
    { label: "artGrade", key: "artgrade" },
    { label: "compGrade", key: "compgrade" },
  ];
  const headers2 = [
    { label: "esis", key: "esis" },
    { label: "name", key: "name" },
    { label: "arabicname", key: "arabicname" },
    { label: "homeroom", key: "homeroom" },
    { label: "islamiceot", key: "islamiceot" },
    { label: "islamicFormative", key: "islamic" },
    { label: "arabicFormative", key: "arabic" },
    // { label: "islamicGrade", key: "islamicgrade" },
    // { label: "ArReading", key: "arabicreading" },
    // { label: "ArWriting", key: "arabicwriting" },
    { label: "Areot", key: "arabiceot" },
    // { label: "ArGrade", key: "arabicgrade" },
    // { label: "EnReading", key: "englishreading" },
    // { label: "EnWriting", key: "englishwriting" },
    { label: "EnFormative", key: "english" },
    { label: "Eneot", key: "englisheot" },
    { label: "mathFormative", key: "math" },
    // { label: "mathGrade", key: "mathgrade" },
    { label: "matheot", key: "matheot" },
    // { label: "EnGrade", key: "englishgrade" },
    { label: "scienceFormative", key: "science" },
    // { label: "scienceGrade", key: "sciencegrade" },
    { label: "scienceeot", key: "scienceeot" },
    { label: "ssFormative", key: "ss" },
    // { label: "ssGrade", key: "ssgrade" },
    { label: "sseot", key: "sseot" },
    { label: "moraleot", key: "sseot" },
    { label: "compeot", key: "compeot" },
    // { label: "compGrade", key: "compgrade" },
    { label: "arteot", key: "arteot" },
    // { label: "artGrade", key: "artgrade" },
    { label: "musiceot", key: "musiceot" },
    // { label: "musicGrade", key: "musicgrade" },
    // { label: "peGrade", key: "pegrade" },
    { label: "peeot", key: "peeot" },
    // { label: "dramaGrade", key: "dramagrade" },
    { label: "dramaeot", key: "dramaeot" },
    { label: "ecoFormative", key: "eco" },
    { label: "ecoeot", key: "ecoeot" },
    // { label: "ecoGrade", key: "ecograde" },
    { label: "govFormative", key: "gov" },
    { label: "goveot", key: "goveot" },
    // { label: "govGrade", key: "govgrade" },
  ];
  if (gradedassignments.length === 0) {
    return <CircularProgress />;
  }
  return (
    <div className="container">
      <div style={{ width: "80%" }}>
        {marksData.length > 0 && (
          <>
            <div>
              <CSVLink
                data={marksData}
                headers={headers}
                filename="Download Marks Summary Term 1"
              >
                Download Marks Summary Term 1
              </CSVLink>
            </div>
            <div>
              <CSVLink
                data={marksData2}
                headers={headers2}
                filename="Download Marks Summary Term 2"
              >
                Download Marks Summary Term 2
              </CSVLink>
            </div>
            <div>
              <CSVLink
                data={marksData3}
                headers={headers2}
                filename="Download Marks Summary Term 3"
              >
                Download Marks Summary Term 3
              </CSVLink>
            </div>
          </>
        )}
        <div style={{ textAlign: "center" }}>
          <h1> Completion of Marks Entry Term 3 (Formative Assessment ONLY</h1>
          <h1> (التقويم البنائي فقط(اكمال ادخال درجات الفصل الثالث</h1>
        </div>
        <div className="container"></div>
        <div className="container" style={{ textAlign: "center" }}>
          {gradedassignments.length > 0 ? (
            <div>
              <table className="marksEntryTracker">
                <thead>
                  <tr>
                    <th>Class</th>
                    <th>En</th>
                    <th>Math</th>
                    <th>Sc</th>
                    <th>Ar</th>
                    <th>IsSt</th>
                    <th>SoSt</th>
                    <th>Drama</th>
                    <th>Art</th>
                    <th>Music</th>
                    <th>PE</th>
                    <th>Comp</th>
                    <th>FL</th>
                    <th>His</th>
                  </tr>
                </thead>
                <tbody>
                  {classes.map((c) => {
                    return (
                      <tr key={c.homroom}>
                        <td>{c.homroom}</td>
                        <td
                          className={
                            countSubject("english", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("english", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("english", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("math", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("math", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("math", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("science", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("science", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("science", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("arabic", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("arabic", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("arabic", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("islamic", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("islamic", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("islamic", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("ss", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("ss", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("ss", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("drama", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("drama", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("drama", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("art", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("art", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("art", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("music", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("music", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("music", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("pe", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("pe", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("pe", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("comp", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("comp", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("comp", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("eco", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("eco", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("eco", c.homroom) + "%"}
                        </td>
                        <td
                          className={
                            countSubject("gov", c.homroom) === 100
                              ? "greenCell"
                              : countSubject("gov", c.homroom) === 0
                              ? "redCell"
                              : "orangeCell"
                          }
                        >
                          {countSubject("gov", c.homroom) + "%"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default SuperadminFinalChecker;
