import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import generalQuery from "../../generalQuery";
import "./StandardsForm.css";

const StandardsForm = ({
  selectedStudentNumber,
  settingShowForm,
  maxTotal,
  total,
  setTotal,
  toUpdate,
  selectedSub,
  term,
  test,
  year,
  studentDataSetting,
  showSetting,
  formData,
  editable,
  formDataSetting,
  toEdit,
}) => {
  const [refresher, setRefresher] = useState();
  const [successBox, setSuccessBox] = useState(false);
  useEffect(() => {
    for (let i = 0; i < formData.length; i++) {
      if (document.getElementById("grade" + i)) {
        document.getElementById("grade" + i).value = "";
      }
    }
    if (toEdit) {
      formData.forEach((e) => {
        e.grade = "";
        e.recommended = "";
        if (document.getElementById("grade" + formData.indexOf(e))) {
          document.getElementById("grade" + formData.indexOf(e)).placeholder =
            "";
        }
        if (document.getElementById("grade" + formData.indexOf(e))) {
          document.getElementById(
            "grade" + formData.indexOf(e)
          ).style.backgroundColor = "pink";
        }
        // if (
        //   document.getElementById("recommended" + formData.indexOf(e))
        //     .placeholder
        // ) {
        //   document.getElementById(
        //     "recommended" + formData.indexOf(e)
        //   ).placeholder = "";
        // }
      });
    }
  }, [refresher]);

  const MarkInput = (n, d) => {
    return (
      <div>
        <input
          autoComplete="off"
          style={
            n === "grade" &&
            (d[n] === "null" ||
              d[n] === "" ||
              d[n] === null ||
              d[n] === "undefined")
              ? { backgroundColor: "pink" }
              : n === "grade"
              ? { backgroundColor: "lightgreen" }
              : { backgroundColor: "white" }
          }
          id={n + formData.indexOf(d)}
          type={n === "grade" ? "number" : "text"}
          className={n === "grade" ? "select marks " : "select"}
          placeholder={d[n] !== "null" ? d[n] : ""}
          onChange={(e) => {
            d[n] = e.target.value;
            if (n === "grade") {
              let sum = 0;
              formData.forEach((el) => {
                if (!isNaN(Number(el[n]))) {
                  sum = sum + Number(el[n]);
                }
              });
              setTotal(sum);
              if (Number(e.target.value) > Number(d.max)) {
                sum = sum - Number(d[n]);
                setTotal(sum);
                alert(`Entry must be less than or equal ${d.max}`);
                document.getElementById(
                  n + formData.indexOf(d)
                ).style.backgroundColor = "pink";
                document.getElementById(n + formData.indexOf(d)).value = "";
              } else {
                if (e.target.value === "") {
                  document.getElementById(
                    n + formData.indexOf(d)
                  ).style.backgroundColor = "pink";
                } else {
                  document.getElementById(
                    n + formData.indexOf(d)
                  ).style.backgroundColor = "lightgreen";
                }
              }
            }
          }}
          onKeyPress={() => {
            if (formData.indexOf(d) === formData.length - 1) {
              document.getElementById(n + 0).focus();
            } else {
              document.getElementById(n + +(+formData.indexOf(d) + 1)).focus();
            }
          }}
        />
      </div>
    );
  };

  if (successBox) {
    return (
      <div className="successMessage">
        <div className="message">
          Saved Successfuly
          <br />
          <div className="container">
            <button
              onClick={() => {
                setSuccessBox(false);
              }}
            >
              Ok
            </button>
          </div>{" "}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container">
        <div className="container" style={{ width: "90%" }}>
          <button
            className="select"
            onClick={() => {
              showSetting(false);
              studentDataSetting([]);
              formDataSetting([]);
              setRefresher(Math.random());
            }}
          >
            Cancel
          </button>

          {editable && term === "Term 4" ? (
            ""
          ) : (
            <button
              className="savebtn"
              onClick={() => {
                if (test === "End of Term 3") {
                  if (selectedSub === "arabic") {
                    generalQuery(
                      `update finalsheet2324t1 set arabicreading=${total} where esis='${selectedStudentNumber}'`
                    );
                  } else if (selectedSub === "english") {
                    generalQuery(
                      `update finalsheet2324t1 set englishreading=${total} where esis='${selectedStudentNumber}'`
                    );
                  } else {
                    generalQuery(
                      `update finalsheet2324t1 set ${
                        selectedSub + "eot"
                      }=${total} where esis='${selectedStudentNumber}'`
                    );
                  }
                }
                if (test === "Unit Assessment 1" && term === "Term 3") {
                  generalQuery(
                    `update unitassessments2324t1 set ${selectedSub}1='${total}', ${selectedSub}2='${total}',${selectedSub}t='${total}',${selectedSub}all1='${maxTotal}',${selectedSub}all2='${maxTotal}' where esis='${selectedStudentNumber}'`
                  );
                }
                formData.forEach((d) => {
                  if (toUpdate === "y") {
                    generalQuery(
                      `update assessmentsmarks set grade='${d.grade}', recommended='${d.recommended}' where  esis='${d.esis}' and academic_year='${year}' and subject='${selectedSub}' and term='${term}' and assessment_type='${test}' and q_id='${d.q_id}'`
                    );
                  } else if (toUpdate === "n") {
                    generalQuery(
                      `INSERT INTO assessmentsmarks(esis, name, arabicname, homeroom, q_id, academic_year, term, grade_level, subject, assessment_type, q_division, learning_outcomes, reference_lesson, ccss, dok, max, grade, recommended,serial) VALUES ('${d.esis}', '${d.name}', '${d.arabicname}','${d.homeroom}', '${d.q_id}', '${d.academic_year}', '${d.term}', '${d.grade_level}', '${d.subject}', '${d.assessment_type}', '${d.q_division}', '${d.learning_outcomes}', '${d.reference_lesson}', '${d.ccss}', '${d.dok}', '${d.max}', '${d.grade}', '${d.recommended}','${d.serial}')`
                    );
                  }
                });
                studentDataSetting([]);
                settingShowForm(false);
                setTotal(0);
                setRefresher(Math.random());
                setSuccessBox(true);
              }}
            >
              <SaveIcon fontSize="large" />
            </button>
          )}
        </div>
        <div>
          Total
          <span style={{ padding: "5px", color: "green", fontSize: "50px" }}>
            {total + "/" + maxTotal}
          </span>
        </div>
      </div>

      <table id="examtimetable">
        <thead>
          <tr>
            <th>#</th>
            <th>Q</th>
            <th>Learning Outcomes</th>
            <th>Reference Lesson</th>
            <th>CCSS/NGSS</th>
            <th>DOK</th>
            <th>Max Grade</th>
            <th>Grade</th>
            {/* <th>Recommendation</th> */}
          </tr>
        </thead>
        <tbody>
          {formData
            .sort((a, b) => (Number(a.serial) > Number(b.serial) ? 1 : -1))
            .map((f) => {
              return (
                <tr key={f.q_id}>
                  <td>{f.serial}</td>
                  <td>{f.q_division}</td>
                  <td>{f.learning_outcomes}</td>
                  <td>{f.reference_lesson}</td>
                  <td>{f.ccss}</td>
                  <td>{f.dok}</td>
                  <td>{f.max}</td>
                  <td className={!editable ? "green" : ""}>
                    {editable
                      ? MarkInput("grade", f)
                      : f.grade !== "null" || f.grade !== "undefined"
                      ? f.grade
                      : ""}
                  </td>
                  {/* <td className={!editable ? "green" : ""}>
                    {editable
                      ? MarkInput("recommended", f)
                      : f.recommended !== "null"
                      ? f.recommended
                      : ""}
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default StandardsForm;
