import { selectClasses } from "@mui/material";
import React from "react";

const TimetableRooms = ({ roomsObjects, sectionObjects }) => {
  const periods = ["p1", "p2", "p3", "p4", "p5", "p6"];
  function generateColorCode(text) {
    // Calculate a unique number based on the text
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the number to RGB components
    // const r = Math.floor(text.length*256/10)
    // const g = Math.floor(text.length*598/20)
    // const b = Math.floor(text.length*300/5)
    const lighterValue = 60;
    let r = (hash & 0xff0000) >> 16;
    let g = (hash & 0x00ff00) >> 8;
    let b = hash & 0x0000ff;
    r = Math.min(255, r + lighterValue);
    g = Math.min(255, g + lighterValue);
    b = Math.min(255, b + lighterValue);
    // Format the RGB components into a valid RGB color code
    const colorCode = `rgb(${r}, ${g}, ${b})`;
    if (text !== "null") {
      return colorCode;
    } else {
      return "white";
    }
  }
  function rgbStringToArray(rgbString) {
    // Remove the 'rgb(' prefix and ')' suffix to get only the numbers
    const rgbValues = rgbString.slice(4, -1);
    // Split the values by ',' to get an array of strings
    const rgbArray = rgbValues
      .split(",")
      .map((value) => parseInt(value.trim(), 10));
    return rgbArray;
  }
  function getFontColorForBackground(background) {
    const [R, G, B] = background;
    const relativeLuminance = (0.2126 * R + 0.7152 * G + 0.0722 * B) / 255;

    return relativeLuminance > 0.5 ? "black" : "white";
  }
  return (
    <div>
      <div >
       <div className="container" style={{fontSize:"36px",fontWeight:"bolder"}}>{roomsObjects.room}</div> 
        <table className="schedule-table">
          <tbody>
            <tr>
              <th></th>
              {/* <th className="timetableHeader">Homeroom</th> */}
              {periods.map((p, pIndex) => {
                return <th key={pIndex + 10}>{p}</th>;
              })}
            </tr>
            {["m", "t", "w", "th", "f"].map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <th className="timetableHeader">
                  {
                    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"][
                      ["m", "t", "w", "th", "f"].indexOf(rowData)
                    ]
                  }
                </th>

                {periods.map((p, pIndex) => {
                  return (
                    <td
                      key={pIndex}
                      style={{
                        backgroundColor:
                          sectionObjects.filter(
                            (e) =>
                              e.section === roomsObjects[rowData + "Class"][p]
                          ).length > 0
                            ? `${generateColorCode(
                                sectionObjects.filter(
                                  (e) =>
                                    e.section ===
                                    roomsObjects[rowData + "Class"][p]
                                )[0][rowData + "Teacher"][p]
                              )}`
                            : "white",
                        color:
                          sectionObjects.filter(
                            (e) =>
                              e.section === roomsObjects[rowData + "Class"][p]
                          ).length > 0
                            ? getFontColorForBackground(
                                rgbStringToArray(
                                  generateColorCode(
                                    sectionObjects.filter(
                                      (e) =>
                                        e.section ===
                                        roomsObjects[rowData + "Class"][p]
                                    )[0][rowData + "Teacher"][p]
                                  )
                                )
                              )
                            : "white",
                      }}
                    >
                      <div className="">
                        {roomsObjects[rowData + "Class"][p]}
                      </div>
                      <div className="">
                        {sectionObjects.filter(
                          (e) =>
                            e.section === roomsObjects[rowData + "Class"][p]
                        ).length > 0
                          ? sectionObjects.filter(
                              (e) =>
                                e.section === roomsObjects[rowData + "Class"][p]
                            )[0][rowData + "Sub"][p]
                          : ""}
                      </div>
                      <div className="homeroomTeacher">
                        {sectionObjects.filter(
                          (e) =>
                            e.section === roomsObjects[rowData + "Class"][p]
                        ).length > 0
                          ? sectionObjects
                              .filter(
                                (e) =>
                                  e.section ===
                                  roomsObjects[rowData + "Class"][p]
                              )[0]
                              [rowData + "Teacher"][p].split(" ")
                              .slice(0, 1)
                              .join(" ") +
                            " " +
                            sectionObjects
                              .filter(
                                (e) =>
                                  e.section ===
                                  roomsObjects[rowData + "Class"][p]
                              )[0]
                              [rowData + "Teacher"][p].split(" ")
                              .slice(1, 2)
                              .join(" ")
                          : ""}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <br></br>
      </div>
    </div>
  );
};

export default TimetableRooms;
