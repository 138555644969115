import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import datesArr from "./intervTimetable";
import "./InterTimetable.css"; // Add your styles here

const InterTimetable = () => {
  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(1); // Default to Week 1
  const [interTimetableData, setInterTimetableData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [allData, setAllData] = useState([]); // Store all fetched data

  useEffect(() => {
    // Generate week options based on the updated datesArr
    const uniqueWeeks = [...new Set(datesArr.map((date) => date.week))];
    setWeeks(uniqueWeeks);
    setSelectedWeek(uniqueWeeks[0]);

    // Fetch all necessary data from interventiongroups25 in a single query
    generalQuery(
      `SELECT * FROM interventiongroups25 WHERE teacher IS NOT NULL ORDER BY teacher`
    ).then((results) => {
      setAllData(results);

      // Extract unique teacher names from the fetched data
      const uniqueTeachers = [...new Set(results.map((r) => r.teacher))].sort(
        (a, b) => a.localeCompare(b)
      );
      setTeachers(uniqueTeachers);
    });
  }, []);

  useEffect(() => {
    if (selectedWeek && allData.length > 0) {
      // Filter the datesArr for the selected week
      const weekDates = datesArr.filter((date) => date.week === selectedWeek);

      // Initialize the InterTimetable structure
      const interTimetable = teachers.map((teacher) => {
        const teacherTimetable = { teacher, subject: "" };
        weekDates.forEach((date) => {
          const group = allData.find(
            (group) =>
              group.teacher === teacher && group.cluster === date.cluster
          );
          if (group) {
            teacherTimetable.subject = group.subject || "";
            teacherTimetable[date.day.toLowerCase()] = group.group;
          } else {
            teacherTimetable[date.day.toLowerCase()] = "";
          }
        });
        return teacherTimetable;
      });

      setInterTimetableData(interTimetable);
    }
  }, [selectedWeek, teachers, allData]);

  const handleWeekChange = (event) => {
    setSelectedWeek(Number(event.target.value));
  };

  return (
    <div className="inter-timetable-container">
      <div className="week-selector">
        <label>Select Week:</label>
        <select value={selectedWeek} onChange={handleWeekChange}>
          {weeks.map((week, index) => (
            <option key={index} value={week}>
              {`Week ${week} - ${
                datesArr.find((date) => date.week === week).date
              }`}
            </option>
          ))}
        </select>
      </div>
      <table className="inter-timetable">
        <thead>
          <tr>
            <th>Teacher</th>
            <th>Subject</th>
            {datesArr
              .filter((date) => date.week === selectedWeek)
              .map((date, index) => (
                <th key={index}>
                  {date.day}
                  <br />
                  {date.date}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {interTimetableData.map((row, index) => (
            <tr key={index}>
              <td>{row.teacher}</td>
              <td>{row.subject}</td>
              {datesArr
                .filter((date) => date.week === selectedWeek)
                .map((date, index) => (
                  <td key={index}>{row[date.day.toLowerCase()]}</td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InterTimetable;
