// const StandardsUploader = () => {
import React, { useState, useEffect } from "react";
import "./StandardsUploader.css";
import generalQuery from "../../generalQuery";
import exportDataToExcel from "../../exportDataToExcel";
import readXlsxFile from "read-excel-file";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IoChevronBackCircleOutline } from "react-icons/io5";

const StandardsUploader = ({ SettinghowAssessmentForm }) => {
  const [academicYear, setAcademicYear] = useState("");
  const [term, setTerm] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  const [subject, setSubject] = useState("");
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event) => {
    setData([]);
    let newRowData = [];
    readXlsxFile(event.target.files[0]).then((rows) => {
      rows.slice(1, rows.length).forEach((r) => {
        let newRaw = {};
        newRaw.serial = r[0];
        newRaw.q_division = r[1];
        newRaw.learning_outcomes = r[2];
        newRaw.reference_lesson = r[3];
        newRaw.ccss = r[4];
        newRaw.dok = r[5];
        newRaw.max = r[6];
        newRaw.grade_level = r[7];
        newRaw.academic_year = academicYear;
        newRaw.assessment_type = assessmentType;
        newRaw.term = term;
        newRaw.subject = subject;
        newRaw.q_id = (academicYear + term + r[7] + assessmentType + r[0])
          .toLowerCase()
          .replace(/\s/g, "");
        newRowData.push(newRaw);
      });
      setUploadedData(newRowData);
    });
  };

  const handleUpload = () => {
    // Use a library like XLSX to parse the uploaded file
    setLoading(true);
    uploadedData.forEach((item) => {
      generalQuery(
        `INSERT INTO public.assessmentstandards(q_id, academic_year, term, grade_level, subject, assessment_type, q_division, learning_outcomes, reference_lesson, ccss, dok, max, grade, recommended, serial) VALUES ('${
          item.q_id
        }', '${item.academic_year}', '${item.term}', '${item.grade_level}','${
          item.subject
        }', '${item.assessment_type}', '${
          item.q_division
        }','${item.learning_outcomes.replace("'", "")}', '${
          item.reference_lesson
        }','${item.ccss}','${item.dok}', '${item.max}', '', '', '${
          item.serial
        }')`
      ).then(() => {
        if (uploadedData.indexOf(item) === uploadedData.length - 1) {
          setData([...uploadedData]);
          setLoading(false);
        }
      });
    });
  };

  const handleDelete = (index, id) => {
    generalQuery(
      `delete from assessmentstandards where q_id='${id}' and subject='${subject}'`
    ).then(() => {
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
    });
  };
  const XlsxLink = (allData, header, strName, txt) => {
    return (
      <a
        style={{ fontWeight: "bold", fontSize: "15px" }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          exportDataToExcel(allData, header, strName);
        }}
      >
        {txt}
      </a>
    );
  };

  return (
    <div className="upload-data">
      <div
        style={{ float: "left", cursor: "pointer", marginTop: "-30px" }}
        onClick={() => {
          SettinghowAssessmentForm(false);
        }}
      >
        <IoChevronBackCircleOutline size={60} />
      </div>
      <div className="upload-form">
        <div className="select-container">
          <label htmlFor="academic-year">Academic Year:</label>
          <select
            className="select"
            id="academic-year"
            value={academicYear}
            onChange={(e) => {
              setData([]);
              setShowData(false);
              setAcademicYear(e.target.value);
            }}
          >
            <option value=""></option>
            <option value="24-25">2024-2025</option>
            {/* <option value="2022-2023">2023-2023</option> */}
          </select>
          <label htmlFor="grade-level">Grade Level:</label>
          <select
            className="select"
            id="grade-level"
            value={gradeLevel}
            onChange={(e) => {
              setData([]);
              setShowData(false);
              setGradeLevel(e.target.value);
            }}
          >
            <option value=""></option>
            <option value="8">Grade 8</option>
            <option value="9">Grade 9</option>
            <option value="10">Grade 10</option>
            <option value="11">Grade 11</option>
          </select>
          <label htmlFor="term">Term:</label>
          <select
            className="select"
            id="term"
            value={term}
            onChange={(e) => {
              setData([]);
              setShowData(false);
              setTerm(e.target.value);
            }}
          >
            <option value=""></option>
            {/* <option value="Term 1">Term 1</option> */}
            {(gradeLevel === "9" || gradeLevel === "10"|| gradeLevel === "11") ? (
              <option value="Semester 1">Semester 1</option>
            ) : (
              <option value="Term 1">Term 1</option>
            )}
          </select>

          <label htmlFor="assessment-type">Assessment Type:</label>
          <select
            className="select"
            id="assessment-type"
            value={assessmentType}
            onChange={(e) => {
              setData([]);
              setShowData(false);
              setAssessmentType(e.target.value);
            }}
          >
            <option value=""></option>
            <option value="Key Assessment 1">Key Assessment 1 </option>
            <option value="Key Assessment 2">Key Assessment 2 </option>
            <option value="Key Assessment 3">Key Assessment 3 </option>
            <option value="Key Assessment 4">Key Assessment 4 </option>
            <option value={"End of " + term}>End of Term </option>
          </select>
          <label htmlFor="subject">Subject:</label>
          <select
            className="select"
            id="subject"
            value={subject}
            onChange={(e) => {
              setData([]);
              setShowData(false);
              setSubject(e.target.value);
            }}
          >
            <option value=""></option>
            <option value="math">Math</option>
            <option value="science">Science</option>
            <option value="english">English</option>
            <option value="arabic">Arabic</option>
            <option value="Islamic">Islamic St</option>
            <option value="Social">Social St</option>
          </select>
          <button
            className="select"
            onClick={() => {
              setData([]);

              generalQuery(
                `select * from assessmentstandards where academic_year='${academicYear}' and term ='${term}' and  grade_level='${gradeLevel}'and  assessment_type='${assessmentType}'and  subject='${subject}' order by serial`
              ).then((results) => {
                setData([...data, ...results]);
                setShowData(true);
              });
            }}
          >
            Apply
          </button>
        </div>
      </div>
      {showData ? (
        <div>
          {XlsxLink(
            [],
            [
              "Order",
              "Q#",
              "Learning Outcome",
              "Lesson Reference",
              "CCSS/NGSS",
              "DOK",
              "Max",
              "Grade Level",
            ],
            "AssessLOsTemplate",
            "Download Template XLSX"
          )}
          <div className="input-container">
            <label htmlFor="file-upload">Upload xlsx file:</label>
            <input type="file" id="file-upload" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
          </div>
          <div className="data-table">
            <table>
              <thead>
                <tr>
                  <th> Q</th>
                  <th>Learning Outcomes</th>
                  <th>Reference Lesson</th>
                  <th>CCSS/NGSS</th>
                  <th>DOK</th>
                  <th>Max Grade</th>

                  <th>
                    {data.length > 0 &&
                      XlsxLink(
                        data,
                        Object.keys(data[0]),
                        data[0]["academic_year"] +
                          data[0]["term"] +
                          data[0]["assessment_type"],
                        data[0]["academic_year"] +
                          data[0]["term"] +
                          data[0]["assessment_type"]
                      )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) => (+a.serial > +b.serial ? 1 : -1))
                  .map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.serial}</td>
                      <td>{rowData.learning_outcomes}</td>
                      <td>{rowData.reference_lesson}</td>
                      <td>{rowData.ccss}</td>
                      <td>{rowData.dok}</td>
                      <td>{rowData.max}</td>
                      <td>
                        <button
                          className="red select"
                          onClick={() => handleDelete(index, rowData.q_id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : loading ? (
        <CircularProgress />
      ) : (
        ""
      )}
    </div>
  );
};
export default StandardsUploader;
