import React, { useEffect, useState } from "react";
import "./MinimumExpectations.css"; // Import the styling sheet
import generalQuery from "../generalQuery";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress from Material-UI (or another loading spinner)
import Modal from "@mui/material/Modal"; // Import Modal
import Box from "@mui/material/Box"; // Box for Modal content
import IconButton from "@mui/material/IconButton"; // Button for closing modal
import CloseIcon from "@mui/icons-material/Close"; // Close Icon

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const TalaAnalysis = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);

  const [classFilter, setClassFilter] = useState("8A"); // Initial value set to G8-A
  const [testPercentileFilter, setTestPercentileFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State for modal open/close

  useEffect(() => {
    if (classFilter) {
      setLoading(true);
      generalQuery(
        `select * from talaspring24 where section = '${classFilter}' order by name`
      )
        .then((results) => {
          // Convert testpercentile to number for proper sorting and filtering
          const processedResults = results.map((item) => ({
            ...item,
            testpercentile: Number(item.average)*100,
          }));
          console.log(processedResults);

          setData(processedResults);
          setFilteredData(processedResults);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [classFilter]);

  useEffect(() => {
    let filtered = data;
    if (testPercentileFilter) {
      if (testPercentileFilter === "<40") {
        filtered = data.filter((item) => item.testpercentile < 40);
      } else if (testPercentileFilter === ">40") {
        filtered = data.filter((item) => item.testpercentile >= 40);
      } else if (testPercentileFilter === ">60") {
        filtered = data.filter((item) => item.testpercentile >= 60);
      }
    }
    setFilteredData(filtered);
  }, [testPercentileFilter, data]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleOpenModal = () => {
    setModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  return (
    <div className="minimum-expectations-container">
      <div className="filters">
        <select
          className="select-filter"
          value={classFilter}
          onChange={(e) => setClassFilter(e.target.value)}
        >
          <option value="8A">8A</option>
          <option value="8B">8B</option>
          <option value="8C">8C</option>
          <option value="8D">8D</option>
          <option value="8E">8E</option>
          <option value="8F">8F</option>
          <option value="8G">8G</option>
          <option value="8H">8H</option>
          <option value="8I">8I</option>
          <option value="8J">8J</option>
          <option value="9A">9A</option>
          <option value="9B">9B</option>
          <option value="9C">9C</option>
          <option value="9D">9D</option>
          <option value="9E">9E</option>
          <option value="9F">9F</option>
          <option value="9G">9G</option>
          <option value="9H">9H</option>
          <option value="9I">9I</option>
          <option value="9J">9J</option>
          <option value="9K">9K</option>
          <option value="10A">10A</option>
          <option value="10B">10B</option>
          <option value="10C">10C</option>
          <option value="10D">10D</option>
          <option value="10E">10E</option>
          <option value="10F">10F</option>
          <option value="10G">10G</option>
          <option value="10H">10H</option>
          <option value="11A">11A</option>
          <option value="11B">11B</option>
          <option value="11C">11C</option>
          <option value="11D">11D</option>
          <option value="11E">11E</option>
          <option value="11F">11F</option>
        </select>

        <select
          className="select-filter"
          value={testPercentileFilter}
          onChange={(e) => setTestPercentileFilter(e.target.value)}
        >
          <option value="">Filter by Average</option>
          <option value="<40">Less than 40</option>
          <option value=">40">Greater than 40</option>
          <option value=">60">Greater than 60</option>
        </select>
      </div>

      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <table className="min-expectations-table">
          <thead className="min-expectations-table-header">
            <tr className="min-expectations-table-header-row">
              {[
                "esis",
                "name",
                "class",
                "teacher",
                "vocabulary",
                "reading_informational",
                "reading_literary",
                "writing",

                "average",
              ].map((header) => (
                <th
                  key={header}
                  onClick={() => handleSort(header)}
                  className="min-expectations-table-header-cell"
                >
                  {header.replace("_", " ").toUpperCase()}
                  {sortConfig?.key === header &&
                    (sortConfig.direction === "ascending" ? " 🔼" : " 🔽")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="min-expectations-table-body">
            {filteredData.map((row, index) => (
              <tr key={index} className="min-expectations-table-body-row">
                <td className="min-expectations-table-body-cell">{row.esis}</td>
                <td className="min-expectations-table-body-cell">
                  {row.name}
                  <br />
                  {/* <button
                    style={{ backgroundColor: "lightgreen", cursor: "pointer" }}
                    onClick={handleOpenModal}
                  >
                    TALA Targets
                  </button> */}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.section}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.teachers}
                </td>
                <td className="min-expectations-table-body-cell">
                {row.vocabulary === "NaN"
                    ? ""
                    :Math.floor(Number(row.vocabulary) * 100)}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.reading_informational === "NaN"
                    ? ""
                    :Math.floor(Number(row.reading_informational) * 100)}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.reading_literary === "NaN"
                    ? ""
                    :Math.floor(Number(row.reading_literary) * 100)}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.writing === "NaN"
                    ? ""
                    : Math.floor(Number(row.writing) * 100)}
                </td>
                <td className="min-expectations-table-body-cell">
                  {row.average === "NaN"
                    ? ""
                    : Math.floor(Number(row.average) * 100)}
                </td>

               
              
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal with empty content */}
    
    </div>
  );
};

export default TalaAnalysis;
