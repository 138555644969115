import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import generalQuery from "../generalQuery";
import readXlsxFile from "read-excel-file";
import exportDataToExcel from "../exportDataToExcel";
import { CSVLink } from "react-csv";
const SuperadminEndOfYear = () => {
  const [marksData, setMarksData] = useState([]);
  const [marksData3, setMarksData3] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [esisData, setEsisData] = useState([]);
  const cleanData = (resp) => {
    ["math", "science", "eco", "gov", "islamic", "ss"].forEach((sub) => {
      resp.forEach((r) => {
        if (r[sub] === null || r[sub] === "null") {
          r[sub] = "";
        }
        if (r[sub + "eot"] === null || r[sub + "eot"] === "null") {
          r[sub + "eot"] = "";
        }
        if (r[sub + "grade"] === null || r[sub + "grade"] === "null") {
          r[sub + "grade"] = "";
        }
      });
    });
    ["arabic", "english"].forEach((sub) => {
      resp.forEach((r) => {
        if (r[sub] === null || r[sub] === "null") {
          r[sub] = "";
        }
        if (r[sub + "reading"] === null || r[sub + "reading"] === "null") {
          r[sub + "reading"] = "";
        }
        if (r[sub + "writing"] === null || r[sub + "writing"] === "null") {
          r[sub + "writing"] = "";
        }
        if (r[sub + "eot"] === null || r[sub + "eot"] === "null") {
          r[sub + "eot"] = "";
        }
        if (r[sub + "grade"] === null || r[sub + "grade"] === "null") {
          r[sub + "grade"] = "";
        }
      });
    });

    return resp;
  };
  useEffect(() => {
    generalQuery(`select * from finalsheet2223t1`).then((resp) => {
      setMarksData(cleanData(resp));
      generalQuery(`select * from term2loaded `).then((resp2) => {
        generalQuery(
          `select * from finalsheet2223t3 where dropped is null`
        ).then((resp3) => {
          setMarksData3(cleanData(resp3));
          let subjects = [
            "islamic",
            "arabic",
            "math",
            "english",
            "science",
            "ss",
            "pe",
            "drama",
            "eco",
            "gov",
            "comp",
            "art",
            "music",
          ];

          resp3.forEach((r) => {
            subjects.forEach((a) => {
              if (isNaN(r[a + "eot"])) {
                r[a + "eot"] = 0;
              }
            });
          });
          resp2.forEach((r) => {
            if (isNaN(r.mark)) {
              r.mark = 0;
            }
          });
          resp.forEach((r) => {
            subjects.forEach((a) => {
              if (isNaN(r[a + "eot"])) {
                r[a + "eot"] = 0;
              }
            });
          });
          resp3.forEach((r) => {
            subjects.forEach((a) => {
              if (
                resp.filter((e) => e.esis === r.esis)[0] &&
                resp2.filter((e) => e.esis === r.esis && e.subject === a)[0]
              ) {
                r[a + "term1"] = resp.filter((e) => e.esis === r.esis)[0][
                  a + "eot"
                ];

                r[a + "term2"] = resp2.filter(
                  (e) => e.esis === r.esis && e.subject === a
                )[0]["mark"];
                r[a + "Av"] = Math.round(
                  Number(
                    resp2.filter(
                      (e) => e.esis === r.esis && e.subject === a
                    )[0]["mark"]
                  ) *
                    0.15 +
                    Number(
                      resp.filter((e) => e.esis === r.esis)[0][a + "eot"] * 0.15
                    ) +
                    (Number(r[a]) + Number(r[a + "eot"]) * 0.3) * 0.7
                );
              }
              if (Math.ceil(Number(r[a]) + Number(r[a + "eot"]) * 0.3) <= 100) {
                r[a + "term3"] = Math.ceil(
                  Number(r[a]) + Number(r[a + "eot"]) * 0.3
                );
              } else {
                r[a + "term3"] = 100;
              }
              if (
                a === "gov" &&
                (r.homeroom === "G9-A" ||
                  r.homeroom === "G9-C" ||
                  r.homeroom === "G9-E" ||
                  r.homeroom === "G9-G")
              ) {
                r[a + "term3"] = Math.ceil(
                  (r.mathterm3 +
                    r.arabicterm3 +
                    r.scienceterm3 +
                    r.englishterm3) /
                    4
                );
                r[a + "term2"] = Math.ceil(
                  (r.mathterm3 +
                    r.arabicterm3 +
                    r.scienceterm3 +
                    r.englishterm3) /
                    4
                );
              }
              if (a === "eco" && r.homeroom === "G9-B") {
                r[a + "term3"] = Math.ceil(
                  (r.mathterm3 +
                    r.arabicterm3 +
                    r.scienceterm3 +
                    r.englishterm3) /
                    4
                );
              }
              if (a === "eco" || a === "gov") {
                r[a + "Av"] = Math.round(
                  ((resp2.filter((e) => e.esis === r.esis && e.subject === a)
                    .length > 0
                    ? Number(
                        resp2.filter(
                          (e) => e.esis === r.esis && e.subject === a
                        )[0]["mark"]
                      )
                    : 0) +
                    (resp.filter((e) => e.esis === r.esis).length > 0
                      ? Number(
                          resp.filter((e) => e.esis === r.esis)[0][a + "eot"]
                        )
                      : 0) +
                    r[a + "term3"]) /
                    2
                );
              }
              if (
                a === "gov" &&
                (r.homeroom === "G9-A" ||
                  r.homeroom === "G9-C" ||
                  r.homeroom === "G9-E" ||
                  r.homeroom === "G9-G")
              ) {
                r[a + "Av"] = Math.ceil(
                  (r.mathterm3 +
                    r.arabicterm3 +
                    r.scienceterm3 +
                    r.englishterm3) /
                    4
                );
              }
            });
          });
          setData(resp3);
          setLoading(false);
        });
      });
    });
  }, []);
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  let summerSch = [
    "405726",
    "655404",
    "571005",
    "307485",
    "1098832",
    "571050",
    "188807",
    "466915",
    "658866",
    "309532",
    "593466",
    "309768",
    "568562",
    "319599",
    "306380",
    "253902",
    "306922",
    "317153",
    "189260",
    "305413",
    "307425",
    "634700",
    "582312",
    "310159",
    "405898",
    "305389",
    "305498",
    "571040",
    "399183",
    "650913",
    "584718",
    "1120298",
    "191031",
    "317332",
    "310060",
    "191351",
  ];

  const headersAv = [
    { label: "esis", key: "esis" },
    // { label: "name", key: "name" },
    // { label: "arabicname", key: "arabicname" },
    { label: "homeroom", key: "homeroom" },
    { label: "mathterm1", key: "mathterm1" },
    { label: "mathterm2", key: "mathterm2" },
    { label: "mathterm3", key: "mathterm3" },
    { label: "mathAv", key: "mathAv" },
    { label: "Enterm1", key: "englishterm1" },
    { label: "Enterm2", key: "englishterm2" },
    { label: "Enterm3", key: "englishterm3" },
    { label: "EnAv", key: "englishAv" },
    { label: "scienceterm1", key: "scienceterm1" },
    { label: "scienceterm2", key: "scienceterm2" },
    { label: "scienceterm3", key: "scienceterm3" },
    { label: "scienceAv", key: "scienceAv" },
    { label: "arabicterm1", key: "arabicterm1" },
    { label: "arabicterm2", key: "arabicterm2" },
    { label: "arabicterm3", key: "arabicterm3" },
    { label: "arabicAv", key: "arabicAv" },
    { label: "ssterm1", key: "ssterm1" },
    { label: "ssterm2", key: "ssterm2" },
    { label: "ssterm3", key: "ssterm3" },
    { label: "ssAv", key: "ssAv" },
    { label: "compterm1", key: "compterm1" },
    { label: "compterm2", key: "compterm2" },
    { label: "compterm3", key: "compterm3" },
    { label: "compAv", key: "compAv" },
    { label: "moralterm1", key: "ssterm1" },
    { label: "moralterm2", key: "ssterm2" },
    { label: "moralcterm3", key: "ssterm3" },
    { label: "moralAv", key: "ssAv" },
    { label: "artterm1", key: "artterm1" },
    { label: "artterm2", key: "artterm2" },
    { label: "artterm3", key: "artterm3" },
    { label: "artAv", key: "artAv" },
    { label: "musicterm1", key: "musicterm1" },
    { label: "musicterm2", key: "musicterm2" },
    { label: "musicterm3", key: "musicterm3" },
    { label: "musicAv", key: "musicAv" },
    { label: "peterm1", key: "peterm1" },
    { label: "peterm2", key: "peterm2" },
    { label: "peterm3", key: "peterm3" },
    { label: "peAv", key: "peAv" },
    { label: "dramaterm1", key: "dramaterm1" },
    { label: "dramaterm2", key: "dramaterm2" },
    { label: "dramaterm3", key: "dramaterm3" },
    { label: "dramaAv", key: "dramaAv" },
    { label: "ecoterm1", key: "ecoterm1" },
    { label: "ecoterm2", key: "ecoterm2" },
    { label: "ecoterm3", key: "ecoterm3" },
    { label: "ecoAv", key: "ecoAv" },
    { label: "govterm1", key: "govterm1" },
    { label: "govterm2", key: "govterm2" },
    { label: "govterm3", key: "govterm3" },
    { label: "govAv", key: "govAv" },
  ];
  return (
    <div className="container">
      <div style={{ width: "80%" }}>
        {marksData.length > 0 && (
          <>
            <div>
              <CSVLink
                data={marksData3}
                headers={headersAv}
                filename="Download Marks Summary Term 3"
              >
                Marks Summary - End of Year
              </CSVLink>
            </div>
          </>
        )}
        <div style={{ textAlign: "center" }}></div>
        <div className="container"></div>
      </div>
      <input
        type="file"
        accept=".xlsx"
        onChange={(e) => {
          readXlsxFile(e.target.files[0]).then((rows) => {
            // rows.slice(1).forEach((r) => {
            //   let value = data.filter((d) => Number(d.esis) === r[1])[0][
            //     r[5] + "Av"
            //   ];
            //   if (+value < 60) {
            //     r[6] = Math.round(
            //       (60 -
            //         data.filter((d) => Number(d.esis) === r[1])[0][
            //           r[5] + "term2"
            //         ] *
            //           0.15 -
            //         data.filter((d) => Number(d.esis) === r[1])[0][
            //           r[5] + "term1"
            //         ] *
            //           0.15) /
            //         0.7
            //     );
            //   } else {
            //     r[6] = data.filter((d) => Number(d.esis) === r[1])[0][
            //       r[5] + "term3"
            //     ];
            //   }
            //   if (summerSch.includes(r[1].toString())) {
            //     r[6] = data.filter((d) => Number(d.esis) === r[1])[0][
            //       r[5] + "term3"
            //     ];
            //   }
            // });
            // setEsisData(rows);

            rows.slice(1).forEach((r) => {
              if (r[6] === null) {
                r[6] = data.filter((d) => Number(d.esis) === r[1])[0][
                  r[5] + "term3"
                ];
              }
            });
            setEsisData(rows);
          });
        }}
      ></input>
      <button
        onClick={(e) => {
          e.preventDefault();
          exportDataToExcel(esisData, Object.keys(esisData[0]), "toExport");
        }}
      >
        Export
      </button>
    </div>
  );
};

export default SuperadminEndOfYear;
