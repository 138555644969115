import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import endOfTermCard from "./endOfTermCard";
import endOfYearCard from "./endOfYearCard";
import { Button, ButtonGroup } from "@material-ui/core";
import generalQuery from "../../src/generalQuery";
import Portal from "../views/Portal";
import {
  CloudDownload,
  CheckCircle,
  Cancel,
  Block,
  LockOpen,
} from "@material-ui/icons";
import kbEncoder from "./encode";
const EndoFTermReport = () => {
  const [esisInput, setEsisInput] = useState("");
  const [classes, setClasses] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("endOfYearCard");
  const [selectedClass, setSelectedClass] = useState("");
  const [aYear, setAYear] = useState("2223");
  const [portalSwitch, setPortalSwitch] = useState(false);
  const pdfUrlt1 = `${process.env.PUBLIC_URL}/Reportst12324/`;
  const pdfUrlt2 = `${process.env.PUBLIC_URL}/Reportst22324/`;

  const generalQueryOriginal = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      setLoading(false);
      if (p === "getClasses") {
        setClasses(resp.filter((r) => r.homroom !== "null"));
        setLoading(false);
      }
      if (p === "getClassData") {
        setStudentData(resp);
        setLoading(false);
      }
      if (p === "getreportbyesis") {
        setLoading(false);
        endOfTermCard(resp[0], "individual", [], term);
      }
      if (p === "getreportbyesis2") {
        setLoading(false);
        // endOfTermCard(resp[0], "all", [], term);
        if (term === "endOfYearCard") {
          endOfYearCard(resp[0], "all", [], term);
        } else {
          endOfTermCard(resp[0], "all", [], term);
        }
      }
      if (p === "updateblockage") {
        setLoading(false);
        generalQueryOriginal(
          `select * from finalsheet${
            aYear + term
          } where homeroom='${selectedClass}'`,
          "getClassData"
        );
      }
      if (p === "updateeligibilityage") {
        setLoading(false);
        generalQueryOriginal(
          `select * from finalsheet${
            aYear + term
          } where homeroom='${selectedClass}'`,
          "getClassData"
        );
      }

      if (p === "getClassResults") {
        setLoading(false);
        // endOfTermCard("", "onefile", resp, term);
        if (term === "endOfYearCard") {
          endOfYearCard("", "onefile", resp, term);
        } else {
          endOfTermCard("", "onefile", resp, term);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkIfPdfExists = async (pdfName) => {
    const pdfUrl = `${pdfName}.pdf`;

    try {
      const response = await fetch(pdfUrl, { method: "HEAD" });
      const contentType = response.headers.get("Content-Type");
      if (
        response.ok &&
        contentType &&
        contentType.includes("application/pdf")
      ) {
        setPortalSwitch(true);
      } else {
        alert("PDF does not exist:" + esisInput);
      }
    } catch (error) {
      console.error("Error checking PDF existence:", error);
    }
  };
  useEffect(() => {
    generalQueryOriginal(
      `select distinct(homroom) from students order by homroom`,
      "getClasses"
    );
  }, []);

  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }

  const getDataForEndOfYear = () => {
    {
      setLoading(true);
      generalQuery(
        `select * from endOfYearloaded where homeroom='${selectedClass}'`
      ).then((resp) => {
        // let st = {}
        let dataArr = [];
        let arrEsis = [];
        resp.forEach((r) => {
          if (!arrEsis.includes(r.pupilnumber)) {
            arrEsis.push(r.pupilnumber);
          }
        });

        arrEsis.forEach((e) => {
          let stjson = { esis: e };
          resp
            .filter((res) => res.pupilnumber === e)
            .forEach((d) => {
              stjson[d.subjectname] = d.finalmark;
              stjson.homeroom = d.homeroom;
              stjson.name = d.studentname;
              stjson.arabicname = d.arabicname;
              stjson.block = d.block;
            });
          dataArr.push(stjson);
        });
        setStudentData(dataArr);
        setLoading(false);
      });
    }
  };
  return (
    <div>
      <div className="container">
        <h1>End of Term Report Cards</h1>
      </div>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            setAYear(e.target.value);
          }}
        >
          <option>--Year--</option>
          <option value="2223">2022-2023</option>
          <option value="2224">2023-2024</option>
          {/* <option value="term3">Term 3</option> */}
        </select>
        <select
          className="select"
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        >
          <option>Select Term</option>
          <option value="t1">Term 1</option>
          <option value="t2">Term 2</option>
          <option value="endOfYearCard">End Of Year </option>
        </select>
        {term !== "" && (
          <>
            <select
              className="select"
              onChange={(e) => {
                if (aYear === "2223") {
                  setSelectedClass(e.target.value);
                  if (term === "endOfYearCard") {
                    setLoading(true);
                    generalQuery(
                      `select * from endOfYearloaded where homeroom='${e.target.value}'`
                    ).then((resp) => {
                      // let st = {}
                      let dataArr = [];
                      let arrEsis = [];
                      resp.forEach((r) => {
                        if (!arrEsis.includes(r.pupilnumber)) {
                          arrEsis.push(r.pupilnumber);
                        }
                      });

                      arrEsis.forEach((e) => {
                        let stjson = { esis: e };
                        resp
                          .filter((res) => res.pupilnumber === e)
                          .forEach((d) => {
                            stjson[d.subjectname] = d.finalmark;
                            stjson.homeroom = d.homeroom;
                            stjson.name = d.studentname;
                            stjson.arabicname = d.arabicname;
                            stjson.block = d.block;
                          });
                        dataArr.push(stjson);
                      });
                      setStudentData(dataArr);
                      setLoading(false);
                    });
                  } else {
                    generalQueryOriginal(
                      `select * from finalsheet${
                        aYear + term
                      } where homeroom='${e.target.value}'`,
                      "getClassData"
                    );
                  }
                } else {
                  generalQuery(
                    `select * from students where homroom='${e.target.value}'`
                  ).then((resp) => {
                    // let st = {}
                    let dataArr = [];
                    let arrEsis = [];
                    resp.forEach((r) => {
                      if (!arrEsis.includes(r.studentnumber)) {
                        arrEsis.push(r.studentnumber);
                      }
                    });

                    arrEsis.forEach((e) => {
                      let stjson = { esis: e };
                      resp
                        .filter((res) => res.studentnumber === e)
                        .forEach((d) => {
                          stjson.homeroom = d.homroom;
                          stjson.name = d.englishname;
                          stjson.arabicname = d.username;
                        });
                      dataArr.push(stjson);
                    });
                    setStudentData(dataArr);
                    setLoading(false);
                  });
                }
              }}
            >
              <option>Select a Class</option>
              {classes.map((m) => {
                return (
                  <option key={m.homroom} value={m.homroom}>
                    {m.homroom}
                  </option>
                );
              })}
            </select>
            <div>
              <h4>Or</h4>
            </div>
            <input
              type="number"
              className="select"
              placeholder="eSIS"
              onChange={(e) => setEsisInput(e.target.value)}
            ></input>
            <button
              className="select"
              onClick={() => {
                if (aYear === "2223") {
                  if (term === "endOfYearCard") {
                    generalQuery(
                      `select * from endOfYearloaded where pupilnumber='${esisInput}'`
                    ).then((resp) => {
                      // let st = {}
                      let dataArr = [];
                      let arrEsis = [];
                      resp.forEach((r) => {
                        if (!arrEsis.includes(r.pupilnumber)) {
                          arrEsis.push(r.pupilnumber);
                        }
                      });
                      arrEsis.forEach((e) => {
                        let stjson = { esis: e };
                        resp
                          .filter((res) => res.pupilnumber === e)
                          .forEach((d) => {
                            stjson[d.subjectname] = d.finalmark;
                            stjson.homeroom = d.homeroom;
                            stjson.name = d.studentname;
                            stjson.arabicname = d.arabicname;
                            stjson.block = d.block;
                          });
                        dataArr.push(stjson);
                      });
                      endOfYearCard(dataArr[0], "individual", [], "");
                    });
                  } else {
                    generalQueryOriginal(
                      `select * from finalsheet${
                        aYear + term
                      } where esis='${esisInput}'`,
                      "getreportbyesis"
                    );
                  }
                } else {
                  // Usage example
                  checkIfPdfExists(esisInput);
                }
              }}
            >
              Download
            </button>
            {/* <Button
              startIcon={<CloudDownload />}
              className="select"
              style={{
                backgroundColor: "green",
                color: "#ffffff",
                marginTop: "4px",
              }}
              onClick={async () => {
                for (let i = 0; i < studentData.length; i++) {
                  const s = studentData[i];
                  await new Promise((resolve) => setTimeout(resolve, 10));
                  if (term !== "endOfYearCard") {
                    await generalQueryOriginal(
                      `select * from finalsheet${aYear + term} where esis='${
                        s.esis
                      }'`,
                      "getreportbyesis2"
                    );
                  } else {
                    endOfYearCard(s, "", [], term);
                  }
                }
              }}
            >
              Bulk ({studentData.length})
            </Button> */}
          </>
        )}
      </div>
      <div className="container">
        {studentData.length > 0 && (
          <>
            <table className="reportTable">
              <thead>
                <tr>
                  <th>eSIS</th>
                  <th>Name</th>
                  <th>Arabic Name</th>
                  <th>Class</th>
                  <th></th>
                </tr>
              </thead>
              {studentData
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((s) => {
                  return (
                    <tr key={s.esis}>
                      <td>{s.esis}</td>
                      <td>{s.name}</td>
                      <td>{s.arabicname}</td>
                      <td>
                        <span> {s.homeroom}</span>
                      </td>
                      <td>
                        <ButtonGroup
                          variant="contained"
                          aria-label="button group"
                        >
                          <Button
                            style={{
                              backgroundColor: "#2196f3",
                              color: "#ffffff",
                            }}
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              // if (term === "endOfYearCard") {
                              //   endOfYearCard(s, "individual", [], term);
                              // } else {
                              //   endOfTermCard(s, "individual", [], term);
                              // }
                              setEsisInput(s.esis);
                              if (aYear === "2223") {
                                if (term === "endOfYearCard") {
                                  generalQuery(
                                    `select * from endOfYearloaded where pupilnumber='${s.esis}'`
                                  ).then((resp) => {
                                    // let st = {}
                                    let dataArr = [];
                                    let arrEsis = [];
                                    resp.forEach((r) => {
                                      if (!arrEsis.includes(r.pupilnumber)) {
                                        arrEsis.push(r.pupilnumber);
                                      }
                                    });
                                    arrEsis.forEach((e) => {
                                      let stjson = { esis: e };
                                      resp
                                        .filter((res) => res.pupilnumber === e)
                                        .forEach((d) => {
                                          stjson[d.subjectname] = d.finalmark;
                                          stjson.homeroom = d.homeroom;
                                          stjson.name = d.studentname;
                                          stjson.arabicname = d.arabicname;
                                          stjson.block = d.block;
                                        });
                                      dataArr.push(stjson);
                                    });
                                    endOfYearCard(
                                      dataArr[0],
                                      "individual",
                                      [],
                                      ""
                                    );
                                  });
                                } else {
                                  generalQueryOriginal(
                                    `select * from finalsheet${
                                      aYear + term
                                    } where esis='${s.esis}'`,
                                    "getreportbyesis"
                                  );
                                }
                              } else {
                                // Usage example
                              
                                checkIfPdfExists(
                                  process.env.PUBLIC_URL +
                                    "/" +
                                    "Reportst12324" +
                                    "/" +
                                    s.esis
                                );
                              }
                            }}
                          ></Button>
                          {/* <Button
                            style={{
                              backgroundColor:
                                s.eligibility === "no" ? "#4caf50" : "#f44336",
                              color: "#ffffff",
                            }}
                            startIcon={
                              s.eligibility === "no" ? (
                                <CheckCircle />
                              ) : (
                                <Cancel />
                              )
                            }
                            onClick={() => {
                              if (s.eligibility === "no") {
                                [
                                  "finalsheet",
                                  "gradedassignments",
                                  "quizzes",
                                  "behatt",
                                  "unitassessments",
                                ].forEach((table) => {
                                  generalQueryOriginal(
                                    `update ${
                                      table + aYear + term
                                    } set eligibility='yes' where esis='${
                                      s.esis
                                    }'`,
                                    "updateeligibilityage"
                                  );
                                });
                              } else {
                                [
                                  "finalsheet",
                                  "gradedassignments",
                                  "quizzes",
                                  "behatt",
                                  "unitassessments",
                                ].forEach((table) => {
                                  generalQueryOriginal(
                                    `update ${
                                      table + aYear + term
                                    } set eligibility='no' where esis='${
                                      s.esis
                                    }'`,
                                    "updateeligibilityage"
                                  );
                                });
                              }
                            }}
                          >
                            {s.eligibility === "no" ? "Unex." : "Exem."}
                          </Button> */}
                          {sessionStorage.getItem("user") === "registrar" ||
                          sessionStorage.getItem("user") === "pec" ? (
                            <Button
                              style={{
                                backgroundColor:
                                  s.block === "no" ? "#f44336" : "#4caf50",
                                color: "#ffffff",
                              }}
                              startIcon={
                                s.block !== "no" ? <LockOpen /> : <Block />
                              }
                              onClick={() => {
                                if (term === "endOfYearCard") {
                                  if (s.block === "no") {
                                    generalQuery(
                                      `update endOfYearloaded set block='yes' where pupilnumber='${s.esis}'`
                                    ).then(() => {
                                      getDataForEndOfYear();
                                    });
                                  } else {
                                    generalQuery(
                                      `update endOfYearloaded set block='no' where pupilnumber='${s.esis}'`
                                    ).then(() => {
                                      getDataForEndOfYear();
                                    });
                                  }
                                } else {
                                  if (s.block === "no") {
                                    generalQueryOriginal(
                                      `update finalsheet${
                                        aYear + term
                                      } set block='yes' where esis='${s.esis}'`,
                                      "updateblockage"
                                    );
                                  } else {
                                    generalQueryOriginal(
                                      `update finalsheet${
                                        aYear + term
                                      } set block='no' where esis='${s.esis}'`,
                                      "updateblockage"
                                    );
                                  }
                                }
                              }}
                            >
                              {s.block === "no" ? "Block" : "Unblock"}
                            </Button>
                          ) : (
                            ""
                          )}
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </>
        )}
      </div>
      {portalSwitch ? (
        <Portal>
          <button
            style={{
              backgroundColor: "red",
              color: "white",
              float: "right",
              marginBottom: "5px",
            }}
            onClick={() => {
              setPortalSwitch(false);
              setEsisInput("");
              // console.log(pdfUrl + esisInput + ".pdf");
            }}
          >
            X
          </button>
          <div>
            {esisInput === "" ? (
              "Please, Enter the esis number!"
            ) : (
              <div>
                <>
                  {term === "t1" ? (
                    <iframe
                      src={pdfUrlt1 + esisInput + ".pdf"}
                      width="100%"
                      height="600px"
                      title="Term 1 Report"
                    ></iframe>
                  ) : term === "t2" ? (
                    <iframe
                      src={pdfUrlt2 + esisInput + ".pdf"}
                      width="100%"
                      height="600px"
                      title="Term 2 Report"
                    ></iframe>
                  ) : (
                    ""
                  )}
                </>
              </div>
            )}
          </div>
        </Portal>
      ) : (
        ""
      )}
    </div>
  );
};

export default EndoFTermReport;
