import React, { useState, useEffect } from "react";
import "./pec.css";
import CircularProgress from "@material-ui/core/CircularProgress";
// import pdfMake from "pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
// import jsPDF from "jspdf";
import { FcPrint } from "react-icons/fc";
import { ConnectedTvOutlined } from "@mui/icons-material";
const Pecattendance = () => {
  const [dateFrom, setDateFrom] = useState(0);
  const [dateTo, setDateTo] = useState(0);
  const [datesArr, setDatesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [gradeSearch, setGradeSearch] = useState("");
  const [classSearch, setClassSearch] = useState("");
  const [classesArr, setClassesArr] = useState([]);
  const [classesDropMenu, setClassesDropMenu] = useState([]);
  const [showPortal, setShowPortal] = useState(false);
  const [individualAbsDays, setIndividualAbsDays] = useState([]);
  const [individualLateDays, setIndividualLateDays] = useState([]);
  const [indName, setIndName] = useState("");
  const [indEsis, setIndEsis] = useState("");
  const [indClass, setIndClass] = useState("");
  const [indAbsNum, setIndAbsNum] = useState(0);
  const [indLateNum, setindLateNum] = useState(0);
  const [actions, setActions] = useState([]);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [actionForm, setActionForm] = useState(false);
  const [actionContent, setActionContent] = useState("");

  const getAbsentStudents = async (x, y) => {
    try {
      const body = { tablename: x };
      const response = await fetch(
        "https://mut.herokuapp.com/getAbsentStudents",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      resp.forEach((r) => Object.assign(r, { date: x }));
      setAllData((allData) => [...allData, resp]);
      setTimeout(() => {
        setLoading(false);
        setButtonShow(true);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  const getStudentsData = async () => {
    try {
      const body = { tablename: "students" };
      const response = await fetch(
        "https://mut.herokuapp.com/getStudentsData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      let arr = [];
      resp.forEach((s) => {
        if (!arr.includes(s.homroom)) {
          arr.push(s.homroom);
        }
      });
      // console.log(arr)
      setClassesArr(arr.sort((a, b) => (a > b ? 1 : -1)));
      setClassesDropMenu(arr.sort((a, b) => (a > b ? 1 : -1)));

      setStudentsData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const addAction = async () => {
    setLoadingPortal(true);
    try {
      const body = {
        esis: indEsis,
        studentname: indName,
        staff: sessionStorage.getItem("email"),
        act: actionContent,
      };
      const response = await fetch("https://mut.herokuapp.com/addAction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      getattactions(indEsis);
      setLoadingPortal(false);
      setActionForm(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getattactions = async (x) => {
    setLoadingPortal(true);
    try {
      const body = { studentnumber: x };
      const response = await fetch("https://mut.herokuapp.com/getattactions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      console.log(resp);
      setActions(resp);
      setLoadingPortal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getAttendanceNames = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getAttendanceNames",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      console.log(resp);
      let arr = [];
      resp.forEach((d) => {
        arr.push(Number(d.table_name.slice(4)));
      });
      setLoading(false);
      setDatesArr(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const generalQuery = async (z, p) => {
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "upatetozero") {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handelData = async (y) => {
    setLoading(true);
    setButtonShow(false);
    setAllData([]);
    setDateTo(y);
    let arr = [];
    datesArr.forEach((d) => {
      // console.log(
      //   new Date(
      //     d.toString().slice(0, 4) +
      //       "-" +
      //       d.toString().slice(4, 6) +
      //       "-" +
      //       d.toString().slice(6, 8)
      //   ).getDay()
      // );
      if (d >= dateFrom && d <= y) {
        arr.push("zatt" + d);
      }
    });
    ["p1", "p2", "p3", "p4", "p5", "p6"].forEach((e) => {
      arr.forEach(async (t) => {
        await generalQuery(
          `update ${t} set ${e}='0' where ${e}='null'`,
          "upatetozero"
        );
      });
    });
    arr.forEach(async (t) => {
      await getAbsentStudents(t, arr.indexOf(t));
    });
    setShowTable(false);
  };
  const dataToShowFunction = () => {
    setShowTable(false);
    studentsData.forEach((s) => {
      let AbsCount = 0;
      let lateCount = 0;
      allData.forEach((x) => {
        Object.assign(x, { absentDays: "", lateDays: "", date: "" });
      });
      allData.forEach((a) => {
        a.forEach((e) => {
          if (e.pupilnumber === s.studentnumber && e.p1 === "0.5") {
            lateCount = lateCount + 1;
          } else if (e.pupilnumber === s.studentnumber && e.p1 === "0") {
            AbsCount = AbsCount + 1;
          }
          s.date = e.date;
        });
      });
      s.lateDays = lateCount;
      s.absentDays = AbsCount;
    });
    setShowTable(true);
  };

  useEffect(() => {
    getAttendanceNames();
    getStudentsData();
  }, []);
  if (showPortal) {
    return (
      <div className="container">
        <div className="containerPortal">
          <button
            onClick={() => {
              setShowPortal(false);
              setActionForm(false);
            }}
            style={{
              float: "right",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              margin: "3px 3px",
            }}
          >
            x
          </button>
          {/* <button onClick={() => printDocument()} style={{ marginTop: "25px" }}>
            <FcPrint size={50} />
          </button> */}
          <div
            style={{ display: "flex", marginTop: "30px", marginRight: "30px" }}
          >
            <div
              style={{
                width: "20vw",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div className="badge absentBadge">{indAbsNum}</div>
              <div className="badge lateBadge">{indLateNum}</div>
            </div>
            <div style={{ width: "80vw" }}></div>
          </div>
          <div id="divToPrint">
            <h2>{indName}</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <h2>{indEsis}</h2>
              <h2>{indClass}</h2>
            </div>
            {individualAbsDays.length > 0 && <h2>Absence Days</h2>}
            <ul>
              {individualAbsDays
                .sort((a, b) =>
                  Number(a.slice(4, 12)) > Number(b.slice(4, 12)) ? -1 : 1
                )
                .map((i) => {
                  return (
                    <li>
                      {i.slice(4, 8) +
                        "-" +
                        i.slice(8, 10) +
                        "-" +
                        i.slice(10, 12)}
                    </li>
                  );
                })}
            </ul>
            {individualLateDays.length > 0 && <h2>Tardiness Days</h2>}
            <ul>
              {individualLateDays
                .sort((a, b) =>
                  Number(a.slice(4, 12)) > Number(b.slice(4, 12)) ? -1 : 1
                )
                .map((i) => {
                  return (
                    <li key={i}>
                      {i.slice(4, 8) +
                        "-" +
                        i.slice(8, 10) +
                        "-" +
                        i.slice(10, 12)}
                    </li>
                  );
                })}
            </ul>
            {!actionForm && !loadingPortal && (
              <div>
                <button
                  className="btn"
                  onClick={() => setActionForm(true)}
                  style={{ float: "left" }}
                >
                  New Action
                </button>
              </div>
            )}
            {loadingPortal ? (
              <CircularProgress />
            ) : actions.length > 0 && !actionForm ? (
              <div
                style={{ overflowX: "scroll", width: "90%", marginLeft: "5vw" }}
              >
                <table id="actTable">
                  <thead>
                    <th style={{ width: "100px" }}>Date</th>
                    <th>PEC/VP</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {actions.map((a) => {
                      return (
                        <tr key={a.act_date}>
                          <td>{a.act_date.slice(0, 10)}</td>
                          <td>{a.staff}</td>
                          <td>{a.act}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : actions.length === 0 && !actionForm ? (
              "No Reports"
            ) : actionForm ? (
              <div>
                <textarea
                  type="text"
                  className="formInput"
                  maxLength={500}
                  onChange={(e) => setActionContent(e.target.value)}
                ></textarea>
                <div>
                  <button
                    className="btn"
                    onClick={() => {
                      addAction();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      setActionForm(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="containerPec">
      <div className="container">
        <div className="label label1">From</div>
        <div className="label">To</div>
      </div>
      <input
        id="inputDate"
        type="date"
        onChange={(e) => {
          setDateFrom(Number(e.target.value.replace("-", "").replace("-", "")));
        }}
      ></input>
      <input
        id="inputDate"
        type="date"
        onChange={(e) => {
          handelData(Number(e.target.value.replace("-", "").replace("-", "")));
        }}
      ></input>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {buttonShow ? (
            <button
              className="Pbtn"
              style={{ marginTop: "15px" }}
              onClick={() => {
                dataToShowFunction();
              }}
            >
              Show Data
            </button>
          ) : dateTo ? (
            <CircularProgress size={20} />
          ) : (
            ""
          )}
          {showTable && (
            <div className="filters">
              <select
                onChange={(e) => {
                  setClassSearch("");
                  setClassesDropMenu(classesArr);
                  setGradeSearch(e.target.value);
                  setClassesDropMenu((classesArr) =>
                    classesArr.filter((d) =>
                      d.slice(1, 2).startsWith(e.target.value)
                    )
                  );
                  // console.log(classesArr);
                }}
                id="selectGradeClass"
              >
                <option value="">By Grade...</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </select>
              <select
                onChange={(e) => setClassSearch(e.target.value)}
                id="selectGradeClass"
              >
                <option value="">By Class...</option>
                {classesDropMenu.map((ar) => {
                  return <option key={ar}>{ar}</option>;
                })}
              </select>
            </div>
          )}
          {showTable ? (
            <table className="coverageTable">
              <thead>
                <tr>
                  <th>esis</th>
                  <th>Name</th>
                  {/* <th>الاسم</th> */}
                  <th>Class</th>
                  <th>Absence Days</th>
                  <th>Tardiness</th>
                  <th>Phone1</th>
                  <th>Phone2</th>
                  <th>Phone3</th>
                  <th>Phone4</th>
                </tr>
              </thead>
              <tbody>
                {studentsData
                  .sort((a, b) => (a.homroom > b.homroom ? 1 : -1))
                  .filter((d) =>
                    d.grade_level
                      .toLowerCase()
                      .startsWith(gradeSearch.toLowerCase())
                  )
                  .filter((d) =>
                    d.homroom
                      .toLowerCase()
                      .startsWith(classSearch.toLowerCase())
                  )
                  .filter((d) => d.absentDays >= 1 || d.lateDays > 2)
                  .sort((a, b) =>
                    Number(a.absentDays) > Number(b.abentDays) ? 1 : -1
                  )
                  .map((s) => {
                    return (
                      <tr key={s.studentnumber}>
                        <td>{s.studentnumber}</td>
                        <td>{s.englishname}</td>
                        {/* <td>{s.username}</td> */}
                        <td>{s.homroom.replace("-", "").replace("G", "")}</td>
                        <td
                          className={Number(s.absentDays) > 2 ? "redAlarm" : ""}
                        >
                          {s.absentDays}
                        </td>
                        <td
                          className={
                            Number(s.lateDays) > 0 ? "orangeAlarm" : ""
                          }
                        >
                          {s.lateDays}
                        </td>
                        <td>{s.father}</td>
                        <td>{s.mobile}</td>
                        <td>{s.mother}</td>
                        <td>{s.phonenumber}</td>
                        <td>
                          <button
                            className="btn"
                            onClick={() => {
                              setIndividualAbsDays([]);
                              setIndividualLateDays([]);
                              getattactions(s.studentnumber);
                              setIndName(s.englishname);
                              setIndEsis(s.studentnumber);
                              setIndClass(s.homroom);
                              setIndAbsNum(s.absentDays);
                              setindLateNum(s.lateDays);
                              allData.forEach((a) =>
                                a.forEach((o) => {
                                  if (
                                    o.pupilnumber === s.studentnumber &&
                                    o.p1 === "0"
                                  ) {
                                    setIndividualAbsDays(
                                      (individualAbsDays) => [
                                        ...individualAbsDays,
                                        o.date,
                                      ]
                                    );
                                  } else if (
                                    o.pupilnumber === s.studentnumber &&
                                    o.p1 === "0.5"
                                  ) {
                                    setIndividualLateDays(
                                      (individualLateDays) => [
                                        ...individualLateDays,
                                        o.date,
                                      ]
                                    );
                                  }
                                })
                              );
                              setShowPortal(true);
                            }}
                          >
                            Reports/Actions
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Pecattendance;
