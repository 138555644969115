import React, { useState, useEffect } from "react";
import "./AssessmentManager.css";
import BehaviorAttendance from "./BehaviorAttendance";
import GradedAssignment from "./GradedAssignment";
import MasterSheet from "./MasterSheet";
import UnitAssessments from "./UnitAssessments";
const AssessmentManager = () => {
  const [gradedAssignTab, setGradedAssignTab] = useState("tabActive");
  const [quizzesTab, setquizzesTab] = useState("tab");
  const [masterTab, setMasterTab] = useState("tab");
  const [teacherClasses, setTeacherClasses] = useState([]);
  const [unitAssessments, setUnitAssessments] = useState("tab");
  const [behaviorAttendance, setBehaviorAttendance] = useState("tab");
  const [en, setEn] = useState(true);
  const [term, setTerm] = useState("t1");
  const [Ayear, setAyear] = useState("2324");
  const sub = sessionStorage.getItem("sub");
  const gradedSubs = [
    "Arabic",
    "eco",
    "English",
    "Gov",
    "Social",
    "Islamic",
    "Math",
    "Science",
  ];
  const generalQuery = async (z, p) => {
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "getteacherclasses") {
        let arr = [];
        resp.forEach((r) => {
          ["m", "t", "w", "th", "f"].forEach((d) => {
            ["1", "2", "3", "4", "5", "6"].forEach((p) => {
              if (
                r[d + p] &&
                arr.indexOf(
                  "G" + r[d + p].match(/\d+/g)  + "-" + r[d + p].slice(-1)
                ) === -1
              ) {
                if (r[d + p] !== "null" && r[d + p] !== "Planning") {
                  arr.push(
                    "G" + r[d + p].match(/\d+/g) + "-" + r[d + p].slice(-1)
                  );
                }
              }
            });
          });
        });
        setTeacherClasses(
          arr
            .filter((a) => a.slice(0, 2) !== "Gn")
            .sort((a, b) => (a > b ? 1 : -1))
        );
      }
      if (p === "getallClasses") {
        let arr = [];
        console.log(resp);
        resp.forEach((d) => {
          if (arr.indexOf(d.homroom) === -1) {
            if (d.homroom !== "null") {
              arr.push(d.homroom);
            }
          }
        });
        setTeacherClasses(arr.sort((a, b) => (a > b ? 1 : -1)));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const teachername = sessionStorage.getItem("teachername");
  useEffect(() => {
    if (["Arabic", "Social","Islamic"].includes(sub)) {
      setEn(false);
    }
    if (sessionStorage.getItem("trole") !== "hod") {
      generalQuery(
        `select * from teacherssummary where teacher='${teachername?.trim()}'`,
        "getteacherclasses"
      );
    } else {
      generalQuery("select homroom from students", "getallClasses");
    }
  }, []);
  return (
    <div>
      <div className="tabs" style={{ textAlign: "center" }}>
        <div
          className={gradedAssignTab}
          onClick={() => {
            setGradedAssignTab("tabActive");
            setquizzesTab("tab");
            setMasterTab("tab");
            setUnitAssessments("tab");
            setBehaviorAttendance("tab");
          }}
        >
          {en && " Graded Assignments"}
          <br />
          {!en && "المهام المصححة"}
        </div>
        <div
          className={quizzesTab}
          onClick={() => {
            setquizzesTab("tabActive");
            setGradedAssignTab("tab");
            setMasterTab("tab");
            setUnitAssessments("tab");
            setBehaviorAttendance("tab");
          }}
        >
          {en && "Pop Quizzes"}
          <br /> {!en && "الاختبارات القصيرةالمفاجئة"}
        </div>
        <div
          className={unitAssessments}
          onClick={() => {
            setMasterTab("tab");
            setGradedAssignTab("tab");
            setquizzesTab("tab");
            setUnitAssessments("tabActive");
            setBehaviorAttendance("tab");
          }}
        >
          {en && "Unit Assessments"}
          <br />
          {!en && " اختبارات الوحدات"}{" "}
        </div>
        <div
          className={behaviorAttendance}
          onClick={() => {
            setMasterTab("tab");
            setGradedAssignTab("tab");
            setquizzesTab("tab");
            setUnitAssessments("tab");
            setBehaviorAttendance("tabActive");
          }}
        >
          {en && "Behavior& Attendance"}
          <br />
          {!en && "السلوك و الحضور"}
        </div>
        {/* {gradedSubs.includes(sub) ? ( */}
        <div
          className={masterTab}
          onClick={() => {
            setMasterTab("tabActive");
            setGradedAssignTab("tab");
            setquizzesTab("tab");
            setUnitAssessments("tab");
            setBehaviorAttendance("tab");
          }}
        >
          {en && "Master Sheet"}
          <br />
          {!en && " الدرجات النهائية"}
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
      <div>
        {/* <select
          className="select"
          onChange={(e) => {
            setAyear(e.target.value);
          }}
        >
          <option>---AY---</option>
          <option value="2223">2022-2023</option>
        </select> */}
        {/* <select
          className="select"
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        >
          <option>---Term---</option>
          <option value="t1">Term 1</option>
          <option value="t2">Term 2</option>
          <option value="t3">Term 3</option>
        </select> */}
        {/* <h4>Term {term.slice(-1)}</h4> */}
        <div
          style={{
            backgroundColor: "lightblue",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Exempted Student (They are eligible to receive a mark) طالب معفي
          (بستحق درجة)
        </div>
      </div>
      <div className="container">
        {gradedAssignTab === "tabActive" ? (
          <div>
            <GradedAssignment
              teacherClasses={teacherClasses}
              assessment={"gradedassignments" + Ayear + term}
              term={term}
              headers="Assignment"
              weight={20}
            />
          </div>
        ) : quizzesTab === "tabActive" ? (
          <div>
            <GradedAssignment
              teacherClasses={teacherClasses}
              assessment={"quizzes" + Ayear + term}
              term={term}
              headers="Pop Quiz"
              weight={10}
            />
          </div>
        ) : unitAssessments === "tabActive" ? (
          <UnitAssessments
            teacherClasses={teacherClasses}
            assessment={"unitassessments" + Ayear + term}
            term={term}
            headers="Unit Assessment"
            weight={20}
          />
        ) : masterTab === "tabActive" ? (
          <MasterSheet
            teacherClasses={teacherClasses}
            term={term}
            assessment={Ayear + term}
          />
        ) : behaviorAttendance === "tabActive" ? (
          <BehaviorAttendance
            teacherClasses={teacherClasses}
            term={term}
            assessment={"behatt" + Ayear + term}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AssessmentManager;
