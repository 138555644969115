import jsPDF from "jspdf";
import { font } from "../../../font";
import mapGrowth from "../../../views/mapGrowth.JPG";
import mapReportHeader from "../../../views/mapReportHeader.JPG";
import mapMathBanner from "../../../views/mapMathBanner.JPG";
import mapReadingBanner from "../../../views/mapReadingBanner.JPG";
import mapLangBanner from "../../../views/mapLangBanner.JPG";
import mapscienceBanner from "../../../views/mapscienceBanner.JPG";
import reportTail from "../../../views/reportTail.PNG";
import mapRitKey from "../../../views/mapRitKey.JPG";
import growthScale from "../../../views/growthScale.jpg";
import mapArrow from "../../../views/mapArrow.jpg";
// [math , reading,lang, science]
const current_2 = [
  [223, 215, 214, 208],
  [227, 218, 216, 211],
  [230, 222, 219, 213],
  [230, 221, 219, 214],
];
const current_3 = [
  [214.75, 210.17, 209.43, 203.86],
  [220.21, 214.2, 212.65, 206.56],
  [224.92, 218.9, 215.54, 209.64],
  [226.43, 218.9, 216.68, 211.4],
];
// the first to the left
const current_4 = [
  [218, 212, 211, 207],
  [224, 215, 215, 209],
  [227, 218, 216, 212],
  [231, 222, 220, 214],
];
// const current_4 = [
//   [215, 210, 209, 204],
//   [219, 213, 213, 207],
//   [224, 217, 215, 210],
//   [228, 221, 219, 212],
// ];
const current_1 = [
  [220, 214, 212, 207],
  [225, 218, 216, 210],
  [226, 219, 217, 211],
  [229, 222, 219, 213],
];
const current = [
  [227, 222, 216, 211],
  [230, 222, 219, 213],
  [232, 221, 219, 214],
  [232, 224, 221, 215],
];

let mathRITs = [];
let readingRITs = [];
let langRITs = [];
let scienceRITs = [];

export const getmapreport = async (x, y, from, to, esis, xAxisValue) => {
  try {
    const body = {
      idqueryString: `select * from  ${x} where studentid='${esis}'`,
    };
    const response = await fetch("https://mut.herokuapp.com/general", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const resp = await response.json();
    console.log(resp);
    if (resp[0].studentgrade === "7") {
      mathRITs = [
        current_4[0][0],
        current_3[0][0],
        current_2[0][0],
        current_1[0][0],
        current[0][0],
      ];
      readingRITs = [
        current_4[0][1],
        current_3[0][1],
        current_2[0][1],
        current_1[0][1],
        current[0][1],
      ];
      langRITs = [
        current_4[0][2],
        current_3[0][2],
        current_2[0][2],
        current_1[0][2],
        current[0][2],
      ];
      scienceRITs = [
        current_4[0][3],
        current_3[0][3],
        current_2[0][3],
        current_1[0][3],
        current[0][3],
      ];
    } else if (resp[0].studentgrade === "8") {
      mathRITs = [
        current_4[1][0],
        current_3[1][0],
        current_2[1][0],
        current_1[1][0],
        current[1][0],
      ];
      readingRITs = [
        current_4[1][1],
        current_3[1][1],
        current_2[1][1],
        current_1[1][1],
        current[1][1],
      ];
      langRITs = [
        current_4[1][2],
        current_3[1][2],
        current_2[1][2],
        current_1[1][2],
        current[1][2],
      ];
      scienceRITs = [
        current_4[1][3],
        current_3[1][3],
        current_2[1][3],
        current_1[1][3],
        current[1][3],
      ];
    } else if (resp[0].studentgrade === "9") {
      mathRITs = [
        current_4[2][0],
        current_3[2][0],
        current_2[2][0],
        current_1[2][0],
        current[2][0],
      ];
      readingRITs = [
        current_4[2][1],
        current_3[2][1],
        current_2[2][1],
        current_1[2][1],
        current[2][1],
      ];
      langRITs = [
        current_4[2][2],
        current_3[2][2],
        current_2[2][2],
        current_1[2][2],
        current[2][2],
      ];
      scienceRITs = [
        current_4[2][3],
        current_3[2][3],
        current_2[2][3],
        current_1[2][3],
        current[2][3],
      ];
    } else if (resp[0].studentgrade === "10") {
      mathRITs = [
        current_4[3][0],
        current_3[3][0],
        current_2[3][0],
        current_1[3][0],
        current[3][0],
      ];
      readingRITs = [
        current_4[3][1],
        current_3[3][1],
        current_2[3][1],
        current_1[3][1],
        current[3][1],
      ];
      langRITs = [
        current_4[2][2],
        current_3[2][2],
        current_2[2][2],
        current_1[2][2],
        current[2][2],
      ];
      scienceRITs = [
        current_4[3][3],
        current_3[3][3],
        current_2[3][3],
        current_1[3][3],
        current[3][3],
      ];
    }

    generatePDF(resp, y, from, to, xAxisValue);
  } catch (error) {
    console.log(error);
    alert(`Student ${esis} did not take ${to} MAP test`);
  }
};

const getSuffix = (x) => {
  if (x % 10 === 1) {
    return "st";
  } else if (x % 10 === 2) {
    return "nd";
  } else if (x % 10 === 3) {
    return "rd";
  } else {
    return "th";
  }
};
const getLevel = (x) => {
  if (x > 80) {
    return "High";
  } else if (x > 60) {
    return "High Average";
  } else if (x > 40) {
    return "Average";
  } else if (x > 20) {
    return "Low Average";
  } else {
    return "Low";
  }
};
const generatePDF = (data, y, from, to, xAxisValue) => {
  var doc = new jsPDF();
  const topHead = (x) => {
    doc.setFontSize(8);
    doc.setTextColor(80, 80, 80);
    doc.text(`Page ${x}`, 189, 10);
    doc.text(
      "ID: " + data[0].studentid + " | Grade: " + data[0].studentgrade,
      170,
      15
    );
    doc.text("Al Mutanabi School", 173, 20);
    doc.setFontSize(14 - 2 * x);
    doc.text(y, 5, 22);
    doc.setFontSize(17 - 2 * x);
    doc.setTextColor(0, 0, 0);
    doc.addImage(mapGrowth, 4, 5, 30, 5);
    doc.text(
      data[0].studentname.split(" ")[0] +
        " " +
        data[0].studentname.split(" ")[
          data[0].studentname.split(" ").length - 1
        ],
      5,
      15
    );
    doc.setFontSize(14 - 2 * x);
    doc.text("Family Report", 35 - 2 * x, 22);
  };
  doc.addImage(mapReportHeader, 5, 25, 200, 70);
  doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
  doc.addFont(
    "IBMPlexSansArabic-Medium-normal.ttf",
    "IBMPlexSansArabic-Medium",
    "normal"
  );
  doc.setFont("IBMPlexSansArabic-Medium"); // set font
  ///////////////Math Section
  const mathData = data.filter((d) => d.course === "Math K-12");
  const readingData = data.filter((d) => d.course === "Reading");
  const sciencegData = data.filter((d) => d.course === "Science K-12");
  const langData = data.filter((d) => d.course === "Language Usage");

  topHead(1);
  const subjectSection = (
    SubjectData,
    ritsArry,
    yDisplacment,
    mapSubjectBanner
  ) => {
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);
    doc.addImage(mapSubjectBanner, 5, 93 + yDisplacment, 200, 10);
    if (
      SubjectData[0].endpercentile &&
      SubjectData[0].endpercentile !== "null"
    ) {
      doc.text(
        getLevel(SubjectData[0].endpercentile) + " Achievement",
        18,
        110 + yDisplacment
      );
    }
    if (
      SubjectData[0].conditionalgrowthpercentile !== "null" &&
      SubjectData[0].conditionalgrowthpercentile !== "0"
    ) {
      doc.text(
        getLevel(SubjectData[0].conditionalgrowthpercentile) + " Growth",
        110,
        110 + yDisplacment
      );
    } else {
      doc.text(" Growth", 110, 110 + yDisplacment);
    }
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    if (
      SubjectData[0].endpercentile &&
      SubjectData[0].endpercentile !== "null"
    ) {
      doc.text(
        SubjectData[0].endpercentile +
          getSuffix(SubjectData[0].endpercentile) +
          " Percentile",
        75,
        110 + yDisplacment
      );
    }
    if (
      SubjectData[0].conditionalgrowthpercentile !== "null" &&
      SubjectData[0].conditionalgrowthpercentile !== "0"
    ) {
      doc.text(
        SubjectData[0].conditionalgrowthpercentile +
          getSuffix(SubjectData[0].conditionalgrowthpercentile) +
          " Percentile",
        165,
        110 + yDisplacment
      );
    }
    doc.setDrawColor(238, 238, 238);
    doc.setLineWidth(0.8);
    doc.setLineDashPattern([0, 0, 0, 0], 10);
    doc.line(105.5, 95 + yDisplacment, 105.5, 185 + yDisplacment);
    doc.setDrawColor(200, 200, 200);
    doc.setLineWidth(0.5);
    doc.rect(18, 115 + yDisplacment, 60, 40);
    doc.setLineWidth(0.2);
    doc.line(24, 115 + yDisplacment, 24, 155 + yDisplacment);
    doc.line(36, 115 + yDisplacment, 36, 155 + yDisplacment);
    doc.line(48, 115 + yDisplacment, 48, 155 + yDisplacment);
    doc.line(60, 115 + yDisplacment, 60, 155 + yDisplacment);
    doc.line(72, 115 + yDisplacment, 72, 155 + yDisplacment);
    doc.setFontSize(8);
    doc.setTextColor(150, 150, 150);
    doc.text(xAxisValue[0].slice(0, -3), 20, 158 + yDisplacment);
    doc.text(
      xAxisValue[0].substr(xAxisValue[0].length - 3),
      20,
      162 + yDisplacment
    );
    doc.text(xAxisValue[1].slice(0, -3), 32, 158 + yDisplacment);
    doc.text(
      xAxisValue[1].substr(xAxisValue[1].length - 3),
      32,
      162 + yDisplacment
    );
    doc.text(xAxisValue[2].slice(0, -3), 44, yDisplacment + 158);
    doc.text(
      xAxisValue[2].substr(xAxisValue[2].length - 3),
      44,
      162 + yDisplacment
    );
    doc.text(xAxisValue[3].slice(0, -3), 56, yDisplacment + 158);
    doc.text(
      xAxisValue[3].substr(xAxisValue[3].length - 3),
      56,
      162 + yDisplacment
    );
    doc.text(xAxisValue[4].slice(0, -3), 68, 158 + yDisplacment);
    doc.text(
      xAxisValue[4].substr(xAxisValue[4].length - 3),
      68,
      162 + yDisplacment
    );
    let valuesarr = [];

    // if (
    //   SubjectData[0].startrit !== "null" &&
    //   SubjectData[0].endrit !== "null"
    // ) {
    //   valuesarr = ritsArry.concat([
    //     +SubjectData[0].startrit,
    //     +SubjectData[0].endrit,
    //   ]);
    // } else if (
    //   SubjectData[0].startrit === "null" &&
    //   SubjectData[0].endrit !== "null"
    // ) {
    //   valuesarr = ritsArry.concat([+SubjectData[0].endrit]);
    // } else if (

    // ) {
    //   valuesarr = ritsArry.concat([+SubjectData[0].startrit]);
    // }

    // valuesarr = ritsArry

    if (
      SubjectData[0].startrit !== "null" &&
      SubjectData[0].endrit !== "null" &&
      SubjectData[0].start_rit !== "null"
    ) {
      valuesarr = ritsArry.concat([
        +SubjectData[0].start_rit,
        +SubjectData[0].startrit,
        +SubjectData[0].endrit,
      ]);
    } else if (
      SubjectData[0].startrit === "null" &&
      SubjectData[0].endrit !== "null" &&
      SubjectData[0].start_rit !== "null"
    ) {
      valuesarr = ritsArry.concat([
        +SubjectData[0].start_rit,
        +SubjectData[0].endrit,
      ]);
    } else if (
      SubjectData[0].startrit !== "null" &&
      SubjectData[0].endrit === "null" &&
      SubjectData[0].start_rit !== "null"
    ) {
      valuesarr = ritsArry.concat([
        +SubjectData[0].start_rit,
        +SubjectData[0].startrit,
      ]);
    } else if (
      SubjectData[0].startrit !== "null" &&
      SubjectData[0].endrit !== "null" &&
      SubjectData[0].start_rit === "null"
    ) {
      valuesarr = ritsArry.concat([
        +SubjectData[0].startrit,
        +SubjectData[0].endrit,
      ]);
    } else if (
      SubjectData[0].startrit === "null" &&
      SubjectData[0].endrit !== "null" &&
      SubjectData[0].start_rit === "null"
    ) {
      valuesarr = ritsArry.concat([+SubjectData[0].endrit]);
    } else if (
      SubjectData[0].startrit === "null" &&
      SubjectData[0].endrit === "null" &&
      SubjectData[0].start_rit !== "null"
    ) {
      valuesarr = ritsArry.concat([+SubjectData[0].start_rit]);
    } else if (
      SubjectData[0].startrit !== "null" &&
      SubjectData[0].endrit === "null" &&
      SubjectData[0].start_rit === "null"
    ) {
      valuesarr = ritsArry.concat([+SubjectData[0].startrit]);
    }
    let min = Math.min(...valuesarr) - 10;
    let max = Math.max(...valuesarr) + 10;
    let range = max - min;
    if (range > 40) {
      const scale = Math.ceil(range / 5);
      let tick1 = min;
      let tick2 = tick1 + scale;
      let tick3 = tick1 + 2 * scale;
      let tick4 = tick1 + 3 * scale;
      let tick5 = tick1 + 4 * scale;
      let tick6 = tick1 + 5 * scale;
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6,
        20,
        yDisplacment + 115 + 40 / 6
      );
      doc.text(tick6.toString(), 10, yDisplacment + 115 + 40 / 6);
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6 + 40 / 6,
        20,
        yDisplacment + 115 + 40 / 6 + 40 / 6
      );
      doc.text(tick5.toString(), 10, yDisplacment + 115 + 40 / 6 + 40 / 6);
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6 + 80 / 6,
        20,
        yDisplacment + 115 + 40 / 6 + 80 / 6
      );
      doc.text(tick4.toString(), 10, yDisplacment + 115 + 40 / 6 + 80 / 6);
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6 + 120 / 6,
        20,
        yDisplacment + 115 + 40 / 6 + 120 / 6
      );
      doc.text(tick3.toString(), 10, yDisplacment + 115 + 40 / 6 + 120 / 6);
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6 + 160 / 6,
        20,
        yDisplacment + 115 + 40 / 6 + 160 / 6
      );
      doc.text(tick2.toString(), 10, yDisplacment + 115 + 40 / 6 + 160 / 6);
      doc.line(
        18,
        yDisplacment + 115 + 40 / 6 + 200 / 6,
        20,
        yDisplacment + 115 + 40 / 6 + 200 / 6
      );
      doc.text(tick1.toString(), 10, yDisplacment + 115 + 40 / 6 + 200 / 6);
      doc.setLineWidth(0.7);
      doc.setDrawColor(120, 120, 120);
      if (
        SubjectData[0].start_rit !== "null" &&
        SubjectData[0].startrit !== "null"
      ) {
        doc.setFontSize(10);
        doc.line(
          36,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].start_rit - min) / range) * 200) / 6,
          48,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].startrit - min) / range) * 200) / 6
        );
      }
      if (
        SubjectData[0].startrit !== "null" &&
        SubjectData[0].endrit !== "null"
      ) {
        doc.setFontSize(10);
        doc.line(
          48,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].startrit - min) / range) * 200) / 6,
          72,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].endrit - min) / range) * 200) / 6
        );
      }
      if (SubjectData[0].start_rit !== "null") {
        doc.ellipse(
          36,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].start_rit - min) / range) * 200) / 6,
          0.5,
          0.5,
          "FD"
        );
        doc.text(
          SubjectData[0].start_rit,
          33,
          yDisplacment +
            112 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].start_rit - min) / range) * 200) / 6
        );
      }
      if (SubjectData[0].startrit !== "null") {
        doc.ellipse(
          48,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].startrit - min) / range) * 200) / 6,
          0.5,
          0.5,
          "FD"
        );
        doc.text(
          SubjectData[0].startrit,
          45,
          yDisplacment +
            112 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].startrit - min) / range) * 200) / 6
        );
      }
      doc.setFillColor(250, 250, 250);
      doc.setFontSize(10);
      doc.setTextColor(120, 120, 120);

      if (SubjectData[0].endrit !== "null") {
        doc.ellipse(
          72,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].endrit - min) / range) * 200) / 6,
          0.5,
          0.5,
          "FD"
        );
        doc.text(
          SubjectData[0].endrit,
          69,
          yDisplacment +
            112 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].endrit - min) / range) * 200) / 6
        );
      }
      doc.setLineDashPattern([0.5, 0.5, 0.5, 0.5], 10);
      doc.setLineWidth(0.5);
      doc.setDrawColor(200, 200, 200);
      doc.line(
        24,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[0] - min) / range) * 200) / 6,
        36,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[1] - min) / range) * 200) / 6
      );
      doc.line(
        36,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[1] - min) / range) * 200) / 6,
        48,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[2] - min) / range) * 200) / 6
      );
      doc.line(
        48,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[2] - min) / range) * 200) / 6,
        60,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[3] - min) / range) * 200) / 6
      );
      doc.line(
        60,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[3] - min) / range) * 200) / 6,
        72,
        yDisplacment +
          115 +
          40 / 6 +
          200 / 6 -
          (((ritsArry[4] - min) / range) * 200) / 6
      );
    } else {
      const scale = Math.ceil(range / 2);
      let tick1 = min;
      let tick2 = tick1 + scale;
      let tick3 = tick1 + 2 * scale;
      doc.line(18, yDisplacment + 125, 20, yDisplacment + 125);
      doc.text(tick3.toString(), 10, yDisplacment + 125);
      doc.line(18, yDisplacment + 135, 20, yDisplacment + 135);
      doc.text(tick2.toString(), 10, yDisplacment + 135);
      doc.line(18, yDisplacment + 145, 20, yDisplacment + 145);
      doc.text(tick1.toString(), 10, yDisplacment + 145);

      doc.setLineWidth(0.7);
      doc.setDrawColor(120, 120, 120);
      doc.setLineDashPattern([0, 0, 0, 0], 10);
      if (
        SubjectData[0].startrit !== "null" &&
        SubjectData[0].endrit !== "null"
      ) {
        doc.setFontSize(10);
        if (SubjectData[0].start_rit !== "null") {
          doc.ellipse(
            36,
            yDisplacment +
              115 +
              40 / 6 +
              200 / 6 -
              (((SubjectData[0].start_rit - min) / range) * 200) / 6,
            0.5,
            0.5,
            "FD"
          );
          doc.text(
            SubjectData[0].start_rit,
            33,
            yDisplacment +
              112 +
              40 / 6 +
              200 / 6 -
              (((SubjectData[0].start_rit - min) / range) * 200) / 6
          );
        }
        doc.line(
          48,
          yDisplacment + 145 - ((SubjectData[0].startrit - min) / range) * 20,
          72,
          yDisplacment + 145 - ((SubjectData[0].endrit - min) / range) * 20
        );
        doc.line(
          36,
          yDisplacment +
            115 +
            40 / 6 +
            200 / 6 -
            (((SubjectData[0].start_rit - min) / range) * 200) / 6,
          48,
          yDisplacment + 145 - ((SubjectData[0].startrit - min) / range) * 20
        );
        doc.text(
          SubjectData[0].startrit,
          45,
          yDisplacment + 142 - ((SubjectData[0].startrit - min) / range) * 20
        );
      }
      if (SubjectData[0].startrit !== "null") {
        doc.ellipse(
          48,
          yDisplacment + 145 - ((SubjectData[0].startrit - min) / range) * 20,
          0.5,
          0.5,
          "FD"
        );
      }
      doc.setFillColor(250, 250, 250);
      doc.setFontSize(10);
      doc.setTextColor(120, 120, 120);
      if (SubjectData[0].endrit !== "null") {
        doc.text(
          SubjectData[0].endrit,
          69,
          yDisplacment + 142 - ((SubjectData[0].endrit - min) / range) * 20
        );
      }
      if (SubjectData[0].endrit !== "null") {
        doc.ellipse(
          72,
          yDisplacment + 145 - ((SubjectData[0].endrit - min) / range) * 20,
          0.5,
          0.5,
          "FD"
        );
      }
      doc.setLineDashPattern([0.5, 0.5, 0.5, 0.5], 10);
      doc.setLineWidth(0.5);
      doc.setDrawColor(200, 200, 200);
      doc.line(
        24,
        yDisplacment + 145 - (20 * (ritsArry[0] - min)) / range,
        36,
        yDisplacment + 145 - (20 * (ritsArry[1] - min)) / range
      );
      doc.line(
        36,
        yDisplacment + 145 - (20 * (ritsArry[1] - min)) / range,
        48,
        yDisplacment + 145 - (20 * (ritsArry[2] - min)) / range
      );
      doc.line(
        48,
        yDisplacment + 145 - (20 * (ritsArry[2] - min)) / range,
        60,
        yDisplacment + 145 - (20 * (ritsArry[3] - min)) / range
      );
      doc.line(
        60,
        yDisplacment + 145 - (20 * (ritsArry[3] - min)) / range,
        72,
        yDisplacment + 145 - (20 * (ritsArry[4] - min)) / range
      );
    }
    doc.setFontSize(8);
    doc.addImage(mapRitKey, 80, yDisplacment + 125, 4, 1.5);
    doc.text(SubjectData[0].studentfirstname, 85, yDisplacment + 126);
    doc.text("---", 80, yDisplacment + 135);
    doc.text("National", 85, yDisplacment + 135);
    doc.text("Average", 85, yDisplacment + 140);
    doc.text("Achievement", 85, yDisplacment + 145);
    doc.setFontSize(9);
    doc.setTextColor(110, 110, 110);
    if (SubjectData[0].endrit !== "null") {
      doc.text(
        SubjectData[0].studentfirstname +
          `'s overall score (RIT score) was a ${SubjectData[0].endrit} on a range of`,
        12,
        yDisplacment + 170
      );
    }
    if (
      SubjectData[0].endpercentile &&
      SubjectData[0].endpercentile !== "null"
    ) {
      doc.text(
        SubjectData[0].course !== "Reading"
          ? `100-350. Your child is in the ${
              SubjectData[0].endpercentile +
              getSuffix(SubjectData[0].endpercentile)
            } percentile of ${SubjectData[0].studentgrade}th graders in`
          : `100-320. Your child is in the ${
              SubjectData[0].endpercentile +
              getSuffix(SubjectData[0].endpercentile)
            } percentile of ${SubjectData[0].studentgrade}th graders in`,
        12,
        yDisplacment + 175
      );
    }
    if (
      SubjectData[0].endpercentile &&
      SubjectData[0].endpercentile !== "null"
    ) {
      doc.text(
        `the ${to.toLowerCase()}, which means they scored better than ${
          SubjectData[0].endpercentile
        }% of`,
        12,
        yDisplacment + 180
      );
    }

    doc.text("their peers.", 12, yDisplacment + 185);
    if (
      SubjectData[0].conditionalgrowthpercentile !== "null" &&
      SubjectData[0].conditionalgrowthpercentile !== "0"
    ) {
      doc.text(`Your child's growth from`, 110, yDisplacment + 120);
      doc.text(`${from} to ${to} is`, 110, yDisplacment + 125);
      doc.text(
        `in the ${
          SubjectData[0].conditionalgrowthpercentile +
          getSuffix(SubjectData[0].conditionalgrowthpercentile)
        } percentile, which`,
        110,
        yDisplacment + 130
      );

      doc.text(`means they made more`, 110, yDisplacment + 135);
      doc.text(
        `progress than ${SubjectData[0].conditionalgrowthpercentile}% of their `,
        110,
        yDisplacment + 140
      );
      doc.text(`peers. `, 110, yDisplacment + 145);
      doc.addImage(growthScale, 160, yDisplacment + 128, 40, 22);
      doc.addImage(
        mapArrow,
        157 + SubjectData[0].conditionalgrowthpercentile * 0.4,
        yDisplacment + 124,
        5,
        4
      );
      doc.text(
        SubjectData[0].conditionalgrowthpercentile +
          getSuffix(SubjectData[0].conditionalgrowthpercentile),
        157 + SubjectData[0].conditionalgrowthpercentile * 0.4,
        yDisplacment + 122
      );
      doc.text(
        SubjectData[0].studentfirstname,
        155 + SubjectData[0].conditionalgrowthpercentile * 0.4,
        yDisplacment + 118
      );
    } else {
      doc.text(`No growth data to show`, 140, yDisplacment + 135);
    }
    if (SubjectData === mathData || SubjectData === readingData) {
      if (
        SubjectData[0].conditionalgrowthpercentile !== "null" &&
        SubjectData[0].conditionalgrowthpercentile !== "0"
      ) {
        doc.text(
          `${SubjectData[0].studentfirstname} is likely to be:`,
          110,
          yDisplacment + 158
        );
        if (SubjectData[0].endrit !== "null") {
          doc.text(
            `${
              SubjectData[0].projectedproficiencylevel1act +
              ` on the ACT College Readiness `
            }`,
            125,
            yDisplacment + 172
          );
        }
        doc.text(`(if taken in ${to})`, 125, yDisplacment + 177);
        if (SubjectData[0].endrit !== "null") {
          doc.text(
            `${
              SubjectData[0].projectedproficiencylevel3sat +
              ` on the SAT (if taken in ${to}) `
            }`,
            125,
            yDisplacment + 182
          );
        }
        doc.setFontSize(16);
        // doc.text("\u2022 ", 118, yDisplacment + 163);
        doc.text("\u2022 ", 118, yDisplacment + 173);
        doc.text("\u2022 ", 118, yDisplacment + 183);
      }
      // if (SubjectData[0].endrit !== "null") {
      //   doc.text(
      //     `${
      //       SubjectData[0].projectedproficiencylevel2map +
      //       ` on the MAP Growth Reading `
      //     }`,
      //     125,
      //     yDisplacment + 162
      //   );
      // }
      // doc.text(`& Mathematics (if taken in ${to})`, 125, yDisplacment + 167);
    }
  };
  if (mathData.length > 0) {
    subjectSection(mathData, mathRITs, 0, mapMathBanner);
  }
  if (readingData.length > 0) {
    subjectSection(readingData, readingRITs, 95, mapReadingBanner);
  }
  doc.addPage();
  topHead(2);
  if (langData.length > 0) {
    subjectSection(langData, langRITs, -67, mapLangBanner);
  }
  if (sciencegData.length > 0) {
    subjectSection(sciencegData, scienceRITs, 28, mapscienceBanner);
  }
  doc.addImage(reportTail, 5, 218, 200, 70);
  doc.setTextColor("blue");
  doc.setFontSize(8);
  doc.textWithLink(" https://nwea.org/familytoolkit/", 77, 276, {
    url: "https://www.nwea.org/the-map-suite/family-toolkit/",
  });
  doc.textWithLink(" https://warmup.nwea.org/", 61, 286.5, {
    url: "https://warmup.nwea.org/",
  });

  doc.save(data[0].studentid);
  sessionStorage.setItem("settingLoading", "no");
  sessionStorage.setItem("loading", "no");
};
