import React, { useState, useEffect } from "react";
import { getmapreport } from "../components/wpl/StudentManual/GeneratePDF";
import { CircularProgress } from "@material-ui/core";
import kbEncoder from "./encode";
const TeacherMapReport = () => {
  const [esisInput, setEsisInput] = useState();
  const [classes, setClasses] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [loading, setLoading] = useState(false);
  const Xaxis = ["Winter'23", "Spring'23", "Fall'23", "Winter'24", "Spring'24"];

  const getclasses = async () => {
    try {
      // const body = {
      //   tokens: kbEncoder(
      //     `select distinct(homroom) from students order by homroom`
      //   ),
      // };
      const body = {
        idqueryString: `select distinct(homroom) from students order by homroom`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setClasses(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const getEsisBulk = async (y) => {
    try {
      const body = {
        idqueryString: `select * from students where homroom='${y}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setStudentData(resp);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getGradeLevel = async () => {
    try {
      const body = {
        idqueryString: `select studentgrade from mapspringreport24 where studentid='${esisInput}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      getMapBenchmarks(resp[0].studentgrade);
    } catch (error) {
      console.log(error);
    }
  };
  const getMapBenchmarks = async (x) => {
    try {
      const body = {
        idqueryString: `select * from mapbenchmarks where grade='${x}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const resp = await response.json();
      getmapreport(
        "mapspringreport24",
        "Spring 2024",
        "Fall 2023",
        "Spring 2024",
        esisInput,
        Xaxis
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getMapBenchmarksBulkReports = async (x) => {
    try {
      const body = {
        idqueryString: `select * from mapbenchmarks where grade='${x}'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setBenchmarks(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getclasses();
  }, []);
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <h1>MAP Report</h1>
      </div>
      <div className="container">
        <input
          type="number"
          className="select"
          placeholder="eSIS"
          onChange={(e) => setEsisInput(e.target.value)}
        ></input>
        <button
          className="select"
          onClick={() => {
            getGradeLevel();
          }}
        >
          Download
        </button>
        <select
          className="select"
          onChange={(e) => {
            getMapBenchmarksBulkReports(e.target.value.slice(1, 2));
            getEsisBulk(e.target.value);
            setLoading(true);
          }}
        >
          <option>Select a Class</option>
          {classes.map((m) => {
            return (
              <option key={m.homroom} value={m.homroom}>
                {m.homroom}
              </option>
            );
          })}
        </select>
        <button
          className="select"
          onClick={() => {
            for (let i = 0; i < 11; ++i) {
              setLoading(true);
              getmapreport(
                "mapspring22",
                "Summer 2022",
                "Fall 2021",
                "Spring 2022",
                studentData[i].studentnumber,
                ["Winter'21", "Spring'21", "Fall'21", "Winter'22", "Spring'22"],
                benchmarks
              );
            }
            setTimeout(() => {
              for (let i = 11; i < studentData.length; ++i) {
                getmapreport(
                  "mapspring22",
                  "Summer 2022",
                  "Fall 2021",
                  "Spring 2022",
                  studentData[i].studentnumber,
                  [
                    "Winter'21",
                    "Spring'21",
                    "Fall'21",
                    "Winter'22",
                    "Spring'22",
                  ],
                  benchmarks
                );

                if (i === studentData.length - 1) {
                  setTimeout(() => {
                    setLoading(false);
                  }, (i - 5) * 1000);
                }
              }
            }, 10000);

            // studentData.forEach((s) => {

            // });
          }}
        >
          Download All
        </button>
      </div>
      <div className="container">
        {studentData.length > 0 && (
          <table className="coverageTable">
            <thead>
              <tr>
                <th>eSIS </th>
                <th>
                  Name{" "}
                  <button
                    onClick={() => {
                      setStudentData(
                        [...studentData].sort((a, b) =>
                          a.englishname > b.englishname ? 1 : -1
                        )
                      );
                    }}
                  >
                    Sort
                  </button>
                </th>
                <th>
                  الاسم{" "}
                  <button
                    onClick={() => {
                      setStudentData(
                        [...studentData].sort((a, b) =>
                          a.username > b.username ? 1 : -1
                        )
                      );
                    }}
                  >
                    رتب
                  </button>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {studentData.map((d) => {
                return (
                  <tr key={d.studentnumber}>
                    <td>{d.studentnumber}</td>
                    <td>{d.englishname}</td>
                    <td>{d.username}</td>
                    <td>
                      <button
                        className="select"
                        onClick={() => {
                          getmapreport(
                            "mapspringreport24",
                            "Spring 2024",
                            "Fall 2023",
                            "Spring 2024",
                            d.studentnumber,
                          Xaxis
                          );
                        }}
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TeacherMapReport;
