import React, { useEffect, useState, forwardRef } from "react";

import { Button } from "@material-ui/core";
import "./coverage.css";
import Portal from "./Portal";
const Coverage = ({ freeTeachers, selectedTable, selectedDay }) => {
  const [absentees, setAbsentees] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [extraAbseentees, setExtraAbseentees] = useState([]);
  const [extraSub, setExtraSub] = useState([]);
  const [showportal, setShowportal] = useState(false);
  const [savedData, setSavedData] = useState([]);
  const selectAbsTybeByVal = [
    "Three Periods Permission",
    "Full Day",
    "Two Periods Permission",
    "One Periods Permission",
    "Four Periods Permission",
    "Swap",
  ];
  const selectAbsColors = [
    "#ec9488",
    "#ef7564",
    "#efb3ab",
    "#fbedeb",
    "#ed7564",
    "#21B6A8",
  ];
  const saveAs = (blob) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = "fileName";
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };
  const exportAsPicture = () => {
    var html = document.getElementsByTagName("HTML")[0];
    var body = document.getElementsByTagName("BODY")[0];
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;
    var data = document.getElementById("capture");
    var newWidth = data.scrollWidth - data.clientWidth;
    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";
  };
  const getAbsentTeachers = async (x) => {
    try {
      const body = {
        idqueryString: `select teacher,${selectedDay + "abs"},${
          selectedDay + "type"
        },${selectedDay + "reason"} from ${selectedTable} where ${
          selectedDay + "abs"
        }='yes'`,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setSavedData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const updateAbsentTeacher = async (x) => {
    try {
      const body = {
        arr: x,
        selectedTable,
        selectedDay,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/updateAbsentTeacher",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAbsentTeachers();
    let arr = [];
    let sub = [];
    freeTeachers
      .filter(
        (e) =>
          e.p1.length > 4 ||
          e.p2.length > 4 ||
          e.p3.length > 4 ||
          e.p4.length > 4 ||
          e.p5.length > 4 ||
          e.p6.length > 4
      )
      .forEach((e) => {
        if (e.p1.length > 4) {
          if (arr.indexOf(e.p1.slice(5)) === -1) {
            arr.push(e.p1.slice(5));
          }
        }
        if (e.p2.length > 4) {
          if (arr.indexOf(e.p2.slice(5)) === -1) {
            arr.push(e.p2.slice(5));
          }
        }
        if (e.p3.length > 4) {
          if (arr.indexOf(e.p3.slice(5)) === -1) {
            arr.push(e.p3.slice(5));
          }
        }
        if (e.p4.length > 4) {
          if (arr.indexOf(e.p4.slice(5)) === -1) {
            arr.push(e.p4.slice(5));
          }
        }
        if (e.p5.length > 4) {
          if (arr.indexOf(e.p5.slice(5)) === -1) {
            arr.push(e.p5.slice(5));
          }
        }
        if (e.p6.length > 4) {
          if (arr.indexOf(e.p6.slice(5)) === -1) {
            arr.push(e.p6.slice(5));
          }
        }
      });
    setAbsentees(arr);
    arr.forEach((a) => {
      freeTeachers.forEach((f) => {
        if (a === f.teacher) {
          sub.push(f.sub);
        }
      });
    });
    setsubjects(sub);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ marginTop: "15px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "30%" }}
          onClick={() => {
            let arrAbs = [];
            let arrExt = [];
            absentees.forEach((a) => {
              arrAbs.push([
                a,
                selectAbsTybeByVal[
                  selectAbsColors.indexOf(
                    document.getElementById("type" + absentees.indexOf(a)).value
                  )
                ],

                document.getElementById("reason" + absentees.indexOf(a)).value,
              ]);
            });
            extraAbseentees.forEach((a) => {
              arrExt.push([
                a,
                selectAbsTybeByVal[
                  selectAbsColors.indexOf(
                    document.getElementById(
                      "typext" + extraAbseentees.indexOf(a)
                    ).value
                  )
                ],
                document.getElementById(
                  "reasonExt" + extraAbseentees.indexOf(a)
                ).value,
              ]);
            });
            arrAbs.concat(arrExt).forEach((a) => {
              updateAbsentTeacher(a);
            });
            exportAsPicture();
          }}
        >
          Save
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          <select
            style={{
              marginBottom: "20px",
              height: "35px",
              marginRight: "5px",
            }}
            onChange={(e) => {
              setExtraAbseentees((extraAbseentees) => [
                ...extraAbseentees,
                e.target.value,
              ]);
              freeTeachers.forEach((t) => {
                if (t.teacher === e.target.value) {
                  extraSub.push(t.sub);
                }
              });
            }}
          >
            <option value=""></option>
            {freeTeachers
              .sort((a, b) => {
                return a.teacher > b.teacher ? 1 : -1;
              })
              .map((e) => {
                return (
                  <option key={e.teacher} value={e.teacher}>
                    {e.teacher}
                  </option>
                );
              })}
          </select>
          <Button
            variant="contained"
            color="primary"
            // style={{ width: "30%" }}
            onClick={() => {
              setExtraAbseentees([]);
              setExtraSub([]);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="default"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setShowportal(!showportal);
            }}
          >
            Show Saved Data
          </Button>
        </div>
      </div>
      <div
        style={{ display: "grid", placeItems: "center", margin: "10px 0px" }}
        id="capture"
      >
        <table className="coverageTable">
          <thead>
            {absentees.map((e) => {
              return (
                <tr key={e}>
                  <th className="defaultColor" id={"t" + absentees.indexOf(e)}>
                    {e}
                  </th>
                  <td
                    style={{ color: "blue" }}
                    id={"sub" + absentees.indexOf(e)}
                  >
                    {subjects[absentees.indexOf(e)]}
                  </td>
                  <td>
                    <select
                      id={"type" + absentees.indexOf(e)}
                      onChange={(v) => {
                        document.getElementById(
                          "t" + absentees.indexOf(e)
                        ).style.backgroundColor = v.target.value;
                        if (v.target.value === "#ec9488") {
                          setOptionValue("Three Periods Permission");
                        }
                        if (v.target.value === "#ef7564") {
                          setOptionValue("Full Day");
                        }
                        if (v.target.value === "#ec9488") {
                          setOptionValue("Two Periods Permission");
                        }
                        if (v.target.value === "#fbedeb") {
                          setOptionValue("One Periods Permission");
                        }
                      }}
                    >
                      <option value="#ef7564">Full Day</option>
                      <option value="#ec9488" id="Three">
                        Three Periods Permission
                      </option>
                      <option value="#ec9488">Four Periods Permission</option>
                      <option value="#efb3ab">Two Periods Permission</option>
                      <option value="#fbedeb">One Period Permission</option>
                      <option value="#21B6A8">Swap</option>
                    </select>
                    {showportal && (
                      <h6>
                        {
                          savedData.filter((s) => s.teacher === e)[0][
                            selectedDay + "type"
                          ]
                        }
                      </h6>
                    )}
                  </td>
                  <td>
                    <input
                      id={"reason" + absentees.indexOf(e)}
                      placeholder={
                        showportal
                          ? savedData.filter((s) => s.teacher === e)[0][
                              selectedDay + "reason"
                            ]
                          : ""
                      }
                    />
                  </td>
                </tr>
              );
            })}
            {extraAbseentees.map((x) => {
              return (
                <tr key={x}>
                  <th
                    className="defaultColor"
                    id={"xt" + extraAbseentees.indexOf(x)}
                  >
                    {x}
                  </th>
                  <td
                    style={{ color: "blue" }}
                    id={"sub" + extraAbseentees.indexOf(x)}
                  >
                    {extraSub[extraAbseentees.indexOf(x)]}
                  </td>
                  <td>
                    <select
                      id={"typext" + extraAbseentees.indexOf(x)}
                      onChange={(v) => {
                        document.getElementById(
                          "xt" + extraAbseentees.indexOf(x)
                        ).style.backgroundColor = v.target.value;
                        if (v.target.value === "#ec9488") {
                          setOptionValue("Three Periods Permission");
                        }
                        if (v.target.value === "#ef7564") {
                          setOptionValue("Full Day");
                        }
                        if (v.target.value === "#ec9488") {
                          setOptionValue("Two Periods Permission");
                        }
                        if (v.target.value === "#fbedeb") {
                          setOptionValue("One Periods Permission");
                        }
                      }}
                    >
                      <option value="#ef7564">Full Day</option>
                      <option value="#ec9488" id="Three">
                        Three Periods Permission
                      </option>
                      <option value="#ec9488">Four Periods Permission</option>
                      <option value="#efb3ab">Two Periods Permission</option>
                      <option value="#fbedeb">One Period Permission</option>
                    </select>
                  </td>
                  <td>
                    <input
                      id={"reasonExt" + extraAbseentees.indexOf(x)}
                      placeholder={
                        showportal
                          ? savedData.filter((s) => s.teacher === x)[0][
                              selectedDay + "reason"
                            ]
                          : ""
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </thead>
        </table>

        <table className="coverageTable">
          <thead>
            <tr>
              <th>Teacher</th>
              <th>Subject</th>
              <th>P1</th>
              <th>P2</th>
              <th>P3</th>
              <th>P4</th>
              <th>P5</th>
              <th>P6</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {freeTeachers
              .filter(
                (e) =>
                  e.p1.length > 4 ||
                  e.p2.length > 4 ||
                  e.p3.length > 4 ||
                  e.p4.length > 4 ||
                  e.p5.length > 4 ||
                  e.p6.length > 4
              )
              .map((a) => {
                return (
                  <tr key={a.teacher} style={{ textAlign: "center" }}>
                    <td style={{ fontWeight: "bold" }}>
                      {a.teacher}
                      <br></br>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {a.phone}
                      </span>
                    </td>
                    <td style={{ color: "orangered" }}>{a.sub}</td>

                    {a.p1.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p1}</td>
                    ) : a.p1.length === 2 ? (
                      <td>{a.p1}</td>
                    ) : (
                      <td></td>
                    )}
                    {a.p2.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p2}</td>
                    ) : a.p2.length === 2 ? (
                      <td>{a.p2}</td>
                    ) : (
                      <td></td>
                    )}
                    {a.p3.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p3}</td>
                    ) : a.p3.length === 2 ? (
                      <td>{a.p3}</td>
                    ) : (
                      <td></td>
                    )}

                    {a.p4.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p4}</td>
                    ) : a.p4.length === 2 ? (
                      <td>{a.p4}</td>
                    ) : (
                      <td></td>
                    )}
                    {a.p5.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p5}</td>
                    ) : a.p5.length === 2 ? (
                      <td>{a.p5}</td>
                    ) : (
                      <td></td>
                    )}
                    {a.p6.length > 4 ? (
                      <td style={{ backgroundColor: "lightblue" }}>{a.p6}</td>
                    ) : a.p6.length === 2 ? (
                      <td>{a.p6}</td>
                    ) : (
                      <td></td>
                    )}
                    <td>
                      <span
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "red",
                          color: "white",
                          padding: "5px",
                          fontWeight: "bold",
                          display: "block",
                        }}
                      >
                        {" "}
                        {a.total}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Coverage;
