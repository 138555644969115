import React, { useEffect, useState } from "react";

const StaffList = () => {
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState([]);
  const generalQuery = async (z) => {
    setData([]);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      console.log(resp);
      setData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    generalQuery("select * from login");
  }, []);
  return (
    <div>
      <div className="container">
        {data.length > 0 && (
          <table>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d.lemail}</td>
                    <td>{d.lpassword}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default StaffList;
