import React from "react";
import generalQuery from "../generalQuery";

const SuperAdmiResetAssessmentTables = () => {
  return (
    <div className="container">
      <button
        onClick={() => {
          [
            "quizzes2324t1",
            "behatt2324t1",
            "finalsheet2324t1",
            "gradedassignments2324t1",
            "unitassessments2324t1",
          ].forEach((a) => {
            generalQuery(`
              BEGIN;
              DELETE FROM ${a}
              WHERE esis NOT IN (SELECT studentnumber FROM students);
              INSERT INTO ${a} (esis, name, arabicname, homeroom)
              SELECT 
                  s.studentnumber,
                  s.englishname,
                  s.username,
                  s.esisclass
              FROM students s
              WHERE NOT EXISTS (
                  SELECT 1 FROM ${a} q
                  WHERE q.esis = s.studentnumber
              );
              UPDATE ${a}
              SET homeroom = (SELECT s.esisclass FROM students s WHERE s.studentnumber = ${a}.esis)
              WHERE EXISTS (
                  SELECT 1 FROM students s
                  WHERE s.studentnumber = ${a}.esis
              );
          
              COMMIT;
            `);
          });
        }}
      >
        Reset 2324T1
      </button>
    </div>
  );
};

export default SuperAdmiResetAssessmentTables;
