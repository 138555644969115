import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "./home.css";
// import HomeIcon from "@material-ui/icons/Home";
import NavHome from "./NavHome";
import WplGradList from "../components/wpl/WplGradList";
import HeaderHome from "./HeaderHome";
// import Info from "../components/wpl/StudentManual/Info";
import Manager from "./Manager";
// import Reports from "./classLists/Reports";
// import MapReport from "../components/wpl/StudentManual/MapReport";
import StudentLogin from "./classLists/StudentLogin";
import RevisionGrades from "../components/revision/RevisionGrades";
import Newreporting from "./NewReporting";
// import Portal from "./Portal";
// import { Button } from "@material-ui/core";
import MapReports from "./MapReports";
import ReportCard from "./ReportCard";
import ProgresReportHome from "./ProgresReportHome";
import Examtimetable from "./ExamTimetable";
import TimetableNew from "./timetableNew";
import WplDisplay from "./WplDisplay";
import CreditHoursInd from "./CreditHoursInd";
import GetStuAttendancePerDay from "./GetStuAttendancePerDay";
import MyAttendance from "./MyAttendance";
import Adaa from "./Adaa";
import StandardsData from "../components/exams/StandardsData";
import AdaaNew from "./AdaaNew";
import BlockedCardsReveald from "./BlockedCardsReveald";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//     "& > *": {
//       margin: theme.spacing(1),
//       width: theme.spacing(16),
//       height: theme.spacing(16),
//     },
//   },
// }));

const Home = () => {
  // const classNamees = useStyles();
  const [switcher, setSwitcher] = useState("");
  // const [loginTab, setloginTab] = useState("Login");
  const [header, setHeader] = useState(true);
  // const [showPortal, setShowPortal] = useState(true);
  // const [grade, setGrade] = useState(
  //   sessionStorage.getItem("grade")?.slice(1, 2)
  // );
  // if (showPortal) {
  //   return (
  //     <Portal>
  //       <div style={{ textAlign: "center", fontSize: "20px", margin: "25px" }}>
  //         {/* <a
  //           target="_blank"
  //           href="https://adekcharterschools-my.sharepoint.com/:b:/g/personal/khaled_badawi_charterschools_ae/EbiQrIPqyExLiBY73nLAUDABiGRWaDBZw3c6VURMvMRK0A?e=bEh7lh"
  //         >
  //           T3 Final Exams Timetable جدول اختبارات نهاية الفصل الثالث
  //         </a>
  //       </div>
  //       <div style={{ textAlign: "center", fontSize: "20px" }}>
  //         {grade === "5" ? (
  //           <a
  //             target="_blank"
  //             href="https://adekcharterschools-my.sharepoint.com/:b:/g/personal/khaled_badawi_charterschools_ae/EW8-5CPVoH9Iut2Q8AIUNOsBWfvnqzFUTj4mHMVmLeW5YA?e=9sxKee"
  //           >
  //             T3 Exams Study List دروس الاختبار
  //           </a>
  //         ) : grade === "6" ? (
  //           <a
  //             target="_blank"
  //             href="https://adekcharterschools-my.sharepoint.com/:b:/g/personal/khaled_badawi_charterschools_ae/EakeIQqtThNPoX6R1a0ffUsBqfSpyXnZgz1pJMqz03CyAQ?e=jJRgrN"
  //           >
  //             T3 Exams Study List دروس الاختبار
  //           </a>
  //         ) : grade === "7" ? (
  //           <a
  //             target="_blank"
  //             href="https://adekcharterschools-my.sharepoint.com/:b:/g/personal/khaled_badawi_charterschools_ae/Ecg_qVhDybZMmpmZ5CS3-JQBgrLPjB12VgQzHmFE6UpOCw?e=RaYo3f"
  //           >
  //             T3 Exams Study List دروس الاختبار
  //           </a>
  //         ) : grade === "8" ? (
  //           <a
  //             target="_blank"
  //             href="https://adekcharterschools-my.sharepoint.com/:b:/g/personal/khaled_badawi_charterschools_ae/EX24G4kAW3hBnTSuF7CGVDsBCrfke_qzc2w4tobap5xWMA?e=ysREcQ"
  //           >
  //             T3 Exams Study List دروس الاختبار
  //           </a>
  //         ) : (
  //           ""
  //         )} */}

  //         {sessionStorage.getItem("reportElig") !== "no" ? (
  //           <Newreporting />
  //         ) : (
  //           "تم حجب الشهادة لعدم سداد المصاريف أو لوجود مستحقات مالية واجبة السداد"
  //         )}
  //       </div>
  //       <div style={{ textAlign: "center", marginTop: "15px" }}>
  //         {sessionStorage.getItem("reportElig") !== "no" && (
  //           <Button
  //             variant="contained"
  //             color="secondary"
  //             onClick={() => setShowPortal(false)}
  //           >
  //             Close
  //           </Button>
  //         )}
  //       </div>
  //     </Portal>
  //   );
  // }
  return (
    <div>
      <Container style={{ textAlign: "center" }}>
        {header && (
          <div>
            <HeaderHome />
          </div>
        )}

        {switcher === "gradeslist" ? (
          <Container maxWidth="sm" style={{ textAlign: "center" }}>
            <WplGradList />
          </Container>
        ) : switcher === "revisionview" ? (
          // <RevisionGrades />
          // <Info />
          <StudentLogin />
        ) : switcher === "admin" ? (
          <Manager />
        ) : switcher === "scheduleView" ? (
          // <Examtimetable />
          // <Newreporting switcher={switcher} />
          <ReportCard />
          // <BlockedCardsReveald/>
        ) : switcher === "mapreport" ? (
          // <Examtimetable />
          // <Reports switcher={switcher} />
          <MapReports />
        ) : switcher === "revisionviewNew" ? (
          // <RevisionGrades />
          <WplDisplay />
        ) : switcher === "timetable" ? (
          <TimetableNew />
        ) : switcher === "progressReport" ? (
          // <ProgresReportHome />
          // <Adaa/>
          <AdaaNew/>
        ) : switcher === "myattendance" ? (
          <MyAttendance/>
        ) : switcher === "assessmentSchedule" ? (
          <Examtimetable />
        ) : switcher === "creditHours" ? (
          <CreditHoursInd />
        ) : (
          <NavHome
            switcher={(v) => setSwitcher(v)}
            header={(v) => setHeader(v)}
          />
        )}
      </Container>
    </div>
  );
};

export default Home;
