import React, { useState, useEffect } from "react";
import "./FeedbackForm.css";
import generalQuery from "../generalQuery";
import { CircularProgress } from "@mui/material";
const FeedbackFormView = ({ settinShowPortal, selectedStudent }) => {
  const [data, setData] = useState({});
  const [goalNames, setGoalNames] = useState([]);
  const [goalRitScore, setGoalRitScore] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let arr = [];
    let arrScores = [];
    Object.keys(selectedStudent).forEach((k) => {
      if (
        k.slice(0, 4) === "goal" &&
        k.slice(-4) === "name" &&
        selectedStudent[k] !== "null"
      ) {
        arr.push(selectedStudent[k]);
        arrScores.push(selectedStudent[k.slice(0, 5) + "ritscore"]);
      }
    });
    setGoalNames(arr);
    setGoalRitScore(arrScores);

    generalQuery(
      `select * from feedbackform where identifier='${
        selectedStudent.studentid +
        selectedStudent.termname +
        selectedStudent.course
      }'`
    ).then((results) => {
      if (results.length > 0) {
        setData(results[0]);
      } else {
        data.achievementstatus = "";
        data.otherreasonfeedback = "";
        data.otheraction = "";
        data.nextstepsinstructionalresources = "";
        data.teachersfeedback = "[]";
        data.actiontakenbyteacher = "[]";
        data.nextstepsbehavior = "[]";
        data.nextstepsattitude = "[]";
        data.nextstepsspecialneeds = "[]";
        data.nextstepsparentalsupport = "[]";
        data.nextstepsseekfeedback = "[]";
      }

      setLoading(false);
    });
  }, []);
  const getItems = (str) => {
    return JSON.parse(data[str]).map((d, index) => {
      return (
        <p key={index} className="feedFormValues">
          {d}
        </p>
      );
    });
  };
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      {!loading && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <button
              id="closeBtn"
              style={{
                padding: "8px",
                marginBottom: "5px",
                marginTop: "-10px",
                borderRadius: "5px",
              }}
              onClick={() => {
                settinShowPortal(false);
              }}
            >
              X
            </button>
          </div>
          <div>
            <div className="studentDetails">
              <div>{selectedStudent.schoolname}</div>
              <div>{selectedStudent.class}</div>
            </div>
            <div className="feedbackFormTable">
              <table>
                <thead>
                  <tr>
                    <th>Strand</th>
                    <th>Past Target</th>
                    <th>RIT Score</th>
                    <th>Next Target</th>
                  </tr>
                </thead>
                <tbody>
                  {goalNames.map((g, index) => {
                    return (
                      <tr key={index}>
                        <td>{g}</td>
                        <td style={{ color: "blue", fontWeight: "bold" }}>
                          {!isNaN(
                            Number(selectedStudent.testritscore) -
                              Number(
                                selectedStudent.falltospringobservedgrowth
                              ) +
                              Number(
                                selectedStudent.falltospringprojectedgrowth
                              )
                          ) &&
                            Number(goalRitScore[goalNames.indexOf(g)]) -
                              Number(
                                selectedStudent.falltospringobservedgrowth
                              ) +
                              Number(
                                selectedStudent.falltospringprojectedgrowth
                              )}
                        </td>
                        <td>{goalRitScore[goalNames.indexOf(g)]}</td>
                        <td>
                          {Number(goalRitScore[goalNames.indexOf(g)]) +
                            Number(selectedStudent.typicalspringtospringgrowth)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form className="feedbackForm">
              <h2>Section 1: Achievement Status</h2>
              <p className="feedFormValues">{data.achievementstatus}</p>
              <h2>Section 2: Teacher's Feedback</h2>
              {getItems("teachersfeedback")}
              <p className="feedFormValues">{data.otherreasonfeedback}</p>
              <h2>Section 3: Action Taken by Teacher</h2>
              {getItems("actiontakenbyteacher")}
              <p className="feedFormValues">{data.otheraction}</p>
              <h2>Section 4: Next Steps to Take</h2>
              <h4>4.1: Behavior</h4>
              {getItems("nextstepsbehavior")}
              <h4>4.2: Attitude to Learning</h4>
              {getItems("nextstepsattitude")}
              <h4>4.3: Special Needs</h4>
              {getItems("nextstepsspecialneeds")}
              <h4>4.4: Lack of Parental Support</h4>

              {getItems("nextstepsparentalsupport")}
              <h4>4.5:Limited Instructional Resources</h4>
              <p className="feedFormValues">
                {data.nextstepsinstructionalresources}
              </p>
              <h4>4.6: Gaps in my professional practice </h4>
              {getItems("nextstepsseekfeedback")}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackFormView;
