import React, { useState } from "react";
import GroupsPage from "./GroupsPage";
import "./InterventionDashboard .css"; // Add styles for the Navbar here
import TeachersIntervention from "./TeachersIntervention ";
import PodsIntervention from "./PodsIntervention";
import InterTimetable from "./InterTimetable";
import InterStudent from "./InterStudent.js";

const InterventionDashboard = () => {
  const [activeTab, setActiveTab] = useState("Timetable");
  const renderContent = () => {
    switch (activeTab) {
      case "Groups":
        return <GroupsPage />;
      case "Teachers":
        return <TeachersIntervention />;
      case "Pod Managers":
        return <PodsIntervention />;
      case "Students":
        return <div><InterStudent/></div>;
      case "Timetable":
        return (
          <div>
            <InterTimetable />
          </div>
        );
      default:
        return <GroupsPage />;
    }
  };

  return (
    <div>
      <nav className="navbarIntervention">
        <ul>
          <li
            className={activeTab === "Timetable" ? "active" : ""}
            onClick={() => setActiveTab("Timetable")}
          >
            Intervention Timetable
          </li>
          <li
            className={activeTab === "Groups" ? "active" : ""}
            onClick={() => setActiveTab("Groups")}
          >
            Groups
          </li>
          <li
            className={activeTab === "Teachers" ? "active" : ""}
            onClick={() => setActiveTab("Teachers")}
          >
            Teachers
          </li>
          <li
            className={activeTab === "Pod Managers" ? "active" : ""}
            onClick={() => setActiveTab("Pod Managers")}
          >
            Pod Managers
          </li>
          <li
            className={activeTab === "Students" ? "active" : ""}
            onClick={() => setActiveTab("Students")}
          >
            Students
          </li>
        </ul>
      </nav>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default InterventionDashboard;
