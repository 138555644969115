import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";

const SuperEditStudentData = ({ stData, classes, refresher, showPortal }) => {
  const [idPassword, setIdPassword] = useState(stData.id);
  const [updatedClass, setUpdatedClass] = useState(stData.homroom);
  const [phoneNumber, setPhoneNumber] = useState(stData.father);
  const [mobile, setMobile] = useState(stData.mobile);
  return (
    <div>
      <div>
        <h4 style={{ color: "blue" }}>{stData.studentnumber}</h4>
        <h4>{stData.englishname}</h4>
        <h6 style={{ marginBottom: "-3px", marginLeft: "5px", color: "blue" }}>
          Class
        </h6>
        <select
          className="select"
          onChange={(e) => {
            setUpdatedClass(e.target.value);
          }}
        >
          <option value={stData.homroom}>{stData.homroom}</option>
          {classes
            .filter(
              (c) =>
                c.slice(1, 2) === stData.homroom.slice(1, 2) &&
                c !== stData.homroom
            )
            .map((s) => {
              return (
                <option key={s} value={s}>
                  {s}
                </option>
              );
            })}
        </select>
        <h6 style={{ marginBottom: "-3px", marginLeft: "5px", color: "blue" }}>
          Mutanabi - Password
        </h6>
        <input
          className="select"
          value={idPassword}
          onChange={(e) => {
            setIdPassword(e.target.value);
          }}
        ></input>
        <h6 style={{ marginBottom: "-3px", marginLeft: "5px", color: "blue" }}>
          Phone
        </h6>
        <input
          className="select"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        ></input>
        <h6 style={{ marginBottom: "-3px", marginLeft: "5px", color: "blue" }}>
          Mobile
        </h6>
        <input
          className="select"
          value={mobile}
          onChange={(e) => {
            setMobile(e.target.value);
          }}
        ></input>
      </div>
      <button
        className="select"
        style={{
          fontSize: "18px",
          width: "80%",
          marginTop: "15px",
          backgroundColor: "lightblue",
        }}
        onClick={() => {
          generalQuery(
            `update students set id='${idPassword}',homroom='${updatedClass}',esisclass='${updatedClass}',homroomalph='${updatedClass}', father='${phoneNumber}',mobile='${mobile}' where studentnumber='${stData.studentnumber}'`
          ).then(() => {
            generalQuery(
              `update masterattendance set homeroomcode='${updatedClass}',homeroomid='${updatedClass}',tempclass='${updatedClass}' where pupilnumber='${stData.studentnumber}'`
            ).then(() => {
              refresher(Math.random());
            });
            showPortal(false);
          });
        }}
      >
        Save
      </button>
    </div>
  );
};
export default SuperEditStudentData;
