import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import Backarrow from "./BackArrow";
import "./examtimetable.css";
import TamblExamTimetable from "./TamblExamTimetable";
import T3EoT6 from "./T3EoT6.png";
import T3EoT7 from "./T3EoT7.png";
import T3EoT8 from "./T3EoT8.png";
import T3EoT9 from "./T3EoT9.png";
const ExamTimetable = () => {
  const [classes, setClasses] = useState([]);
  const [data, setData] = useState([]);
  const grade_level = sessionStorage.getItem("grade")?.slice(0, 2);
  const getDate = (r, dt) => {
    if (r[dt]) {
      // return new Date("2023-" + r[dt].slice(-1) + "-" + r[dt].slice(0, 2));
      return r[dt];
    } else {
      return "";
    }
  };

  useEffect(() => {
    generalQuery(`select distinct(class) from exams order by class`).then(
      (results) => setClasses(results)
    );
  }, []);
  return (
    <div>
      {sessionStorage.getItem("user") === "student" && <Backarrow />}
      <h2>Key Assessment 1 (KA1) </h2>
      <h2>2024-2025 </h2>
      
      {/* {grade_level === "G9" ? (
        <img src={T3EoT9}></img>
      ) : grade_level === "G8" ? (
        <img src={T3EoT8}></img>
      ) : grade_level === "G7" ? (
        <img src={T3EoT7}></img>
      ) : grade_level === "G6" ? (
        <img src={T3EoT6}></img>
      ) : (
        ""
      )} */}
      <select
        className="select"
        id="selectClass"
        onChange={(e) => {
          generalQuery(
            `select * from exams where class='${e.target.value}'`
          ).then((results) => {
            console.log(results)
            setData(
              [
                ["Arabic", getDate(results[0], "arabic")],
                ["English", getDate(results[0], "english")],
                ["Math", getDate(results[0], "math")],
                ["Science", getDate(results[0], "science")],
                ["Islamic St", getDate(results[0], "islamic")],
                ["Social St", getDate(results[0], "socialst")],
              ]
                .filter((a) => a[1] !== "")
                .sort((a, b) => (a[1] > b[1] ? 1 : -1))
            );
          });
        }}
      >
        <option>Select Class</option>
        {classes.map((c) => {
          return (
            <option key={c.class} value={c.class}>
              {c.class}
            </option>
          );
        })}
      </select>
      <TamblExamTimetable data={data} />
    </div>
  );
};

export default ExamTimetable;
