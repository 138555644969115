import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
const SuperAdminProgressReportTracker = () => {
  const [data, setData] = useState([]);
  const [classes, setClasses] = useState([]);
  const recommendations = [
    "Brings books and material.",
    "Works independently.",
    "Exerts best effort.",
    "Attends regularly and on time.",
    "Submits quality work on time.",
    "Good Performance on Assignments and Projects.",
    "Good Performance on Assessments.",
    "Works cooperatively with peers.",
  ];

  const generalQuery = async (z, p) => {
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "getclasses") {
        setClasses(resp);
      }
      if (p === "getData") {
        resp.forEach((r) => {
          ["arabic", "english", "math", "science"].forEach((sub) => {
            ["attendancescale", "behaviourscale", "readinessscale"].forEach(
              (e) => {
                if (
                  r[sub + e] === null ||
                  r[sub + e] === "null" ||
                  r[sub + e] === "undefined"
                ) {
                  r[sub + e] = "";
                } else if (r[sub + e] === "0") {
                  r[sub + e] = "Exceeds Expectations";
                } else if (r[sub + e] === "1") {
                  r[sub + e] = "Meeting Expectations";
                } else if (r[sub + e] === "2") {
                  r[sub + e] = "Below Expectations";
                }
              }
            );
            if (r[sub + "recom"]) {
              if (
                r[sub + "recom"][0] === "undefined" ||
                r[sub + "recom"][0] === "null" ||
                r[sub + "recom"][0] === null
              ) {
                r[sub + "recom"][0] = "";
              } else {
                let recString = "";
                r[sub + "recom"][0].split(",").forEach((rec) => {
                  recString =
                    recString + recommendations[Number(rec.slice(-1))];
                });
                r[sub + "recom"][0] = recString;
              }
            }
          });
        });

        setData(resp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checker = (homeroom, sub) => {
    let dataClass = data.filter((d) => d.homeroom === homeroom);
    let completionStatus = 0;
    dataClass.forEach((el) => {
      if (
        el[sub + "attendancescale"] !== "" &&
        el[sub + "attendancescale"] !== "null" &&
        el[sub + "attendancescale"] !== "undefined" &&
        el[sub + "behaviourscale"] !== "" &&
        el[sub + "behaviourscale"] !== "null" &&
        el[sub + "behaviourscale"] !== "undefined" &&
        el[sub + "readinessscale"] !== "" &&
        el[sub + "readinessscale"] !== "null" &&
        el[sub + "readinessscale"] !== "undefined" &&
        el[sub + "recom"] &&
        el[sub + "recom"][0] !== "" &&
        el[sub + "recom"][0] !== "null" &&
        el[sub + "recom"][0] !== "undefined" &&
        el[sub + "recom"][0] !== null
      ) {
        completionStatus = completionStatus + 1;
      }
    });
    if ((completionStatus * 100) / dataClass.length > 0) {
      return Math.floor((completionStatus * 100) / dataClass.length) + "%";
    }
  };
  useEffect(() => {
    generalQuery(
      `select distinct(homeroom) from prgrpt2 order by homeroom`,
      "getclasses"
    );
    generalQuery(`select * from prgrpt2`, "getData");
  }, []);
  const headers = [
    { label: "esis", key: "esis" },
    { label: "name", key: "name" },
    { label: "arabicname", key: "arabicname" },
    { label: "homeroom", key: "homeroom" },
    { label: "arabicattendancescale", key: "arabicattendancescale" },
    { label: "arabicbehaviourscale", key: "arabicbehaviourscale" },
    { label: "arabicreadinessscale", key: "arabicreadinessscale" },
    { label: "arabicrecom", key: "arabicrecom" },
    { label: "englishattendancescale", key: "englishattendancescale" },
    { label: "englishbehaviourscale", key: "englishbehaviourscale" },
    { label: "englishreadinessscale", key: "englishreadinessscale" },
    { label: "englishrecom", key: "englishrecom" },
    { label: "mathattendancescale", key: "mathattendancescale" },
    { label: "mathbehaviourscale", key: "mathbehaviourscale" },
    { label: "mathreadinessscale", key: "mathreadinessscale" },
    { label: "mathrecom", key: "mathrecom" },
    { label: "scienceattendancescale", key: "scienceattendancescale" },
    { label: "sciencebehaviourscale", key: "sciencebehaviourscale" },
    { label: "sciencereadinessscale", key: "sciencereadinessscale" },
    { label: "sciencerecom", key: "sciencerecom" },
  ];
  return (
    <div>
      <div className="container">
        <CSVLink data={data} headers={headers} filename="Progress Report">
          Download Progress Report Data
        </CSVLink>
      </div>
      <div className="container">
        {data.length !== 0 ? (
          <table className="reportTable">
            <thead>
              <tr>
                <th>Class</th>
                <th>Arabic</th>
                <th>English</th>
                <th>Math</th>
                <th>Science</th>
              </tr>
            </thead>
            <tbody>
              {classes.map((c) => {
                return (
                  <tr key={c.homeroom}>
                    <td>{c.homeroom}</td>
                    {["arabic", "english", "math", "science"].map((sub) => {
                      return (
                        <td
                          key={c.homeroom + sub}
                          className={
                            checker(c.homeroom, sub) === "100%"
                              ? "green"
                              : "red"
                          }
                        >
                          {checker(c.homeroom, sub)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="container">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminProgressReportTracker;
