
import GroupsPage from "./InterventionAfterSchool/GroupsPage";
import Newlogin from "./views/NewLogin";


function App() {
  return (
    <div className="App">

      <Newlogin />
 
    </div>
  );
}

export default App;
