import React, { useState, useEffect } from "react";
import "./CoverageNew.css";
const CoverageNew = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [refresher, setRefresher] = useState();
  const [weeks, setWeeks] = useState([]);
  const [subTimetable, setSubTimetable] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const generalQuery = async (z, p) => {
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "getWeeks") {
        setWeeks(resp);
      }
      if (p === "getsubTimetable") {
        setSubTimetable(resp);
      }
      if (p === "update") {
        generalQuery(`select * from ${selectedWeek} order by total desc`, "getsubTimetable");
      }
    } catch (error) {
      console.log(error);
    }
  };
  let head = (
    <tr>
      <th></th>
      <th>p1</th>
      <th>p2</th>
      <th>p3</th>
      <th>p4</th>
      <th>p5</th>
      <th>p6</th>
    </tr>
  );
  useEffect(() => {
    generalQuery(
      `SELECT table_name FROM information_schema.tables WHERE table_schema='public' AND table_type='BASE TABLE' and table_name like 'subs%'`,
      "getWeeks"
    );
  }, [refresher]);
  return (
    <div>
      <div className="coverageContainer">
        <div className="container">
          <input
            type="date"
            className="select"
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          />
          <button
            className="select"
            onClick={() => {
              generalQuery(
                `create table ${
                  "subs" + selectedDate.replace("-", "").replace("-", "")
                } as select * from timetable`
              );

              setRefresher(Math.random());
            }}
          >
            New Week
          </button>
        </div>
        <div className="container">
          <select
            className="select"
            onChange={(e) => {
              setSelectedWeek(e.target.value);
              generalQuery(
                `select * from ${e.target.value} order by total desc`,
                "getsubTimetable"
              );
            }}
          >
            <option>Select Week</option>
            {weeks.map((w) => {
              return (
                <option key={w.table_name} value={w.table_name}>
                  {w.table_name}
                </option>
              );
            })}
          </select>
          <select
            className="select"
            onChange={(e) => {
              setSelectedDay(e.target.value);
            }}
          >
            <option aria-label="None" value="">
              select day...
            </option>
            <option value="m">Monday</option>
            <option value="t">Tuesday</option>
            <option value="w">Wednesday</option>
            <option value="th">Thursday</option>
            <option value="f">Friday</option>
          </select>
        </div>
        {subTimetable.length > 0 ? (
          <div className="coverageContainer">
            <select
              className="select"
              onChange={(e) => {
                setSelectedTeacher(e.target.value);
              }}
            >
              <option>Select Absent Teacher</option>
              {subTimetable.map((t) => {
                return (
                  <option key={t.teacher} value={t.teacher}>
                    {t.teacher}
                  </option>
                );
              })}
            </select>
            <table>
              {head}
              {selectedDay.length > 0 ? (
                <tr className="creatortable">
                  <td>{selectedTeacher.slice(0, 12)}</td>
                  {selectedTeacher.length > 0
                    ? ["1", "2", "3", "4", "5", "6"].map((i) => {
                        return (
                          <td key={i + "td"}>
                            {subTimetable.filter(
                              (t) => t.teacher === selectedTeacher
                            )[0][selectedDay + i] &&
                              subTimetable.filter(
                                (t) => t.teacher === selectedTeacher
                              )[0][selectedDay + i] !== "free" &&
                              subTimetable.filter(
                                (t) => t.teacher === selectedTeacher
                              )[0][selectedDay + i]}
                          </td>
                        );
                      })
                    : ""}
                </tr>
              ) : (
                ""
              )}
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
      {head}
      {subTimetable.length > 0 && selectedTeacher.length > 0
        ? subTimetable
            .filter((s) => s.teacher !== selectedTeacher)
            .map((t) => {
              return (
                <tr key={t.teacher} className="creatortable">
                  <td>{t.teacher}</td>
                  {["1", "2", "3", "4", "5", "6"].map((i) => {
                    return (
                      <td key={i + "td"}>
                        {t[selectedDay + i] === "free" &&
                          subTimetable.filter(
                            (t) => t.teacher === selectedTeacher
                          )[0][selectedDay + i] !== "free" && (
                            <button
                              onClick={() => {
                                generalQuery(
                                  `update ${selectedWeek} set ${
                                    selectedDay + i
                                  }='${
                                    subTimetable.filter(
                                      (t) => t.teacher === selectedTeacher
                                    )[0][selectedDay + i] +
                                    "--" +
                                    selectedTeacher.slice(0, 6)
                                  }' where teacher='${t.teacher}'`,
                                  "update"
                                );
                              }}
                            >
                              +
                            </button>
                          )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
        : ""}
    </div>
  );
};

export default CoverageNew;
