import React from "react";
import SuperadminEndOfYear from "./SuperadminEndOfYear";
import DataEntryForm from "./DataEntryForm";

const SuperStaffTable = ({ data }) => {
  return (
    <div>
      {/* StaffTable */}
<DataEntryForm/>
    </div>
  );
};

export default SuperStaffTable;
