import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Container from "@material-ui/core/Container";
import WplTable from "./WplTable";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function WplList({ weeks, wplData, grade }) {
  const classes = useStyles();
  const [showTable, setShowTable] = useState(false);
  const [week, setWeek] = useState("");
  if (showTable) {
    
    return <WplTable week={week} wplData={wplData} grade={grade} />;
  }
  return (
    <Container
      maxWidth="lg"
      style={{ textAlign: "center", marginLeft: "15%", marginTop: "10%" }}
    >
      {/* <div
        style={{ float: "inline-start" }}
        onClick={() => {
          // window.location.reload();
        }}
      >
        <HomeIcon fontSize="large" />
      </div> */}
      <List className={classes.root}>

      <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(9 + " " + "[31/10/2021]");
            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 9} secondary="31/10/2021" />
        </ListItem>
       

      <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(8 + " " + "[24/10/2021]");
            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 8} secondary="24/10/2021" />
        </ListItem>
      
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(7 + " " + "[17/10/2021]");
            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 7} secondary="17/10/2021" />
        </ListItem>
     
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(6 + " " + "[10/10/2021]");

            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 6} secondary="10/10/2021" />
        </ListItem>
   
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(5 + " " + "[3/09/2021]");

            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 5} secondary="3/09/2021" />
        </ListItem>
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(4 + " " + "[26/09/2021]");

            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 4} secondary="23/05/2021" />
        </ListItem>
    
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            setWeek(3 + " " + "[19/09/2021]");

            setShowTable(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <DateRangeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"الأسبوع " + 3} secondary="19/09/2021" />
        </ListItem>
    
      </List>
    </Container>
  );
}
