import React, { useState, useEffect } from "react";
import Coverage from "./Coverage";
import "./subs2Table.css";
import Swapstable from "./SwapsTable";
const Subs2table = ({
  selectedTable,
  periodsArr,
  selectedTeacher,
  showCoverage,
  swaps,
  settingSwaps,
  selectedDay,
}) => {
  const [freeTeachers, setFreeTeachers] = useState([]);
  const [arr, setArr] = useState([]);
  const [period1, setPeriod1] = useState([]);
  const [period2, setPeriod2] = useState([]);
  const [period3, setPeriod3] = useState([]);
  const [period4, setPeriod4] = useState([]);
  const [period5, setPeriod5] = useState([]);
  const [period6, setPeriod6] = useState([]);
  const [period7, setPeriod7] = useState([]);
  const [i, setI] = useState();
  const [swapClass, setSwapClass] = useState("");
  const [swapPeriod, setSwapPeriod] = useState("");
  const assignCover = async (x, y, z, v, o) => {
    try {
      const body = {
        tablename: selectedTable,
        value: y,
        selectedPeriod: x,
        teacher: z,
        total: v,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/assignCover",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setI(Math.random());
      document.getElementById(o).style.backgroundColor = "green";
      document.getElementById(o).style.color = "white";
    } catch (error) {
      console.log(error);
    }
  };
  const getteacherClasses = async () => {
    try {
      const body = {
        selectedTable,
        periodsArr,
        teacher: selectedTeacher,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/getteacherClasses",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setArr([
        resp[0].p1,
        resp[0].p2,
        resp[0].p3,
        resp[0].p4,
        resp[0].p5,
        resp[0].p6,
        resp[0].p7,
      ]);
      if (resp) {
        if (
          resp[0].p1 !== "free" &&
          period1.indexOf(resp[0].p1 + " - " + selectedTeacher) === -1
        ) {
          period1.push(resp[0].p1 + " - " + selectedTeacher);
        }
        if (
          resp[0].p2 !== "free" &&
          period2.indexOf(resp[0].p2 + " - " + selectedTeacher) === -1
        ) {
          period2.push(resp[0].p2 + selectedTeacher);
        }
        if (
          resp[0].p3 !== "free" &&
          period3.indexOf(resp[0].p3 + " - " + selectedTeacher) === -1
        ) {
          period3.push(resp[0].p3 + selectedTeacher);
        }
        if (
          resp[0].p4 !== "free" &&
          period4.indexOf(resp[0].p4 + " - " + selectedTeacher) === -1
        ) {
          period4.push(resp[0].p4 + selectedTeacher);
        }
        if (
          resp[0].p5 !== "free" &&
          period5.indexOf(resp[0].p5 + " - " + selectedTeacher) === -1
        ) {
          period5.push(resp[0].p5 + selectedTeacher);
        }
        if (
          resp[0].p6 !== "free" &&
          period6.indexOf(resp[0].p6 + " - " + selectedTeacher) === -1
        ) {
          period6.push(resp[0].p6 + selectedTeacher);
        }
        if (
          resp[0].p7 !== "free" &&
          period7.indexOf(resp[0].p7 + " - " + selectedTeacher) === -1
        ) {
          period7.push(resp[0].p7 + selectedTeacher);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatedToFree = async (x, y, z, o) => {
    try {
      const body = {
        selectedTable,
        period: x,
        teacher: y,
        total: z,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/updatedToFree",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      document.getElementById(o).style.backgroundColor = "white";
      document.getElementById(o).style.color = "black";
      setI(Math.random());
    } catch (error) {
      console.log(error);
    }
  };
  const getSubteacher = async (x, y) => {
    try {
      const body = {
        tablename: selectedTable,
        periodsArr: y,
        selectedPeriod: x,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/getSubteacher",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();

      setFreeTeachers(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getteacherClasses();
    periodsArr.forEach((el) => {
      getSubteacher(el, periodsArr);
    });
  }, [i]);
  if (showCoverage) {
  }
  return (
    <div style={{ overflow: "auto" }}>
      {showCoverage ? (
        <div style={{ width: "100vw" }}>
          <Coverage
            freeTeachers={freeTeachers}
            selectedDay={selectedDay}
            selectedTable={selectedTable}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            <table className="coverageTable">
              <thead>
                <tr>
                  <th>Teacher</th>
                  <th>P1</th>
                  <th>P2</th>
                  <th>P3</th>
                  <th>P4</th>
                  <th>P5</th>
                  <th>P6</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedTeacher}</td>
                  <td
                    id="p1"
                    className={arr[0] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[0]);
                      setSwapPeriod("p1");
                    }}
                  >
                    {arr[0]}
                  </td>
                  <td
                    id="p2"
                    className={arr[1] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[1]);
                      setSwapPeriod("p2");
                    }}
                  >
                    {arr[1]}
                  </td>
                  <td
                    id="p3"
                    className={arr[2] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[2]);
                      setSwapPeriod("p3");
                    }}
                  >
                    {arr[2]}
                  </td>
                  <td
                    id="p4"
                    className={arr[3] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[3]);
                      setSwapPeriod("p4");
                    }}
                  >
                    {arr[3]}
                  </td>
                  <td
                    id="p5"
                    className={arr[4] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[4]);
                      setSwapPeriod("p5");
                    }}
                  >
                    {arr[4]}
                  </td>
                  <td
                    id="p6"
                    className={arr[5] !== "free" ? "swapsButton" : ""}
                    onClick={() => {
                      settingSwaps(true);
                      setSwapClass(arr[5]);
                      setSwapPeriod("p6");
                    }}
                  >
                    {arr[5]}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {!swaps ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <table
                style={{ border: "solid black" }}
                className="coverageTable"
              >
                <thead>
                  <tr>
                    <th>Teacher</th>
                    <th>P1</th>
                    <th>P2</th>
                    <th>P3</th>
                    <th>P4</th>
                    <th>P5</th>
                    <th>P6</th>

                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {freeTeachers.map((e) => {
                    return (
                      <tr key={e.teacher}>
                        <td>{e.teacher}</td>
                        {e.p1 === "free" ? (
                          <td>
                            {arr[0] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[0],
                                    arr[0] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p1"
                                  );
                                }}
                              >
                                {arr[0] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p1 === arr[0] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[0],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p1"
                                );
                              }}
                            >
                              {arr[0] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p1}</td>
                        )}
                        {e.p2 === "free" ? (
                          <td>
                            {arr[1] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[1],
                                    arr[1] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p2"
                                  );
                                }}
                              >
                                {arr[1] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p2 === arr[1] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[1],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p2"
                                );
                              }}
                            >
                              {arr[1] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p2}</td>
                        )}
                        {e.p3 === "free" ? (
                          <td>
                            {arr[2] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[2],
                                    arr[2] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p3"
                                  );
                                }}
                              >
                                {arr[2] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p3 === arr[2] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[2],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p3"
                                );
                              }}
                            >
                              {arr[2] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p3}</td>
                        )}
                        {e.p4 === "free" ? (
                          <td>
                            {arr[3] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[3],
                                    arr[3] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p4"
                                  );
                                }}
                              >
                                {arr[3] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p4 === arr[3] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[3],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p4"
                                );
                              }}
                            >
                              {arr[3] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p4}</td>
                        )}
                        {e.p5 === "free" ? (
                          <td>
                            {arr[4] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[4],
                                    arr[4] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p5"
                                  );
                                }}
                              >
                                {arr[4] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p5 === arr[4] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[4],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p5"
                                );
                              }}
                            >
                              {arr[4] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p5}</td>
                        )}
                        {e.p6 === "free" ? (
                          <td>
                            {arr[5] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[5],
                                    arr[5] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p6"
                                  );
                                }}
                              >
                                {arr[5] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p6 === arr[5] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[5],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p6"
                                );
                              }}
                            >
                              {arr[5] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p6}</td>
                        )}
                        {e.p7 === "free" ? (
                          <td>
                            {arr[6] !== "free" && (
                              <button
                                onClick={() => {
                                  assignCover(
                                    periodsArr[6],
                                    arr[6] + "---" + selectedTeacher,
                                    e.teacher,
                                    Number(e.total) + 1,
                                    "p7"
                                  );
                                }}
                              >
                                {arr[6] + "---" + selectedTeacher}
                              </button>
                            )}
                          </td>
                        ) : e.p7 === arr[6] + "---" + selectedTeacher ? (
                          <td>
                            <button
                              style={{
                                backgroundColor: "red",
                                marginTop: "10px",
                                color: "white",
                                padding: "3px",
                              }}
                              onClick={() => {
                                updatedToFree(
                                  periodsArr[6],
                                  e.teacher,
                                  Number(e.total) - 1,
                                  "p7"
                                );
                              }}
                            >
                              {arr[6] + "---" + selectedTeacher}
                            </button>
                          </td>
                        ) : (
                          <td>{e.p7}</td>
                        )}
                        <td>{e.total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Swapstable
              freeTeachers={freeTeachers}
              swapClass={swapClass}
              swapPeriod={swapPeriod}
              arr={arr}
              selectedTable={selectedTable}
              selectedTeacher={selectedTeacher}
              periodsArr={periodsArr}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Subs2table;
