import { LeaderboardOutlined } from "@mui/icons-material";
import exportDataToExcel from "../../exportDataToExcel";
const assignJudgment = (percentage_65_or_above, percentage_75_or_above) => {
  let judgment = "Weak";
  if (percentage_65_or_above >= 75) {
    judgment = "Acceptable";
    if (percentage_75_or_above >= 75) {
      judgment = "Outstanding";
    } else if (percentage_75_or_above >= 60) {
      judgment = "Very Good";
    } else if (percentage_75_or_above >= 49.9) {
      judgment = "Good";
    }
  }
  return judgment;
};
const processData = (rawData) => {
  let learningOutComesArr = [];
  rawData.forEach((item) => {
    if (learningOutComesArr.indexOf(item.learning_outcomes) === -1) {
      learningOutComesArr.push(item.learning_outcomes);
    }
  });

  let processedData = [];
  learningOutComesArr.forEach((lo) => {
    let count65 = 0;
    let count75 = 0;
    let loData = rawData.filter((r) => r.learning_outcomes === lo);

    loData.forEach((e) => {
      if ((Number(e.grade)*100 / Number(e.max)) >= 65) {
        count65++;
      }
      if ((Number(e.grade)*100 / Number(e.max))  >= 75) {
        count75++;
      }
    });

    // Create a new object for each learning outcome
    let objectForLos = {
      learning_outcomes: lo,
      dok: loData[0].dok,
      ccss: loData[0].ccss,
      max_possible: loData[0].max,
      percentage_65_or_above: Math.ceil((count65 * 100) / loData.length)+"%", // converting to percentage
      percentage_75_or_above: Math.ceil((count75 * 100) / loData.length)+"%", // converting to percentage
      judgment: assignJudgment(
        Math.ceil((count65 * 100) / loData.length),
        Math.ceil((count75 * 100) / loData.length)
      ),
    };

    processedData.push(objectForLos);
  });

  return processedData;
};

const DataExportLink = ({ data }) => {
  const handleExport = (e) => {
    e.preventDefault();
    let processedData = processData(data);
    const headers = [
      "learning_outcomes",
      "dok",
      "ccss",
      "max_possible",
      "percentage_65_or_above",
      "percentage_75_or_above",
      "judgment",
    ];
    const fileName = data.length ? data[0].assessment_type+"_G"+data[0].grade_level+"_"+data[0].subject : "export";
    exportDataToExcel(processedData, headers, fileName);
  };

  return (
    <a
      style={{ fontWeight: "bold", fontSize: "25px" }}
      href="#"
      onClick={handleExport}
    >
      Download Irtiqaa Analysis
    </a>
  );
};

export default DataExportLink;
