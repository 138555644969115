import React, { useState, useEffect } from "react";
import "./FeedbackForm.css";
import generalQuery from "../generalQuery";
const FeedbackForm = ({ settinShowPortal, selectedStudent }) => {
  const [achievementStatus, setAchievementStatus] = useState("");
  const [teachersFeedback, setTeachersFeedback] = useState([]);
  const [actionTakenByTeacher, setActionTakenByTeacher] = useState([]);
  const [otherReasonFeedback, setOtherReasonFeedback] = useState("");
  const [otherAction, setOtherAction] = useState("");
  const [nextStepsBehavior, setNextStepsBehavior] = useState([]);
  const [nextStepsAttitude, setNextStepsAttitude] = useState([]);
  const [nextStepsSpecialNeeds, setNextStepsSpecialNeeds] = useState([]);
  const [nextStepsParentalSupport, setNextStepsParentalSupport] = useState([]);
  const [nextStepsInstructionalResources, setNextStepsInstructionalResources] =
    useState("");
  const [nextStepsSeekFeedback, setNextStepsSeekFeedback] = useState([]);
  const [goalNames, setGoalNames] = useState([]);
  const [goalRitScore, setGoalRitScore] = useState([]);
  useEffect(() => {
    let arr = [];
    let arrScores = [];
    Object.keys(selectedStudent).forEach((k) => {
      if (
        k.slice(0, 4) === "goal" &&
        k.slice(-4) === "name" &&
        selectedStudent[k] !== "null"
      ) {
        arr.push(selectedStudent[k]);
        arrScores.push(selectedStudent[k.slice(0, 5) + "ritscore"]);
      }
    });
    setGoalNames(arr);
    setGoalRitScore(arrScores);
  }, []);
  const handleAchievementStatusChange = (e) => {
    setAchievementStatus(e.target.value);
  };

  const handleTeachersFeedbackChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setTeachersFeedback(selectedOptions);
  };

  const handleActionTakenByTeacherChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setActionTakenByTeacher(selectedOptions);
  };

  const handleOtherFeedbackChange = (e) => {
    setOtherReasonFeedback(e.target.value);
  };
  const handleActionChange = (e) => {
    setOtherAction(e.target.value);
  };

  const handleNextStepsBehaviorChange = (e) => {
    const selectedOptions = Array.from(
      document.querySelectorAll('input[name="nextStepsBehavior"]:checked'),
      (checkbox) => checkbox.value
    );
    setNextStepsBehavior(selectedOptions);
  };
  const handleNextStepsAttitudeChange = (e) => {
    const selectedOptions = Array.from(
      document.querySelectorAll('input[name="nextStepsAttitude"]:checked'),
      (checkbox) => checkbox.value
    );
    setNextStepsAttitude(selectedOptions);
  };

  const handleNextStepsSpecialNeedsChange = (e) => {
    const selectedOptions = Array.from(
      document.querySelectorAll('input[name="nextStepsSpecialNeeds"]:checked'),
      (checkbox) => checkbox.value
    );
    setNextStepsSpecialNeeds(selectedOptions);
  };

  const handleNextStepsParentalSupportChange = (e) => {
    const selectedOptions = Array.from(
      document.querySelectorAll(
        'input[name="nextStepsParentalSupport"]:checked'
      ),
      (checkbox) => checkbox.value
    );
    setNextStepsParentalSupport(selectedOptions);
  };

  const handleNextStepsInstructionalResourcesChange = (e) => {
    setNextStepsInstructionalResources(e.target.value);
  };

  const handleNextStepsSeekFeedbackChange = (e) => {
    const selectedOptions = Array.from(
      document.querySelectorAll('input[name="nextStepsSeekFeedback"]:checked'),
      (checkbox) => checkbox.value
    );
    setNextStepsSeekFeedback(selectedOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or data processing here
    if (
      achievementStatus !== "" ||
      teachersFeedback.length === 0 ||
      actionTakenByTeacher.length === 0
    ) {
      generalQuery(`INSERT INTO FeedbackForm (achievementStatus, teachersFeedback, actionTakenByTeacher, otherReasonFeedback, otherAction, nextStepsBehavior, nextStepsAttitude, nextStepsSpecialNeeds, nextStepsParentalSupport, nextStepsInstructionalResources, nextStepsSeekFeedback, esis,maptest,subject,identifier,teacher)
    VALUES ('${achievementStatus}', '${JSON.stringify(
        teachersFeedback
      )}', '${JSON.stringify(
        actionTakenByTeacher
      )}', '${otherReasonFeedback}', '${otherAction}', '${JSON.stringify(
        nextStepsBehavior
      )}', '${JSON.stringify(nextStepsAttitude)}', '${JSON.stringify(
        nextStepsSpecialNeeds
      )}', '${JSON.stringify(
        nextStepsParentalSupport
      )}', '${nextStepsInstructionalResources}', '${JSON.stringify(
        nextStepsSeekFeedback
      )}', '${selectedStudent.studentid}','${selectedStudent.termname}','${
        selectedStudent.course
      }','${
        selectedStudent.studentid +
        selectedStudent.termname +
        selectedStudent.course
      }','${sessionStorage.getItem(
        "teachername"
      )}') ON CONFLICT (identifier) DO UPDATE
    SET achievementStatus='${achievementStatus}', teachersFeedback='${JSON.stringify(
        teachersFeedback
      )}', actionTakenByTeacher='${JSON.stringify(
        actionTakenByTeacher
      )}', otherReasonFeedback='${otherReasonFeedback}', otherAction='${otherAction}', nextStepsBehavior='${JSON.stringify(
        nextStepsBehavior
      )}', nextStepsAttitude='${JSON.stringify(
        nextStepsAttitude
      )}', nextStepsSpecialNeeds='${JSON.stringify(
        nextStepsSpecialNeeds
      )}', nextStepsParentalSupport='${JSON.stringify(
        nextStepsParentalSupport
      )}', nextStepsInstructionalResources='${nextStepsInstructionalResources}', nextStepsSeekFeedback='${JSON.stringify(
        nextStepsSeekFeedback
      )}',teacher='${sessionStorage.getItem("teachername")}'`).then(
        (results) => {
          if (!isNaN(results.length)) {
            settinShowPortal(false);
          }
        }
      );
    } else {
      alert("Please, complete your feedback!");
    }
  };

  return (
    <div>
      <div className="studentDetails">
        <div>{selectedStudent.schoolname}</div>
        <div>{selectedStudent.class}</div>
      </div>
      <div className="feedbackFormTable">
        <table>
          <thead>
            <tr>
              <th>Strand</th>
              <th>Past Target</th>
              <th>RIT Score</th>
              <th>Next Target</th>
            </tr>
          </thead>
          <tbody>
            {goalNames.map((g, index) => {
              return (
                <tr key={index}>
                  <td>{g}</td>
                  <td style={{ color: "blue", fontWeight: "bold" }}>
                    {!isNaN(
                      Number(selectedStudent.testritscore) -
                        Number(selectedStudent.falltospringobservedgrowth) +
                        Number(selectedStudent.falltospringprojectedgrowth)
                    ) &&
                      Number(goalRitScore[goalNames.indexOf(g)]) -
                        Number(selectedStudent.falltospringobservedgrowth) +
                        Number(selectedStudent.falltospringprojectedgrowth)}
                  </td>
                  <td>{goalRitScore[goalNames.indexOf(g)]}</td>
                  <td>
                    {Number(goalRitScore[goalNames.indexOf(g)]) +
                      Number(selectedStudent.typicalspringtospringgrowth)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <form onSubmit={handleSubmit} className="feedbackForm">
        <h2>Section 1: Achievement Status</h2>
        <select
          className="select"
          value={achievementStatus}
          onChange={handleAchievementStatusChange}
        >
          <option value="">Select an option</option>
          <option value="Exceeding">Exceeding</option>
          <option value="Met">Met</option>
          <option value="No Change">No Change</option>
          <option value="Progress">Progress</option>
          <option value="Regression">Regression</option>
        </select>

        <h2>Section 2: Teacher's Feedback</h2>
        <select
          id="mutliselect"
          multiple
          value={teachersFeedback}
          onChange={handleTeachersFeedbackChange}
        >
          <option value="Attendance">Attendance</option>
          <option value="Behavior">Behavior</option>
          <option value="Attitude to learning">Attitude to learning</option>
          <option value="Affected by Online Teaching">
            Affected by Online Teaching
          </option>
          <option value="Medical Reasons">Medical Reasons</option>
          <option value="Special Needs">Special Needs</option>
          <option value="Parental Support">Parental Support</option>
          <option value="Lack of Instructional Resources">
            Lack of Instructional Resources
          </option>
          <option value="Gaps in my professional practice">
            Gaps in my professional practice
          </option>
        </select>
        <textarea
          type="text"
          value={otherReasonFeedback}
          onChange={handleOtherFeedbackChange}
          placeholder="Other reason"
        ></textarea>

        <h2>Section 3: Action Taken by Teacher</h2>
        <select
          multiple
          value={actionTakenByTeacher}
          onChange={handleActionTakenByTeacherChange}
        >
          <option value="Direct Communication with the Student">
            Direct Communication with the Student
          </option>
          <option value="Communication with Social Worker">
            Communication with Social Worker
          </option>
          <option value="Communication with VP, Head of Department, Social Worker, Pod Manager">
            Communication with VP,HoD, Pod Manager
          </option>
          <option value="Communication with Parents via School Voice">
            Communication with Parents via School Voice
          </option>
          <option value="Communication with Parents via Alef Guardian app">
            Communication with Parents via Alef Guardian app
          </option>
          <option value="Communication with Parents via phone calls">
            Communication with Parents via phone calls
          </option>
          <option value="Communication with the Nurse and/or Parents">
            Communication with the Nurse and/or Parents
          </option>
          <option value="Communication with SEN department">
            Communication with SEN department
          </option>
          <option value="Parents were unreachable">
            Parents were unreachable
          </option>
          <option value="Implementation of Exact Path in my lessons">
            Implementation of Exact Path in my lessons
          </option>
          <option value="Implementation of RAZ Kids in my lessons">
            Implementation of RAZ Kids in my lessons
          </option>
          <option value="Implementation of Exact Path and RAZ Kids in my lessons">
            Implementation of Exact Path and RAZ Kids in my lessons
          </option>
          <option value="Implementation of Non - Alef resources in my lessons">
            Implementation of Non - Alef resources in my lessons
          </option>
          <option value="Communication with other Professionals within the school">
            Communication with other Professionals within the school
          </option>
          <option value="Communication with other Professionals from other schools">
            Communication with other Professionals from other schools
          </option>
          <option value="No Action">No Action</option>
        </select>
        <textarea
          type="text"
          value={otherAction}
          onChange={handleActionChange}
          placeholder="Other action"
        ></textarea>

        <h2>Section 4: Next Steps to Take</h2>
        <h4>4.1: Behavior</h4>
        <label>
          <input
            name="nextStepsBehavior"
            type="checkbox"
            value="Setting and reinforcing clear expectations in my classroom"
            onChange={handleNextStepsBehaviorChange}
          />
          Setting and reinforcing clear expectations in my classroom
        </label>
        <label>
          <input
            name="nextStepsBehavior"
            type="checkbox"
            value="Build relationships with students that are positive, empathetic and understanding"
            onChange={handleNextStepsBehaviorChange}
          />
          Build relationships with students that are positive, empathetic and
          understanding
        </label>

        <h4>4.2: Attitude to Learning</h4>
        <label>
          <input
            name="nextStepsAttitude"
            type="checkbox"
            value="Provide opportunities for student choice and voice"
            onChange={handleNextStepsAttitudeChange}
          />
          Provide opportunities for student choice and voice
        </label>
        <label>
          <input
            type="checkbox"
            value="More praise and encouragement"
            name="nextStepsAttitude"
            onChange={handleNextStepsAttitudeChange}
          />
          More praise and encouragement
        </label>

        <h4>4.3: Special Needs</h4>
        <label>
          <input
            type="checkbox"
            value="Familiarize myself with the IEP"
            name="nextStepsSpecialNeeds"
            onChange={handleNextStepsSpecialNeedsChange}
          />
          Familiarize myself with the IEP
        </label>
        <label>
          <input
            type="checkbox"
            value="Explore appropriate resources"
            name="nextStepsSpecialNeeds"
            onChange={handleNextStepsSpecialNeedsChange}
          />
          Explore appropriate resources
        </label>
        <label>
          <input
            type="checkbox"
            value="Collaborate with inclusion team"
            name="nextStepsSpecialNeeds"
            onChange={handleNextStepsSpecialNeedsChange}
          />
          Collaborate with inclusion team
        </label>

        <h4>4.4: Lack of Parental Support</h4>
        <label>
          <input
            type="checkbox"
            value="Reach out to parents"
            name="nextStepsParentalSupport"
            onChange={handleNextStepsParentalSupportChange}
          />
          Reach out to parents
        </label>
        <label>
          <input
            type="checkbox"
            value="Highlight how parents can help"
            name="nextStepsParentalSupport"
            onChange={handleNextStepsParentalSupportChange}
          />
          Highlight how parents can help
        </label>
        <label>
          <input
            type="checkbox"
            value="Suggest a parent workshop"
            name="nextStepsParentalSupport"
            onChange={handleNextStepsParentalSupportChange}
          />
          Suggest a parent workshop
        </label>

        <h4>4.5:Limited Instructional Resources</h4>
        <br />
        <textarea
          value={nextStepsInstructionalResources}
          onChange={handleNextStepsInstructionalResourcesChange}
          maxLength={200}
          placeholder="Offer additional options(200 characters max)"
        />
        <br />
        <br />
        <h4>4.6: Gaps in my professional practice </h4>
        <label>
          <input
            type="checkbox"
            value="Seek feedback from colleagues"
            name="nextStepsSeekFeedback"
            onChange={handleNextStepsSeekFeedbackChange}
          />
          Seek feedback from colleagues
        </label>
        <label>
          <input
            type="checkbox"
            value="Identify my areas for growth"
            name="nextStepsSeekFeedback"
            onChange={handleNextStepsSeekFeedbackChange}
          />
          Identify my areas for growth
        </label>

        <div className="btnContainer">
          <button
            type="submit"
            //   disabled
            onClick={() => {
              // //   settinShowPortal(false);
              //   console.log(selectedStudent);
            }}
          >
            Submit
          </button>
          <button
            id="closeBtn"
            onClick={() => {
              settinShowPortal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
