import { useEffect, useState } from "react";
import generalQuery from "../generalQuery";
import { CircularProgress } from "@material-ui/core";
const CreateInterGroups = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    generalQuery(
      "select lname,teachername,sub from login where lname='teacher' and sub in ('Math', 'English', 'Science', 'Arabic')"
    ).then((results) => {
      setTeachers(
        results.sort((a, b) => (a.teachername > b.teachername ? 1 : -1))
      );
      setLoading(false);
    });
    generalQuery("select * from intervention_groups").then((results) => {
      console.log(results);
    });
   
  }, []);
  const handleAddGroup = () => {
    // Add new group logic
    // generalQuery(
    //   `INSERT INTO intervention_groups (subject, teacher, unique_name) VALUES ('${subject}','${teachername}','${
    //     subject + "_" + group_name + "_" + Number(groups.length + 1)
    //   }')`
    // ).then(() => {
    //   fetchGroups();
    // });
  };
  return (
    <div>
      <div className="container">
        {" "}
        {!loading ? (
          <select className="select">
            <option>Select a Teacher</option>
            {teachers.map((t) => {
              return (
                <option key={t.teachername} value={t.teachername}>
                  {t.teachername}
                </option>
              );
            })}
          </select>
        ) : (
          <CircularProgress />
        )}{" "}
      </div>
    </div>
  );
};

export default CreateInterGroups;
