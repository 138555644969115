import React, { useEffect, useState } from "react";
import generalQuery from "../../generalQuery";
import { CircularProgress } from "@mui/material";
import Portal from "@mui/base/Portal";
import exportDataToExcel from "../../exportDataToExcel";
import DataExportLink from "./DataExportLink";
const StudentsPerformance = () => {
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [standards, setStandards] = useState([]);
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [grade_levels, setgrade_levels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [searchYear, setSearchYear] = useState("");
  const [searchGrade, setSearchGrade] = useState("");
  const [searchSubject, setSearchSubject] = useState("");
  const [searchAssessment, setSearchAssessment] = useState("");
  const [assessmet_types, setAssessmet_types] = useState([]);
  const [showPortal, setShowPortal] = useState(false);
  const [dataToDisply, setDataToDisply] = useState([]);
  useEffect(() => {
    generalQuery(
      `select distinct(academic_year) from assessmentsmarks order by academic_year desc`
    ).then((results) => {
      setYears(results);
    });
    generalQuery(
      `select distinct(grade_level) from assessmentsmarks order by grade_level`
    ).then((results) => {
      setgrade_levels(results);
    });
    generalQuery(
      `select distinct(subject) from assessmentsmarks order by subject`
    ).then((results) => {
      setSubjects(results);
    });
  }, []);
  const horizontalBar = (scale) => {
    let fillColor = "";
    if (scale > 80) {
      fillColor = "green";
    } else if (scale >= 60 && scale <= 80) {
      fillColor = "lightgreen";
    } else if (scale < 60 && scale >= 30) {
      fillColor = "orange";
    } else {
      fillColor = "red";
    }
    return (
      <div className="container">
        <svg width="300" height="10">
          <rect width={3 * scale} height="10" fill={fillColor}></rect>
        </svg>
        <div style={{ marginLeft: "20px" }}>{scale}%</div>
      </div>
    );
  };
  const getGraphs = (homeroom, serial) => {
    let standardDataGrade = data.filter((d) => d.serial === serial);
    let gradeSum = 0;

    standardDataGrade.forEach((s) => {
      gradeSum = gradeSum + Number(s.grade);
    });
    let homeroomSum = 0;
    let standardDataPerClass = data.filter(
      (d) => d.homeroom === homeroom && d.serial === serial
    );
    standardDataPerClass.forEach((s) => {
      homeroomSum = homeroomSum + Number(s.grade);
    });
    let extLowerAchievers = standardDataPerClass.filter(
      (s) => Number(s.grade) / Number(s.max) < 0.3
    );
    let lowerAchievers = standardDataPerClass.filter(
      (s) =>
        Number(s.grade) / Number(s.max) < 0.6 &&
        Number(s.grade) / Number(s.max) <= 0.3
    );
    let averageAchievers = standardDataPerClass.filter(
      (s) =>
        Number(s.grade) / Number(s.max) >= 0.6 &&
        Number(s.grade) / Number(s.max) <= 0.8
    );
    let highAchievers = standardDataPerClass.filter(
      (s) => Number(s.grade) / Number(s.max) > 0.8
    );
    return (
      <div>
        <div className="container">
          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (extLowerAchievers.length * 150) / standardDataPerClass.length
              }
              fill="red"
            ></rect>
          </svg>
          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (lowerAchievers.length * 150) / standardDataPerClass.length
              }
              fill="orange"
            ></rect>
          </svg>
          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (averageAchievers.length * 150) / standardDataPerClass.length
              }
              fill="lightgreen"
            ></rect>
          </svg>

          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (highAchievers.length * 150) / standardDataPerClass.length
              }
              fill="green"
            ></rect>
          </svg>
        </div>
        <div style={{ marginTop: "-50px" }}>
          <button
            className="classifierbtn"
            onClick={() => {
              setShowPortal(true);
              setDataToDisply(standardDataPerClass);
            }}
          >
            St Classifier
          </button>
        </div>
        <div style={{ padding: "5px" }}>
          Average=
          {Math.floor(
            ((homeroomSum / standardDataPerClass.length) * 100) /
              standardDataPerClass[0]?.max
          )}
          %
        </div>
        {Math.floor(
          ((homeroomSum / standardDataPerClass.length) * 100) /
            standardDataPerClass[0]?.max
        ) >
        Math.floor(
          ((gradeSum / standardDataGrade.length) * 100) /
            standardDataGrade[0]?.max
        ) ? (
          <div className="greenbtn">Above Grade Average</div>
        ) : Math.floor(
            ((homeroomSum / standardDataPerClass.length) * 100) /
              standardDataPerClass[0]?.max
          ) ===
          Math.floor(
            ((gradeSum / standardDataGrade.length) * 100) /
              standardDataGrade[0]?.max
          ) ? (
          <div className="orangebtn">Equal Grade Average</div>
        ) : (
          <div className="redbtn">Below Grade Average</div>
        )}
      </div>
    );
  };
  const getGraphsGrade = (serial) => {
    let standardDataGrade = data.filter((d) => d.serial === serial);
    let gradeSum = 0;
    standardDataGrade.forEach((s) => {
      gradeSum = gradeSum + Number(s.grade);
    });

    let extLowerAchievers = standardDataGrade.filter(
      (s) => Number(s.grade) / Number(s.max) < 0.3
    );
    let lowerAchievers = standardDataGrade.filter(
      (s) =>
        Number(s.grade) / Number(s.max) < 0.6 &&
        Number(s.grade) / Number(s.max) <= 0.3
    );
    let averageAchievers = standardDataGrade.filter(
      (s) =>
        Number(s.grade) / Number(s.max) >= 0.6 &&
        Number(s.grade) / Number(s.max) <= 0.8
    );
    let highAchievers = standardDataGrade.filter(
      (s) => Number(s.grade) / Number(s.max) > 0.8
    );
    return (
      <div>
        <div className="container">
          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (extLowerAchievers.length * 150) / standardDataGrade.length
              }
              fill="red"
            ></rect>
          </svg>
          <svg width="15" height="150">
            <rect
              width="10"
              height={(lowerAchievers.length * 150) / standardDataGrade.length}
              fill="orange"
            ></rect>
          </svg>
          <svg width="15" height="150">
            <rect
              width="10"
              height={
                (averageAchievers.length * 150) / standardDataGrade.length
              }
              fill="lightgreen"
            ></rect>
          </svg>

          <svg width="15" height="150">
            <rect
              width="10"
              height={(highAchievers.length * 150) / standardDataGrade.length}
              fill="green"
            ></rect>
          </svg>
        </div>
        <div style={{ marginTop: "-50px" }}>
          <button
            className="classifierbtn"
            onClick={() => {
              setDataToDisply(standardDataGrade);
              setShowPortal(true);
            }}
          >
            St Classifier
          </button>
        </div>

        <div style={{ padding: "10px", textAlign: "center", fontSize: "30px" }}>
          {Math.floor(
            ((gradeSum / standardDataGrade.length) * 100) /
              standardDataGrade[0]?.max
          )}
          %
        </div>
      </div>
    );
  };

  if (showPortal) {
    return (
      <div>
        <Portal>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <button
                className="redbtn"
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPortal(false);
                }}
              >
                X
              </button>
            </div>
            <div className="container">
              <h2>CCSS/NGSS: - {dataToDisply[0].ccss}</h2>
            </div>
            <div className="container">
              <h2>Learning Outcome: - {dataToDisply[0].learning_outcomes}</h2>
            </div>
            <div className="container">
              {dataToDisply.length < 39 ? (
                <h2>{dataToDisply[0].homeroom}</h2>
              ) : (
                <h2>Grade {dataToDisply[0].grade_level}</h2>
              )}
            </div>
            <div className="container">
              <div style={{ width: "70%" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    exportDataToExcel(
                      dataToDisply,
                      Object.keys(dataToDisply[0]),
                      dataToDisply[0].q_id.slice(0, 30)
                    );
                  }}
                >
                  Download
                </a>
                <table id="examtimetable" style={{ overflow: "scroll" }}>
                  <thead>
                    <tr>
                      <th>eSis</th>
                      <th>Name</th>
                      <th>Performance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataToDisply
                      .sort((a, b) =>
                        Number(a.grade) < Number(b.grade) ? 1 : -1
                      )
                      .map((d) => {
                        return (
                          <tr key={d.esis}>
                            <td>{d.esis}</td>
                            {searchSubject === "arabic" ? (
                              <td>{d.arabicname}</td>
                            ) : (
                              <td>{d.name}</td>
                            )}
                            <td>
                              {horizontalBar(
                                Math.floor(
                                  (Number(d.grade) * 100) / Number(d.max)
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Portal>
      </div>
    );
  }
  return (
    <div style={{ margin: "10px", zoom: "80%" }}>
      <div className="container">
        <h1>Students Achievement Analysis </h1>
      </div>
      <div className="container">
        <h1>تحليل بيانات تحصيل الطلاب </h1>
      </div>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            setSearchYear(e.target.value);
            setAssessmet_types([]);
            setData([]);
          }}
        >
          <option>---AY---</option>
          {years.map((y) => {
            return (
              <option key={y.academic_year} value={y.academic_year}>
                {y.academic_year}
              </option>
            );
          })}
        </select>
        <select
          className="select"
          onChange={(e) => {
            setData([]);
            setAssessmet_types([]);
            setSearchGrade(e.target.value);
            generalQuery(
              `select distinct(homeroom) from assessmentsmarks where grade_level='${e.target.value}' order by homeroom`
            ).then((results) => {
              setClasses(results);
            });
          }}
        >
          <option>---Grade---</option>
          {grade_levels.map((y) => {
            return (
              <option key={y.grade_level} value={y.grade_level}>
                {y.grade_level}
              </option>
            );
          })}
        </select>
        <select
          className="select"
          onChange={(e) => {
            setData([]);
            setAssessmet_types([]);
            setSearchSubject(e.target.value);
            generalQuery(
              `select distinct(assessment_type), subject from assessmentsmarks where academic_year='${searchYear}' and grade_level='${searchGrade}' and subject='${e.target.value}' order by assessment_type`
            ).then((results) => {
              setAssessmet_types(results);
            });
          }}
        >
          <option>---Subject---</option>
          {subjects.map((y) => {
            return (
              <option key={y.subject} value={y.subject}>
                {y.subject[0].toUpperCase() + y.subject.substring(1)}
              </option>
            );
          })}
        </select>
        {assessmet_types.length > 0 && (
          <>
            <select
              className="select"
              onChange={(e) => {
                setData([]);

                setSearchAssessment(e.target.value);
              }}
            >
              <option>---Assessment---</option>
              {assessmet_types.map((y) => {
                return (
                  <option key={y.assessment_type} value={y.assessment_type}>
                    {y.assessment_type}
                  </option>
                );
              })}
            </select>
            <button
              className="select"
              onClick={() => {
                setLoading(true);
                generalQuery(
                  `select * from assessmentsmarks where academic_year='${searchYear}' and grade_level='${searchGrade}' and subject='${searchSubject}' and assessment_type='${searchAssessment}' and grade is not null and grade<>'null'`
                ).then((results) => {
                  generalQuery(
                    `select * from assessmentstandards where academic_year='${searchYear}' and grade_level='${searchGrade}' and subject='${searchSubject}' and assessment_type='${searchAssessment}'`
                  ).then((outpouts) => {
                    setStandards(outpouts);
                  });
                  setData(results);
                  setLoading(false);
                });
              }}
            >
              Search
            </button>
          </>
        )}
      </div>
      <div>
        {loading ? (
          <div className="container">
            <CircularProgress />
          </div>
        ) : data.length > 0 ? (
          <div>
            
            <a
              style={{ fontWeight: "bold", fontSize: "25px" }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                exportDataToExcel(
                  data,
                  Object.keys(data[0]),
                  data[0].assessment_type
                );
              }}
            >
              Download
            </a>
            <br/><br/>
            <DataExportLink data={data}/>
            <table id="examtimetable" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>CCSS/NGSS</th>
                  <th>LO</th>
                  <th>DOK</th>
                  {classes.map((c) => {
                    return <th key={classes.indexOf(c)}>{c.homeroom}</th>;
                  })}
                  <th style={{ backgroundColor: "darkblue" }}>
                    Grade {searchGrade}
                  </th>
                </tr>
              </thead>
              <tbody>
                {standards.map((s) => {
                  return (
                    <tr key={s.q_id}>
                      <td>{s.ccss}</td>
                      <td>{s.learning_outcomes}</td>
                      <td>{s.dok}</td>
                      {classes.map((c) => {
                        return (
                          <td key={s.q_id + c.homeroom}>
                            {getGraphs(c.homeroom, s.serial)}
                          </td>
                        );
                      })}
                      <td style={{ backgroundColor: "grey", color: "blue" }}>
                        {data && getGraphsGrade(s.serial)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StudentsPerformance;
