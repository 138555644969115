import React, { useState, useEffect } from "react";
import "./pec.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import TeacherReporting from "./TeacherReporting";
import Attendancehistory from "./AttendanceHistory";
import Pecattendance from "./PecAttendance";
import FinalReporting from "./FinalReport";
import AttendanceTakingTracker from "./AttendanceTakingTracker";
import EndoFTermReport from "./EndoFTermReport";
import Registrar from "./Registrar";
const Pec = () => {
  const [loading, setLoading] = useState(false);
  const [navStatus, setnavStatus] = useState("School Attendance");

  useEffect(() => {}, []);
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="containerPec">
      <div className="nav noPrint">
        <div
          className={
            navStatus === "School Attendance" ? "navTabActive" : "navTab"
          }
          onClick={() => {
            setnavStatus("School Attendance");
          }}
        >
          School Attendance
        </div>
        <div
          className={
            navStatus === " Attendance Records" ? "navTabActive" : "navTab"
          }
          onClick={() => {
            setnavStatus(" Attendance Records");
          }}
        >
          Attendance Records
        </div>
        <div
          className={
            navStatus === " T2 Reporting System" ? "navTabActive" : "navTab"
          }
          onClick={() => {
            setnavStatus(" T2 Reporting System");
          }}
        >
          End of Year
        </div>
        <div
          className={navStatus === "Tracker" ? "navTabActive" : "navTab"}
          onClick={() => {
            setnavStatus("Tracker");
          }}
        >
          Attendance Taking Tracker
        </div>
        <div
          className={navStatus === "Tracker" ? "navTabActive" : "navTab"}
          onClick={() => {
            setnavStatus("dailyAttendance");
          }}
        >
         Daily Attendance
        </div>
      </div>
      {navStatus === " T2 Reporting System" ? (
        // <TeacherReporting />
        // <FinalReporting />
        <EndoFTermReport/>
      ) : navStatus === " Attendance Records" ? (
        <Attendancehistory />
      )
       : navStatus === "Tracker" ? (
        <AttendanceTakingTracker />
      ) 
       : navStatus === "dailyAttendance" ? (
        <Registrar />
        // ""
      ) 
      : (
        <Pecattendance />
      )}
    </div>
  );
};

export default Pec;
