import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ccss.css";
const Ccss = () => {
  const [data, setData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [selectedSub, setSelectedSub] = useState("");
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searhByDescription, setSearhByDescription] = useState("");
  const [highSchoolYears, setHighSchoolYears] = useState([]);
  const generalQuery = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };

      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      setLoading(false);
      if (p === "get_grade_name") {
        setGrades(resp);
      }
      if (p === "get_content_type") {
        setSubjects(resp);
      }
      if (p === "updatingData") {
        getData();
      }
      if (p === "getallForSub") {
        setData(resp);
        resp.forEach((e) => {
          if (e.status) {
            document.getElementById("status" + e.id).value = e.status;
            document.getElementById("term" + e.id).value = e.ay22_23;
          }
          if (e.status && e.status === "Completed") {
            document.getElementById("status" + e.id).style.backgroundColor =
              "lightgreen";
          } else if (e.status && e.status === "Partially") {
            document.getElementById("status" + e.id).style.backgroundColor =
              "orange";
          } else {
            document.getElementById("status" + e.id).style.backgroundColor =
              "white";
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getData = () => {
    setData([]);
    if (selectedGrade === "all") {
      generalQuery(
        `select * from ccss where content_type='${selectedSub}'`,
        "getallForSub"
      );
    } else {
      generalQuery(
        `select * from ccss where content_type='${selectedSub}' and grade_name='${selectedGrade}'`,
        "getallForSub"
      );
    }
  };
  useEffect(() => {
    let arr = [];

    setHighSchoolYears(arr);

    generalQuery(
      `select distinct(grade_name) from ccss order by grade_name`,
      "get_grade_name"
    );
    generalQuery(
      `select distinct(content_type) from ccss `,
      "get_content_type"
    );
    ["G9-", "G10-", "G11-", "G12-"].forEach((g) => {
      ["Term 1", "Term 2", "Term 3"].forEach((t) => {
        arr.push(g + t);
      });
    });
  }, []);

  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="container">
        <select
          className="select"
          onChange={(e) => setSelectedSub(e.target.value)}
        >
          <option>----</option>
          {subjects.map((s) => {
            return (
              <option key={s.content_type} value={s.content_type}>
                {s.content_type}
              </option>
            );
          })}
        </select>
        <select
          className="select"
          onChange={(e) => setSelectedGrade(e.target.value)}
        >
          <option>----</option>
          <option value="all">All</option>
          {grades.map((s) => {
            return (
              <option key={s.grade_name} value={s.grade_name}>
                {s.grade_name}
              </option>
            );
          })}
        </select>
        <button
          className="select"
          onClick={() => {
            getData();
          }}
        >
          Get Data
        </button>
      </div>
      {data.length > 0 ? (
        <div>
          <h2>{data[0].content_type}</h2>
          <h2>{selectedGrade}</h2>

          <div className="container">
            <input
              placeholder="Search By Code.."
              type="text"
              className="select"
              onChange={(e) => setSearchVal(e.target.value)}
            ></input>
            <input
              placeholder="Search By Description.."
              type="text"
              className="select"
              onChange={(e) => setSearhByDescription(e.target.value)}
            ></input>
          </div>
          <div style={{ textAlign: "center" }}>
            {sessionStorage.getItem("trole") === "hod" &&
            (sessionStorage.getItem("sub") === "English" ||
              sessionStorage.getItem("sub") === "Math") ? (
              <button
                className="select"
                id="widebtn"
                onClick={() => {
                  data.forEach((d) => {
                    if (d.last_update !== null) {
                      generalQuery(
                        `update ccss set ay22_23='${d.ay22_23}', comment='${d.comment}',last_update='${d.last_update}',status='${d.status}' where id='${d.id}'`,
                        "updatingData"
                      );
                    }
                  });
                }}
              >
                Save
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="container">
            <table className="reportTable">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>
                    Content
                    <br />
                  </th>
                  <th>Domain</th>
                  <th>Grade</th>
                  <th>Description</th>
                  <th>2022-2023</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter((d) =>
                    d.id.toLowerCase().startsWith(searchVal.toLowerCase())
                  )
                  .filter((d) =>
                    d.description
                      .toLowerCase()
                      .includes(searhByDescription.toLowerCase())
                  )
                  .map((d) => {
                    return (
                      <tr key={d.id + d.content_type}>
                        {/* id	content_type	category_name	grade_name		description	*/}
                        <td>{d.id}</td>
                        <td>{d.content_type}</td>
                        <td>{d.category_name}</td>
                        <td>{d.grade_name}</td>
                        <td>{d.description}</td>
                        <td>
                          {selectedGrade.slice(0, 1) !== "H" ? (
                            <select
                              id={"term" + d.id}
                              className="select"
                              onChange={(e) => {
                                d.ay22_23 = e.target.value;
                                d.last_update = new Date()
                                  .toISOString()
                                  .slice(0, 10);
                                document.getElementById("date").innerHTML =
                                  new Date().toISOString().slice(0, 10);
                              }}
                            >
                              <option>---Term---</option>
                              <option value="Term 1">Term 1</option>
                              <option value="Term 2">Term 2</option>
                              <option value="Term 3">Term 3</option>
                            </select>
                          ) : (
                            <select
                              id={"term" + d.id}
                              className="select"
                              onChange={(e) => {
                                d.ay22_23 = e.target.value;
                                d.last_update = new Date()
                                  .toISOString()
                                  .slice(0, 10);
                                if (document.getElementById("date")) {
                                  document.getElementById("date").innerHTML =
                                    new Date().toISOString().slice(0, 10);
                                }
                              }}
                            >
                              <option value="">---Term---</option>
                              {highSchoolYears.map((y) => {
                                return (
                                  <option key={y} value={y}>
                                    {y}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          <select
                            id={"status" + d.id}
                            className="select"
                            onChange={(e) => {
                              if (e.target.value === "Completed") {
                                document.getElementById(
                                  "status" + d.id
                                ).style.backgroundColor = "lightgreen";
                              } else if (e.target.value === "Partially") {
                                document.getElementById(
                                  "status" + d.id
                                ).style.backgroundColor = "orange";
                              } else {
                                document.getElementById(
                                  "status" + d.id
                                ).style.backgroundColor = "white";
                              }
                              d.status = e.target.value;
                              d.last_update = new Date()
                                .toISOString()
                                .slice(0, 10);
                              if (document.getElementById("date")) {
                                document.getElementById("date").innerHTML =
                                  new Date().toISOString().slice(0, 10);
                              }
                            }}
                          >
                            <option value="NA">--Status--</option>
                            <option
                              value="Completed"
                              style={{
                                backgroundColor: "lightgreen",
                              }}
                            >
                              Completed
                            </option>
                            <option
                              value="Partially"
                              style={{
                                backgroundColor: "Orange",
                              }}
                            >
                              Partially
                            </option>
                          </select>
                          <br />
                          {/* <span
                        id="date"
                        className={
                          document.getElementById("date").innerHTML.length > 0
                            ? "highlight"
                            : ""
                        }
                      >
                        {d.last_update}
                      </span> */}
                        </td>
                        <td>
                          <textarea
                            placeholder={d.comment}
                            onChange={(e) => {
                              d.comment = e.target.value;
                            }}
                          ></textarea>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Ccss;
