import React, { useEffect, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import generalQuery from "../generalQuery";
import SuperadminEndOfYear from "./SuperadminEndOfYear";
import { getmapreport } from "../components/wpl/StudentManual/GeneratePDF";
const SuperExportAdek = () => {
  const Xaxis = ["Winter'23", "Spring'23", "Fall'23", "Winter'24", "Spring'24"];

  // useEffect(() => {
  //   generalQuery(
  //     "select esis,islamic,islamiceot,arabic,arabiceot,math,matheot,english,englisheot,science,scienceeot,ss,sseot,comp,compeot,ss,sseot,art,arteot,music,musiceot,pe,peeot,drama,dramaeot,math,matheot,science,scienceeot,english,englisheot,eco,ecoeot,gov,goveotfrom finalsheet2223t2"
  //   ).then((results) => {
  //     setEsisData(results);
  //   });
  // }, []);
  const esis = [
 "317907",
"565406",
"569955",
"579922",
"583689",
"586381",
"595297",
"602552",
"613894",
"620308",
"621203",
"630011",
"631884",
"637589",
"640482",
"655101",
"657007",
"658194",
"658266",
"869225",


  ];

  return (
    <div className="container">
      {/* <SuperadminEndOfYear /> */}
      <button
        onClick={() => {
          esis.forEach((e) => {
            getmapreport(
              "mapspringreport24",
              "Spring 2024",
              "Fall 2023",
              "Spring 2024",
              e,
              Xaxis
            );
          });
        }}
      >
        Download all
      </button>
    </div>
  );
};

export default SuperExportAdek;
