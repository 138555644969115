import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import { CircularProgress } from "@material-ui/core";
import Portal from "./Portal";
import SuperEditStudentData from "./SuperEditStudentData";
import "../creatortable.css";
import AddStudent from "./AddStudent";
const SuperStudentsTable = () => {
  const [data, setData] = useState([]);
  const [dataTomap, setDataTomap] = useState([]);
  const [search, setSearch] = useState();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPortal, setShowPortal] = useState(false);
  const [stData, setStData] = useState();
  const [refresher, setRefresher] = useState();
  const [showPortalNewStudent, setShowPortalNewStudent] = useState(false);
  const [columnsStudents, setColumnsStudents] = useState([]);
  const [columnsAttendance, setColumnsAttendance] = useState([]);
  const [formData, setFormData] = useState({});
  const [showPortalDelete, setShowPortalDelete] = useState(false);
  useEffect(() => {
    setLoading(true);
    const ac = new AbortController();
    generalQuery(`select * from students order by homroom, englishname`).then(
      (results) => {
        let arr = [];
        results.forEach((r) => {
          if (!arr.includes(r.homroom)) {
            arr.push(r.homroom);
          }
        });
        setClasses(arr.sort((a, b) => (a.homroom > b.homroom ? -1 : 1)));
        setData(results);
        setDataTomap(results);
        setLoading(false);
      }
    );
    // Replace these with your actual generalQuery calls to get column names
    generalQuery("SELECT * FROM  students").then((data) =>
      setColumnsStudents(data)
    );
    generalQuery("SELECT * from masterattendance").then((data) =>
      setColumnsAttendance(data)
    );

    return () => ac.abort();
  }, [refresher]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <input
          name="unique_name_1234567"
          className="select"
          placeholder="eSIS"
          onChange={(e) => {
            setDataTomap(
              data.filter((d) => d.studentnumber.startsWith(e.target.value))
            );
            setSearch(e.target.value);
          }}
        ></input>
        <input
          className="select"
          placeholder="Name"
          onChange={(e) => {
            setDataTomap(
              data.filter((d) =>
                d.englishname
                  .toLowerCase()
                  .startsWith(e.target.value.toLowerCase())
              )
            );

            setSearch(e.target.value);
          }}
        ></input>
        <select
          className="select"
          onChange={(e) => {
            setDataTomap(data.filter((d) => d.homroom === e.target.value));
          }}
        >
          <option>Class</option>
          {classes.map((c) => {
            return (
              <option key={c} value={c}>
                {c}
              </option>
            );
          })}
        </select>
        <button
          className="select"
          onClick={() => {
            setShowPortalNewStudent(true);
          }}
        >
          Add Student
        </button>
      </div>
      <div className="container">
        <table className="creatortable">
          <thead>
            <tr>
              <th>eSIS</th>
              <th>Name</th>
              <th>الاسم</th>
              <th>Class</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataTomap.map((d) => {
              return (
                <tr key={d.studentnumber}>
                  <td>{d.studentnumber}</td>
                  <td>{d.englishname}</td>
                  <td>{d.username}</td>
                  <td>{d.homroom}</td>
                  <td style={{ border: "none" }}>
                    <button
                      style={{
                        backgroundColor: "orange",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setStData(d);
                        setShowPortal(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      style={{
                        backgroundColor: "red",
                        borderRadius: "5px",
                        padding: "5px",
                        color: "white",
                        border: "solid red",
                        marginLeft: "5px",
                      }}
                      onClick={() => {
                        setStData(d);
                        setShowPortalDelete(true);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                  <td style={{ border: "none" }}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPortal && (
        <Portal>
          <div>
            <div className="container" style={{ justifyContent: "right" }}>
              <div
                style={{
                  border: "solid red 1px",
                  padding: "5px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "5px",
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPortal(false);
                }}
              >
                X
              </div>
            </div>
            <div className="container">
              <SuperEditStudentData
                stData={stData}
                classes={classes}
                refresher={(v) => setRefresher(v)}
                showPortal={(v) => setShowPortal(v)}
              />
            </div>
          </div>
        </Portal>
      )}
      {showPortalNewStudent && (
        <Portal>
          <div>
            <div className="container" style={{ justifyContent: "right" }}>
              <div
                style={{
                  border: "solid red 1px",
                  padding: "5px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "5px",
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPortalNewStudent(false);
                }}
              >
                X
              </div>
            </div>
            <div className="container">
              <div className="form-container ">
                <AddStudent
                  refresher={(v) => setRefresher(v)}
                  showPortalNewStudent={(v) => setShowPortalNewStudent(v)}
                />
              </div>
            </div>
          </div>
        </Portal>
      )}
      {showPortalDelete && (
        <Portal>
          <div>
            <div className="form-container">
              <div
                style={{
                  color: "red",
                  border: "2px solid red",
                  padding: "10px",
                  margin: "10px",
                }}
              >
                <strong>⚠️ Caution:</strong> You are about to
                <em> permanently delete</em> the student record from the system!
                Proceed with extreme caution.
              </div>
              <div style={{ marginLeft: "30%" }}>
                <button
                  style={{
                    border: "solid red 1px",
                    padding: "5px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "-20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    generalQuery(`BEGIN;     
                    DELETE FROM masterattendance
                    WHERE pupilnumber = '${stData.studentnumber}';
                    DELETE FROM students
                    WHERE studentnumber = '${stData.studentnumber}';
                    COMMIT;`).then(() => {
                      setShowPortalDelete(false);
                      setRefresher(Math.random());
                    });
                  }}
                >
                  I Agree!
                </button>
                <button
                  style={{
                    border: "solid blue 1px",
                    padding: "5px",
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "-20px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setShowPortalDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};
export default SuperStudentsTable;
