import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import generalQuery from '../generalQuery';

const AttendanceReport = () => {
  const [startDate, setStartDate] = useState(new Date('2023-08-28'));
  const [endDate, setEndDate] = useState(new Date('2024-06-28'));
  const [isDataProcessed, setIsDataProcessed] = useState(false);
  const [dfSummary, setDfSummary] = useState([]);

  const processData = async () => {
    try {
      let currentDate = new Date(startDate);
      let end = new Date(endDate);
      let dfsAtt = [];
      let dfsSub = [];
      let dfsSubFiltered = [];

      while (currentDate <= end) {
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          let tableName = 'zatt' + currentDate.toISOString().split('T')[0].replace(/-/g, '');

          const checkTableQuery = `SELECT EXISTS (SELECT FROM pg_tables WHERE tablename = '${tableName}');`;
          const tableExistsResult = await generalQuery(checkTableQuery);
          console.log(`Checking table ${tableName}:`, tableExistsResult);
          
          if (tableExistsResult.rows && tableExistsResult.rows[0] && tableExistsResult.rows[0].exists) {
            const queryAtt = `SELECT pupilnumber, p1, p2, p3, p4, p5, p6 FROM ${tableName};`;
            const querySub = `SELECT pupilnumber, p1sub, p2sub, p3sub, p4sub, p5sub, p6sub FROM ${tableName};`;

            const dfAttResult = await generalQuery(queryAtt);
            const dfSubResult = await generalQuery(querySub);

            if (dfAttResult.rows && dfSubResult.rows) {
              const dfAtt = dfAttResult.rows;
              const dfSub = dfSubResult.rows;
              console.log(`Attendance data for ${tableName}:`, dfAtt);
              console.log(`Subject data for ${tableName}:`, dfSub);

              let dfSubFiltered = dfSub.map(row => ({ ...row }));

              for (let period = 1; period <= 6; period++) {
                dfSubFiltered.forEach((row, index) => {
                  if (dfAtt[index] && (dfAtt[index][`p${period}`] === '0' || dfAtt[index][`p${period}`] === 'null')) {
                    row[`p${period}sub`] = null;
                  }
                });
              }

              dfsAtt.push(dfAtt);
              dfsSub.push(dfSub);
              dfsSubFiltered.push(dfSubFiltered);
            } else {
              console.error(`Invalid data for ${tableName}:`, dfAttResult, dfSubResult);
            }
          } else {
            console.warn(`Table ${tableName} does not exist.`);
          }
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

  
      const uniquePupilNumbers = [...new Set(dfsAtt.flat().map(row => row.pupilnumber))];
      const uniqueSubjects = [...new Set(dfsSubFiltered.flat().flatMap(row =>
        [row.p1sub, row.p2sub, row.p3sub, row.p4sub, row.p5sub, row.p6sub]
      ).filter(subject => subject !== null))];

      let summaryData = uniquePupilNumbers.map(pupilnumber => {
        let summary = { pupilnumber };
        uniqueSubjects.forEach(subject => {
          summary[subject] = 0;
        });
        return summary;
      });

      dfsSubFiltered.flat().forEach(row => {
        uniqueSubjects.forEach(subject => {
          for (let period = 1; period <= 6; period++) {
            if (row[`p${period}sub`] === subject) {
              let pupil = summaryData.find(p => p.pupilnumber === row.pupilnumber);
              if (pupil) {
                pupil[subject]++;
              } else {
                console.error(`Pupil number ${row.pupilnumber} not found in summaryData.`);
              }
            }
          }
        });
      });

      // Check summary data before setting it to state
      console.log('Summary data:', summaryData);

      setDfSummary(summaryData);
      setIsDataProcessed(true);

    } catch (error) {
      console.error('Error processing data:', error);
    }
  };

  const exportData = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(dfSummary);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, 'attendance_summary.xlsx');
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <div>
      <h1>Export Attendance Data</h1>
      <div>
        <label>Start Date: </label>
        <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
      </div>
      <div>
        <label>End Date: </label>
        <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
      </div>
      <button onClick={processData}>Process Data</button>
      {isDataProcessed && <button onClick={exportData}>Export Data as XLSX</button>}
    </div>
  );
};

export default AttendanceReport;
