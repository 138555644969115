import React, { useEffect, useState } from "react";
import generalQuery from "../../generalQuery";

const AssessmentStandardsForm = ({
  refresher,
  academic_year,
  term,
  grade_level,
  assessment_type,
  subject,
  settingRefresher,
}) => {
  const [selectedStandards, setSelectedStandards] = useState([]);
  useEffect(() => {
    generalQuery(
      `select * from assessmentstandards where academic_year='${academic_year}' and term ='${term}' and  grade_level='${grade_level}'and  assessment_type='${assessment_type}'and  subject='${subject}' order by serial`
    ).then((results) => {
      setSelectedStandards(results);
    });
  }, [refresher]);
  return (
    <div>
      <div style={{ width: "80%" }}>
        {selectedStandards.length > 0 ? (
          <table id="examtimetable">
            <thead>
              <tr>
                <th>Question</th>
                <th>LO</th>
                <th>Lesson Refernce</th>
                <th>CCSS/NGSS</th>
                <th>DOK</th>
                <th>Max Grade</th>
              </tr>
            </thead>
            <tbody>
              {selectedStandards
                .sort((a, b) => (+a.serial > +b.serial ? 1 : -1))
                .map((s) => {
                  return (
                    <tr key={s.q_id}>
                      <td>{s.serial}</td>
                      <td>{s.learning_outcomes}</td>
                      <td>{s.reference_lesson}</td>
                      <td>{s.ccss}</td>
                      <td>{s.dok}</td>
                      <td>{s.max}</td>
                      <td className="container">
                        <button
                          className="red"
                          onClick={() => {
                            generalQuery(
                              `delete from assessmentstandards where q_id='${s.q_id}'`
                            ).then((results) => {
                              settingRefresher(Math.random);
                            });
                          }}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AssessmentStandardsForm;
