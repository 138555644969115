import React from "react";
import Registrar from "./Registrar";
import SuperStudentsTable from "./SuperStudentsTable";
import EndoFTermReport from "./EndoFTermReport";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import QuizIcon from "@mui/icons-material/Quiz";
import GroupsIcon from "@mui/icons-material/Groups";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Attendancehistory from "./AttendanceHistory";
import GradingIcon from "@mui/icons-material/Grading";
import MarksConverter from "./MarksConverter";
import AttendanceReport from "./AttendanceReport";
import AttendanceDashboard from "./AttendanceDashboard";
const RegistrarHome = () => {
  const [value, setValue] = React.useState(0);
  return (
    <div>
      <div>
        <div className="container">
          <Box sx={{ width: 500 }}>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                label="Attendance الحصور و الغياب "
                icon={<GroupsIcon />}
              />
              <BottomNavigationAction
                label="Report Cards التقارير"
                icon={<SummarizeIcon />}
              />
              <BottomNavigationAction
                label="Student Data "
                icon={<QuizIcon />}
              />
              <BottomNavigationAction
                label="Attendance Record "
                icon={<HistoryEduIcon />}
              />
              <BottomNavigationAction
                label="ADEK Marks Converter"
                icon={<GradingIcon />}
              />
              <BottomNavigationAction
                label="Get Credit Hours"
                icon={<GradingIcon />}
              />
            </BottomNavigation>
          </Box>
        </div>
        {value === 0 ? (
          <Registrar />
          // <AttendanceDashboard/>
        ) : value === 2 ? (
          <SuperStudentsTable />
        ) : value === 1 ? (
          <EndoFTermReport />
        ) : value === 3 ? (
          <Attendancehistory />
        ) : value === 3 ? (
          <Attendancehistory />
        )
         : value === 4 ? (
          <MarksConverter />
        ) 
         : value === 5 ? (
          <AttendanceReport />
        ) 
        : (
          ""
        )}
      </div>
    </div>
  );
};

export default RegistrarHome;
