import kbEncoder from "./views/encode";
const generalQuery = async (z) => {
  try {
    const body = {
      idqueryString: z,
    };
    const response = await fetch("https://mut.herokuapp.com/general", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    console.log(error);
  }
};
export default generalQuery;
