import React from "react";
import TimetableNew from "./timetableNew";

const SpecialTimetable = () => {
  return (
    <div>
      <TimetableNew />
    </div>
  );
};

export default SpecialTimetable;
