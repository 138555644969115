import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import exportDataToExcel from "../exportDataToExcel";
import kbEncoder from "./encode";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
const MasterSheet = ({ teacherClasses, assessment, term }) => {
  const [data, setData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideAr, setHideAr] = useState(false);
  const [hideEn, setHideEn] = useState(false);
  const [sub, setSub] = useState(sessionStorage.getItem("sub").toLowerCase());
  const generalQuery = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "getdata") {
        if (nonCoreSubject.includes(sub)) {
          resp.forEach((el) => {
            if (C_assess(el) !== "") {
              el.overall = Math.ceil((C_assess(el) * 10) / 7);
              getScore(el.overall, el);
            } else {
              el.score = "";
            }
          });
        }
        setData(resp);
        const arr = [];
        resp.forEach((el) => {
          if (arr.indexOf(el.homeroom) === -1) {
            arr.push(el.homeroom);
          }
          if (["english", "arabic"].includes(sub)) {
            if (!["null", null, ""].includes(el.reading)) {
              if (!["null", null, ""].includes(el.writing)) {
                el.endofterm = Math.ceil(
                  Number(el.reading) + Number(el.writing)
                );
                el.overall = Math.ceil(
                  C_assess(el) + (Number(el.reading) + Number(el.writing)) * 0.3
                );
              }
            }
          }

          if (["math", "science", "ss", "islamic"].includes(sub)) {
            el.overall = Math.ceil(C_assess(el) + Number(el.endofterm) * 0.3);
          }

          setTimeout(() => {
            if (el.overall) {
              getScore(el.overall, el);
            }
          }, 150);
        });
        setLoading(false);
      }
      if (p === "updateterm1final2022EnAr") {
        getDataEnAr(selectedClass);
      }
      if (p === "updateterm1final2022") {
        getData(selectedClass);
      }
      if (p === "testing") {
        setLoading(false);
        return resp;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getScore = (valForScore, d) => {
    if (document.getElementById(d.esis + "score")) {
      if (valForScore >= 97) {
        d.score = "A+";
        document.getElementById(d.esis + "score").innerHTML = "A+";
      } else if (valForScore >= 93 && valForScore < 97) {
        d.score = "A";
        document.getElementById(d.esis + "score").innerHTML = "A";
      } else if (valForScore >= 90 && valForScore < 93) {
        d.score = "A-";
        document.getElementById(d.esis + "score").innerHTML = "A-";
      } else if (valForScore >= 87 && valForScore < 90) {
        d.score = "B+";
        document.getElementById(d.esis + "score").innerHTML = "B+";
      } else if (valForScore >= 83 && valForScore < 87) {
        d.score = "B";
        document.getElementById(d.esis + "score").innerHTML = "B";
      } else if (valForScore >= 80 && valForScore < 83) {
        d.score = "B-";
        document.getElementById(d.esis + "score").innerHTML = "B-";
      } else if (valForScore >= 77 && valForScore < 80) {
        d.score = "C+";
        document.getElementById(d.esis + "score").innerHTML = "C+";
      } else if (valForScore >= 73 && valForScore < 77) {
        d.score = "C";
        document.getElementById(d.esis + "score").innerHTML = "C";
      } else if (valForScore >= 70 && valForScore < 73) {
        d.score = "C-";
        document.getElementById(d.esis + "score").innerHTML = "C-";
      } else if (valForScore >= 67 && valForScore < 70) {
        d.score = "D+";
        document.getElementById(d.esis + "score").innerHTML = "D+";
      } else if (valForScore >= 63 && valForScore < 67) {
        d.score = "D";
        document.getElementById(d.esis + "score").innerHTML = "D";
      } else if (valForScore >= 60 && valForScore < 63) {
        d.score = "D-";
        document.getElementById(d.esis + "score").innerHTML = "D-";
      } else if (valForScore < 60) {
        d.score = "F";
        document.getElementById(d.esis + "score").innerHTML = "F";
      } else {
        data.score = null;
        document.getElementById(d.esis + "score").innerHTML = "";
      }
      document.getElementById(d.esis + "finalMark").innerHTML = valForScore;
    }
  };
  const MarkInput = (d) => {
    return (
      <div>
        <input
          autoComplete="off"
          style={
            d.endofterm === "null"
              ? { backgroundColor: "pink" }
              : d.endofterm === null
              ? { backgroundColor: "pink" }
              : { backgroundColor: "lightgreen" }
          }
          id={"final" + data.indexOf(d)}
          type="text"
          className="marks"
          placeholder={d.endofterm !== "null" ? d.endofterm : ""}
          onChange={(e) => {
            if (
              isNaN(Number(e.target.value)) &&
              e.target.value.toLowerCase() !== "a"
            ) {
              document.getElementById("final" + data.indexOf(d)).value = "";
              alert(
                "Enter A for Abent or a numeric value Aأدخل حرف  للغائب أو قيمة عددية"
              );
            }
            if (Number(e.target.value) > 100 || Number(e.target.value) < 0) {
              document.getElementById("final" + data.indexOf(d)).value = "";
              alert("Overall mark is 100 الدرجة الكلية");
            }
            d.endofterm = e.target.value;
            let valForScore = Math.ceil(
              Number(e.target.value) * 0.3 + Number(C_assess(d))
            );
            getScore(valForScore, d);
            if (e.target.value === "") {
              document.getElementById(
                "final" + data.indexOf(d)
              ).style.backgroundColor = "pink";
            } else {
              document.getElementById(
                "final" + data.indexOf(d)
              ).style.backgroundColor = "lightgreen";
            }
          }}
          onKeyPress={() => {
            if (data.indexOf(d) === data.length - 1) {
              document.getElementById("final" + 0).focus();
            } else {
              document
                .getElementById("final" + +(+data.indexOf(d) + 1))
                .focus();
            }
          }}
        />
      </div>
    );
  };
  const MarkInputReading = (d) => {
    return (
      <div>
        <input
          autoComplete="off"
          style={
            d.overall === "null"
              ? { backgroundColor: "pink" }
              : d.overall === null
              ? { backgroundColor: "pink" }
              : { backgroundColor: "lightgreen" }
          }
          id={"finalReading" + data.indexOf(d)}
          type="text"
          className="marks"
          placeholder={d.reading !== "null" ? d.reading : ""}
          onChange={(e) => {
            if (
              isNaN(Number(e.target.value)) &&
              e.target.value.toLowerCase() !== "a"
            ) {
              document.getElementById("finalReading" + data.indexOf(d)).value =
                "";
              alert(
                "Enter A for Abent or a numeric value Aأدخل حرف  للغائب أو قيمة عددية"
              );
            }
            if (Number(e.target.value) > 80 || Number(e.target.value) < 0) {
              document.getElementById("finalReading" + data.indexOf(d)).value =
                "";
              alert("Overall mark is 80 الدرجة الكلية");
            }

            d.reading = e.target.value;
            d.endofterm = Number(e.target.value) + Number(d.writing);
            let valForScore = Math.ceil(
              (Number(e.target.value) + Number(d.writing)) * 0.3 +
                Number(C_assess(d))
            );
            getScore(valForScore, d);

            d.endofterm =
              Math.ceil(Number(e.target.value) + Number(d.writing)) * 0.3;

            d.overall =
              Math.ceil(Number(e.target.value) + Number(d.writing)) * 0.3 +
              C_assess(d);

            if (e.target.value === "") {
              document.getElementById(
                "finalReading" + data.indexOf(d)
              ).style.backgroundColor = "pink";
            } else {
              document.getElementById(
                "finalReading" + data.indexOf(d)
              ).style.backgroundColor = "lightgreen";
            }
          }}
          onKeyPress={() => {
            if (data.indexOf(d) === data.length - 1) {
              document.getElementById("finalReading" + 0).focus();
            } else {
              document
                .getElementById("finalReading" + +(+data.indexOf(d) + 1))
                .focus();
            }
          }}
        />
      </div>
    );
  };
  const MarkInputWriting = (d) => {
    return (
      <div>
        <input
          autoComplete="off"
          style={
            d.overall === "null"
              ? { backgroundColor: "pink" }
              : d.overall === null
              ? { backgroundColor: "pink" }
              : { backgroundColor: "lightgreen" }
          }
          id={"finalWriting" + data.indexOf(d)}
          type="text"
          className="marks"
          placeholder={d.writing !== "null" ? d.writing : ""}
          onChange={(e) => {
            if (
              isNaN(Number(e.target.value)) &&
              e.target.value.toLowerCase() !== "a"
            ) {
              document.getElementById("finalWriting" + data.indexOf(d)).value =
                "";
              alert(
                "Enter A for Abent or a numeric value Aأدخل حرف  للغائب أو قيمة عددية"
              );
            }
            if (Number(e.target.value) > 20 || Number(e.target.value) < 0) {
              document.getElementById("finalWriting" + data.indexOf(d)).value =
                "";
              alert("Overall mark is 20 الدرجة الكلية");
            }
            d.writing = e.target.value;
            d.endofterm = Number(e.target.value) + Number(d.reading);
            let valForScore = Math.ceil(
              (Number(e.target.value) + Number(d.reading)) * 0.3 +
                Number(C_assess(d))
            );
            getScore(valForScore, d);
            d.endofterm =
              Math.ceil(Number(e.target.value) + Number(d.reading)) * 0.3;
            if (e.target.value === "") {
              document.getElementById(
                "finalWriting" + data.indexOf(d)
              ).style.backgroundColor = "pink";
            } else {
              document.getElementById(
                "finalWriting" + data.indexOf(d)
              ).style.backgroundColor = "lightgreen";
            }
          }}
          onKeyPress={() => {
            if (data.indexOf(d) === data.length - 1) {
              document.getElementById("finalWriting" + 0).focus();
            } else {
              document
                .getElementById("finalWriting" + +(+data.indexOf(d) + 1))
                .focus();
            }
          }}
        />
      </div>
    );
  };
  const C_assess = (d) => {
    if (
      isNaN(
        Number(d.att) +
          Number(d.beh) +
          Number(d.ga) +
          Number(d.qz) +
          Number(d.ua)
      )
    ) {
      return "";
    } else {
      return Math.ceil(
        Number(d.att) +
          Number(d.beh) +
          Number(d.ga) +
          Number(d.qz) +
          Number(d.ua)
      );
    }
  };
  const getData = (value) => {
    setLoading(true);

    generalQuery(
      `select GA.esis,GA.eligibility,GA.name,GA.dropped,GA.arabicname,GA.homeroom,GA.${sub}t as ga,Q.${sub}t as qz,UA.${sub}t as ua,BA.${sub}t as beh,BA.${sub}att as att,Fnl.${sub} as total,Fnl.${sub}grade as score, Fnl.${sub}eot as endofterm from gradedassignments${assessment} GA inner join quizzes${assessment} Q on GA.esis= Q.esis inner join unitassessments${assessment} UA on UA.esis=Q.esis inner join finalsheet${assessment} Fnl on Fnl.esis=Q.esis inner join behatt${assessment} BA on BA.esis=UA.esis where GA.homeroom='${value}'  order by GA.homeroom`,
      "getdata"
    );
  };
  const getDataEnAr = (value) => {
    setLoading(true);
    generalQuery(
      `select GA.esis,GA.name,GA.dropped,GA.arabicname,GA.homeroom,GA.${sub}t as ga,
      Q.${sub}t as qz,
      UA.${sub}t as ua, 
      BA.${sub}t as beh,
       BA.${sub}att as att,
       Fnl.${sub} as total,
       Fnl.${sub}grade as score, 
       Fnl.${sub}grade as grade, 
       Fnl.${sub}reading as reading, 
       Fnl.${sub}writing as writing, 
       Fnl.${sub}eot as endofterm 
       from gradedassignments${assessment} GA inner join quizzes${assessment} Q on GA.esis= Q.esis inner join unitassessments${assessment} UA on UA.esis=Q.esis inner join finalsheet${assessment} Fnl on Fnl.esis=Q.esis inner join behatt${assessment} BA on BA.esis=UA.esis where GA.homeroom='${value}' order by GA.homeroom`,
      "getdata"
    );
  };
  const updateData = () => {
    setLoading(true);
    data.forEach((d) => {
      if (!nonCoreSubject.includes(sub)) {
        generalQuery(
          `update finalsheet${assessment} set ${sub}grade='${
            d.score
          }',${sub}='${C_assess(d)}', ${sub}eot='${Math.ceil(
            d.endofterm
          )}' where esis='${d.esis}'`,
          "updateterm1final2022"
        );
      } else {
        generalQuery(
          `update finalsheet${assessment} set ${sub}grade='${
            d.score
          }',${sub}='${C_assess(d)}', ${sub}eot='${Math.ceil(
            d.overall
          )}' where esis='${d.esis}'`,
          "updateterm1final2022"
        );
      }
      if (data.indexOf(d) === data.length - 1) {
        setLoading(false);
      }
    });
  };
  const updateDataEnAr = () => {
    data.forEach((d) => {
      generalQuery(
        `update finalsheet${assessment} set ${sub}grade='${
          d.score
        }',${sub}='${C_assess(d)}', ${sub}eot='${Math.ceil(
          d.endofterm
        )}',${sub}reading='${d.reading}',${sub}writing='${
          d.writing
        }' where esis='${d.esis}'`,
        "updateterm1final2022EnAr"
      );
      if (data.indexOf(d) === data.length - 1) {
        setLoading(false);
      }
    });
  };
  const nonCoreSubject = ["pe", "music", "art", "drama", "comp"];

  useEffect(() => {
    if (["english", "arabic"].includes(sub)) {
      getDataEnAr(selectedClass);
    } else {
      getData(selectedClass);
    }
  }, [assessment, sub]);
  return (
    <div>
      <h1>
        {sessionStorage.getItem("sub") === "Gov"
          ? "History"
          : sessionStorage.getItem("sub")}
      </h1>
      <div style={{ textAlign: "center" }}>
        <h4>
          {sub === "ss" ? (
            <>اجتماعيات</>
          ) : sub === "islamic" ? (
            "التربية الاسلامية"
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className="container">
        {sub === "is/ss" && (
          <select
            className="select"
            onChange={(e) => {
              setSub(e.target.value);
            }}
          >
            <option>اختر المادة</option>
            <option value="islamic">الاسلامية</option>
            <option value="ss">الاجتماعيات</option>
          </select>
        )}
        {(sub === "islamic" || sub === "ss") && (
          <button
            className="select"
            onClick={() => {
              if (sub === "islamic") {
                setSub("ss");
              } else {
                setSub("islamic");
              }
            }}
          >
            غير المادة
          </button>
        )}

        {sub !== "Is/Ss" && (
          <select
            id="selectClass"
            className="select"
            onChange={(e) => {
              setLoading(true);
              if (["english", "arabic"].includes(sub)) {
                getDataEnAr(e.target.value);
              } else {
                getData(e.target.value);
              }
              setSelectedClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {teacherClasses.map((c) => {
              return <option key={c}>{c}</option>;
            })}
          </select>
        )}
      </div>
      {data.length > 0 ? (
        <div className="container">
          <>
            {/* <button
              className="savebtn"
              onClick={() => {
                if (["english", "arabic"].includes(sub)) {
                  updateDataEnAr();
                } else {
                  updateData();
                }
              }}
            >
              Save <SaveIcon fontSize="large" />
            </button> */}
            {/* <Button
              variant="contained"
              startIcon={<PublishIcon />}
              onClick={() => {
                if (["english", "arabic"].includes(sub)) {
                  updateDataEnAr();
                } else {
                  updateData();
                }
              }}
            >
              Publish
            </Button> */}
          </>
        </div>
      ) : (
        ""
      )}

      {selectedClass !== "" && (
        <div>
          <div className="container">
            {!loading ? (
              <>
                {data.length > 0 && (
                  <>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        exportDataToExcel(
                          data,
                          Object.keys(data[0]),
                          assessment
                        );
                      }}
                    >
                      Download
                    </a>
                    <table className="creatortable">
                      <thead>
                        <tr>
                          <th>eSIS</th>
                          {!hideEn && (
                            <th
                              onClick={() => {
                                setHideEn(true);
                                setHideAr(false);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.arabicname > b.arabicname ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p> Name</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          {!hideAr && (
                            <th
                              onClick={() => {
                                setHideEn(false);
                                setHideAr(true);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.name > b.name ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p>الاسم</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          <th>Class</th>
                          <th>
                            <p>Performance Skills المهارات الآدائية %70</p>
                          </th>
                          {!nonCoreSubject.includes(sub) && (
                            <>
                              {!["english", "arabic"].includes(sub) ? (
                                <th>
                                  <p>End of Term/100 نهاية الفصل </p>
                                </th>
                              ) : (
                                <th>Reading القراءة/80</th>
                              )}
                              {["english", "arabic"].includes(sub) && (
                                <th>Writing الكتابة/20</th>
                              )}
                            </>
                          )}
                          {/* <th>
                            <p>Overall/100 الدرجة الكلية </p>
                          </th> */}

                          <th>
                            <p>Grade التقدير</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data
                          .filter(
                            (t) =>
                              t.homeroom === selectedClass && t.dropped !== "y"
                          )
                          .map((d) => {
                            return (
                              <tr
                                key={d.esis}
                                style={
                                  d.eligibility === "no"
                                    ? { backgroundColor: "lightblue" }
                                    : { backgroundColor: "white" }
                                }
                              >
                                <td>{d.esis}</td>
                                {!hideEn && <td>{d.name}</td>}
                                {!hideAr && <td>{d.arabicname}</td>}
                                <td>{d.homeroom}</td>
                                <td
                                  className={
                                    C_assess(d) === "null" ||
                                    C_assess(d) === "NaN" ||
                                    C_assess(d) === ""
                                      ? "emptyCell"
                                      : "filledCell"
                                  }
                                >
                                  {C_assess(d) === "null" ||
                                  C_assess(d) === "NaN" ||
                                  C_assess(d) === ""
                                    ? ""
                                    : C_assess(d)}
                                </td>

                                {!nonCoreSubject.includes(sub) && (
                                  <>
                                    {["english", "arabic"].includes(sub) ? (
                                      <td id={d.esis + "total"}>
                                        {/* {d.reading === "null" ||
                                        d.reading === null ||
                                        d.reading === "0"||["A","a"].includes(d.reading)
                                          ? MarkInputReading(d)
                                          : d.reading} */}
                                        {MarkInputReading(d)}
                                      </td>
                                    ) : (
                                      <td id={d.esis + "total"}>
                                        {MarkInput(d)}
                                      </td>
                                    )}

                                    {["english", "arabic"].includes(sub) && (
                                      <td id={d.esis + "writingtotal"}>
                                        {MarkInputWriting(d)}
                                      </td>
                                    )}
                                  </>
                                )}
                                <>
                                  {/* <td
                                    id={d.esis + "finalMark"}
                                    className={
                                      Number(d.overall) < 55 ? "redcell" : ""
                                    }
                                  >
                                    {d.overall !== "null" && d.overall !== null
                                      ? d.overall
                                      : ""}
                                  </td> */}
                                  <td
                                    id={d.esis + "score"}
                                    className={d.score === "F" ? "redcell" : ""}
                                  >
                                    {d.score !== "null" && d.score !== null
                                      ? d.score
                                      : ""}
                                  </td>
                                </>
                                {/* {!nonCoreSubject.includes(sub) ? (
                               
                              ) : (
                                <>
                                  <td id={d.esis + "finalMark"}>
                                    {C_assess(d) === "null" ||
                                    C_assess(d) === "NaN" ||
                                    C_assess(d) === ""
                                      ? ""
                                      : Math.ceil((C_assess(d) * 10) / 7)}
                                  </td>
                                  <td id={d.esis + "score"}></td>
                                </>
                              )} */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterSheet;
