import React, { useState } from "react";
import FileUploader from "./FileUploader";

const SuperAdminData = () => {
  const [switcher, setSwitcher] = useState("");
  return (
    <div className="container">
      <select
        className="select"
        onChange={(e) => {
          setSwitcher(e.target.value);
        }}
      >
        <option value="">-------</option>
        <option value="classteacher">Class-Teacher*</option>
        <option value="teacherssummary">Teachers Summary*</option>
        <option value="subjectsummary">Subject Summary*</option>
        <option value="roomsummary">Rooms Summary*</option>
        <option value="summerschool">Summer School</option>
        <option value="endofyearloaded">endofyearloaded</option>
        <option value="teachersrooms">teachersrooms</option>
        <option value="homerooms">homerooms</option>
        <option value="students">students</option>
        <option value="student_growth_data">student_growth_data</option>

        <option value="login">login</option>
        <option value="masterattendance">masterattendance</option>
        <option value="assessmentstandards">assessmentstandards</option>
        <option value="exams">exams</option>
        <option value="ccss">ccss</option>
        <option value="finalsheet2223t2">finalsheet2223t2</option>
        <option value="finalsheet2223t3">finalsheet2223t3</option>
        <option value="mapspring23forteachers">mapspring23ForTeachers</option>
        <option value="mapspring2023">mapspring2023</option>
        <option value="mapspring2024">mapspring2024</option>
        <option value="mapspringReport24">mapspringReport24</option>
        <option value="classteachertemp">Class-TeacherTemp*</option>
        <option value="teacherssummarytemp">Teachers SummaryTemp*</option>
        <option value="subjectsummarytemp">Subject SummaryTemp*</option>
        <option value="roomsummarytemp">Rooms SummaryTemp*</option>
        <option value="classchart">Class Chart</option>
        <option value="teacherplanningrooms"> teacherplanningrooms</option>
        <option value="mapfall2023"> mapfall2023</option>
        <option value="credithours"> credithours</option>
        <option value="BlockedReportCards"> BlockedReportCards</option>
        <option value="zatt20240904"> zatt20240904</option>
      </select>
      {switcher === "teacherssummary" ? (
        <div>
          <h2> Teachers Summary* </h2>
          <div>
            <FileUploader datatype="teacherssummary" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "subjectsummary" ? (
        <div>
          <h2> Subject Summary* </h2>
          <div>
            <FileUploader datatype="subjectsummary" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "classteachertemp" ? (
        <div>
          <h2> classteachertemp* </h2>
          <div>
            <FileUploader datatype="classteachertemp" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "teacherssummarytemp" ? (
        <div>
          <h2> teacherssummarytemp* </h2>
          <div>
            <FileUploader datatype="teacherssummarytemp" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "subjectsummarytemp" ? (
        <div>
          <h2> subjectsummarytemp* </h2>
          <div>
            <FileUploader datatype="subjectsummarytemp" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "roomsummarytemp" ? (
        <div>
          <h2> roomsummarytemp* </h2>
          <div>
            <FileUploader datatype="roomsummarytemp" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "roomsummary" ? (
        <div>
          <h2> Rooms Summary* </h2>
          <div className="container">
            <FileUploader datatype="roomsummary" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "summerschool" ? (
        <div>
          <h2> Summer School </h2>
          <div className="container">
            <FileUploader datatype="summerschool" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "teachersrooms" ? (
        <div>
          <h2> Teachers Rooms </h2>
          <div className="container">
            <FileUploader datatype="teachersrooms" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "homerooms" ? (
        <div>
          <h2> Homeroom Teachers </h2>
          <div className="container">
            <FileUploader datatype="homerooms" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "students" ? (
        <div>
          <h2> Students </h2>
          <div className="container">
            <FileUploader datatype="students" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "login" ? (
        <div>
          <h2> Login </h2>
          <div className="container">
            <FileUploader datatype="login" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "masterattendance" ? (
        <div>
          <h2> Attendance </h2>

          <div className="container">
            <FileUploader datatype="masterattendance" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "assessmentstandards" ? (
        <div>
          <h2> Assessment Standards </h2>
          <div className="container">
            <FileUploader datatype="assessmentstandards" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "exams" ? (
        <div>
          <h2> Exams </h2>
          <div className="container">
            <FileUploader datatype="exams" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "ccss" ? (
        <div>
          <h2> CCSS </h2>
          <div className="container">
            <FileUploader datatype="ccss" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "finalsheet2223t2" ? (
        <div>
          <h2> finalsheet2223t2 </h2>
          <div className="container">
            <FileUploader datatype="finalsheet2223t2" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "mindmap" ? (
        <div>
          <h2> MindMap </h2>
          <div className="container">
            <FileUploader datatype="mindmap" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "intgroups" ? (
        <div>
          <h2> intgroups </h2>
          <div className="container">
            <FileUploader datatype="intgroups" />
          </div>
        </div>
      ) : switcher === "intervention_sessions" ? (
        <div>
          <h2> intervention_sessions </h2>
          <div className="container">
            <FileUploader
              datatype="intervention_sessions"
              switcher={switcher}
            />
          </div>
        </div>
      ) : switcher === "classchart" ? (
        <div>
          <h2> classchart </h2>
          <div className="container">
            <FileUploader datatype="classchart" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "teacherssummary" ? (
        <div>
          <h2> teacherssummary </h2>
          <div className="container">
            <FileUploader datatype="teacherssummary" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "term2loaded" ? (
        <div>
          <h2> term2loaded </h2>
          <div className="container">
            <FileUploader datatype="term2loaded" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "finalsheet2223t3" ? (
        <div>
          <h2> finalsheet2223t3 </h2>
          <div className="container">
            <FileUploader datatype="finalsheet2223t3" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "mapspring23forteachers" ? (
        <div>
          <h2> mapspring23forteachers </h2>
          <div className="container">
            <FileUploader datatype="mapspring23" switcher={switcher} />
          </div>
        </div>
      ) 
      : switcher === "mapspring2024" ? (
        <div>
          <h2> mapspring24 </h2>
          <div className="container">
            <FileUploader datatype="mapspring2024" switcher={switcher} />
          </div>
        </div>
      ) 
      : switcher === "mapspringReport24" ? (
        <div>
          <h2> mapspringReport24 </h2>
          <div className="container">
            <FileUploader datatype="mapspringReport24" switcher={switcher} />
          </div>
        </div>
      ) 
      : switcher === "mapspring23" ? (
        <div>
          <h2> mapspring23 </h2>
          <div className="container">
            <FileUploader datatype="mapspring2023" switcher={switcher} />
          </div>
        </div>
      ) 
      : switcher === "endOfYearloaded" ? (
        <div>
          <h2> endOfYearloaded </h2>
          <div className="container">
            <FileUploader datatype="endOfYearloaded" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "classteacher" ? (
        <div>
          <h2> classteacher </h2>
          <div className="container">
            <FileUploader datatype="classteacher" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "mapspring2023" ? (
        <div>
          <h2> mapspring2023 </h2>
          <div className="container">
            <FileUploader datatype="mapspring2023" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "endofyearloaded" ? (
        <div>
          <h2> endofyearloaded </h2>
          <div className="container">
            <FileUploader datatype="endofyearloaded" switcher={switcher} />
          </div>
        </div>
      ) : switcher === "classchart" ? (
        <div>
          <h2> classchart </h2>
          <div className="container">
            <FileUploader datatype="classchart" switcher={switcher} />
          </div>
        </div>
      ) 
      : switcher === "teacherplanningrooms" ? (
        <div>
          <h2> teacherplanningrooms </h2>
          <div className="container">
            <FileUploader datatype="teacherplanningrooms" switcher={switcher} />
          </div>
        </div>
      )
      : switcher === "mapfall2023" ? (
        <div>
          <h2> mapfall2023 </h2>
          <div className="container">
            <FileUploader datatype="mapfall2023" switcher={switcher} />
          </div>
        </div>
      )
      : switcher === "credithours" ? (
        <div>
          <h2> credithours </h2>
          <div className="container">
            <FileUploader datatype="credithours" switcher={switcher} />
          </div>
        </div>
      )
      : switcher === "BlockedReportCards" ? (
        <div>
          <h2> BlockedReportCards </h2>
          <div className="container">
            <FileUploader datatype="BlockedReportCards" switcher={switcher} />
          </div>
        </div>
      )
      
      : switcher === "student_growth_data" ? (
        <div>
          <h2> student_growth_data </h2>
          <div className="container">
            <FileUploader datatype="student_growth_data" switcher={switcher} />
          </div>
        </div>
      )
      
      : switcher === "zatt20240904" ? (
        <div>
          <h2> zatt20240904 </h2>
          <div className="container">
            <FileUploader datatype="zatt20240904" switcher={switcher} />
          </div>
        </div>
      )
      
      : 
      (
        ""
      )}
    </div>
  );
};

export default SuperAdminData;
