import { utils, writeFile } from "xlsx";
const exportDataToExcel = (data, columnNames, worksheetName) => {
  const rows = data.map((d) => {
    let arr = [];
    columnNames.forEach((c) => {
      if (d[c] === "null") {
        arr.push("");
      } else {
        arr.push(d[c]);
      }
    });
    return arr;
  });

  const workbook = utils.book_new();
  const worksheetData = [columnNames, ...rows];
  const worksheet = utils.aoa_to_sheet(worksheetData);
  utils.book_append_sheet(workbook, worksheet, worksheetName);
  writeFile(workbook, `${worksheetName}.xlsx`);
};

export default exportDataToExcel;
