const blockedCards = ["400329",
"403788",
"570965",
"575248",
"575588",
"579686",
"596634",
"600930",
"611103",
"611727",
"612301",
"637589",
"639039",
"640776",
"647118",
"647306",
"647501",
"647632",
"650012",
"653079",
"655101",
"639039",
"640776",
"647118",
"647306",
"647501",
"647632",
"650012",
"653079",
"655101",
"650012",
"653079",
"655101",
"655182",
"655665",
"658647",
"659707",
"660392",
"660688",
"662665",
"682851",
"691250",
"693300",
"695525",
"696268",
"697458",
"697534",
"700524",
"703712",
"707612",
"708020",
"718635",
"720394",
"723684",
"724698",
"765711",
"765717",
"766345",
"766419",
"767527",
"767643",
"767645",
"767772",
"768131",
"769363",
"770120",
"772952",
"773633",
"773863",
"775046",
"775277",
"775422",
"775567",
"776737",
"777670",
"793752",
"793753",
"793757",
"793986",
"793990",
"794201",
"794719",
"794724",
"804658",
"806903",
"818364",
"819500",
"822410",
"822532",
"822541",
"823171",
"824735",
"825091",
"825114",
"826014",
]
export default blockedCards;
