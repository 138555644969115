import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import WplList from "./WplList";
import CircularProgress from "@material-ui/core/CircularProgress";
import app from "../../views/appstore.png";
import play from "../../views/playStore.png";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const WplGradList = () => {
  const classNamees = useStyles();
  const [weeks, setWeeks] = useState([]);
  const [weeksNum, setWeeksNum] = useState([]);
  const [wplData, setWplData] = useState([]);
  const [showWplList, setShowWplList] = useState(false);
  const [grade, setGrade] = useState("");
  const [switcher, setSwitcher] = useState("");

  const getwpl = async () => {
    setSwitcher("loader");
    try {
      const response = await fetch("https://mut.herokuapp.com/getwpl", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp = await response.json();
      const data = resp.rows;
      setWplData(data);
      data.map((e) => {
        if (!weeksNum.includes(e.week)) {
          weeksNum.push(e.week);
          weeks.push({
            num: Number(e.week.slice(0, 2)),
            startDate: e.week.slice(2, 15),
          });
        }
      });
      function compare(a, b) {
        if (a.num < b.num) {
          return -1;
        }
        if (a.num > b.num) {
          return 1;
        }
        return 0;
      }

      weeks.sort(compare);
      setSwitcher("gradeslist");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getwpl();
  }, []);
  return (
    <div>
      Please Download School Voice App
      {/* <h5>للحصول علي الخطة الأسبوعية </h5>  */}
      <h5>يرجي تنزيل التطبيق</h5>{" "}
      <a
        href="https://itunes.apple.com/us/app/schoolvoice/id1046828492?mt=8"
        // className="ios _y"
        // style="opacity: 1; transform: ;"
        style={{ opacity: "1", transform: "matrix(1, 0, 0, 1, 0, 0)" }}
      >
        <img src={app}></img>
      </a>
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=ae.netaq.schoolvoice&amp;hl=en"
          // class="android _y"
          style={{ opacity: "1", transform: "matrix(1, 0, 0, 1, 0, 0)" }}
        >
          <img src={play}></img>
        </a>
      </div>
    </div>
    // <div>
    //   {showWplList ? (
    //     <WplList weeks={weeks} wplData={wplData} grade={grade} />
    //   ) : switcher === "gradeslist" ? (
    //     <div classNameName={classNamees.root} style={{ marginLeft: "15%" }}>
    //       <Paper
    //         elevation={3}
    //         style={{
    //           backgroundColor: "pink",
    //           fontSize: "30px",
    //           textAlign: "center",
    //           marginRight: "10%",
    //           cursor: "pointer",
    //         }}
    //         onClick={() => {
    //           setGrade("5");
    //           setShowWplList(true);
    //         }}
    //       >
    //         <p style={{ marginTop: "10%", padding: "20px" }}> Grade 5</p>
    //       </Paper>
    //       <Paper
    //         elevation={3}
    //         style={{
    //           backgroundColor: "lightblue",
    //           fontSize: "30px",
    //           textAlign: "center",
    //           marginRight: "10%",
    //           cursor: "pointer",
    //         }}
    //         onClick={() => {
    //           setGrade("6");
    //           setShowWplList(true);
    //         }}
    //       >
    //         <p style={{ marginTop: "10%", padding: "20px" }}> Grade 6</p>
    //       </Paper>
    //       <Paper
    //         elevation={3}
    //         style={{
    //           backgroundColor: "lightcoral",
    //           fontSize: "30px",
    //           textAlign: "center",
    //           marginRight: "10%",
    //           cursor: "pointer",
    //         }}
    //         onClick={() => {
    //           setGrade("7");
    //           setShowWplList(true);
    //         }}
    //       >
    //         <p style={{ marginTop: "10%", padding: "20px" }}> Grade 7</p>
    //       </Paper>
    //       <Paper
    //         elevation={3}
    //         style={{
    //           backgroundColor: "lightseagreen",
    //           fontSize: "30px",
    //           textAlign: "center",
    //           marginRight: "10%",
    //           cursor: "pointer",
    //         }}
    //         onClick={() => {
    //           setGrade("8");
    //           setShowWplList(true);
    //         }}
    //       >
    //         <p style={{ marginTop: "10%", padding: "20px" }}> Grade 8</p>
    //       </Paper>
    //     </div>
    //   ) : switcher === "loader" ? (
    //     <div style={{ marginTop: "30%" }}>
    //       <CircularProgress color="secondary" />
    //     </div>
    //   ) : (
    //     ""
    //   )}
    // </div>
  );
};

export default WplGradList;
