import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import exportDataToExcel from "../exportDataToExcel";
import generalQuery from "../generalQuery";
import Button from "@mui/material/Button";
import { ArrowForward } from "@mui/icons-material";
const BehaviorAttendance = ({ teacherClasses, assessment, term }) => {
  const [data, setData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideAr, setHideAr] = useState(false);
  const [hideEn, setHideEn] = useState(false);
  const [sub, setSub] = useState(sessionStorage.getItem("sub"));
  const [termFrom, setTermFrom] = useState("");

  const generalQueryLocal = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      if (p === "getdata") {
        setData(resp);
        const arr = [];
        resp.forEach((el) => {
          if (arr.indexOf(el.homeroom) === -1) {
            arr.push(el.homeroom);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const MarkInput = (n, d) => {
    return (
      <input
        placeholder={d[n] === "null" ? "" : d[n]}
        autoComplete="off"
        style={
          d[n] === "null" || d[n] === "" || d[n] === null
            ? { backgroundColor: "pink" }
            : { backgroundColor: "lightgreen" }
        }
        id={n + data.indexOf(d)}
        type="text"
        className="marks"
        onKeyPress={() => {
          if (data.indexOf(d) === data.length - 1) {
            document.getElementById(n + 0).focus();
          } else {
            document.getElementById(n + +(+data.indexOf(d) + 1)).focus();
          }
        }}
        onChange={(e) => {
          if (+e.target.value > 10) {
            alert(`Entry must be less than or equal يجب ألا تزيد عن${d[n]}`);
            document.getElementById(n + data.indexOf(d)).value = "";
          }
          if (
            isNaN(Number(e.target.value)) &&
            e.target.value.toLowerCase() !== "a"
          ) {
            alert(
              "Enter A for Abent or a numeric value Aأدخل حرف  للغائب أو قيمة عددية"
            );
            document.getElementById(n + data.indexOf(d)).value = "";
          }
          if (e.target.value === "") {
            document.getElementById(n + data.indexOf(d)).style.backgroundColor =
              "pink";
          } else {
            document.getElementById(n + data.indexOf(d)).style.backgroundColor =
              "lightgreen";
          }
          d[n] = e.target.value;
          let totalValue = (
            ((Number(d.b1) / 10 + Number(d.b2) / 10 + Number(d.b3) / 10) / 3) *
            10
          ).toFixed(1);
          d.total = totalValue;
          document.getElementById(d.esis + "total").innerHTML = totalValue;
        }}
      />
    );
  };
  const getData = (value) => {
    setLoading(true);
    generalQueryLocal(
      `select esis,name,dropped,arabicname,eligibility,homeroom,${
        sub + "b1"
      } as b1,${sub + "b2"} as b2,${sub + "b3"} as b3,${sub + "t"} as total,${
        sub + "att"
      } as attendance from ${assessment} where homeroom='${value}'`,
      "getdata"
    );
  };
  const copyData = () => {
    setLoading(true);
    data.forEach((d) => {
      ["b1", "b2", "b3"].forEach((v) => {
        generalQuery(
          `select ${sub + v},${sub + "t"} ,${sub + "att"} from ${
            assessment.slice(0, -2) + termFrom
          } where esis='${d.esis}'`
        ).then((r) => {
          generalQuery(
            `update  ${assessment} set ${sub + v}='${
              r[0][(sub + v).toLowerCase()]
            }', ${sub + "t"}='${r[0][sub.toLowerCase() + "t"]}', ${
              sub + "att"
            }='${r[0][sub.toLowerCase() + "att"]}' where esis='${d.esis}'`
          ).then(() => {
            if (data.indexOf(d) === data.length - 1) {
              getData(selectedClass);
            }
          });
        });
      });
    });
  };

  useEffect(() => {
    getData(selectedClass);
  }, [assessment, sub]);
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h4>
          {sub === "Ss" ? (
            <>اجتماعيات</>
          ) : sub === "Islamic" ? (
            "التربية الاسلامية"
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className="container">
        {sub === "Is/Ss" && (
          <select
            className="select"
            onChange={(e) => {
              setSub(e.target.value);
            }}
          >
            <option>اختر المادة</option>\
            <option value="Islamic">الاسلامية</option>
            <option value="Ss">الاجتماعيات</option>
          </select>
        )}
        {(sub === "Islamic" || sub === "Ss") && (
          <button
            className="select"
            onClick={() => {
              if (sub === "Islamic") {
                setSub("Ss");
              } else {
                setSub("Islamic");
              }
            }}
          >
            غير المادة
          </button>
        )}
        {sub !== "Is/Ss" && (
          <select
            id="selectClass"
            className="select"
            onChange={(e) => {
              setLoading(true);
              getData(e.target.value);
              setSelectedClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {teacherClasses.map((c) => {
              return <option key={c}>{c}</option>;
            })}
          </select>
        )}
        {data.length > 0? (
          <div className="container">
            {/* <button
              className="savebtn"
              onClick={() => {
                data.forEach((d) => {
                  generalQueryLocal(
                    `update ${assessment} set ${sub + "b1"}='${d.b1}',${
                      sub + "b2"
                    }='${d.b2}', ${sub + "b3"}='${d.b3}', ${sub + "t"}='${
                      d.total
                    }', ${sub + "att"}='${d.attendance}' where esis='${
                      d.esis
                    }'`,
                    "updateData"
                  );
                });
              }}
            >
              <SaveIcon fontSize="large" />
            </button> */}
          </div>
        ) : (
          ""
        )}
      </div>
      {selectedClass !== "" && (
        <div>
          {["Art", "Comp", "Drama", "eco", "Gov", "PE", "Music"].includes(
            sub
          ) && term === "t3" ? (
            <div className="container">
              <select
                className="select"
                onChange={(e) => {
                  setTermFrom(e.target.value);
                }}
              >
                <option>--From--</option>
                <option value="t1">Term 1</option>
                <option value="t2">Term 2</option>
              </select>
              {termFrom !== "" && (
                <Button
                  variant="contained"
                  startIcon={<ArrowForward />}
                  sx={{ height: 37 }}
                  onClick={() => {
                    copyData();
                  }}
                >
                  Copy Data
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="container">
            {!loading ? (
              <>
                {data.length > 0 && (
                  <>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        exportDataToExcel(
                          data,
                          Object.keys(data[0]),
                          assessment
                        );
                      }}
                    >
                      Download
                    </a>
                    <table className="creatortable">
                      <thead>
                        <tr>
                          <th>eSIS</th>
                          {!hideEn && (
                            <th
                              onClick={() => {
                                setHideEn(true);
                                setHideAr(false);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.arabicname > b.arabicname ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p> Name</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          {!hideAr && (
                            <th
                              onClick={() => {
                                setHideEn(false);
                                setHideAr(true);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.name > b.name ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p>الاسم</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          <th>Class</th>
                          <th> Readiness/10 الاستعداد</th>
                          <th> Class Engagement/10 المشاركة الفعالة</th>
                          <th> Commitment to Task /10 الالتزام بالمهام</th>
                          <th>
                            Total<p>10% of the overall Mark</p>
                          </th>
                          <th>
                            Attendance<p>10% of the overall Mark</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data
                          .filter(
                            (t) =>
                              t.homeroom === selectedClass && t.dropped !== "y"
                          )
                          .map((d) => {
                            return (
                              <tr
                                key={d.esis}
                                style={
                                  d.eligibility === "no"
                                    ? { backgroundColor: "lightblue" }
                                    : { backgroundColor: "white" }
                                }
                              >
                                <td id="lessPadding">{d.esis}</td>
                                {!hideEn && <td>{d.name}</td>}
                                {!hideAr && <td>{d.arabicname}</td>}
                                <td id="lessPadding">{d.homeroom}</td>
                                <td>{MarkInput("b1", d)}</td>
                                <td>{MarkInput("b2", d)}</td>
                                <td>{MarkInput("b3", d)}</td>
                                <td id={d.esis + "total"}>
                                  {d.total !== "null"
                                    ? d.total
                                    : d.total === NaN
                                    ? "0"
                                    : ""}
                                </td>
                                <td>{MarkInput("attendance", d)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BehaviorAttendance;
