import React, { useState, useEffect } from "react";
import "./attendancehistory.css";
const GetStuAttendancePerDay = ({
  settingLoading,
  pupilnumber,
  days,
  settingShowReport,
}) => {
  const [stAttArr, setStAttArr] = useState([]);
  const [studentAttRatio, setStudentAttRatio] = useState(0);
  const getStuAttendancePerDay = async (x) => {
    settingLoading(true);
    try {
      const body = { table_name: x, pupilnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/getStuAttendancePerDay",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      console.log();
      if (resp.p1 === "null" || resp.p1 === "") {
        resp.p1 = 0;
      }
      if (resp.p2 === "null" || resp.p2 === "") {
        resp.p2 = 0;
      }
      if (resp.p3 === "null" || resp.p3 === "") {
        resp.p3 = 0;
      }
      if (resp.p4 === "null" || resp.p4 === "") {
        resp.p4 = 0;
      }
      if (resp.p5 === "null" || resp.p5 === "") {
        resp.p5 = 0;
      }
      if (resp.p6 === "null" || resp.p6 === "") {
        resp.p6 = 0;
      }
      Object.assign(resp, { date: x });
      let arr = [];
      stAttArr.forEach((a) => {
        arr.push(a.date);
      });

      if (!arr.includes(resp.date)) {
        setStAttArr((stAttArr) =>
          [...stAttArr, resp].filter((a, b) => a.date !== b.date)
        );
        if (
          Number(resp.p1) +
            Number(resp.p2) +
            Number(resp.p3) +
            Number(resp.p4) +
            Number(resp.p5) +
            Number(resp.p6) >
          0
        ) {
          setStudentAttRatio((studentAttRatio) => studentAttRatio + 1);
        }
      } else {
        setStAttArr((stAttArr) => [...stAttArr]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handelData = () => {
    days.forEach((d) => getStuAttendancePerDay(d.table_name));
    settingLoading(false);
  };
  useEffect(() => {
    handelData();
    sessionStorage.getItem("trole");
  }, []);
  return (
    <div>
      <div className="byStudentDiv ">
        <div id="tab">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {stAttArr.length > 0 && (
              <>
                {(sessionStorage.getItem("trole") === "hod" ||
                  sessionStorage.getItem("trole") === "teacher") && (
                  <button
                    className="noPrint"
                    style={{
                      backgroundColor: "red",
                      padding: "0 10px",
                      color: "white",
                    }}
                    onClick={() => {
                      settingShowReport(false);
                    }}
                  >
                    Close
                  </button>
                )}
                <h2>{stAttArr[0].studentname}</h2>
                <h2>{stAttArr[0].homeroomcode}</h2>
                <h2>
                  {Math.trunc((studentAttRatio * 100) / stAttArr.length) + "%"}
                </h2>
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {stAttArr.length > 0 && (
              <table style={{ fontSize: "12px" }} className="coverageTable">
                <thead className="noPrint">
                  <th>Date</th>
                  <th>P1</th>
                  <th>P2</th>
                  <th>P3</th>
                  <th>P4</th>
                  <th>P5</th>
                  <th>P6</th>
                </thead>
                <tbody>
                  {stAttArr
                    .sort((a, b) =>
                      Number(a.date.slice(4, 12)) > Number(b.date.slice(4, 12))
                        ? -1
                        : 1
                    )
                    .map((s) => {
                      let attValue =
                        Number(s.p1) +
                        Number(s.p2) +
                        Number(s.p3) +
                        Number(s.p4) +
                        Number(s.p5) +
                        Number(s.p6);
                      return (
                        <tr>
                          <td>
                            {s.date.slice(4, 8) +
                              "-" +
                              s.date.slice(8, 10) +
                              "-" +
                              s.date.slice(10, 12)}
                          </td>
                          <td
                            className={
                              Number(s.p1) === 0.5
                                ? "L"
                                : Number(s.p1) === 1
                                ? "P"
                                : s.p1conf !== "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p1) === 0.5
                              ? "L"
                              : Number(s.p1) === 1
                              ? "P"
                              : s.p1conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td
                            className={
                              Number(s.p2) === 0.5
                                ? "L"
                                : Number(s.p2) === 1
                                ? "P"
                                : s.p2conf != "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p2) === 0.5
                              ? "L"
                              : Number(s.p2) === 1
                              ? "P"
                              : s.p2conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td
                            className={
                              Number(s.p3) === 0.5
                                ? "L"
                                : Number(s.p3) === 1
                                ? "P"
                                : s.p3conf != "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p3) === 0.5
                              ? "L"
                              : Number(s.p3) === 1
                              ? "P"
                              : s.p3conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td
                            className={
                              Number(s.p4) === 0.5
                                ? "L"
                                : Number(s.p4) === 1
                                ? "P"
                                : s.p4conf != "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p4) === 0.5
                              ? "L"
                              : Number(s.p4) === 1
                              ? "P"
                              : s.p4conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td
                            className={
                              Number(s.p5) === 0.5
                                ? "L"
                                : Number(s.p5) === 1
                                ? "P"
                                : s.p5conf != "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p5) === 0.5
                              ? "L"
                              : Number(s.p5) === 1
                              ? "P"
                              : s.p5conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td
                            className={
                              Number(s.p6) === 0.5
                                ? "L"
                                : Number(s.p6) === 1
                                ? "P"
                                : s.p6conf != "taken"
                                ? "x"
                                : "A_"
                            }
                          >
                            {Number(s.p6) === 0.5
                              ? "L"
                              : Number(s.p6) === 1
                              ? "P"
                              : s.p6conf != "taken"
                              ? "x"
                              : "A_"}
                          </td>
                          <td className={attValue > 0 ? "present" : "absent"}>
                            {attValue === 0 ? "Absent غائب" : "Present حاضر"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStuAttendancePerDay;
