const datesArr = [
    { day: "Monday", date: "14-Oct-24", cluster: "C1", week: 1 },
    { day: "Tuesday", date: "15-Oct-24", cluster: "C2", week: 1 },
    { day: "Thursday", date: "17-Oct-24", cluster: "C3", week: 1 },
    { day: "Monday", date: "21-Oct-24", cluster: "C1", week: 2 },
    { day: "Tuesday", date: "22-Oct-24", cluster: "C2", week: 2 },
    { day: "Thursday", date: "24-Oct-24", cluster: "C3", week: 2 },
    { day: "Monday", date: "28-Oct-24", cluster: "C1", week: 3 },
    { day: "Tuesday", date: "29-Oct-24", cluster: "C2", week: 3 },
    { day: "Thursday", date: "31-Oct-24", cluster: "C3", week: 3 },
    { day: "Monday", date: "4-Nov-24", cluster: "C1", week: 4 },
    { day: "Tuesday", date: "5-Nov-24", cluster: "C2", week: 4 },
    { day: "Thursday", date: "7-Nov-24", cluster: "C3", week: 4 },
    { day: "Monday", date: "11-Nov-24", cluster: "C1", week: 5 },
    { day: "Tuesday", date: "12-Nov-24", cluster: "C2", week: 5 },
    { day: "Thursday", date: "14-Nov-24", cluster: "C3", week: 5 },
    { day: "Monday", date: "18-Nov-24", cluster: "C1", week: 6 },
    { day: "Tuesday", date: "19-Nov-24", cluster: "C2", week: 6 },
    { day: "Thursday", date: "21-Nov-24", cluster: "C3", week: 6 },
    { day: "Monday", date: "25-Nov-24", cluster: "C1", week: 7 },
    { day: "Tuesday", date: "26-Nov-24", cluster: "C2", week: 7 },
    { day: "Thursday", date: "28-Nov-24", cluster: "C3", week: 7 },
    { day: "Monday", date: "6-Jan-25", cluster: "C1", week: 8 },
    { day: "Tuesday", date: "7-Jan-25", cluster: "C2", week: 8 },
    { day: "Thursday", date: "9-Jan-25", cluster: "C3", week: 8 },
    { day: "Monday", date: "13-Jan-25", cluster: "C1", week: 9 },
    { day: "Tuesday", date: "14-Jan-25", cluster: "C2", week: 9 },
    { day: "Thursday", date: "16-Jan-25", cluster: "C3", week: 9 },
    { day: "Monday", date: "20-Jan-25", cluster: "C1", week: 10 },
    { day: "Tuesday", date: "21-Jan-25", cluster: "C2", week: 10 },
    { day: "Thursday", date: "23-Jan-25", cluster: "C3", week: 10 },
    { day: "Monday", date: "27-Jan-25", cluster: "C1", week: 11 },
    { day: "Tuesday", date: "28-Jan-25", cluster: "C2", week: 11 },
    { day: "Thursday", date: "30-Jan-25", cluster: "C3", week: 11 },
    { day: "Monday", date: "3-Feb-25", cluster: "C1", week: 12 },
    { day: "Tuesday", date: "4-Feb-25", cluster: "C2", week: 12 },
    { day: "Thursday", date: "6-Feb-25", cluster: "C3", week: 12 },
    { day: "Monday", date: "10-Feb-25", cluster: "C1", week: 13 },
    { day: "Tuesday", date: "11-Feb-25", cluster: "C2", week: 13 },
    { day: "Thursday", date: "13-Feb-25", cluster: "C3", week: 13 },
    { day: "Monday", date: "17-Feb-25", cluster: "C1", week: 14 },
    { day: "Tuesday", date: "18-Feb-25", cluster: "C2", week: 14 },
    { day: "Thursday", date: "20-Feb-25", cluster: "C3", week: 14 },
    { day: "Monday", date: "24-Feb-25", cluster: "C1", week: 15 },
    { day: "Tuesday", date: "25-Feb-25", cluster: "C2", week: 15 },
    { day: "Thursday", date: "27-Feb-25", cluster: "C3", week: 15 },
    { day: "Monday", date: "14-Apr-25", cluster: "C1", week: 16 },
    { day: "Tuesday", date: "15-Apr-25", cluster: "C2", week: 16 },
    { day: "Thursday", date: "17-Apr-25", cluster: "C3", week: 16 },
    { day: "Monday", date: "21-Apr-25", cluster: "C1", week: 17 },
    { day: "Tuesday", date: "22-Apr-25", cluster: "C2", week: 17 },
    { day: "Thursday", date: "24-Apr-25", cluster: "C3", week: 17 },
    { day: "Monday", date: "28-Apr-25", cluster: "C1", week: 18 },
    { day: "Tuesday", date: "29-Apr-25", cluster: "C2", week: 18 },
    { day: "Thursday", date: "1-May-25", cluster: "C3", week: 18 },
    { day: "Monday", date: "5-May-25", cluster: "C1", week: 19 },
    { day: "Tuesday", date: "6-May-25", cluster: "C2", week: 19 },
    { day: "Thursday", date: "8-May-25", cluster: "C3", week: 19 },
    { day: "Monday", date: "12-May-25", cluster: "C1", week: 20 },
    { day: "Tuesday", date: "13-May-25", cluster: "C2", week: 20 },
    { day: "Thursday", date: "15-May-25", cluster: "C3", week: 20 },
    { day: "Monday", date: "19-May-25", cluster: "C1", week: 21 },
    { day: "Tuesday", date: "20-May-25", cluster: "C2", week: 21 },
    { day: "Thursday", date: "22-May-25", cluster: "C3", week: 21 },
    { day: "Monday", date: "26-May-25", cluster: "C1", week: 22 },
    { day: "Tuesday", date: "27-May-25", cluster: "C2", week: 22 },
    { day: "Thursday", date: "29-May-25", cluster: "C3", week: 22 },
  ];
  
  export default datesArr;
  