import React, { useState, useEffect } from "react";
import logo from "../../logo192.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backarrow from "../BackArrow";
import generalQuery from "../../generalQuery";
import "./StudentInfo.css";
const StudentLogin = ({ switcher }) => {
  const esis = sessionStorage.getItem("esis");
  const eid = sessionStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [stInfo, setStInfo] = useState();
  const [classchartcode, setClasschartcode] = useState("");
  const StudentsDataLogin = async () => {
    setLoading(true);
    try {
      const body = { esis, eid };
      const response = await fetch(
        "https://mut.herokuapp.com/StudentsDataLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      generalQuery(
        `select comment,resit from students where studentnumber='${esis}'`
      ).then((res) => {
        resp.comment = res[0]["comment"];
        resp.resit = res[0]["resit"];
        setStInfo(resp);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("Please, check your eSIS or EID!");
      setLoading(false);
    }
  };
  useEffect(() => {
    StudentsDataLogin();
    generalQuery(`select * from classchart where studentnumber='${esis}'`).then(
      (results) => {
        // let txt = "";
        // results.forEach((t) => {
        //   txt = txt + "," + t.mmpassword;
        // });
        setClasschartcode(results[0].classchartcode);
      }
    );
  }, []);
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <CircularProgress />
      </div>
    );
  }
  if (showError) {
    return <div>Esis Number and/or EID is incorrect</div>;
  }
  if (stInfo) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div style={{ marginTop: "200px", float: "left" }}>
          <Backarrow />
        </div>
        <div className="student-info">
          <h2 className="info-title">Student's Information</h2>
          <p>
            <strong className="info-label">Name:</strong> {stInfo.englishname}
          </p>
          <p>
            <strong className="info-label">eSIS Number:</strong>{" "}
            {stInfo.studentnumber}
          </p>
          <p>
            <strong className="info-label">Class:</strong>{" "}
            <p className="red">{stInfo.homroom}</p>
            <strong className="info-label">Alef Password:</strong>{" "}
            <p className="red" style={{color:"red"}}>{stInfo.alefpass}</p>
          </p>
          <strong>
            <p className="red">{stInfo.comment !== "null" && stInfo.comment}</p>
          </strong>
          <p>
            <strong className="info-label">Classchart Link:</strong>
            <a
              className="info-link"
              href="https://www.classcharts.com/student/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.classcharts.com/
            </a>
          </p>
          <p></p>
          <p>
            <strong className="info-label">Classchart Code:</strong>{" "}
            {classchartcode}
          </p>
          <p>
            <strong className="info-label">HMH (Math & Science):</strong>
            <a
              className="info-link"
              href="https://www.hmhco.com/ui/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hmhco.com/ui/login
            </a>
          </p>
          <p></p>
          <p>
            <strong className="info-label">Username:</strong>{" "}
            {"s" + stInfo.studentnumber + "@charterschools.ae"}
          </p>
          <p></p>
          <p>
            <strong className="info-label">Password:</strong>{" "}
            {"ams@" + stInfo.studentnumber}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center" }}>
      <img src={logo} alt="" style={{ width: "80px", marginTop: "50px" }} />
    </div>
  );
};

export default StudentLogin;
