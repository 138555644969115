import React, { useState, useEffect } from "react";
import SuperStaffTable from "./SuperStaffTable";
import SuperStudentsTable from "./SuperStudentsTable";
import SuperAdminTimetable from "./SuperAdminTimetable";
import generalQuery from "../generalQuery";
import SuperAdmiResetAssessmentTables from "./SuperAdmiResetAssessmentTables";
const SuperAdminProgressUsers = () => {
  const [dataType, setDataType] = useState("");
  return (
    <div>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            setDataType(e.target.value);
          }}
        >
          <option>Choose Dataset</option>
          <option value="login">Staff</option>
          <option value="students">Students</option>
          <option value="timetable">Bulk Data</option>
          {/* <option value="resetassessmenttables">Reset Assessment Tables</option> */}
        </select>
      </div>
      {dataType === "login" ? (
        <SuperStaffTable />
      ) : // <SuperadminEndOfYear />
      dataType === "students" ? (
        <SuperStudentsTable />
      ) : dataType === "timetable" ? (
        <SuperAdminTimetable />
      ) : dataType === "resetassessmenttables" ? (
        <SuperAdmiResetAssessmentTables />
      ) : (
        ""
        // <button
        //   onClick={() => {
        //     generalQuery(
        //       "SELECT tablename FROM pg_catalog.pg_tables WHERE tablename LIKE 'zatt%'"
        //     ).then((results) => {
        //       results.forEach((r) => {
        //         generalQuery(`drop table ${r.tablename}`);
        //       });
        //     });
        //   }}
        // >
        //   Clear Attendance tables
        // </button>
      )}
    </div>
  );
};

export default SuperAdminProgressUsers;
