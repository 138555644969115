import React, { useState } from "react";
import "./AddStudent.css";
import generalQuery from "../generalQuery";

const AddStudent = ({ refresher, showPortalNewStudent }) => {
  const [setsections, setSections] = useState([]);
  const [eSIS, setEsis] = useState("");
  const [name, setName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [grade_level, setGrade_level] = useState("");
  const [homeroom, sethomeroom] = useState("");
  const [id, setId] = useState("");
  const [mobile, setMobile] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate that all fields have values
    if (
      !eSIS ||
      !name ||
      !arabicName ||
      !phoneNumber ||
      !grade_level ||
      !homeroom ||
      !id ||
      !mobile
    ) {
      alert("All fields must be filled out");
      return;
    }

    // Use generalQuery to insert formData into both tables
    generalQuery(
      `Begin; INSERT INTO students (status,charteremail,englishname,username,studentnumber,grade_level,esisclass,homroom,homroomalph,id,mobile,interv_group,phonenumber,role) VALUES ('Registered','${
        "s" + eSIS + "@charterschools.ae"
      }','${name}','${arabicName}','${eSIS}','${grade_level}','${homeroom}','${homeroom}','${homeroom}','${id}','${mobile}','NA','${phoneNumber}','student');   INSERT INTO masterattendance (schoolid,	studentname,	pupilnumber,	attendancemode,	attendancepattern,	homeroomid,	homeroomcode,	arabicname,	tempclass) VALUES ('6023','${name}','${eSIS}','CR','PH','${homeroom}','${homeroom}','${arabicName}','${homeroom}'); INSERT INTO credithours ( pupilnumber, ara, ara_intv, art, el1_sports_1, el1_sports_2, el1_sports_3, el2_drm2, el2_drm3, el2_mus1, el2_mus2, el3_drm1, el3_ict, el3_robo, el3_vart, eng, eng_intv, ict, isl, mor, mth, mth_intv, mus, peh, sci, sci_intv, soc, swm ) VALUES ( '${eSIS}',0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0); Commit;`
    ).then(() => {
      refresher(Math.random());
      showPortalNewStudent(false);
    });
  };
  const getClasses = (grade) => {
    generalQuery(
      `select distinct(homroom) from students where grade_level='${grade}' order by homroom`
    ).then((results) => {
      setSections(results);
    });
  };
  return (
    <div className="student-form" style={{ alignSelf: "end" }}>
      <form onSubmit={handleSubmit} className="student-form">
        <h4>Student Information</h4>
        <input
          autoComplete="off"
          type="text"
          placeholder="eSIS"
          name="studentNumber"
          className="input-field"
          onChange={(e) => {
            setEsis(e.target.value);
          }}
        />
        <input
          autoComplete="off"
          type="text"
          placeholder="English Name"
          name="englishName"
          className="input-field"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          autoComplete="off"
          type="text"
          placeholder="Arabic Name"
          name="arabicName"
          className="input-field"
          onChange={(e) => {
            setArabicName(e.target.value);
          }}
        />
        <input
          autoComplete="off"
          type="text"
          placeholder="Phone Number"
          name="phoneNumber"
          className="input-field"
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        />
        <select
          className="input-field"
          onChange={(e) => {
            setSections([]);
            getClasses(e.target.value);
            setGrade_level(e.target.value);
          }}
        >
          <option>Select Grade Level</option>
          
          <option value="Grade 8">8</option>
          <option value="Grade 9">9</option>
          <option value="Grade 10">10</option>
          <option value="Grade 11">11</option>
        </select>
        {setsections.length > 0 ? (
          <div>
            <select
              className="input-field"
              onChange={(e) => {
                sethomeroom(e.target.value);
              }}
            >
              <option>--Class--</option>
              {setsections.map((s) => {
                return (
                  <option value={s.homroom} key={s}>
                    {s.homroom}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        <input
          autoComplete="off"
          type="text"
          placeholder="ID"
          name="id"
          className="input-field"
          onChange={(e) => {
            setId(e.target.value);
          }}
        />

        <input
          autoComplete="off"
          type="text"
          placeholder="Mobile"
          name="mobile"
          className="input-field"
          onChange={(e) => {
            setMobile(e.target.value);
          }}
        />
        <button type="submit" className="submit-button">
          Add Student
        </button>
      </form>
    </div>
  );
};

export default AddStudent;
