import React, { useState } from "react";
import "./MapAnalysis.css"; // Import the CSS stylesheet for the Navbar component

import TeacherDashboard from "./TeacherDashboard";
import SchoolOverall from "./SchoolOverall";
import MinimumExpectations from "./MinimumExpectations";
import ErrorBoundary from "./ErrorBoundary";
import TalaAnalysis from "./TalaAnalysis";
const MapAnalysis = () => {
  const [activeTab, setActiveTab] = useState("minimumExpectations");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="navbar">
        <div
          className={`navbar-tab ${
            activeTab === "minimumExpectations" ? "active" : ""
          }`}
          onClick={() => handleTabChange("minimumExpectations")}
        >
          Below Minimum Expectations {"< 41"} Pecentile
        </div>
        <div
          className={`navbar-tab ${activeTab === "tala" ? "active" : ""}`}
          onClick={() => handleTabChange("tala")}
        >
          طلــــع
        </div>
        <div
          className={`navbar-tab ${activeTab === "overall" ? "active" : ""}`}
          onClick={() => handleTabChange("overall")}
        >
          School MAP Performance
        </div>
        <div
          className={`navbar-tab ${activeTab === "dashboard" ? "active" : ""}`}
          onClick={() => handleTabChange("dashboard")}
        >
          90% Goal Analysis
        </div>

        <div className="clear"></div>
      </div>{" "}
      {activeTab === "dashboard" ? (
        <TeacherDashboard />
      ) : activeTab === "minimumExpectations" ? (
        <ErrorBoundary>
          <MinimumExpectations />
        </ErrorBoundary>
      ) : activeTab === "tala" ? (
        <ErrorBoundary>
          <TalaAnalysis />
        </ErrorBoundary>
      ) : (
        <SchoolOverall />
      )}
    </div>
  );
};

export default MapAnalysis;
