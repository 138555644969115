import React, { useState } from "react";
import "./DataEntryForm.css";
import generalQuery from "../generalQuery";
function DataEntryForm() {
  const [formData, setFormData] = useState({
    id: "",
    lname: "",
    lemail: "",
    lpassword: "",
    sid: "",
    sub: "",
    trole: "",
    teachername: "",
    intervsubject: "",
    intervgroup: "",
    intervroom: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const query = `INSERT INTO login ( id,lname,lemail,lpassword,sid,sub,trole,teachername,intervsubject,intervgroup,intervroom) VALUES ('${formData.id}', '${formData.lname}', '${formData.lemail}', '${formData.lpassword}', '${formData.sid}', '${formData.sub}', '${formData.trole}', '${formData.teachername}', '${formData.intervsubject}', '${formData.intervgroup}', '${formData.intervroom}')`;
    generalQuery(query);
    // console.log(formData)
    // console.log(query)
  };

  return (
    <form className="data-entry-form" onSubmit={handleSubmit}>{/* You will include input fields for each value here */}
      {Object.keys(formData).map((key) => (
        <div className="form-group" key={key}>
          <label className="form-label">{key}</label>
          <input
            className="form-input"
            type="text"
            name={key}
            value={formData[key]}
            onChange={handleChange}
          />
        </div>
      ))}
      <button type="submit" className="submit-button">
        Submit
      </button>
    </form>
  );
}

export default DataEntryForm;
