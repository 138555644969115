import React, { useState, useEffect } from "react";
import GetStuAttendancePerDay from "./GetStuAttendancePerDay";
import { CircularProgress } from "@mui/material";
import Backarrow from "./BackArrow";
const MyAttendance = () => {
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAttendanceNames = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getAttendanceNames",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      setDays(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAttendanceNames();
  }, []);
  return (
    <div>
      <>
        {days.length === 0 ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <GetStuAttendancePerDay
              pupilnumber={sessionStorage.getItem("esis")}
              days={days}
              settingLoading={(v) => {
                setLoading(v);
              }}
            />
          </>
        )}
      </>
    </div>
  );
};

export default MyAttendance;
