import React from "react";
import { Button } from "@material-ui/core";
const InfoErrMessage = ({ open }) => {
  return (
    <div>
      <div>Sorry, something went wrong. </div>
      <div style={{marginBottom:"10px"}}>Please try again later. </div>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          open(false);
        }}
      >
        Close
      </Button>
    </div>
  );
};

export default InfoErrMessage;
