import React, { useEffect, useState } from "react";
import generalQuery from "../generalQuery"; // Assuming you have this query utility
import './ExamScheduleTable.css'; // Import the CSS file

const ExamScheduleTable = () => {
  const [data, setData] = useState([]);
  const [sortedDates, setSortedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    generalQuery("select * from exams")
      .then((response) => {
        const groupedData = groupDataByDate(response);
        setData(groupedData);
        const sortedDateKeys = sortDates(Object.keys(groupedData));
        setSortedDates(sortedDateKeys);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Function to group data by date, then by class and subjects
  const groupDataByDate = (data) => {
    const groupedByDate = {};
    data.forEach((row) => {
      Object.keys(row).forEach((subject) => {
        if (subject !== "class") {
          const date = row[subject];
          if (!groupedByDate[date]) {
            groupedByDate[date] = [];
          }
          groupedByDate[date].push({ class: row.class, subject });
        }
      });
    });

    // Sort classes for each date
    Object.keys(groupedByDate).forEach((date) => {
      groupedByDate[date] = sortClasses(groupedByDate[date]);
    });

    return groupedByDate;
  };

  // Helper function to convert dd/mm/yyyy to yyyy-mm-dd and sort the dates
  const sortDates = (dates) => {
    return dates
      .filter((date) => date && date !== 'null') // Exclude null or undefined dates
      .sort((a, b) => {
        const [dayA, monthA, yearA] = a.split("/");
        const [dayB, monthB, yearB] = b.split("/");
        const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
        const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
        return dateA - dateB;
      });
  };

  // Helper function to sort classes by numeric and alphabetic part
  const sortClasses = (classArray) => {
    return classArray.sort((a, b) => {
      const classA = a.class.match(/(\d+)([A-Za-z]*)/);
      const classB = b.class.match(/(\d+)([A-Za-z]*)/);

      const numA = parseInt(classA[1], 10);
      const numB = parseInt(classB[1], 10);

      if (numA !== numB) {
        return numA - numB;
      } else {
        return classA[2].localeCompare(classB[2]); // Compare letters
      }
    });
  };

  // Helper function to capitalize the first letter of the subject name
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Handle selecting a date
  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="exam-schedule-container">
      
      <div className="exam-schedule-slicer">
        <h3 className="exam-schedule-title">Select a Date</h3>
        <ul className="exam-schedule-list">
          {sortedDates.map((date, index) => (
            <li
              key={index}
              className={`exam-schedule-list-item ${selectedDate === date ? "active" : ""}`}
              onClick={() => handleDateSelect(date)}
            >
              {date || 'null'} {/* Handling null dates */}
            </li>
          ))}
        </ul>
      </div>

      {/* Table Display (Right Pane) */}
      <div className="exam-schedule-table-wrapper">
        {selectedDate ? (
          <table className="exam-schedule-table">
            <thead>
              <tr>
                <th>Class</th>
                <th>Subjects</th>
              </tr>
            </thead>
            <tbody>
              {data[selectedDate].map((entry, idx) => (
                <tr key={idx}>
                  <td className="exam-schedule-class-column">{entry.class}</td>
                  <td className="exam-schedule-subject-column">
                    {capitalizeFirstLetter(entry.subject)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="exam-schedule-placeholder">Please select a date from the left.</p>
        )}
      </div>
    </div>
  );
};

export default ExamScheduleTable;
