import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import exportDataToExcel from "../exportDataToExcel";
import generalQuery from "../generalQuery";
import Button from "@mui/material/Button";
import { ArrowForward } from "@mui/icons-material";
const UnitAssessments = ({
  teacherClasses,
  assessment,
  headers,
  weight,
  term,
}) => {
  const [data, setData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideAr, setHideAr] = useState(false);
  const [hideEn, setHideEn] = useState(false);
  const [sub, setSub] = useState(sessionStorage.getItem("sub"));
  const [termFrom, setTermFrom] = useState("");

  const generalQueryLocal = async (z, p) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      if (p === "getdata") {
        setData(resp);
        const arr = [];
        resp.forEach((el) => {
          if (arr.indexOf(el.homeroom) === -1) {
            arr.push(el.homeroom);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const coreSubjects = ["Arabic", "English", "Math", "Science"];
  const MarkInput = (n, d) => {
    return (
      <div>
        <input
          autoComplete="off"
          style={
            d["assign" + n] === "null" ||
            d["assign" + n] === "" ||
            d["assign" + n] === null
              ? { backgroundColor: "pink" }
              : { backgroundColor: "lightgreen" }
          }
          id={"assignment" + n + data.indexOf(d)}
          type="text"
          className="marks"
          placeholder={d["assign" + n] !== "null" ? d["assign" + n] : ""}
          onChange={(e) => {
            if (
              isNaN(Number(e.target.value)) &&
              e.target.value.toLowerCase() !== "a"
            ) {
              alert(
                "Enter A for Abent or a numeric value Aأدخل حرف  للغائب أو قيمة عددية"
              );
              document.getElementById(
                "assignment" + n + data.indexOf(d)
              ).value = "";
            }
            if (d["overall" + n] === "null") {
              alert(
                "Please Enter the overall mark!أرجو ادخال الدرجة الكلية أولا"
              );
              document.getElementById(
                "assignment" + n + data.indexOf(d)
              ).value = "";
              document.getElementById("assignment" + n).style.backgroundColor =
                "red";
            } else {
              if (+e.target.value > +d["overall" + n]) {
                alert(
                  `Entry must be less than or equal يجب ألا تزيد عن${
                    d["overall" + n]
                  }`
                );
                document.getElementById(
                  "assignment" + n + data.indexOf(d)
                ).value = "";
              } else {
                d["assign" + n] = e.target.value;
                let totalValue = (
                  ((Number(d.assign1) / Number(d.overall1) +
                    Number(d.assign2) / Number(d.overall2)) /
                    2) *
                  weight
                ).toFixed(1);
                d.total = totalValue;
                document.getElementById(d.esis + "total").innerHTML =
                  totalValue;
              }
              if (e.target.value === "") {
                document.getElementById(
                  "assignment" + n + data.indexOf(d)
                ).style.backgroundColor = "pink";
              } else {
                document.getElementById(
                  "assignment" + n + data.indexOf(d)
                ).style.backgroundColor = "lightgreen";
              }
            }
          }}
          onKeyPress={() => {
            if (data.indexOf(d) === data.length - 1) {
              document.getElementById("assignment" + n + 0).focus();
            } else {
              document
                .getElementById("assignment" + n + +(+data.indexOf(d) + 1))
                .focus();
            }
          }}
        />
      </div>
    );
  };
  const getData = (value) => {
    setLoading(true);
    generalQueryLocal(
      `select esis,name,dropped,arabicname,eligibility,homeroom,${
        sub + "1"
      } as assign1,${sub + "2"} as assign2,${sub + "t"} as total,${
        sub + "all1"
      } as overall1 ,${
        sub + "all2"
      } as overall2 from ${assessment} where homeroom='${value}'`,
      "getdata"
    );
  };

  const copyData = () => {
    setLoading(true);
    data.forEach((d) => {
      ["1", "2"].forEach((v) => {
        generalQuery(
          `select ${sub + v} ,${sub + "all" + v},${sub + "t"} from ${
            assessment.slice(0, -2) + termFrom
          } where esis='${d.esis}'`
        ).then((r) => {
          generalQuery(
            `update  ${assessment} set ${sub + v}='${
              r[0][(sub + v).toLowerCase()]
            }', ${sub + "all" + v}='${r[0][sub.toLowerCase() + "all" + v]}', ${
              sub + "t"
            }='${r[0][sub.toLowerCase() + "t"]}' where esis='${d.esis}'`
          ).then(() => {
            if (data.indexOf(d) === data.length - 1) {
              getData(selectedClass);
            }
          });
        });
      });
    });
  };

  useEffect(() => {
    getData(selectedClass);
  }, [assessment, sub]);
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h4>
          {sub === "Ss" ? (
            <>اجتماعيات</>
          ) : sub === "Islamic" ? (
            "التربية الاسلامية"
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className="container">
        {sub === "Is/Ss" && (
          <select
            className="select"
            onChange={(e) => {
              setSub(e.target.value);
            }}
          >
            <option>اختر المادة</option>\
            <option value="Islamic">الاسلامية</option>
            <option value="Ss">الاجتماعيات</option>
          </select>
        )}
        {(sub === "Islamic" || sub === "Ss") && (
          <button
            className="select"
            onClick={() => {
              if (sub === "Islamic") {
                setSub("Ss");
              } else {
                setSub("Islamic");
              }
            }}
          >
            غير المادة
          </button>
        )}
        {sub !== "Is/Ss" && (
          <select
            id="selectClass"
            className="select"
            onChange={(e) => {
              setLoading(true);
              getData(e.target.value);
              setSelectedClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {teacherClasses.map((c) => {
              return <option key={c}>{c}</option>;
            })}
          </select>
        )}
        {data.length > 0 ? (
          <div className="container">
            {/* <button
              className="savebtn"
              onClick={() => {
                data.forEach((d) => {
                  generalQueryLocal(
                    `update ${assessment} set ${sub + "1"}='${d.assign1}',${
                      sub + "2"
                    }='${d.assign2}', ${sub + "all1"}='${d.overall1}', ${
                      sub + "all2"
                    }='${d.overall2}', ${sub + "t"}='${d.total}' where esis='${
                      d.esis
                    }'`,
                    "updateData"
                  );
                });
              }}
            >
              <SaveIcon fontSize="large" />
            </button> */}
          </div>
        ) : (
          ""
        )}
      </div>
      {selectedClass !== "" && (
        <div>
          {["Art", "Comp", "Drama", "eco", "Gov", "PE", "Music"].includes(
            sub
          ) && term === "t3" ? (
            <div className="container">
              <select
                className="select"
                onChange={(e) => {
                  setTermFrom(e.target.value);
                }}
              >
                <option>--From--</option>
                <option value="t1">Term 1</option>
                <option value="t2">Term 2</option>
              </select>
              {termFrom !== "" && (
                <Button
                  variant="contained"
                  startIcon={<ArrowForward />}
                  sx={{ height: 37 }}
                  onClick={() => {
                    copyData();
                  }}
                >
                  Copy Data
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="container">
            {!loading ? (
              <>
                {data.length > 0 && (
                  <>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        exportDataToExcel(
                          data,
                          Object.keys(data[0]),
                          assessment
                        );
                      }}
                    >
                      Download
                    </a>
                    <table className="creatortable">
                      <thead>
                        <tr>
                          <th>eSIS</th>
                          {!hideEn && (
                            <th
                              onClick={() => {
                                setHideEn(true);
                                setHideAr(false);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.arabicname > b.arabicname ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p> Name</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          {!hideAr && (
                            <th
                              onClick={() => {
                                setHideEn(false);
                                setHideAr(true);
                                setData((data) => [
                                  ...data.sort((a, b) =>
                                    a.name > b.name ? 1 : -1
                                  ),
                                ]);
                              }}
                            >
                              <p>الاسم</p>
                              <VisibilityOffIcon />
                            </th>
                          )}
                          <th>Class</th>
                          <th>
                            {headers + " 1"}
                            <input
                              id="assignment1"
                              type="number"
                              className="totalmark"
                              placeholder={
                                data[0].overall1 !== "null"
                                  ? "/" + data[0].overall1
                                  : "/"
                              }
                              onChange={(e) => {
                                data.forEach((d) => {
                                  d.overall1 = e.target.value;
                                });
                                document.getElementById(
                                  "assignment1"
                                ).style.backgroundColor = "white";
                              }}
                            ></input>
                          </th>
                          <th>
                            {headers + " 2"}
                            <input
                              id="assignment2"
                              type="number"
                              className="totalmark"
                              placeholder={
                                data[0].overall2 !== "null"
                                  ? "/" + data[0].overall2
                                  : "/"
                              }
                              onChange={(e) => {
                                data.forEach((d) => {
                                  d.overall2 = e.target.value;
                                });
                                document.getElementById(
                                  "assignment2"
                                ).style.backgroundColor = "white";
                              }}
                            ></input>
                          </th>

                          <th>
                            Total<p>{weight}% of the overall Mark</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data
                          .filter(
                            (t) =>
                              t.homeroom === selectedClass && t.dropped !== "y"
                          )
                          .map((d) => {
                            return (
                              <tr
                                key={d.esis}
                                style={
                                  d.eligibility === "no"
                                    ? { backgroundColor: "lightblue" }
                                    : { backgroundColor: "white" }
                                }
                              >
                                <td id="lessPadding">{d.esis}</td>
                                {!hideEn && <td>{d.name}</td>}
                                {!hideAr && <td>{d.arabicname}</td>}
                                <td id="lessPadding">{d.homeroom}</td>
                                {["1", "2"].map((n) => {
                                  return <td>{MarkInput(n, d)}</td>;
                                })}
                                <td id={d.esis + "total"}>
                                  {d.total !== "null"
                                    ? d.total
                                    : d.total === NaN
                                    ? "0"
                                    : ""}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitAssessments;
