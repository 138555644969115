import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import generalQuery from "../generalQuery";
import "./TeacherDashboard.css";
import "./ClassMapPerformance.css";
import { getmapreport } from "../components/wpl/StudentManual/GeneratePDF";

const ClassMapPerformance = ({ selectedCourse, selectedClass }) => {
  const [students, setstudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teachername, setTeachername] = useState("");

  useEffect(() => {
    generalQuery(
      `select * from mapspring23 where class24='${selectedClass}' and course='${selectedCourse}' order by testritscore`
    ).then((results) => {
      setTeachername(results[0].teacher24);
      let arr = [];
      results.forEach((e) => {
        arr.push({
          studentId: e.studentid,
          studentName: e.schoolname,
          TestDurationMinutes: e.testdurationminutes,
          TestRITScore: e.testritscore,
          TestPercentile: e.testpercentile,
          FallToSpringProjectedGrowth: e.falltospringprojectedgrowth,
          FallToSpringObservedGrowth: e.falltospringobservedgrowth,
          TypicalSpringToSpringGrowth: e.typicalspringtospringgrowth,
          // ConversationFeedback: e.ConversationFeedback,
        });
      });
      setstudents(arr);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          width: "30px",
          textAlign: "center",
          backgroundColor: "yellow",
        }}
      >
        <strong> {selectedClass}</strong>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "blue",
        }}
      >
        <div>
          <strong>{selectedCourse}</strong>{" "}
        </div>
        <div>
          <strong>{teachername}</strong>{" "}
        </div>
      </div>

      <table className="student-table">
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Student Name</th>

            <th>Test Duration (Minutes)</th>
            <th>Test RIT Score</th>
            <th>Test Percentile</th>
            <th>Fall to Spring Projected Growth</th>
            <th>Fall to Spring Observed Growth</th>
            <th>Typical Spring to Spring Growth</th>
            <th>MAP Report</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={index}>
              {Object.values(student).map((value, i) => (
                <>
                  <td key={i}>{value}</td>
                </>
              ))}
              <td>
                <button
                  className="select"
                  onClick={() => {
                    getmapreport(
                      "mapfall2023",
                      "Fall 2023",
                      "Spring 2023",
                      "Fall 2023",
                      student.studentId,
                      [
                        "Spring'22",
                        "Fall'22",
                        "Winter'23",
                        "Spring'23",
                        "Fall'23",
                      ]
                    );
                  }}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassMapPerformance;
