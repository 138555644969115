const lightColorsRGB = [
  "rgb(255, 0, 0)", // Red
  "rgb(0, 255, 0)", // Green
  "rgb(0, 0, 255)", // Blue
  "rgb(255, 255, 0)", // Yellow
  "rgb(255, 0, 255)", // Magenta
  "rgb(0, 255, 255)", // Cyan
  "rgb(255, 128, 0)", // Orange
  "rgb(128, 0, 255)", // Purple
  "rgb(0, 128, 255)", // Light Blue
  "rgb(255, 0, 128)", // Pink
  "rgb(0, 255, 128)", // Sea Green
  "rgb(128, 255, 0)", // Lime Green
  "rgb(128, 0, 0)", // Maroon
  "rgb(0, 128, 0)", // Dark Green
  "rgb(0, 0, 128)", // Navy
  "rgb(128, 128, 0)", // Olive
  "rgb(128, 0, 128)", // Purple
  "rgb(0, 128, 128)", // Teal
  "rgb(192, 192, 192)", // Silver
  "rgb(128, 128, 128)", // Gray
  "rgb(255, 255, 115)", // White
  "rgb(0, 0, 0)", // Black
  "rgb(255, 165, 0)", // Orange (Web)
  "rgb(153, 50, 204)", // Dark Orchid
  "rgb(160, 82, 45)", // Sienna
  "rgb(75, 0, 130)", // Indigo
  "rgb(0, 100, 0)", // Dark Green
  "rgb(85, 107, 47)", // Dark Olive Green
  "rgb(72, 61, 139)", // Dark Slate Blue
  "rgb(139, 69, 19)", // Saddle Brown
  "rgb(205, 133, 63)", // Peru
  "rgb(222, 184, 135)", // Burly Wood
  "rgb(95, 158, 160)", // Cadet Blue
  "rgb(123, 104, 238)", // Medium Slate Blue
  "rgb(50, 205, 50)", // Lime Green
  "rgb(210, 105, 30)", // Chocolate
  "rgb(173, 216, 230)", // Light Blue
  "rgb(0, 139, 139)", // Dark Cyan
  "rgb(144, 238, 144)", // Light Green
  "rgb(240, 128, 128)", // Light Coral
  "rgb(176, 224, 230)", // Powder Blue
  "rgb(219, 112, 147)", // Pale Violet Red
  "rgb(255, 192, 203)", // Pink
  "rgb(147, 112, 219)", // Medium Purple
  "rgb(64, 224, 208)", // Turquoise
  "rgb(255, 228, 225)", // Misty Rose
  "rgb(0, 255, 127)", // Spring Green
  "rgb(221, 160, 221)", // Plum
  "rgb(255, 69, 0)", // Red (Web)
  "rgb(218, 112, 214)", // Orchid
  "rgb(244, 164, 96)", // Sandy Brown
  "rgb(0, 191, 255)", // Deep Sky Blue
  "rgb(255, 222, 173)", // Navajo White
  "rgb(46, 139, 87)", // Sea Green
  "rgb(255, 218, 185)", // Peach Puff
  "rgb(100, 149, 237)", // Cornflower Blue
  "rgb(255, 245, 238)", // Seashell
  "rgb(240, 230, 140)", // Khaki
  "rgb(135, 206, 235)", // Sky Blue
  "rgb(250, 128, 114)", // Salmon
  "rgb(152, 251, 152)", // Pale Green
  "rgb(255, 250, 205)", // Lemon Chiffon
  "rgb(144, 238, 144)", // Light Green
  "rgb(255, 215, 0)", // Gold
  "rgb(255, 182, 193)", // Light Pink
  "rgb(64, 224, 208)", // Turquoise
  "rgb(255, 239, 213)", // Papaya Whip
  "rgb(0, 128, 128)", // Teal
  "rgb(250, 235, 215)", // Antique White
  "rgb(50, 205, 50)", // Lime Green
  "rgb(255, 240, 245)", // Lavender Blush
  "rgb(152, 251, 152)", // Pale Green
  "rgb(245, 222, 179)", // Wheat
  "rgb(0, 128, 0)", // Green (Web)
  "rgb(255, 105, 180)", // Hot Pink
  "rgb(176, 224, 230)", // Powder Blue
  "rgb(255, 228, 181)", // Moccasin
  "rgb(70, 130, 180)", // Steel Blue
  "rgb(255, 250, 240)", // Floral White
  "rgb(127, 255, 212)", // Aquamarine
  "rgb(255, 99, 71)", // Tomato
  "rgb(250, 250, 210)", // Light Goldenrod Yellow
  "rgb(135, 206, 235)", // Sky Blue
  "rgb(255, 215, 0)", // Gold
  "rgb(255, 192, 203)", // Pink
  "rgb(240, 230, 140)", // Khaki
  "rgb(255, 160, 122)", // Light Salmon
  "rgb(144, 238, 144)", // Light Green
  "rgb(255, 245, 238)", // Seashell
  "rgb(255, 228, 196)", // Bisque
  "rgb(123, 104, 238)", // Medium Slate Blue
  "rgb(255, 182, 193)", // Light Pink
  "rgb(255, 255, 0)", // Yellow
  "rgb(107, 142, 35)", // Olive Drab
  "rgb(0, 255, 0)", // Lime
  "rgb(70, 130, 180)", // Steel Blue
  "rgb(0, 255, 127)", // Spring Green
  "rgb(255, 222, 173)", // Navajo White
  "rgb(255, 0, 255)", // Magenta
  "rgb(255, 250, 240)", // Floral White
  "rgb(255, 165, 0)", // Orange (Web)
  "rgb(0, 0, 255)", // Blue
  "rgb(240, 128, 128)", // Light Coral
  "rgb(139, 0, 139)", // Dark Magenta
  "rgb(0, 0, 0)", // Black
];

export default lightColorsRGB;
