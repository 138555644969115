import React from "react";
import logo from "../components/logo192.png";
const HeaderHome = () => {
  return (
    <div>
      <div className="header">
        <img src={logo} style={{ maxHeight: "80px",userSelect:"none" }}></img>
      </div>
      <h3 style={{ color: "#0e80ac", fontFamily: "sans-serif" }}>
        مدرسة المتنبي
      </h3>
      <h3 style={{ color: "#0e80ac", fontFamily: "sans-serif" }}>
        Al Mutanabi School
      </h3>
    </div>
  );
};

export default HeaderHome;
