import React, { useState } from "react";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoErrMessage from "./InfoErrMessage";
import logo from "../../logo192.png"

const InfoManager = () => {
  const [loading, setLoading] = useState(false);
  const [studentnumber, setStudentnumber] = useState("");
  const [stInfo, setStInfo] = useState();
  const [alefInfo, setAlefInfo] = useState();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState();
  const [alefPassword, setalefPassword] = useState();

  const schoolLearningCount = async (x) => {
    try {
      const body = { homroom: x };
      const response = await fetch(
        "https://mut.herokuapp.com/getschoolLearningCount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setCount(resp.count);
      if (resp.count > 19) {
        alert("Sorry,number of students exceeds the limit!");
      } else {
        updatetoSchoolLearning();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updatetoSchoolLearning = async (x) => {
    try {
      const body = { studentnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/updatetoSchoolLearning",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      getStudentsData();
    } catch (error) {
      console.log(error);
    }
  };
  const updatetoDistanceLearning = async (x) => {
    try {
      const body = { studentnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/updatetoDistanceLearning",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      getStudentsData();
    } catch (error) {
      console.log(error);
    }
  };
  const getalefpassword = async () => {
    try {
      const body = { studentnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/getalefpassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setalefPassword(resp.alefpassword);
      setLoading(false);
    } catch (error) {
      setAlefInfo("not found");
      console.log(error);
    }
  };
  const getAlefData = async (x) => {
    try {
      const body = { studentnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/getfromAlefList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setAlefInfo(resp);
      setLoading(false);
    } catch (error) {
      setAlefInfo({
        email: `S${studentnumber}@charterschools.ae`,
        password: `AMS@${studentnumber}`,
        username: "not found",
      });
      console.log(error);
    }
  };
  const getStudentsData = async (x) => {
    setLoading(true);
    try {
      const body = { studentnumber };
      const response = await fetch(
        "https://mut.herokuapp.com/getfromStudentList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      // getAlefData();
      // getalefpassword();
      setStInfo(resp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setOpen(true);
      setLoading(false);
    }
  };

  return (
    <div>
            <img src={logo} alt="" style={{ width: "80px", marginTop: "50px" }} />

      {open ? (
        <InfoErrMessage open={(v) => setOpen(v)} />
      ) : (
        <div style={{ display: "grid", placeItems: "center" }}>
          <input
            type="text"
            placeholder="Please Enter Your eSIS#"
            style={{ padding: "15px", width: "70%", marginBottom: "10px" }}
            onChange={(e) => {
              setStudentnumber(e.target.value);
            }}
          ></input>
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                getStudentsData();
              }}
            >
              Search
            </Button>
          </div>

          {loading ? (
            <div style={{ margin: "20%" }}>
              <CircularProgress />
            </div>
          ) : stInfo ? (
            <table>
              <tbody>
                <tr>
                  {/* <th style={{ padding: "10px" }}>Learning Mode</th> */}
                  {/* <td
                    style={{
                      padding: "10px",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {stInfo && <>{stInfo.learningmode}</>}
                  </td> */}
                  <td>
                    {/* <button
                      onClick={() => {
                        if (stInfo.learningmode === "School Learning") {
                          updatetoDistanceLearning();
                        } else {
                          schoolLearningCount(stInfo.homroom);
                        }
                      }}
                    >
                      Change
                    </button> */}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "10px" }}>Student Name</th>
                  <td style={{ padding: "10px", color: "blue" }}>
                    {stInfo && <>{stInfo.englishname}</>}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "10px" }}>eSIS</th>
                  <td style={{ padding: "10px", color: "blue" }}>
                    {stInfo && <>{stInfo.studentnumber}</>}
                  </td>
                </tr>

                <tr>
                  <th style={{ padding: "10px" }}>Class</th>
                  <td style={{ padding: "10px", color: "red" }}>
                    {stInfo && <>{stInfo.homroom}</>}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "10px" }}>Alef Password</th>
                  <td
                    style={{
                      padding: "10px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    {stInfo && <>{stInfo.alefpass}</>}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "10px" }}></th>
                  <td
                    style={{
                      padding: "10px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    <a href="https://charterschools.ae/">charterschools.ae/</a>
                  </td>
                </tr>
                {/* <tr>
                  <th style={{ padding: "10px" }}>Email</th>
                  <td style={{ padding: "10px", color: "red" }}>
                  
                  </td>
                </tr> */}
                <tr>
                  <th style={{ padding: "10px" }}>Username</th>
                  <td style={{ padding: "10px", color: "red" }}>
                  {stInfo && <>s{stInfo.studentnumber}@charterschools.ae</>}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "10px" }}>Password</th>
                  <td style={{ padding: "10px", color: "red" }}>
                    {stInfo && <>{stInfo.charterpass}</>}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default InfoManager;
