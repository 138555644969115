import React, { useEffect, useState } from "react";
import generalQuery from "./generalQuery";
import { CircularProgress } from "@material-ui/core";
import CreditHoursPerStudent from "./CreditHoursPerStudent";
const CreditHoursTracker = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [setselectedClassToShow, setSetselectedClassToShow] = useState([]);
  const [attendanceTables, setAttendanceTables] = useState([]);
  const [selectedSub, setSelectedSub] = useState("");
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [pupilnumberArr, setPupilnumberArr] = useState([]);
  const [maxGrade, setMaxGrade] = useState();
  const subjects = [];

  useEffect(() => {
    generalQuery(
      "select distinct(homroom) from students order by homroom"
    ).then((results) => {
      let arr = [];
      results.forEach((r) => {
        if (arr.indexOf(r.homroom.replace(/G(\d+)-([A-Z])/, "$1$2")) === -1) {
          arr.push(r.homroom.replace(/G(\d+)-([A-Z])/, "$1$2"));
        }
      });

      setClasses(arr);
    });
  }, []);
  return (
    <div>
      <h3 className="container"> Credit Hours</h3>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            setSelectedClass("");
            setSelectedSub(e.target.value);
            // document.getElementById("classAtt").valu = "";
          }}
        >
          <option>Subject</option>
          <option value="ara">ARA</option>
          <option value="ara_intv">ARA-INTV</option>
          <option value="art">ART</option>
          <option value="el1_sports_1">EL1-Sports 1</option>
          <option value="el1_sports_2">EL1-Sports 2</option>
          <option value="el1_sports_3">EL1-Sports 3</option>
          <option value="el2_drm2">EL2-DRM2</option>
          <option value="el2_drm3">EL2-DRM3</option>
          <option value="el2_mus1">EL2-MUS1</option>
          <option value="el2_mus2">EL2-MUS2</option>
          <option value="el3_drm1">EL3-DRM1</option>
          <option value="el3_ict">EL3-ICT</option>
          <option value="el3_robo">EL3-ROBO</option>
          <option value="el3_vart">EL3-VART</option>
          <option value="eng">ENG</option>
          <option value="eng_intv">ENG-INTV</option>
          <option value="ict">ICT</option>
          <option value="isl">ISL</option>
          <option value="mor">MOR</option>
          <option value="mth">MTH</option>
          <option value="mth_intv">MTH-INTV</option>
          <option value="mus">MUS</option>
          <option value="peh">PEH</option>
          <option value="sci">SCI</option>
          <option value="sci_intv">SCI-INTV</option>
          <option value="soc">SOC</option>
          <option value="swm">SWM</option>
        </select>
        {selectedSub !== "" && (
          <select
            id="classAtt"
            className="select"
            onChange={(e) => {
              setSelectedClass([]);
              setPupilnumberArr([]);
              let cls = e.target.value.replace(/(\d+)([A-Z])/, "G$1-$2");
              generalQuery(
                `select studentnumber,username,englishname,esisclass,mobile,phonenumber from students where esisclass='${cls}'`
              ).then((results) => {
                setSelectedClass(results);
                setSetselectedClassToShow(results);
                results.forEach((r) =>
                  pupilnumberArr.push("'" + r.studentnumber + "'")
                );
                generalQuery(
                  `select max(${selectedSub}) from credithours where pupilnumber in (${pupilnumberArr})`
                ).then((results) => {
                  setMaxGrade(Number(results[0].max));
                });
              });
            }}
          >
            <option>Class</option>
            {classes.map((c) => {
              return (
                <option key={c} value={c}>
                  {c}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className="container">
        {selectedClass.length === 0 ? (
          // <CircularProgress />
          ""
        ) : (
          <div>
            <table>
              <thead>
                <tr>
                  <th>eSIS</th>
                  <th>Name</th>
                  <th>الاسم</th>
                  <th>Class</th>
                  <th>#Credit Hours</th>
                </tr>
              </thead>
              <tbody>
                {setselectedClassToShow.map((s) => {
                  return (
                    <tr key={s.studentnumber}>
                      <td>{s.studentnumber}</td>
                      <td>{s.englishname}</td>
                      <td>{s.username}</td>
                      <td>{s.esisclass}</td>
                      <td>
                        <CreditHoursPerStudent
                          attendanceTables={attendanceTables}
                          studentnumber={s.studentnumber}
                          selectedSub={selectedSub}
                          maxGrade={maxGrade}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditHoursTracker;
