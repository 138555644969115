import React, { useEffect, useState } from "react";
import generalQuery from "../generalQuery";
import "./blockedCardsRevealed.css";
const BlockedCardsReveald = () => {
  const [studentData, setStudentData] = useState([]);
  useEffect(() => {
    generalQuery(
      `select * from BlockedReportCards where esis='${sessionStorage.getItem(
        "esis"
      )}'AND marks IS NOT NULL`
    ).then((results) => {
      setStudentData(results);
    });
  }, []);
  return (
    <>
      {studentData.length > 0 ? (
        <div>
          <h2>Report Card for {studentData[0].period} </h2>
          <h2>{studentData[0].studentname}</h2>
          <p>eSIS: {studentData[0].esis}</p>
          <p>{studentData[0].homeroom}</p>
          <table className="tableblocked">
            <thead>
              <tr className="trblocked">
                <th className="thblocked">Subject</th>
                <th className="thblocked">Marks</th>
              </tr>
            </thead>
            <tbody>
              {studentData.map((s, index) => (s.marks!=='null'&&
                <tr className="trblocked" key={index}>
                  <td className="tdblocked">{s.subjectname}</td>
                  <td className="tdblocked">{s.marks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BlockedCardsReveald;
