import React from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";

const Backarrow = () => {
  return (
    <div>
      <div
        style={{ float: "left", cursor: "pointer", marginTop: "-200px" }}
        onClick={() => window.location.reload()}
      >
        <IoChevronBackCircleOutline size={60} />
      </div>
    </div>
  );
};

export default Backarrow;
