import React, { useState, useEffect } from "react";
import "./MarksEntry.css";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import reportHead from "../reportHead.png";
import signature from "../signature.png";
import stamp from "../stamp.png";
import { font } from "../font.js";
export default function FinalReporting() {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [homroom, sethomroom] = useState("");
  const [esisInput, setEsisInput] = useState("");
  const [marksArry, setMarksArry] = useState([]);
  let arr = [];
  const getReportDataByEsis = async (x, y) => {
    try {
      const body = { esis: x, report: y };
      const response = await fetch(
        "https://mut.herokuapp.com/getReportDataByEsis",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      arr.push(resp);
      console.log(resp);
      setMarksArry((marksArry) => [...marksArry, resp]);
      if (arr.length === 3) {
        generatePDF(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getClasses = async () => {
    console.log(sessionStorage.getItem("email"));
    try {
      const body = { teacherEmail: sessionStorage.getItem("email") };
      const response = await fetch(
        "https://mut.herokuapp.com/getClassesPerTeacher",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      let arr = [];
      ["m", "t", "w", "th", "f"].forEach((x) => {
        [1, 2, 3, 4, 5, 6].forEach((n) => {
          if (arr.indexOf(resp[x + n]) === -1 && resp[x + n] !== "free") {
            arr.push(resp[x + n]);
          }
        });
      });
      setClasses(arr.sort((a, b) => (a > b ? 1 : -1)));
    } catch (error) {
      console.log(error);
    }
  };
  
  const getClassStudents = async (x) => {
    let arr = [];
    setLoading(true);
    try {
      const body = { homroom: x };
      const response = await fetch(
        "https://mut.herokuapp.com/getClassStudents",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      console.log(resp);
      resp
        .filter((s) => s.reportelig !== "no")
        .forEach((e) => {
          arr.push({
            studentnumber: e.studentnumber,
            englishname: e.englishname,
            username: e.username,
          });
        });
      setList(arr);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // const makeUpArrayT3 = ["#VALUE!", "Cheated", "Absent", "A", "a", "absent"];
  const generatePDF = (x) => {
    var doc = new jsPDF();
    doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
    doc.addFont(
      "IBMPlexSansArabic-Medium-normal.ttf",
      "IBMPlexSansArabic-Medium",
      "normal"
    );
    doc.setFont("IBMPlexSansArabic-Medium"); // set font
    doc.setFontSize(15);
    var width = doc.internal.pageSize.getWidth();
    // var height = doc.internal.pageSize.getHeight();
    doc.addImage(reportHead, 5, 5, 200, 38);
    doc.setLineWidth(1);
    doc.line(5, 43, width - 5, 43);
    doc.line(5, 88, width - 5, 88);
    doc.line(5, 43, 5, 88);
    doc.line(width - 5, 43, width - 5, 88);
    // doc.line(5, 202, width - 5, 202);
    // doc.line(5, 215, width - 5, 215);
    // doc.line(5, 202, 5, 215);
    // doc.line(width - 5, 202, width - 5, 215);
    doc.line(5, 220, 5, 280);
    doc.line(width - 5, 220, width - 5, 280);
    doc.line(5, 220, width - 5, 220);
    doc.line(5, 280, width - 5, 280);

    doc.setFontSize(12);
    doc.text("Reporting Period: ", 7, 50);
    doc.text(":الفترة الاختبارية ", 178, 50);
    doc.text("Student Name: ", 7, 58);
    doc.text(":اسم الطالب", 183, 58);
    doc.text("Student ID: ", 7, 72);
    doc.text(":رقم الطالب  ", 183, 72);
    doc.text("Grade: ", 7, 80);
    doc.text(":الصف  ", 190, 80);

    doc.text("Date: ", 10, 230);
    doc.text(" التاريخ ", width - 10, 230, { align: "right" });
    doc.text("Approved / School Principal: ", 10, 240);
    doc.text(" يعتمد / مديرالمدرسة ", width - 10, 240, { align: "right" });
    doc.setFontSize(9);
    doc.text("End of Year ", 47, 50);
    doc.text("نهاية العام ", 150, 50);
    doc.text(x[0].name, 47, 58);
    doc.text(x[0].arname, 100, 62);
    doc.text(x[0].esis, 47, 72);
    doc.text(x[0].esis, 150, 72);
    doc.text(x[2].class, 100, 80);
    doc.text(new Date().toDateString(), 80, 230);
    doc.text("Hassan Al Yahri", 80, 250);
    doc.addImage(signature, 80, 253, 20, 20);
    doc.addImage(stamp, 100, 240, 30, 30);
    doc.line(5, 92, 5, 197);
    doc.line(width - 5, 92, width - 5, 197);
    doc.line(5, 92, width - 5, 92);
    doc.line(5, 197, width - 5, 197);
    doc.setLineWidth(0.1);
    // doc.line(63, 202, 63, 215);
    // doc.line(width - 63, 202, width - 63, 215);
    const column1 = [
      "Subject",
      "Islamic St",
      "Arabic",
      "Social Studies",
      "English",
      "Math",
      "Science",
    ];
    const column2 = [
      "المادة",
      "التربية الاسلامية",
      "اللغة العربية",
      "الاجتماعيات",
      "الانجليزية",
      "الرياضيات",
      "العلوم",
    ];
    const head1 = ["Minimum Mark", "Maximum Mark", "Obtained Mark "];
    const head2 = ["الحد الأدني", "الحد الأعلي", " الدرجة النهائية"];
    let isArray = [
      "50",
      "100",
      (
        (Number(x[0].isformative) +
          Number(x[0].issummative) +
          Number(x[1].isformative) +
          Number(x[1].issummative) +
          Number(x[2].isformative) +
          Number(x[2].issummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    let arabicArray = [
      "50",
      "100",
      (
        (Number(x[0].arabicformative) +
          Number(x[0].arabicsummative) +
          Number(x[1].arabicformative) +
          Number(x[1].arabicsummative) +
          Number(x[2].arabicformative) +
          Number(x[2].arabicsummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    let socialArray = [
      "50",
      "100",
      (
        (Number(x[0].ssformative) +
          Number(x[0].sssummative) +
          Number(x[1].ssformative) +
          Number(x[1].sssummative) +
          Number(x[2].ssformative) +
          Number(x[2].sssummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    let englishArray = [
      "50",
      "100",
      (
        (Number(x[0].engformative) +
          Number(x[0].engsummative) +
          Number(x[1].engformative) +
          Number(x[1].engsummative) +
          Number(x[2].engformative) +
          Number(x[2].engsummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    let mathArray = [
      "50",
      "100",
      (
        (Number(x[0].mathformative) +
          Number(x[0].mathsummative) +
          Number(x[1].mathformative) +
          Number(x[1].mathsummative) +
          Number(x[2].mathformative) +
          Number(x[2].mathsummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    let sciArray = [
      "50",
      "100",
      (
        (Number(x[0].sciformative) +
          Number(x[0].scisummative) +
          Number(x[1].sciformative) +
          Number(x[1].scisummative) +
          Number(x[2].sciformative) +
          Number(x[2].scisummative)) /
        6
      )
        .toFixed(0)
        .toString(),
    ];
    // if (x.column1 === "OK") {
    for (var i = 1; i < 8; ++i) {
      doc.line(5, 92 + i * 15, width - 5, 92 + i * 15);
      doc.setFontSize(12);
      doc.text(column1[i - 1], 9, 90 + i * 14);
      doc.text(column2[i - 1], 68, 90 + i * 14, { align: "right" });
    }
    for (var k = 1; k < 5; ++k) {
      doc.line(5 + k * 34, 92, 5 + k * 34, 197);
    }

    for (var k = 0; k < 2; ++k) {
      doc.setFontSize(8);
      doc.text(head1[k], 74.5 + k * 35, 100);
      doc.text(head2[k], 78 + k * 35, 103);
      doc.setFontSize(10);
      doc.text(isArray[k], 85 + k * 35, 115);
      doc.text(arabicArray[k], 85 + k * 35, 133);
      doc.text(socialArray[k], 85 + k * 35, 148);
      doc.text(englishArray[k], 85 + k * 35, 161);
      doc.text(mathArray[k], 85 + k * 35, 176);
      doc.text(sciArray[k], 85 + k * 35, 188);
    }
    doc.setFontSize(10);
    doc.text(head1[2], 74.5 + 2 * 35, 100);
    doc.text(head2[2], 74.5 + 3 * 35, 100);
    doc.setFontSize(15);
    if (isNaN(Number(isArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 115);
    } else if (Number(isArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 119);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(isArray[2], 85 + 2.5 * 35, 115);
    }
    if (isNaN(Number(arabicArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 133);
    } else if (Number(arabicArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 137);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(arabicArray[2], 85 + 2.5 * 35, 133);
    }

    if (isNaN(Number(socialArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 148);
    } else if (Number(socialArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 152);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(socialArray[2], 85 + 2.5 * 35, 148);
    }

    if (isNaN(Number(englishArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 161);
    } else if (Number(englishArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 165);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(englishArray[2], 85 + 2.5 * 35, 161);
    }

    if (isNaN(Number(mathArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 176);
    } else if (Number(mathArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 180);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(mathArray[2], 85 + 2.5 * 35, 176);
    }

    if (isNaN(Number(sciArray[2]))) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("Make-Up Test اختبار تعويضي", 85 + 2 * 35, 188);
    } else if (Number(sciArray[2]) < 50) {
      doc.setFontSize(8);
      doc.setTextColor(255, 0, 0);
      doc.text("(Resit Test دور ثان)", 85 + 2.2 * 35, 192);
    } else {
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(sciArray[2], 85 + 2.5 * 35, 188);
    }

    doc.setTextColor(0, 0, 0);
    doc.save(x[0].esis);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("sub") === "na" ||
      sessionStorage.getItem("sub") === "sen"
    ) {
      setClasses([
        "5A",
        "5B",
        "5C",
        "5D",
        "5E",
        "5F",
        "5G",
        "5H",
        "5I",
        "5J",
        "5L",
        "6A",
        "6B",
        "6C",
        "6D",
        "6E",
        "6F",
        "6G",
        "6H",
        "6I",
        "6J",
        "6K",
        "6L",
        "7A",
        "7B",
        "7C",
        "7D",
        "7E",
        "7F",
        "7G",
        "7H",
        "7I",
        "7J",
        "7K",
        "8A",
        "8B",
        "8C",
        "8D",
        "8E",
        "8F",
        "8G",
        "8H",
        "8I",
        "8J",
        "8K",
        "8L",
        "8M",
      ]);
    } else {
      getClasses();
    }
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <h1>Final Report التقرير النهائي</h1>
      </div>
      <div className="container">
        <input
          type="number"
          className="select"
          placeholder="eSIS"
          onChange={(e) => setEsisInput(e.target.value)}
        ></input>
        <Button
          variant="text"
          onClick={() => {
            arr = [];
            setMarksArry([]);
            ["reportst3", "reportst2final", "reports"].forEach((r) => {
              getReportDataByEsis(esisInput, r);
            });
          }}
        >
          Download
        </Button>
        <span style={{ fontSize: "30px", margin: "0 15px", color: "blue" }}>
          OR
        </span>
        <select
          id="selecthomeroom"
          className="select"
          onChange={(e) => {
            let home =
              "G" +
              e.target.value.slice(0, 1) +
              "-" +
              e.target.value.slice(1, 2);
            sethomroom(home);
            getClassStudents(home);
          }}
        >
          <option value="">Select a Class اختر صف</option>
          {classes.map((c) => {
            return (
              <option key={c} value={c}>
                {c}
              </option>
            );
          })}
        </select>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : list.length > 0 ? (
        <div className="container">
          <table className="coverageTable">
            <thead>
              <tr>
                <th>eSIS</th>
                <th>Name</th>
                <th>الاسم</th>
                <th>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      list.forEach((l) => {
                        setTimeout(() => {
                          document
                            .getElementById(`${l.studentnumber}`)
                            ?.click();
                        }, 1500);
                      });
                    }}
                  >
                    Download All
                  </Button> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((l) => {
                return (
                  <tr key={l.studentnumber}>
                    <td>{l.studentnumber}</td>
                    <td>{l.englishname}</td>
                    <td>{l.username}</td>
                    <td>
                      {/* <Button
                        id={l.studentnumber}
                        variant="text"
                        onClick={() => {
                          arr = [];
                          setMarksArry([]);
                          ["reports", "reportst2final", "reportst3"].forEach(
                            (r) => {
                              getReportDataByEsis(l.studentnumber, r);
                            }
                          );
                        }}
                      >
                        Download
                      </Button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
// engformative character varying(10) COLLATE pg_catalog."default",
// engsummative character varying(10) COLLATE pg_catalog."default",
// engattitudetolearning character varying(10) COLLATE pg_catalog."default",
// engbehaviour character varying(10) COLLATE pg_catalog."default",
