import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { listPdfgenerate } from "./listPdf";
const ClassLists = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [classes, setClasses] = useState([]);
  const [list, setList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const getClasses = async () => {
    setLoadingSelect(true);
    try {
      const response = await fetch("https://mut.herokuapp.com/getClasses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp = await response.json();
      setClasses(resp);
      setLoadingSelect(false);
    } catch (error) {
      console.log(error);
    }
  };
  const generalQuery = async (z) => {
    setLoading(true);
    try {
      const body = {
        idqueryString: z,
      };
      const response = await fetch("https://mut.herokuapp.com/general", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();

      setLoading(false);
      setList(resp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClasses();
  }, []);
  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <h3>Class Lists قوائم الطلاب</h3>
      <div style={{ display: "flex" }}>
        {loadingSelect ? (
          <CircularProgress />
        ) : (
          <select
            className="select"
            onChange={(e) => {
              setSelectedClass(e.target.value);
              if (e.target.value === "all") {
                generalQuery("select * from students order by homroom");
              } else {
                generalQuery(
                  `select * from students where homroom='${e.target.value}'`
                );
              }
            }}
          >
            <option value="">Class....</option>
            <option value="all">All....</option>
            {classes.map((c) => {
              return <option key={c.homroom}>{c.homroom}</option>;
            })}
          </select>
        )}
      </div>
      {loading ? (
        <CircularProgress />
      ) : list.length > 0 ? (
        <div>
          <div className="container">
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button btn"
              table="table-to-xls"
              filename={selectedClass}
              sheet="tablexls"
              buttonText="Download as XLS"
            />
            <button
              className="btn"
              onClick={() => {
                listPdfgenerate(list, "ar");
              }}
            >
              تنزيل ع
            </button>
            <button
              className="btn"
              onClick={() => {
                listPdfgenerate(list, "en");
              }}
            >
              PDF En
            </button>
          </div>
          {selectedClass === "all" ? (
            <div className="container">
              <div className="container">
                <table className="selected-dataset">
                  <tbody>
                    <tr>
                     
                      {Array.from(new Set(list.map((l) => l.grade_level)))
                        .sort()
                        .map((item) => {
                          return <th>{item}</th>;
                        })}
                        <th>Total</th>
                    </tr>
                    <tr>
                      {Array.from(new Set(list.map((l) => l.grade_level)))
                        .sort()
                        .map((item) => {
                          return (
                            <th>
                              {
                                list.filter((l) => l.grade_level === item)
                                  .length
                              }
                            </th>
                          );
                        })}
                        <th>{list.length}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
          <table
            id="table-to-xls"
            className="selected-dataset"
            style={{ marginLeft: "10px" }}
          >
            <thead>
              <tr >
                <th></th>
                <th>eSIS#</th>
                <th>English Name</th>
                <th>Arabic Name</th>
                <th>Class</th>
                <th>Mobile1</th>
                <th>Mobile2</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {list.map((l) => {
                return (
                  <tr key={l.studentnumber} style={{}}>
                    <td style={{ padding: "0px " }}>{list.indexOf(l) + 1}</td>
                    <td style={{ padding: "2px " }}>{l.studentnumber}</td>
                    <td style={{ padding: "2px " }}>{l.englishname}</td>
                    <td style={{ padding: "2px ",textAlign:"right" }}>{l.username}</td>
                    <td>{l.homroom}</td>
                    <td>
                      {l.father !== "null" &&
                        l.father !== "#ERROR_#N/A" &&
                        l.father}
                    </td>
                    <td style={{ color: "blue" }}>
                      {l.mother !== "null" &&
                        l.mother !== "#ERROR_#N/A" &&
                        l.mother}
                    </td>
                 
                    <td style={{ color: "red" }}>
                      {l.mobile !== "null" &&
                        l.mobile !== "#ERROR_#N/A" &&
                        l.mobile}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClassLists;
