import React, { useState } from "react";
import Attendancehistory from "./AttendanceHistory";
import Example from "./classLists/Toprint";
import "./cobra.css";
import FinalReporting from "./FinalReport";
import HomeroomTeachers from "./HomeroomTeachers";
import StaffList from "./StaffList";
import Sub2 from "./Sub2";
import TeacherMapReport from "./TeacherMapReport";

import TimetableNew from "./timetableNew";
const Cobra = () => {
  const [switcher, setSwitcher] = useState("");
  return (
    <div>
      <div className="container">
        <button className="select" onClick={() => setSwitcher("coverage")}>
          Coverage and Attendance
        </button>
        <button className="select" onClick={() => setSwitcher("timetable")}>
          Timetable
        </button>
        <button className="select" onClick={() => setSwitcher("FinalReports")}>
          Final Reports
        </button>
        <button className="select" onClick={() => setSwitcher("MAP")}>
          MAP
        </button>
        <button className="select" onClick={() => setSwitcher("attendance")}>
          Attendance
        </button>
        <button className="select" onClick={() => setSwitcher("Class Lists")}>
          Class Lists
        </button>
        <button
          className="select"
          onClick={() => setSwitcher("Homeroom Teachers")}
        >
          Homeroom Teachers
        </button>
        <button className="select" onClick={() => setSwitcher("Staff List")}>
          Staff List
        </button>
      </div>
      <div>
        {switcher === "coverage" ? (
          <Sub2 />
        ) : switcher === "timetable" ? (
          <TimetableNew />
        ) : switcher === "FinalReports" ? (
          <FinalReporting />
        ) : switcher === "MAP" ? (
          <TeacherMapReport />
        ) : switcher === "attendance" ? (
          <Attendancehistory />
        ) : switcher === "Class Lists" ? (
          <Example />
        ) : switcher === "Homeroom Teachers" ? (
          <HomeroomTeachers />
        ) : switcher === "Staff List" ? (
          <StaffList />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Cobra;
