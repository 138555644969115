import { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./attendancehistory.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dailyabsentstudentsreport from "./DailyAbsentStudentsReport";
import { FcPrint } from "react-icons/fc";
import GetStuAttendancePerDay from "./GetStuAttendancePerDay";
import generalQuery from "../generalQuery";
const Attendancehistory = () => {
  const [days, setDays] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [attData, setAttData] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [navStatus1, setNavStatus1] = useState("navAttactive");
  const [navStatus2, setNavStatus2] = useState("navAtt");
  const [navStatus3, setNavStatus3] = useState("navAtt");
  const [pupilnumber, setPupilnumber] = useState();
  const [showReport, setShowReport] = useState(false);
  const [loading, setLoading] = useState(false);

  function createPDF() {
    window.print(); // PRINT THE CONTENTS.
  }

  const getTableNameAttendance = async (table_name) => {
    try {
      const body = { table_name, selectedClass };
      const response = await fetch(
        "https://mut.herokuapp.com/getTableNameAttendance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      resp.forEach((e) => {
        if (e.p1 === "null") {
          e.p1 = 0;
        }
        if (e.p2 === "null") {
          e.p2 = 0;
        }
        if (e.p3 === "null") {
          e.p3 = 0;
        }
        if (e.p4 === "null") {
          e.p4 = 0;
        }
        if (e.p5 === "null") {
          e.p5 = 0;
        }
        if (e.p6 === "null") {
          e.p6 = 0;
        }
      });
      setAttData(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const getAttendanceNames = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getAttendanceNames",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      setDays(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const getClasses = async () => {
    generalQuery(
      "select distinct(homroom) from students order by homroom"
    ).then((results) => {
      setClasses(results);
    });
  };

  const attendanceStatusPerPeriod = (period) => {
    if (period === "1") {
      return "P";
    } else if (period === "0") {
      return "A";
    } else {
      return "L";
    }
  };
  function createPDF() {
    window.print(); // PRINT THE CONTENTS.
  }
  useEffect(() => {
    getClasses();
    getAttendanceNames();
  }, []);
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "35px",
        }}
        className="noPrint"
      >
        <div
          className={navStatus1}
          onClick={() => {
            setNavStatus1("navAttactive");
            setNavStatus2("navAtt");
            setNavStatus3("navAtt");
          }}
        >
          By Student
        </div>
        <div
          className={navStatus2}
          onClick={() => {
            setNavStatus2("navAttactive");
            setNavStatus1("navAtt");
            setNavStatus3("navAtt");
          }}
        >
          Class Attendance
        </div>
        <div
          className={navStatus3}
          onClick={() => {
            setNavStatus3("navAttactive");
            setNavStatus1("navAtt");
            setNavStatus2("navAtt");
          }}
        >
          Absent Students - Daily Report
        </div>
      </div>
      {navStatus2 === "navAttactive" ? (
        <div>
          <div className="selectClassDate">
            <select
              className="inputField"
              onChange={(e) => {
                setAttData([]);
                setSelectedDay("");
                setSelectedClass(e.target.value);
              }}
            >
              <option value="">Select Class...اختر الشعبة الصفية</option>
              {classes.map((c) => {
                return (
                  <option key={c.homroom} value={c.homroom}>
                    {c.homroom}
                  </option>
                );
              })}
            </select>
            <input
              className="inputField"
              type="date"
              value={selectedDay}
              onChange={(e) => {
                setAttData([]);
                getTableNameAttendance(
                  "zatt" + e.target.value.replace("-", "").replace("-", "")
                );
                setSelectedDay(e.target.value);
              }}
            />
          </div>
          {/* <span
            onClick={() => {
              document.getElementById("test-table-xls-button").click();
            }}
          >
            <SimCardDownloadIcon style={{ fontSize: "50px" }} />
          </span> */}
          {attData.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {selectedDay}
              <table id="table-to-xls" className="creatortable">
                <thead>
                  <tr>
                    <th>eSIS</th>
                    <th>Name</th>
                    <th>P1</th>
                    
                    <th>P2</th>
                    <th>P3</th>
                    <th>P4</th>
                    <th>P5</th>
                    <th>P6</th>

                    <th>Status</th>

                    <th>
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={selectedClass + "-" + selectedDay}
                        sheet={selectedClass + "-" + selectedDay}
                        buttonText="Download as XLS"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attData.map((d) => {
                    if (new Date(selectedDay).getDay !== 1) {
                      return (
                        <tr>
                          <td>{d.pupilnumber}</td>
                          <td>{d.studentname}</td>
                          {["p1", "p2", "p3", "p4", "p5", "p6"].map((p) => {
                            return (
                              <td
                                className={
                                  d[p] === "0.5"
                                    ? "L"
                                    : d[p] === "1"
                                    ? "P"
                                    : d[p + "conf"] !== "taken"
                                    ? "x"
                                    : "A_"
                                }
                              >
                                {attendanceStatusPerPeriod(d[p])}
                              </td>
                            );
                          })}

                          <td>
                            {d.p1 + d.p2 + d.p3 + d.p4 + d.p5 + d.p6 > 0 ? (
                              <>✓</>
                            ) : (
                              <>🚫</>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : navStatus1 === "navAttactive" ? (
        <>
          {!showReport ? (
            <>
              <input
                type="number"
                placeholder="eSIS#"
                className="inputField noPrint"
                onChange={(e) => {
                  setPupilnumber(e.target.value);
                }}
              />
              <button
                className="noPrint"
                onClick={() => {
                  setShowReport(true);
                }}
              >
                Show Report
              </button>
            </>
          ) : (
            ""
          )}

          <button
            onClick={() => createPDF()}
            style={{ float: "right" }}
            className="noPrint"
          >
            <FcPrint size={50} />
          </button>
          {showReport && (
            <GetStuAttendancePerDay
              pupilnumber={pupilnumber}
              days={days}
              loading={loading}
              settingShowReport={(v) => setShowReport(v)}
              settingLoading={(v) => {
                setLoading(v);
              }}
            />
          )}
        </>
      ) : (
        <div>
          <Dailyabsentstudentsreport />
        </div>
      )}
    </div>
  );
};

export default Attendancehistory;
