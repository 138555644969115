import React, { useState, useEffect } from "react";
import { keyCreator } from "../components/functions/keyCreator";
import Recordabsentstudents from "./RecordAbsentStudents";
import Registrar from "./Registrar";
import Subs2table from "./Subs2Table";
const Sub2 = () => {
  const [subweeks, setSubweeks] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [periodsArr, setPeriodsArr] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [freeTeachers, setFreeTeachers] = useState([]);
  const [arr, setArr] = useState([]);
  const [period1, setPeriod1] = useState([]);
  const [period2, setPeriod2] = useState([]);
  const [period3, setPeriod3] = useState([]);
  const [period4, setPeriod4] = useState([]);
  const [period5, setPeriod5] = useState([]);
  const [period6, setPeriod6] = useState([]);
  const [period7, setPeriod7] = useState([]);
  const [swaps, setSwaps] = useState(false);
  const [refresher, setRefresher] = useState();
  const [showCoverage, setShowCoverage] = useState(false);
  const [showIndividualAttendanceRatio, setShowIndividualAttendanceRatio] =
    useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [showMarks, setShowMarks] = useState(false);
  const selectWeeks = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/selectWeeks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();

      setSubweeks(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeachersSubs = async () => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getTeachersSubs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      setTeachers(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const getteacherClasses = async () => {
    try {
      const body = {
        periodsArr,
        teacher: selectedTeacher,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/getteacherClasses",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      setArr([
        resp[0].p1,
        resp[0].p2,
        resp[0].p3,
        resp[0].p4,
        resp[0].p5,
        resp[0].p6,
        resp[0].p7,
      ]);
      if (resp) {
        if (
          resp[0].p1 !== "free" &&
          period1.indexOf(resp[0].p1 + " - " + selectedTeacher) === -1
        ) {
          period1.push(resp[0].p1 + " - " + selectedTeacher);
        }
        if (
          resp[0].p2 !== "free" &&
          period2.indexOf(resp[0].p2 + " - " + selectedTeacher) === -1
        ) {
          period2.push(resp[0].p2 + selectedTeacher);
        }
        if (
          resp[0].p3 !== "free" &&
          period3.indexOf(resp[0].p3 + " - " + selectedTeacher) === -1
        ) {
          period3.push(resp[0].p3 + selectedTeacher);
        }
        if (
          resp[0].p4 !== "free" &&
          period4.indexOf(resp[0].p4 + " - " + selectedTeacher) === -1
        ) {
          period4.push(resp[0].p4 + selectedTeacher);
        }
        if (
          resp[0].p5 !== "free" &&
          period5.indexOf(resp[0].p5 + " - " + selectedTeacher) === -1
        ) {
          period5.push(resp[0].p5 + selectedTeacher);
        }
        if (
          resp[0].p6 !== "free" &&
          period6.indexOf(resp[0].p6 + " - " + selectedTeacher) === -1
        ) {
          period6.push(resp[0].p6 + selectedTeacher);
        }
        if (
          resp[0].p7 !== "free" &&
          period7.indexOf(resp[0].p7 + " - " + selectedTeacher) === -1
        ) {
          period7.push(resp[0].p7 + selectedTeacher);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const insertIntoWeeks = async () => {
    try {
      const body = {
        name:
          "subs" +
          new Date()
            .toISOString()
            .slice(0, 10)
            .replace("-", "")
            .replace("-", ""),
      };
      const response = await fetch(
        "https://mut.herokuapp.com/insertIntoWeeks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      selectWeeks();
    } catch (error) {
      console.log(error);
    }
  };
  const createSubsTable = async () => {
    try {
      const body = {
        tablename:
          "subs" +
          new Date()
            .toISOString()
            .slice(0, 10)
            .replace("-", "")
            .replace("-", ""),
      };

      const response = await fetch(
        "https://mut.herokuapp.com/createSubsTable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
      insertIntoWeeks();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    selectWeeks();
    getTeachersSubs();
  }, []);
  if (showIndividualAttendanceRatio) {
    return (
      <Recordabsentstudents
        settingShowIndividualAtt={(e) => setShowIndividualAttendanceRatio(e)}
      />
    );
  }
  // if (showMarks) {
  //   return (
  //     <Marksfollowup/>
  //   );
  // }
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {/* <button
            style={{ float: "left" }}
            onClick={() => setShowIndividualAttendanceRatio(true)}
          >
            Show Individual Attendance Ratios
          </button> */}
          {/* <button style={{ float: "left" }} onClick={() => setShowMarks(true)}>
            Marks Entry
          </button> */}
        </div>
      </div>

      <div>
        <Registrar
          refresher={refresher}
          settingrefresher={(v) => setRefresher(v)}
        />
      </div>

      <button
        onClick={() => {
          createSubsTable();
        }}
      >
        New Week
      </button>

      <select
        style={{ margin: "10px" }}
        onChange={(e) => {
          setShowTable(false);
          setSelectedTable(e.target.value);
        }}
      >
        <option>Week</option>
        {subweeks.map((s) => {
          return (
            <option value={s.name} key={s.name+keyCreator("week")}>
              {s.name}
            </option>
          );
        })}
      </select>
      <select
        style={{ margin: "10px  " }}
        onChange={(e) => {
          setShowTable(false);
          let arr = [
            e.target.value + "1",
            e.target.value + "2",
            e.target.value + "3",
            e.target.value + "4",
            e.target.value + "5",
            e.target.value + "6",
          ];

          setPeriodsArr(arr);
          setSelectedDay(e.target.value);
        }}
      >
        <option aria-label="None" value="">
          select day...
        </option>
        <option value="m">Monday</option>
        <option value="t">Tuesday</option>
        <option value="w">Wednesday</option>
        <option value="th">Thursday</option>
        <option value="f">Friday</option>
      </select>
      <select
        style={{ margin: "10px" }}
        onChange={(e) => {
          setShowTable(false);
          setSelectedTeacher(e.target.value);
          getteacherClasses();
        }}
      >
        <option>teachers</option>
        {teachers.map((s) => {
          return (
            <option key={s.teacher} value={s.teacher}>
              {s.teacher}
            </option>
          );
        })}
      </select>
      <button
        onClick={() => {
          setShowTable(true);
          setShowCoverage(false);
          setSwaps(false);
        }}
      >
        +
      </button>
      <button
        style={{ marginLeft: "10px" }}
        onClick={() => {
          setPeriod1([]);
          setPeriod2([]);
          setPeriod3([]);
          setPeriod4([]);
          setPeriod5([]);
          setPeriod6([]);
          setPeriod7([]);
          setShowCoverage(true);
        }}
      >
        Print Coverage
      </button>
      {showTable ? (
        <div style={{ display: "flex" }}>
          <Subs2table
            freeTeachers={freeTeachers}
            selectedTable={selectedTable}
            selectedDay={selectedDay}
            periodsArr={periodsArr}
            selectedTeacher={selectedTeacher}
            showCoverage={showCoverage}
            swaps={swaps}
            settingSwaps={(v) => {
              setSwaps(v);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sub2;
