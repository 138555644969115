import React, { useState, useEffect } from "react";
import "./GroupsPage.css";
import generalQuery from "../generalQuery";
import datesArr from "./intervTimetable";
const GroupsPage = () => {
  const [groupsData, setGroupsData] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [grades, setGrades] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [activeGroup, setActiveGroup] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  useEffect(() => {
    generalQuery(`select * from interventiongroups25`).then((results) => {
      if (results && results.length > 0) {
        // Sort the results by name, class, group, and cluster
        const sortedResults = results
          .filter((r) => r.teacher !== null)
          .sort((a, b) => {
            // If names are the same, compare class
            if (a.class < b.class) return -1;
            if (a.class > b.class) return 1;
            // Compare names
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            // If names and classes are the same, compare group
            if (a.group < b.group) return -1;
            if (a.group > b.group) return 1;

            // If names, classes, and groups are the same, compare cluster
            if (a.cluster < b.cluster) return -1;
            if (a.cluster > b.cluster) return 1;

            // If all are the same, return 0
            return 0;
          });

        // Group the sorted results
        const groupedData = groupBy(sortedResults, ["group", "group_level"]);
        setGroupsData(groupedData);

        // Extract unique and sorted grade values
        const uniqueGrades = [
          ...new Set(sortedResults.map((item) => item.grade)),
        ].sort((a, b) => a - b);
        setGrades(uniqueGrades);
        // Extract unique and sorted teacher values
        const uniqueTeachers = [
          ...new Set(sortedResults.map((item) => item.teacher)),
        ].sort((a, b) => a - b);
        setTeachers(uniqueTeachers);
        const uniqueSubjects = [
          ...new Set(sortedResults.map((item) => item.subject)),
        ].sort((a, b) => a - b);
        setSubjects(uniqueSubjects);
      }
    });
  }, []);
  const groupBy = (array, keys) => {
    return array.reduce((result, currentValue) => {
      const key = keys.map((k) => currentValue[k]).join("-");
      if (!result[key]) {
        // Filter the datesArr to include only the dates relevant to the current cluster (group)
        const filteredDates = datesArr
          .filter((dateObj) => dateObj.cluster === currentValue.cluster)
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        // Create the groupSchedule based on filtered and sorted dates
        const groupSchedule = filteredDates.map((dateObj, index) => ({
          session: (index + 1).toString(),
          date: dateObj.date,
          day: dateObj.day,
          status: "Planned", // You can dynamically set this status based on your logic
          action: "View",
        }));

        result[key] = {
          id: key,
          group: currentValue.group,
          group_level: currentValue.group_level,
          subject: currentValue.subject,
          teacher: currentValue.teacher || "Unknown",
          classroom: currentValue.room || "Unknown",
          level: currentValue.quartile,
          EOTSum: 0, // Sum of eot values for this group
          MAPSum: 0, // Sum of map_percentile values for this group
          count: 0, // Count of students in this group
          grade: currentValue.grade, // Store the grade for filtering
          students: [],
          groupSchedule: groupSchedule, // Use the dynamically generated groupSchedule
        };
      }

      // Incrementally sum the EOT and MAP values
      result[key].EOTSum += Number(currentValue.eot);
      result[key].MAPSum += Number(currentValue.map_percentile);
      result[key].count += 1;

      result[key].students.push({
        eSIS: currentValue["esis#"],
        name: currentValue.name,
        class: currentValue.class,
      });

      // After processing all students, calculate the average EOT and MAP
      result[key].EOT = Math.floor(result[key].EOTSum / result[key].count);
      result[key].MAP = Math.floor(result[key].MAPSum / result[key].count);

      return result;
    }, {});
  };

  const handleGroupClick = (groupKey) => {
    setActiveGroup(groupsData[groupKey]);
  };

  const handleGradeChange = (event) => {
    const selectedGrade = event.target.value;
    setSelectedGrade(selectedGrade);
    setSelectedTeacher("");
    setSelectedSubject("");

    // Filter groups by the selected grade
    const filtered = Object.keys(groupsData).filter((groupKey) => {
      return (
        groupsData[groupKey].grade === selectedGrade || selectedGrade === ""
      );
    });

    setFilteredGroups(filtered);
  };
  const handleTeacherChange = (event) => {
    const selectedTeacher = event.target.value;
    setSelectedTeacher(selectedTeacher);
    setSelectedGrade("");
    setSelectedSubject("");
    // Filter groups by the selected teacher
    const filtered = Object.keys(groupsData).filter((groupKey) => {
      return groupsData[groupKey].teacher === selectedTeacher;
    });

    setFilteredGroups(filtered);
  };
  const handleSubjectChange = (event) => {
    const selectedSubject = event.target.value;
    setSelectedSubject(selectedSubject);
    setSelectedGrade("");
    setSelectedTeacher("");
    // Filter groups by the selected teacher
    const filtered = Object.keys(groupsData).filter((groupKey) => {
      return groupsData[groupKey].subject === selectedSubject;
    });

    setFilteredGroups(filtered);
  };

  return (
    <>
      {" "}
      <h3>After School Intervention Groups (2:30 pm-4:30 pm)</h3>
      <div className="groups-page">
        <div className="left-column">
          <div className="filters">
            <div className="filter-by-grade">
              <p>Filter By Grade Level</p>
              <select
                className="select"
                value={selectedGrade}
                onChange={handleGradeChange}
              >
                <option value="">Select Grade Level</option>
                {grades.map((grade, index) => (
                  <option key={index} value={grade}>
                    Grade {grade}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-by-grade">
              <p>Filter By Teacher</p>
              <select
                className="select"
                value={selectedTeacher}
                onChange={handleTeacherChange}
              >
                <option value="">Select Teacher</option>
                {teachers
                  .filter((teacher) => teacher !== " ") // Remove empty values
                  .map((teacher, index) => (
                    <option key={index} value={teacher}>
                      {teacher}
                    </option>
                  ))}
              </select>
            </div>

            <div className="filter-by-grade">
              <p>Filter By Subject</p>
              <select
                className="select"
                value={selectedSubject}
                onChange={handleSubjectChange}
              >
                <option value="">Select Subject</option>
                {subjects
                  .filter((subject) => subject !== " ") // Remove empty values
                  .map((subject, index) => (
                    <option key={index} value={subject}>
                      {subject}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="groups-list">
            {filteredGroups.length > 0
              ? filteredGroups
                  .filter((g) => g.teacher !== "Unknown")
                  .map(
                    (groupKey) =>
                      groupsData[groupKey].group !== "" && (
                        <div
                          key={groupKey}
                          className={`group-box ${
                            activeGroup && activeGroup.id === groupKey
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleGroupClick(groupKey)}
                        >
                          <p>{groupsData[groupKey].group}</p>
                          <p> {groupsData[groupKey].teacher}</p>
                          <p> {groupsData[groupKey].subject}</p>
                          <p>Classroom: {groupsData[groupKey].classroom}</p>
                          <p>Grade: {groupsData[groupKey].grade}</p>
                          <p>Level: {groupsData[groupKey].group_level}</p>
                          <p>EoT: {groupsData[groupKey].EOT}</p>
                          <p>MAP %: {groupsData[groupKey].MAP}</p>
                        </div>
                      )
                  )
              : Object.keys(groupsData).map(
                  (groupKey) =>
                    groupsData[groupKey].group !== "" && (
                      <div
                        key={groupKey}
                        className={`group-box ${
                          activeGroup && activeGroup.id === groupKey
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleGroupClick(groupKey)}
                      >
                        <p>{groupsData[groupKey].group}</p>
                        <p> {groupsData[groupKey].teacher}</p>
                        <p>Classroom: {groupsData[groupKey].classroom}</p>
                        <p>Grade: {groupsData[groupKey].grade}</p>
                        <p>Level: {groupsData[groupKey].group_level}</p>
                        <p>EoT: {groupsData[groupKey].EOT}</p>
                        <p>MAP %: {groupsData[groupKey].MAP}</p>
                      </div>
                    )
                )}
          </div>
        </div>
        <div className="middle-column">
          {activeGroup ? (
            <div className="students-list">
              <table>
                <thead>
                  <tr>

                    <th>eSIS</th>
                    <th>Name</th>
                    {/* <th>Subject</th> */}
                    {/* <th>Teacher</th> */}
                    <th>Class</th>
                    <th>Room</th>
                  </tr>
                </thead>
                <tbody>
                  {activeGroup.students.map((student, index) => (
                    <tr key={index}>
                      <td>{student.eSIS}</td>
                      <td>{student.name}</td>
                      {/* <td>{activeGroup.subject}</td> */}
                      {/* <td>{activeGroup.teacher}</td> */}
                      <td>{student.class}</td>
                      <td>{activeGroup.classroom}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Select a group to see students.</p>
          )}
        </div>
        <div className="right-column">
          {activeGroup ? (
            <div className="group-schedule">
              <table>
                <thead>
                  <tr>
                    <th>Session #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {activeGroup.groupSchedule.map((session, index) => (
                    <tr key={index}>
                      <td>{session.session}</td>
                      <td
                        className={session.day === "Thursday" ? "red" : "blue"}
                      >
                        {session.day}-{session.date}
                      </td>
                      <td>{session.status}</td>
                      <td>{session.action}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Select a group to see schedule.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupsPage;
