import React, { useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
const TamblExamTimetable = ({ data }) => {
 
  function getDayOfWeekFromDDMMYYYY(dateString) {
    // Split the date string into [day, month, year]
    const parts = dateString.split("/");

    // Reformat the date to YYYY-MM-DD for JavaScript compatibility
    // Note: Months are 0-indexed in JavaScript Date, so subtract 1 from month
    const formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);

    // Return the day of the week
    return formattedDate.toLocaleDateString("en-US", { weekday: "long" });
  }

  const dayOfWeek = getDayOfWeekFromDDMMYYYY("31/01/2024"); // This will o
  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <div>
      <div className="container" >
        {data.length > 0 && (
          <>
       
            <table id="examtimetable">
              <thead>
                <tr>
                  <th>Day </th>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <>
                      {d[1] !== "null" && (
                        <tr key={d[0]}>
                          <th>
                            <>
                              {d[1] !== "null"
                                ? getDayOfWeekFromDDMMYYYY(d[1]) + " - " + d[1]
                                : ""}
                            </>
                          </th>
                          <td>{d[0]}</td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default TamblExamTimetable;
