import jsPDF from "jspdf";
import reportHead from "../reportHeadT12223.png";
import signature from "../signature.png";
import stamp from "../stamp.png";
// import reportTerm1CoverLetter from "../reportTerm1CoverLetter.png";
import conversion from "../conversion.png";
// import reportTerm1CoverLetter2 from "../reportTerm1CoverLetter2.png";
import { font } from "../font.js";
const endOfTermCard = (x, b, data, term) => {
  let termEn = "";
  let termAr = "";
  if (term === "t1") {
    termAr = " الفصل الأول ";
    termEn = "Term 1 ";
  } else if (term === "t2") {
    termAr = " الفصل الثاني ";
    termEn = "Term 2 ";
  } else if (term === "t3") {
    termAr = " الفصل الثالث ";
    termEn = "Term 3 ";
  }
  var doc = new jsPDF("", "", "", true);
  if (b === "individual") {
    doc.addImage(conversion, 5, 5, 200,80);
    doc.setFontSize(10);
    doc.text("Best Wishes", 100, 100);
    doc.setFontSize(15);
    doc.addPage();
    // doc.addImage(reportTerm1CoverLetter2, 0, 5, 220, 220);
    // doc.addPage();
  }
  doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
  doc.addFont(
    "IBMPlexSansArabic-Medium-normal.ttf",
    "IBMPlexSansArabic-Medium",
    "normal"
  );
  doc.setFont("IBMPlexSansArabic-Medium"); // set font

  const singleReport = (x) => {
    doc.setFontSize(15);
    var width = doc.internal.pageSize.getWidth();
    doc.addImage(reportHead, 5, 5, 200, 38);
    doc.setLineWidth(1);
    doc.line(5, 43, width - 5, 43);
    doc.setFillColor(0, 105, 184);
    doc.line(5, 88, width - 5, 88);
    doc.setTextColor(0, 0, 0);
    doc.line(5, 43, 5, 88);
    doc.line(width - 5, 43, width - 5, 88);

    doc.line(5, 220, 5, 280);
    doc.line(width - 5, 220, width - 5, 280);
    doc.line(5, 220, width - 5, 220);
    doc.line(5, 280, width - 5, 280);

    doc.setFontSize(12);
    doc.text("Reporting Period: ", 7, 50);
    doc.text(":الفترة الاختبارية ", 178, 50);
    doc.text("Student Name: ", 7, 58);
    doc.text(":اسم الطالب", 183, 58);
    doc.text("Student ID: ", 7, 72);
    doc.text(":رقم الطالب  ", 183, 72);
    doc.text("Grade: ", 7, 80);
    doc.text(":الصف  ", 190, 80);
    doc.setTextColor(0, 0, 0);
    doc.text("Date: ", 10, 230);
    doc.text(" التاريخ ", width - 10, 230, { align: "right" });
    doc.text("Approved / School Principal: ", 10, 240);
    doc.text(" يعتمد / مديرالمدرسة ", width - 10, 240, { align: "right" });
    doc.setFontSize(10);
    doc.text(termEn, 47, 50);
    doc.text(termAr, 150, 50);
    doc.text(x.name, 47, 58);
    doc.text(x.arabicname, 100, 62);
    doc.text(x.esis, 47, 72);
    doc.text(x.esis, 150, 72);
    doc.text(x.homeroom, 100, 80);
    doc.text(new Date().toDateString(), 80, 230);
    doc.text("Hassan Al Yahri", 80, 250);
    doc.addImage(signature, 80, 253, 20, 20);
    doc.addImage(stamp, 106, 246, 30, 30);
    doc.setLineWidth(0.1);

    if (x.block === "no") {
      if (x.homeroom.slice(0, 2) !== "G9") {
        doc.line(5, 92, 5, 197);
        doc.line(width - 5, 92, width - 5, 197);
        doc.line(70, 92, 70, 197);
        doc.line(108, 92, 108, 197);
        doc.line(108.5, 92, 108.5, 197);
        doc.line(170, 92, 170, 197);
      } else {
        doc.line(width - 5, 92, width - 5, 215);
        doc.line(5, 92, 5, 215);
        doc.line(5, 215, width - 5, 215);
        doc.line(70, 92, 70, 215);
        doc.line(108, 92, 108, 215);
        doc.line(108.5, 92, 108.5, 215);
        doc.line(170, 92, 170, 215);
        doc.setFillColor(182, 229, 248);
        doc.rect(5.5, 197, 64, 17.5, "F");
        doc.rect(109, 197, 60.5, 17.5, "F");
        doc.text("History", 9, 205);
        doc.text("التاريخ", 68, 205, { align: "right" });
        doc.text("Financial Literacy", 112.5, 205);
        doc.text("مبادئ مالية", 169, 205, { align: "right" });
        doc.setFontSize(12);
        if (x.eco === "a" || x.eco === "A") {
          doc.text("Abs", 186, 205);
        } else if (x.ecograde) {
          if (x.ecograde === "F") {
            doc.setTextColor(255, 0, 0);
          }
          doc.text(x.ecograde, 186, 205);
        } else {
          doc.text("", 186, 205);
        }
        if (x.gov === "a" || x.gov === "A") {
          doc.text("Abs", 88, 205);
        } else if (x.govgrade) {
          if (x.govgrade === "F") {
            doc.setTextColor(255, 0, 0);
          }
          doc.text(x.govgrade, 88, 205);
        } else {
          doc.text("", 88, 205);
        }
      }
      doc.setFillColor(0, 105, 184);
      doc.rect(5, 92, width - 10.1, 14.5, "F");
      const column1 = [
        "Subject",
        "Islamic Education",
        "Arabic Language",
        "Mathematics ",
        "English Language",
        "Science",
        "UAE Social Studies",
        "Subject",
        "ICT",
        "Moral Education",
        "Art",
        "Music",
        "Physical Ed",
        "Drama",
      ];
      const column2 = [
        "المادة",
        "التربية الاسلامية",
        "اللغة العربية",
        "الرياضيات",
        "الانجليزية",
        "العلوم",
        "الاجتماعيات",
        "المادة",
        "تكنولوجبا المعلومات و التواصل",
        "التربية الأخلاقية",
        "الفن",
        "الموسيقي",
        "التربية الرياضية",
        "الدراما",
      ];
      console.log(x)
      const values = [
        "Grade",
        x.islamic === "a" || (x.islamic === "A" && x.islamicgrade !== "Ex")
          ? "Abs"
          : x.islamicgrade
          ? x.islamicgrade
          : "",
        x.arabicreading === "a" ||
        (x.arabicreading === "A" && x.arabicgrade !== "Ex")
          ? "Abs"
          : x.arabicwriting === "a" || x.arabicwriting === "A"
          ? "Abs"
          : x.arabicreading === "a" ||
            (x.arabicreading === "A" && x.arabicgrade === "Ex")
          ? "Abs"
          : x.arabicgrade
          ? x.arabicgrade
          : "",
        x.math === "a" || (x.math === "A" && x.mathgrade !== "Ex")
          ? "Abs"
          : x.mathgrade,
        x.englishreading === "a" ||
        (x.englishreading && (x.englishgrade !== "Ex") === "A")
          ? "Abs in Reading"
          : x.englishwriting === "a" ||
            (x.englishwriting && (x.englishrade !== "Ex") === "A")
          ? "Abs in Writing"
          : x.englishreading === "a" &&
            x.englishreading === "A" &&
            x.englishgrade !== "Ex"
          ? "Abs"
          : x.englishgrade
          ? x.englishgrade
          : "",
        x.science === "a" || (x.science === "A" && x.sciencegrade !== "Ex")
          ? "Abs"
          : x.sciencegrade
          ? x.sciencegrade
          : "",
        x.ss === "a" || (x.ss === "A" && x.ssgrade !== "Ex")
          ? "Abs"
          : x.ssgrade
          ? x.ssgrade
          : "",
        "Grade",
        x.compgrade ? x.compgrade : "",
        x.ss === "a" || (x.ss === "A" && x.ssgrade !== "Ex")
          ? "Abs"
          : x.ssgrade
          ? x.ssgrade
          : "",
        x.artgrade ? x.artgrade : x.arttotal === "NaN" ? "" : "",
        x.musicgrade && x.musicgrade !== "NULL"
          ? x.musicgrade
          : x.musictotal === "NaN"
          ? ""
          : "",
        x.pegrade ? x.pegrade : "",
        x.dramagrade ? x.dramagrade : "",
      ];
      for (var i = 1; i < 8; ++i) {
        if (i < 7) {
          doc.setFillColor(182, 229, 248);
          if (b === "individual") {
            doc.rect(5.5, 92 + i * 15, 64, 14.5, "F");
          }
        }
        if (i > 1) {
          doc.setTextColor(0, 0, 0);
        } else {
          doc.setTextColor(255, 255, 255);
        }
        doc.setFontSize(10);
        doc.line(5, 92 + i * 15, width - 5, 92 + i * 15);
        doc.text(column1[i - 1], 9, 90 + i * 14);
        doc.text(column2[i - 1], 68, 90 + i * 14, { align: "right" });
        if (i !== 1) {
          doc.setFontSize(12);
        }
        if (values[i - 1] === "F") {
          doc.setTextColor(255, 0, 0);
        }
        if (values[i - 1] && values[i - 1] !== "null") {
          doc.text(values[i - 1], 88, 90 + i * 14);
        }
      }

      for (var i = 8; i < 15; ++i) {
        if (i < 14) {
          doc.setFillColor(182, 229, 248);
          if (b === "individual") {
            doc.rect(108.6, 77.2 + (i - 6) * 15, 61, 14.3, "F");
          }
        }
        if (i === 8) {
          doc.setTextColor(255, 255, 255);
        } else {
          doc.setTextColor(0, 0, 0);
        }
        doc.setFontSize(10);
        doc.text(column1[i - 1], 112, 90 + (i - 7) * 14);
        doc.text(column2[i - 1], 169, 90 + (i - 7) * 14, { align: "right" });
        if (i !== 8) {
          doc.setFontSize(12);
        }
        if (values[i - 1] === "F") {
          doc.setTextColor(255, 0, 0);
        }
        if (values[i - 1] && values[i - 1] !== "null") {
          doc.text(values[i - 1], 186, 90 + (i - 7) * 14);
        }
      }
    } else {
      doc.text(
        // "Please contact the school in the beginning of next term!",
        "Please contact the school!",
        70,
        150
      );
      doc.text(
        // "يرجي التواصل مع ادارة المدرسة في بداية الفصل القادم",
        "يرجي التواصل مع ادارة المدرسة",
        200,
        190,
        {
          align: "right",
        }
      );
      doc.text(
        "تم حجب الشهادة بسبب عدم تحديث كل أو أحد البيانات التالية: ",
        200,
        170,
        { align: "right" }
      );
      doc.text("السكن - هوية الأم-هوية الأب - هوية الطالب", 200, 180, {
        align: "right",
      });
    }
  };
  if (b === "onefile") {
    data
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .forEach((e) => {
        singleReport(e);
        if (data.indexOf(e) + 1 !== data.length) {
          doc.addPage();
        }
      });
    doc.save(data[0].homeroom);
  } else {
    singleReport(x);
    // doc.text("نظرا لخلل فني تتم حاليا مراجعة نظام اصدار التقارير !", 150, 90, {
    //   align: "right",
    // });
    // doc.text(" نأسف عن الإزعاج وكل التوفيق لأبنائنا الطلاب ", 150, 100, {
    //   align: "right",
    // });
    doc.save(x.esis);
  }

  doc.setFontSize(15);
};

export default endOfTermCard;
